import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Layout, SuccessOrErrorState, Banners } from "../../App";
import { config } from "../../../constants";
import {
  fetchTemplates,
  InitialMessageState,
  toggleMessagingModal,
} from "../../../domains/Messages";
import { DescriptionPanel } from "./DescriptionPanel";
import { TemplatesTable } from "./TemplatesTable";
import { CreateTemplateForm } from "./CreateTemplate";

export const ViewTemplatesPage: FC = () => {
  const dispatch = useDispatch();
  const { messageLoaders, templates, templatesPagination } = useSelector(
    (state: { message: InitialMessageState }) => state.message
  );

  useEffect(() => {
    const fetchTemplatesAsync = () => {
      dispatch(fetchTemplates());
    };
    fetchTemplatesAsync();
  }, [dispatch]);

  const handlePagination = (page: number) => {
    dispatch(
      fetchTemplates({
        page: page - 1,
        size: templatesPagination.size,
      })
    );
  };

  const handleCreateTemplate = async () =>
    await dispatch(
      toggleMessagingModal({
        addTemplates: true,
      })
    );

  return (
    <Layout>
      <div>
        <Banners
          withAction
          actionLabel="Create Template"
          onClick={handleCreateTemplate}
        >
          <p className="pl-3">Do you want to create a template?</p>
        </Banners>
      </div>
      <CreateTemplateForm />
      <div className="pt-6">
        {_.isEmpty(templates) && !messageLoaders.fetchSmsRequests ? (
          <SuccessOrErrorState
            withAction
            state="empty"
            message="No Templates"
            actionLabel="Create Template"
            onClick={handleCreateTemplate}
          />
        ) : (
          <TemplatesTable
            columns={config.messages.templates.listColumns}
            pagination={templatesPagination}
            data={templates}
            loading={messageLoaders.fetchTemplates}
            onPaginationChange={handlePagination}
            SubComponent={(value: any) => {
              return (
                <>
                  <div className="w-full pt-2 pb-4 px-4">
                    <DescriptionPanel
                      label="Body"
                      description={value?.row?.original?.body}
                    />
                  </div>
                </>
              );
            }}
          />
        )}
      </div>
    </Layout>
  );
};
