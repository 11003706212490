import { joiResolver } from "@hookform/resolvers/joi";
import { FC } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { schemaValidation } from "../../../constants";
import { openNotification } from "../../../domains/App";
import {
  createResellerDfsGateway,
  InitialResellerDfsGatewayState,
  toggleResellerDfsGatewayModal,
} from "../../../domains/Reseller/ResellerDfsGateway";
import { Modal } from "../../App";
import { Select } from "../../App/FormComponents";

interface ResellerDfsGateway {
  id: string;
}
interface ResellerDfsGatewayProps {
  paymentGateWays: any;
}


export const ResellerDfsGatewayForm: FC<ResellerDfsGatewayProps> = ({paymentGateWays}) => {
  const dispatch = useDispatch();
  const {
    resellerDfsGatewayModals,
    resellerDfsGatewaysLoaders,
  } = useSelector(
    (state: { resellerDfsGateway: InitialResellerDfsGatewayState }) =>
      state.resellerDfsGateway
  );

  const { dfsId = "" } = useParams<{ dfsId?: string }>();

  const { control, handleSubmit, reset } = useForm<ResellerDfsGateway>({
    resolver: joiResolver(schemaValidation.ResellerDfsGatewaySchema),
    defaultValues: {
      id: "",
    },
  });
  // const paymentGatewayOptions = paymentGateways.map((f: any) => ({
  //   label: f.name,
  //   value: f.id,
  // }));

  const onClose = async () => {
    reset();
    await dispatch(
      toggleResellerDfsGatewayModal({
        addResellerDfsGateway: false,
      })
    );
  };

  const onSubmit: SubmitHandler<ResellerDfsGateway> = async (props) => {
    try {
      await dispatch(createResellerDfsGateway(dfsId, props));
      await onClose();
      await dispatch(
        openNotification({
          openNotification: true,
          type: "success",
          message: "Reseller Product Gateway added!",
        })
      );
    } catch (e) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Something went wrong",
        })
      );
    }
  };
  return (
    <Modal
      formId="reseller_dfs_gateway_form"
      btnType="submit"
      modalTitle="Add a Reseller Product Gateway"
      isOpen={resellerDfsGatewayModals.addResellerDfsGateway}
      onClose={onClose}
      btnLoading={resellerDfsGatewaysLoaders.submit}
    >
      {
        paymentGateWays.length > 0 &&
            <p>{paymentGateWays[0].name}</p>
      }
      <form
        id="reseller_dfs_gateway_form"
        className="space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="id"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Select
              id="id"
              label="Payment Gateway"
              selectedOption={value}
              error={error?.message}
              onChange={onChange}
              options={[
                {
                  value: "no_selection",
                  label: "No selection",
                  unavailable: true,
                },
                ...paymentGateWays,
              ]}
            />
          )}
        />
      </form>
    </Modal>
  );
};
