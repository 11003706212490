import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { NavigationProps } from "../../domains/App";
import { matchSidebarPaths } from "../../utils";

interface SidebarProps {
  navigation: NavigationProps[];
  iconMapping: Record<
    string,
    FC<{
      className: string;
    }>
  >;
}

export const Sidebar: FC<SidebarProps> = ({ navigation, iconMapping }) => {
  const { pathname } = useLocation();
  return (
    <div className="hidden md:flex md:flex-shrink-0">
      <div className="flex flex-col w-56">
        <div className="flex flex-col flex-grow border-r border-gray-200 pb-4 bg-tandaPurple overflow-y-auto">
          <div className="flex items-center flex-shrink-0 px-4">
            <img
              className="h-20 w-auto m-auto"
              src="/images/logo-lg.png"
              alt="Workflow"
            />
          </div>
          <div className="pt-5 flex-grow flex flex-col">
            <nav className="flex-1 px-2 bg-tandaPurple space-y-1">
              {navigation.map((navigate) => {
                const pathMatches = matchSidebarPaths(pathname, navigate.path);
                const pathIncludes = pathname.includes(navigate.path);
                const Icon = iconMapping[navigate.icon];
                if (navigate.children) {
                  return (
                    <Disclosure
                      as="div"
                      key={navigate.label}
                      className="space-y-1"
                    >
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className={classNames(
                              "group flex items-center px-2 py-2 text-sm font-medium rounded-md w-full",
                              {
                                "bg-tandaPurple2 text-white": pathIncludes,
                                "text-gray-300 hover:bg-gray-800 hover:text-white":
                                  !pathIncludes,
                              }
                            )}
                          >
                            <Icon
                              className={classNames(
                                "mr-3 flex-shrink-0 h-6 w-6",
                                {
                                  "text-gray-500": !pathIncludes,
                                  "text-gray-300 group-hover:text-gray-400":
                                    pathIncludes,
                                }
                              )}
                              aria-hidden="true"
                            />
                            {navigate.label}
                            {open ? (
                              <ChevronUpIcon
                                className={classNames(
                                  "ml-auto flex-shrink-0 h-4 w-4",
                                  {
                                    "text-gray-500": !pathIncludes,
                                    "text-gray-300 group-hover:text-gray-400":
                                      pathIncludes,
                                  }
                                )}
                              />
                            ) : (
                              <ChevronDownIcon
                                className={classNames(
                                  "ml-auto flex-shrink-0 h-4 w-4",
                                  {
                                    "text-gray-500": !pathIncludes,
                                    "text-gray-300 group-hover:text-gray-400":
                                      pathIncludes,
                                  }
                                )}
                              />
                            )}
                          </Disclosure.Button>
                          <Disclosure.Panel className="space-y-1">
                            {navigate.children?.map((item) => {
                              const pathMatches = matchSidebarPaths(
                                pathname,
                                item.path
                              );
                              return (
                                <Link
                                  key={item.label}
                                  to={item.path}
                                  className={classNames(
                                    "group flex items-center pl-11 pr-2 py-2 text-sm font-medium rounded-md",
                                    {
                                      "bg-tandaPurple2 text-white": pathMatches,
                                      "text-gray-300 hover:bg-gray-800 hover:text-white":
                                        !pathMatches,
                                    }
                                  )}
                                >
                                  {item.label}
                                </Link>
                              );
                            })}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  );
                }
                return (
                  <Link
                    key={navigate.label}
                    to={navigate.path}
                    className={classNames(
                      "group flex items-center px-2 py-2 text-sm font-medium rounded-md",
                      {
                        "bg-gray-900 text-white": pathMatches,
                        "text-gray-300 hover:bg-gray-700 hover:text-white":
                          !pathMatches,
                      }
                    )}
                  >
                    <Icon
                      className={classNames("mr-3 flex-shrink-0 h-6 w-6", {
                        "text-gray-500": pathMatches,
                        "text-gray-400 group-hover:text-gray-500": !pathMatches,
                      })}
                      aria-hidden="true"
                    />
                    {navigate.label}
                  </Link>
                );
              })}
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};
