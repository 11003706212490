import { createReducer } from "@reduxjs/toolkit";
import _ from "lodash";
import { PaginationProps } from "../App";
import { Types } from "./actionTypes";

export interface InitialCustomerTransactionFeeRateState {
  cache: Record<string, any>;
  customerTransactionFees: any[];
  selectedCustomerTransactionFeeRate: any;
  customerTransactionFeeRatesPagination: PaginationProps;
  customerTransactionFeeRateModals: any;
  customerTransactionFeeRatesLoaders: any;
}

const initialState: InitialCustomerTransactionFeeRateState = {
  customerTransactionFees: [],
  customerTransactionFeeRatesPagination: {
    number: 0,
    totalPages: 1,
    size: 10,
    totalElements: 0,
  },
  selectedCustomerTransactionFeeRate: {},
  customerTransactionFeeRatesLoaders: {
    submit: false,
    fetchCustomerTransactionFeeRates: false,
  },
  customerTransactionFeeRateModals: {
    addCustomerTransactionFeeRate: false,
    editCustomerTransactionFeeRate: false,
    deleteCustomerTransactionFeeRate: false,
    disableCustomerTransactionFeeRate: false,
  },
  cache: {},
};

export const customerTransactionFeeRatesReducer = createReducer(initialState, {
  [Types.TOGGLE_CUSTOMERTRANSACTIONFEERATE_MODALS]: (state, action) => {
    state.customerTransactionFeeRateModals = {
      ...state.customerTransactionFeeRateModals,
      ...action.payload,
    };
  },

  [Types.FETCH_CUSTOMERTRANSACTIONFEERATES]: (state) => {
    state.customerTransactionFeeRatesLoaders.fetchCustomerTransactionFeeRates =
      true;
  },
  [Types.FETCH_CUSTOMERTRANSACTIONFEERATES_SUCCESS]: (state, action) => {
    const {
      data: { content, page },
    } = action.payload;
    state.customerTransactionFeeRatesLoaders.fetchCustomerTransactionFeeRates =
      false;
    state.customerTransactionFees = content;
    state.customerTransactionFeeRatesPagination = {
      number: page.number,
      size: page.size,
      totalPages: page.totalPages,
      totalElements: page.totalElements,
    };
  },
  [Types.FETCH_CUSTOMERTRANSACTIONFEERATES_FAIL]: (state) => {
    state.customerTransactionFeeRatesLoaders.fetchCustomerTransactionFeeRates =
      false;
  },

  [Types.CREATE_CUSTOMERTRANSACTIONFEERATE]: (state) => {
    state.customerTransactionFeeRatesLoaders.submit = true;
  },
  [Types.CREATE_CUSTOMERTRANSACTIONFEERATE_SUCCESS]: (state, action) => {
    state.customerTransactionFeeRatesLoaders.submit = false;
    state.customerTransactionFees = [
      action.payload.data,
      ...state.customerTransactionFees,
    ];
  },
  [Types.CREATE_CUSTOMERTRANSACTIONFEERATE_FAIL]: (state) => {
    state.customerTransactionFeeRatesLoaders.submit = false;
  },

  [Types.DELETE_CUSTOMERTRANSACTIONFEERATE]: (state) => {
    state.customerTransactionFeeRatesLoaders.submit = true;
  },
  [Types.DELETE_CUSTOMERTRANSACTIONFEERATE_SUCCESS]: (state, action) => {
    const {
      previousAction: {
        payload: {
          request: {
            deleteProp: { id },
          },
        },
      },
    } = action.meta;
    state.customerTransactionFees = [
      ...state.customerTransactionFees.filter(
        (customerTransactionFeeRate) => customerTransactionFeeRate.id !== id
      ),
    ];
    if (_.isEmpty(state.customerTransactionFees)) {
      state.customerTransactionFeeRatesPagination.number = 0;
    }
    state.customerTransactionFeeRatesLoaders.submit = false;
  },
  [Types.DELETE_CUSTOMERTRANSACTIONFEERATE_FAIL]: (state) => {
    state.customerTransactionFeeRatesLoaders.submit = false;
  },

  [Types.UPDATE_CUSTOMERTRANSACTIONFEERATE]: (state) => {
    state.customerTransactionFeeRatesLoaders.submit = true;
  },
  [Types.UPDATE_CUSTOMERTRANSACTIONFEERATE_SUCCESS]: (state, action) => {
    const {
      previousAction: {
        payload: {
          request: {
            customerTransactionFeeRate: { id },
            customerTransactionFeeRate,
          },
        },
      },
    } = action.meta;
    state.customerTransactionFeeRatesLoaders.submit = false;
    state.customerTransactionFees = [
      ...state.customerTransactionFees.map((customerTransactionFees) =>
        customerTransactionFees.id === id
          ? customerTransactionFeeRate
          : customerTransactionFees
      ),
    ];
  },
  [Types.UPDATE_CUSTOMERTRANSACTIONFEERATE_FAIL]: (state) => {
    state.customerTransactionFeeRatesLoaders.submit = false;
  },

  [Types.CHANGE_CUST_STATUS]: (state) => {
    state.customerTransactionFeeRatesLoaders.submit = true;
  },

  [Types.CHANGE_CUST_STATUS_SUCCESS]: (state, action) => {
    const {
      previousAction: {
        payload: {
          request: {
            selectedCustomerTransactionFeeRate: { id },
            selectedCustomerTransactionFeeRate,
          },
        },
      },
    } = action.meta;
    state.customerTransactionFeeRatesLoaders.submit = false;
    state.customerTransactionFees = [
      ...state.customerTransactionFees.map((customerTransactionFee) =>
        customerTransactionFee.id === id
          ? selectedCustomerTransactionFeeRate
          : customerTransactionFee
      ),
    ];
  },
  [Types.CHANGE_CUST_STATUS_FAIL]: (state) => {
    state.customerTransactionFeeRatesLoaders.submit = false;
  },
});
