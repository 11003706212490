import { CreditCardIcon, UserIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { useState } from "react";
import { Info } from ".";
import { Layout } from "../../App";
import { ResellerTabs } from "../ResellerTabs";
import { ApiClients } from "./ApiClients";

export const ResellerInfoPage = () => {
  const [selectedTab, setSelectedTab] = useState<string>("details");

  const handleTabClick = () => {
    setSelectedTab(selectedTab === "details" ? "app" : "details");
  };
  return (
    <Layout>
      <ResellerTabs />
      <div className="flex bg-white rounded mt-4 divide-x">
        <div id="tab" className="tabWrap flex flex-col divide-y">
          <div>
            <div
              onClick={handleTabClick}
              className={classNames(
                "tab tab-label py-4 mx-6",
                selectedTab === "details" ? "active" : ""
              )}
            >
              <div className="flex cursor-pointer">
                <div className="center-contents">
                  <UserIcon className="h-4 w-4" />
                </div>
                <div className="ml-3 center-contents">Details</div>
              </div>
            </div>
          </div>
          <div>
            <div
              onClick={handleTabClick}
              className={classNames(
                "tab tab-label py-4 mx-6",
                selectedTab === "apps" ? "active" : ""
              )}
            >
              <div className="flex cursor-pointer">
                <div className="center-contents">
                  <CreditCardIcon className="h-4 w-4" />
                </div>
                <div className="ml-3 center-contents">Apps</div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full p-6">
          {selectedTab === "details" && <Info />}
          {selectedTab === "app" && <ApiClients />}
        </div>
      </div>
    </Layout>
  );
};
