import { createReducer } from "@reduxjs/toolkit";
import { Types } from "./actionTypes";
import { PaginationProps } from "../App";

export interface InitialIOWalletState {
  cache: Record<string, any>;
  iowallets: any[];
  ioTransactions: any[];
  iowalletsPagination: PaginationProps;
  iowalletModals: any;
  iowalletsLoaders: any;
  selectedIOWallet: any;
}

const initialState: InitialIOWalletState = {
  iowallets: [],
  ioTransactions: [],
  iowalletsPagination: {
    number: 0,
    totalPages: 1,
    size: 10,
    totalElements: 0,
  },

  iowalletModals: {
    addIOWallet: false,
    editIOWallet: false,
    deleteIOWallet: false,
    disableIOWallet: false,
    disableEnableIOWallet: false,
  },
  iowalletsLoaders: {
    submit: false,
    fetchIOWallets: false,
    fetchTrasactionFees: false,
    fetchTransaction:false,
  },

  cache: {
    emailRequests: {},
    smsRequests: {},
    inAppRequests: {},
  },
  selectedIOWallet: {},
};

export const iowalletsReducer = createReducer(initialState, {
  [Types.UPDATE_SELECTED_IOWALLET]: (state, action) => {
    state.selectedIOWallet = action.payload;
  },
  [Types.TOGGLE_IOWALLET_MODALS]: (state, action) => {
    state.iowalletModals = {
      ...state.iowalletModals,
      ...action.payload,
    };
  },

  [Types.FETCH_IOWALLETS]: (state) => {
    state.iowalletsLoaders.fetchIOWallets = true;
  },
  [Types.FETCH_IOWALLETS_SUCCESS]: (state, action) => {
    const {
      data: { content, page },
    } = action.payload;
    state.iowalletsLoaders.fetchIOWallets = false;
    state.iowallets = content;
    state.iowalletsPagination = {
      number: page.number,
      size: page.size,
      totalPages: page.totalPages,
      totalElements: page.totalElements,
    };
  },
  [Types.FETCH_IOWALLETS_FAIL]: (state) => {
    state.iowalletsLoaders.fetchIOWallets = false;
  },

  [Types.FETCH_TRANSACTION]: (state) => {
    state.iowalletsLoaders.fetchTransaction = true;
  },
  [Types.FETCH_TRANSACTION_SUCCESS]: (state, action) => {
    const {
      data: { content, page },
    } = action.payload;
    state.iowalletsLoaders.fetchTransaction = false;
    state.ioTransactions = content;
    state.iowalletsPagination = {
      number: page.number,
      size: page.size,
      totalPages: page.totalPages,
      totalElements: page.totalElements,
    };
  },
  [Types.FETCH_TRANSACTION_FAIL]: (state) => {
    state.iowalletsLoaders.fetchTransaction = false;
  },

  [Types.CREATE_IOWALLET]: (state) => {
    state.iowalletsLoaders.submit = true;
  },
  [Types.CREATE_IOWALLET_SUCCESS]: (state, action) => {
    state.iowalletsLoaders.submit = false;
    state.iowallets = [action.payload.data, ...state.iowallets];
  },
  [Types.CREATE_IOWALLET_FAIL]: (state) => {
    state.iowalletsLoaders.submit = false;
  },

  [Types.UPDATE_IOWALLET]: (state) => {
    state.iowalletsLoaders.submit = true;
  },

  [Types.UPDATE_IOWALLET_SUCCESS]: (state, action) => {
    const {
      previousAction: {
        payload: {
          request: {
            iowallet: { id },
            iowallet,
          },
        },
      },
    } = action.meta;
    state.iowalletsLoaders.submit = false;
    state.iowallets = [
      ...state.iowallets.map((pr) => (pr.id === id ? iowallet : pr)),
    ];
  },
  [Types.UPDATE_IOWALLET_FAIL]: (state) => {
    state.iowalletsLoaders.submit = false;
  },

  [Types.CHANGE_IOWALLET_STATUS]: (state) => {
    state.iowalletsLoaders.submit = true;
  },

  [Types.CHANGE_IOWALLET_STATUS_SUCCESS]: (state, action) => {
    const {
      previousAction: {
        payload: {
          request: {
            selectedIOWallet: { id },
            selectedIOWallet,
          },
        },
      },
    } = action.meta;
    state.iowalletsLoaders.submit = false;
    state.iowallets = [
      ...state.iowallets.map((iowallet) =>
        iowallet.id === id ? selectedIOWallet : iowallet
      ),
    ];
  },
  [Types.CHANGE_IOWALLET_STATUS_FAIL]: (state) => {
    state.iowalletsLoaders.submit = false;
  },
});
