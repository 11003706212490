import _ from "lodash";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { config } from "../../../constants";
import {
  fetchIOWallets,
  InitialIOWalletState,
  toggleIOWalletModal,
} from "../../../domains/IOWallets";
import { Banners, Layout, SuccessOrErrorState } from "../../App";
import { IOWalletForm, IOWalletTable } from "../IOWallet";

export const IOWalletPage: FC = () => {
  const dispatch = useDispatch();
  const { iowallets, iowalletsLoaders, iowalletsPagination } = useSelector(
    (state: { iowallet: InitialIOWalletState }) => state.iowallet
  );

  useEffect(() => {
    const fetchIOWalletsAsync = async () =>
      await dispatch(fetchIOWallets({ size: 1000 }));

    fetchIOWalletsAsync();
  }, [dispatch]);

  const handlePagination = (page: number) => {
    dispatch(
      fetchIOWallets({
        page: page - 1,
        size: iowalletsPagination.size,
      })
    );
  };

  const handleModal = async () => {
    await dispatch(
      toggleIOWalletModal({
        addIOWallet: true,
      })
    );
  };
  return (
    <Layout>
      <div>
        <Banners
          // withAction
          actionLabel="Create IOWallet"
          onClick={handleModal}
        >
          <p className="pl-3">Tanda I/O wallets</p>
        </Banners>
      </div>
      <IOWalletForm />
      <div className="pt-2">
        {_.isEmpty(iowallets) && !iowalletsLoaders.fetchIOWallets ? (
          <SuccessOrErrorState
            state="empty"
            message="No IO Wallet Products Found"
          />
        ) : (
          <IOWalletTable
            title={config.iowallets.iowallet.normal.title}
            columns={config.iowallets.iowallet.normal.listColumns}
            data={iowallets}
            pagination={iowalletsPagination}
            loading={iowalletsLoaders.fetchIOWallets}
            onPaginationChange={handlePagination}
          />
        )}
      </div>
    </Layout>
  );
};
