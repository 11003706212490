import { FC } from "react";
import classNames from "classnames";
import { getInitials } from "../../utils";

interface AvatarProps {
  src?: string;
  name?: string;
  size?: number;
}

export const Avatar: FC<AvatarProps> = ({ src, name, size = 8 }) => {
  const textSize = size < 8 ? "text-xs font-medium" : "text-base font-normal";
  return (
    <>
      {src ? (
        <img
          className={classNames(
            "inline-block rounded-full",
            `h-${size} w-${size}`
          )}
          src={src}
          alt={`${name}'s profile`}
        />
      ) : name ? (
        <span
          className={classNames(
            "inline-flex items-center justify-center rounded-full bg-gray-500",
            `h-${size} w-${size}`
          )}
        >
          <span className={classNames("leading-none text-white", textSize)}>
            {getInitials(name)}
          </span>
        </span>
      ) : (
        <span
          className={classNames(
            "inline-block rounded-full overflow-hidden bg-gray-100",
            `h-${size} w-${size}`
          )}
        >
          <svg
            className="h-full w-full text-gray-300"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
        </span>
      )}
    </>
  );
};
