import { getURL } from "../../../utils";
import { Types } from "./actionTypes";

export const toggleResellerDfsModal = (payload: any) => async (dispatch: any) =>
  dispatch({
    type: Types.TOGGLE_RESELLER_DFS_MODALS,
    payload,
  });

export const updateSelectedResellerDfs =
  (payload: any) => async (dispatch: any) => {
    await dispatch({
      type: Types.UPDATE_SELECTED_RESELLER_DFS,
      payload,
    });
  };

export const fetchResellerDfss =
  (resellerId: any, pagination?: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.FETCH_RESELLER_DFSS,
      payload: {
        request: {
          method: "GET",
          url: getURL(
            `/io-wallets/v1/resellers/${resellerId}/dfses`,
            pagination
          ),
          useCache: true,
        },
      },
    });

export const createResellerDfs =
  (resellerId: any, data: any) => async (dispatch: any) => {
    await dispatch({
      type: Types.CREATE_RESELLER_DFS,
      payload: {
        request: {
          url: `/io-wallets/v1/resellers/${resellerId}/dfses`,
          method: "POST",
          data,
        },
      },
    });
  };

export const updateResellerDfs =
  (resellerId: any, dfs: any, data: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.UPDATE_RESELLER_DFS,
      payload: {
        request: {
          url: `/io-wallets/v1/resellers/${resellerId}/dfses/${dfs?.dfs?.id}`,
          method: "PUT",
          data,
        },
      },
    });

export const deleteResellerDfs =
  (resellerId: any, dfs: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.DELETE_RESELLER_DFS,
      payload: {
        request: {
          url: `/io-wallets/v1/resellers/${resellerId}/dfses/${dfs?.dfs?.id}`,
          method: "DELETE",
          deleteProp: dfs,
        },
      },
    });
