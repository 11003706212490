import { FC } from "react";

interface CheckBoxProps {
  name: string;
  value: string;
  onChange: (value: string) => void;
}

export const CheckBox: FC<CheckBoxProps> = ({ name, value, onChange }) => {
  return (
    <div className="mb-2">
      <div className="flex items-start">
        <div className="flex items-center h-5">
          <input
            name={name}
            type="checkbox"
            className="focus:ring-tandaPurple h-4 w-4 text-tandaPurple border-gray-300 rounded"
            onChange={() => onChange(value)}
          />
        </div>
      </div>
    </div>
  );
};
