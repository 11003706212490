import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { TextArea, TextField } from "../../App/FormComponents";
import { Modal } from "../../App";
import { schemaValidation } from "../../../constants";
import {
  InitialResellerState,
  toggleResellerModal,
} from "../../../domains/Reseller/Reseller";

interface FormData {
  name: string;
  publicKey: string;
}

// function generateRandomString(length: any) {
//   const characters =
//     "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
//   let result = "";
//   for (let i = 0; i < length; i++) {
//     const randomIndex = Math.floor(Math.random() * characters.length);
//     result += characters.charAt(randomIndex);
//   }
//   return result;
// }

// const randomString = generateRandomString(10); // Change the length as needed

export const AddClient: FC = () => {
  const dispatch = useDispatch();
  // const [submitting, setSubmitting] = useState(false);
  let { resellerModals } = useSelector(
    (state: { reseller: InitialResellerState }) => state.reseller
  );
  useEffect(() => {}, [dispatch]);

  const { control, handleSubmit, reset } = useForm<FormData>({
    resolver: joiResolver(schemaValidation.ApiClientSchema),
    defaultValues: {
      name: "",
      publicKey: "",
    },
  });

  const onClose = async () => {
    reset();
    await dispatch(
      toggleResellerModal({
        addClient: false,
      })
    );
  };
  const onSubmit: SubmitHandler<FormData> = async (props) => {
    // try {
    //   let url = "/io-accounts/v1/resellers";
    //   let username = props.firstName + generateRandomString(3) + "001";
    //   let resellerData = {
    //     name: props.name,
    //     owner: {
    //       userName: username,
    //       firstName: props.firstName,
    //       otherNames: props.otherNames,
    //       emailAddress: props.emailAddress,
    //       phoneNumber: "254" + props.phoneNumber,
    //       nationalId: props.nationalId,
    //       officePhysicalAddress: props.officePhysicalAddress,
    //       officePostalAddress: props.officePostalAddress,
    //     },
    //     ids: [
    //       {
    //         typeId: "002",
    //         idNumber: props.businessNumber,
    //       },
    //     ],
    //   };
    //   setSubmitting(true);
    //   API.post(url, resellerData)
    //     .then((response: any) => {
    //       setSubmitting(false);
    //       dispatch(fetchResellers({ size: 12 }));
    //       onClose();
    //       openNotification({
    //         openNotification: true,
    //         type: "success",
    //         message: "Request submitted successfully",
    //       });
    //     })
    //     .catch((e) => {
    //       setSubmitting(false);
    //       // onClose();
    //       dispatch(
    //         openNotification({
    //           openNotification: true,
    //           type: "error",
    //           message: "Something went wrong",
    //         })
    //       );
    //     });
    // } catch (e) {
    //   dispatch(
    //     openNotification({
    //       openNotification: true,
    //       type: "error",
    //       message: "Something went wrong",
    //     })
    //   );
    // }
  };
  return (
    <Modal
      formId="reseller_form"
      btnType="submit"
      modalTitle="Add App"
      isOpen={resellerModals.addClient}
      onClose={onClose}
      btnLoading={true}
    >
      <form
        id="reseller_form"
        className="space-y-2 py-6"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mb-6">
          <Controller
            control={control}
            name="name"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id="name"
                label="Name"
                type="text"
                value={value}
                error={error?.message}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className="mb-6">
          <Controller
            control={control}
            name="name"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextArea
                id="publicKey"
                label="Public Key"
                value={value}
                error={error?.message}
                onChange={onChange}
              />
            )}
          />
        </div>
      </form>
    </Modal>
  );
};
