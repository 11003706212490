export const Types: Record<string, string> = {
  TOGGLE_RESELLER_DFS_FEE_RATE_MODALS: "TOGGLE_RESELLER_DFS_FEE_RATE_MODALS",

  FETCH_RESELLER_DFS_FEE_RATES: "FETCH_RESELLER_DFS_FEE_RATES",
  FETCH_RESELLER_DFS_FEE_RATES_SUCCESS: "FETCH_RESELLER_DFS_FEE_RATES_SUCCESS",
  FETCH_RESELLER_DFS_FEE_RATES_FAIL: "FETCH_RESELLER_DFS_FEE_RATES_FAIL",

  CREATE_RESELLER_DFS_FEE_RATE: "CREATE_RESELLER_DFS_FEE_RATE",
  CREATE_RESELLER_DFS_FEE_RATE_SUCCESS: "CREATE_RESELLER_DFS_FEE_RATE_SUCCESS",
  CREATE_RESELLER_DFS_FEE_RATE_FAIL: "CREATE_RESELLER_DFS_FEE_RATE_FAIL",

  UPDATE_RESELLER_DFS_FEE_RATE: "UPDATE_RESELLER_DFS_FEE_RATE",
  UPDATE_RESELLER_DFS_FEE_RATE_SUCCESS: "UPDATE_RESELLER_DFS_FEE_RATE_SUCCESS",
  UPDATE_RESELLER_DFS_FEE_RATE_FAIL: "UPDATE_RESELLER_DFS_FEE_RATE_FAIL",

  DELETE_RESELLER_DFS_FEE_RATE: "DELETE_RESELLER_DFS_FEE_RATE",
  DELETE_RESELLER_DFS_FEE_RATE_SUCCESS: "DELETE_RESELLER_DFS_FEE_RATE_SUCCESS",
  DELETE_RESELLER_DFS_FEE_RATE_FAIL: "DELETE_RESELLER_DFS_FEE_RATE_FAIL",
};
