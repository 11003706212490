import { FC, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { CheckBox2, Select, TextField } from "../../App/FormComponents";
import { Modal } from "../../App";
import {
  InitialBSWalletState,
  toggleBSWalletModal,
  updateBSWallet,
} from "../../../domains/BSWallet";
import { openNotification } from "../../../domains/App";
import { schemaValidation } from "../../../constants";
import _ from "lodash";
import {
  fetchPaymentGateways,
  InitialPaymentGatewayState,
} from "../../../domains/PaymentGateway";

interface BSWallet {
  name: string;
  number: string;
  type: string;
  paymentGateway: any;
  withdrawable: boolean;
}

interface EditBSWalletFormProps {
  bSWallet: any;
}

export const EditBSWalletForm: FC<EditBSWalletFormProps> = ({ bSWallet }) => {
  const dispatch = useDispatch();
  const { bswalletModals, bswalletsLoaders } = useSelector(
    (state: { bswallet: InitialBSWalletState }) => state.bswallet
  );
  const { control, handleSubmit, reset } = useForm<BSWallet>({
    resolver: joiResolver(schemaValidation.BSWalletSchema),
    defaultValues: {
      name: "",
      number: "",
      type: "",
      paymentGateway: "",
      withdrawable: false,
    },
  });

  useEffect(() => {
    if (!_.isEmpty(bSWallet)) {
      reset({
        name: bSWallet?.name || "",
        number: bSWallet?.number || "",
        type: bSWallet?.type || "",
        paymentGateway: bSWallet?.paymentGateway || "",
        withdrawable: bSWallet?.withdrawable || "",
      });
    }
  }, [bSWallet, reset]);
  const { paymentGateways } = useSelector(
    (state: { paymentGateway: InitialPaymentGatewayState }) =>
      state.paymentGateway
  );

  useEffect(() => {
    const fetchPaymentGatewaysAsync = async () =>
      await dispatch(fetchPaymentGateways());

    fetchPaymentGatewaysAsync();
  }, [dispatch]);

  const onClose = async () => {
    reset();
    await dispatch(
      toggleBSWalletModal({
        editBSWallet: false,
      })
    );
  };

  const paymentGatewayselectOptions = paymentGateways.map((f) => ({
    label: f.name,
    value: f,
  }));

  const onSubmit: SubmitHandler<BSWallet> = async (props) => {
    try {
      await dispatch(updateBSWallet(bSWallet, props));
      await onClose();
      await dispatch(
        openNotification({
          openNotification: true,
          type: "success",
          message: "Batch Type updated!",
        })
      );
    } catch (e) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Something went wrong",
        })
      );
    }
  };

  return (
    <Modal
      formId="edit_business_client_wallet_form"
      btnType="submit"
      modalTitle="Edit Business Client Wallet"
      isOpen={bswalletModals.editBSWallet}
      onClose={onClose}
      btnLoading={bswalletsLoaders.submit}
    >
      <form
        id="edit_business_client_wallet_form"
        className="space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="name"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="name"
              label="Name"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="number"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="number"
              label="Number"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="paymentGateway"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Select
              id="paymentGateway"
              label="Payment Gateway"
              selectedOption={value}
              error={error?.message}
              onChange={onChange}
              options={paymentGatewayselectOptions}
            />
          )}
        />
        <Controller
          control={control}
          name="type"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Select
              id="type"
              label="Type"
              selectedOption={value}
              error={error?.message}
              onChange={onChange}
              options={[
                {
                  value: "THIRD_PARTY_WALLET",
                  label: "THIRD PARTY WALLET",
                },
                {
                  value: "BANK_ACCOUNT",
                  label: "BANK ACCOUNT",
                },
                {
                  value: "MOBILE_WALLET",
                  label: "MOBILE WALLET",
                },
                {
                  value: "MMO_PAYBILL",
                  label: "MMO PAYBILL",
                },
                {
                  value: "MMO_TILL",
                  label: "MMO TILL",
                },
              ]}
            />
          )}
        />
        <Controller
          control={control}
          name="withdrawable"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <CheckBox2
              name="withdrawable"
              label="Withdrawable"
              value={value}
              onChange={onChange}
            />
          )}
        />
      </form>
    </Modal>
  );
};
