import { FC, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { CheckBox2, TextArea, TextField } from "../../App/FormComponents";
import { Modal } from "../../App";
import {
  InitialIOWalletState,
  toggleIOWalletModal,
  updateIOWallet,
} from "../../../domains/IOWallets";
import { openNotification } from "../../../domains/App";
import { schemaValidation } from "../../../constants";
import _ from "lodash";

interface IOWallet {
  name: string;
  description: string;
  enabled: boolean;
  available: boolean;
  minimumValue: number;
  maximumValue: number;
  sp: any;
  batchType: any;
  dfsType: any;
}

interface EditIOWalletFormProps {
  iowallet: any;
}

export const EditIOWalletForm: FC<EditIOWalletFormProps> = ({ iowallet }) => {
  const dispatch = useDispatch();
  const { iowalletModals, iowalletsLoaders } = useSelector(
    (state: { iowallet: InitialIOWalletState }) => state.iowallet
  );

  const { control, handleSubmit, reset } = useForm<IOWallet>({
    resolver: joiResolver(schemaValidation.IOWalletSchema),
    defaultValues: {
      name: "",
      description: "",
      minimumValue: 0,
      maximumValue: 0,
      enabled: false,
      available: false,
    },
  });

  useEffect(() => {
    if (!_.isEmpty(iowallet)) {
      reset({
        name: iowallet?.name || "",
        description: iowallet?.description || "",
        enabled: iowallet?.enabled || false,
        available: iowallet?.available || false,
        minimumValue: iowallet?.minimunValue || "",
        maximumValue: iowallet?.maximumValue || "",
      });
    }
  }, [iowallet, reset]);

  const onClose = async () => {
    reset();
    await dispatch(
      toggleIOWalletModal({
        editIOWallet: false,
      })
    );
  };

  const onSubmit: SubmitHandler<IOWallet> = async (props) => {
    try {
      iowallet = {
        ...iowallet,
        available: props.available,
        description: props.description,
        enabled: props.enabled,
        maximumValue: props.maximumValue,
        minimunValue: props.minimumValue,
        name: props.name,
      };
      await dispatch(updateIOWallet(iowallet, props));
      await onClose();
      await dispatch(
        openNotification({
          openNotification: true,
          type: "success",
          message: "IOWallet updated!",
        })
      );
    } catch (e) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Something went wrong",
        })
      );
    }
  };
  return (
    <Modal
      formId="edit_iowallet_form"
      btnType="submit"
      modalTitle="Edit IOWallet"
      isOpen={iowalletModals.editIOWallet}
      onClose={onClose}
      btnLoading={iowalletsLoaders.submit}
    >
      <form
        id="edit_iowallet_form"
        className="space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="name"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="name"
              label="Name"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="description"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextArea
              id="description"
              label="Description"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <div className="grid grid-cols-2 text-center">
          <Controller
            control={control}
            name="enabled"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <CheckBox2
                name="enabled"
                label="Enabled"
                value={value}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="available"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <CheckBox2
                name="available"
                label="Available"
                value={value}
                onChange={onChange}
              />
            )}
          />
        </div>

        <div className="grid grid-cols-2  ">
          <div className="mr-1">
            <Controller
              control={control}
              name="minimumValue"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  id="minimumValue"
                  label="Minimum Value"
                  type="number"
                  value={value}
                  error={error?.message}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="ml-1">
            <Controller
              control={control}
              name="maximumValue"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  id="maximumValue"
                  label="Maximum Value"
                  type="number"
                  value={value}
                  error={error?.message}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};
