import { createReducer } from "@reduxjs/toolkit";
import _ from "lodash";
import { PaginationProps } from "../../App";
import { Types } from "./actionTypes";

export interface InitialResellerDfsFeeRateState {
  cache: Record<string, any>;
  resellerDfsFeeRates: any[];
  selectedResellerDfsFeeRate: any;
  resellerDfsFeeRatesPagination: PaginationProps;
  resellerDfsFeeRateModals: any;
  resellerDfsFeeRatesLoaders: any;
}

const initialState: InitialResellerDfsFeeRateState = {
  resellerDfsFeeRates: [],
  resellerDfsFeeRatesPagination: {
    number: 0,
    totalPages: 0,
    size: 100,
    totalElements: 0,
  },
  selectedResellerDfsFeeRate: {},
  resellerDfsFeeRatesLoaders: {
    submit: false,
    fetchResellerDfsFeeRates: false,
  },
  resellerDfsFeeRateModals: {
    addResellerDfsFeeRate: false,
    editResellerDfsFeeRate: false,
    deleteResellerDfsFeeRate: false,
  },
  cache: {},
};

export const resellerDfsFeeRatesReducer = createReducer(initialState, {
  [Types.TOGGLE_RESELLER_DFS_FEE_RATE_MODALS]: (state, action) => {
    state.resellerDfsFeeRateModals = {
      ...state.resellerDfsFeeRateModals,
      ...action.payload,
    };
  },

  [Types.FETCH_RESELLER_DFS_FEE_RATES]: (state) => {
    state.resellerDfsFeeRatesLoaders.fetchResellerDfsFeeRates = true;
  },
  [Types.FETCH_RESELLER_DFS_FEE_RATES_SUCCESS]: (state, action) => {
    const { data } = action.payload;
    state.resellerDfsFeeRatesLoaders.fetchResellerDfsFeeRates = false;
    state.resellerDfsFeeRates = data;
  },
  [Types.FETCH_RESELLER_DFS_FEE_RATES_FAIL]: (state) => {
    state.resellerDfsFeeRatesLoaders.fetchResellerDfsFeeRates = false;
  },

  [Types.CREATE_RESELLER_DFS_FEE_RATE]: (state) => {
    state.resellerDfsFeeRatesLoaders.submit = true;
  },
  [Types.CREATE_RESELLER_DFS_FEE_RATE_SUCCESS]: (state, action) => {
    state.resellerDfsFeeRatesLoaders.submit = false;
    state.resellerDfsFeeRates = [
      action.payload.data,
      ...state.resellerDfsFeeRates,
    ];
  },
  [Types.CREATE_RESELLER_DFS_FEE_RATE_FAIL]: (state) => {
    state.resellerDfsFeeRatesLoaders.submit = false;
  },

  [Types.DELETE_RESELLER_DFS_FEE_RATE]: (state) => {
    state.resellerDfsFeeRatesLoaders.submit = true;
  },
  [Types.DELETE_RESELLER_DFS_FEE_RATE_SUCCESS]: (state, action) => {
    const {
      previousAction: {
        payload: {
          request: {
            deleteProp: { id },
          },
        },
      },
    } = action.meta;
    state.resellerDfsFeeRates = [
      ...state.resellerDfsFeeRates.filter(
        (resellerDfsFeeRate) => resellerDfsFeeRate.id !== id
      ),
    ];
    if (_.isEmpty(state.resellerDfsFeeRates)) {
      state.resellerDfsFeeRatesPagination.number = 0;
    }
    state.resellerDfsFeeRatesLoaders.submit = false;
  },
  [Types.DELETE_RESELLER_DFS_FEE_RATE_FAIL]: (state) => {
    state.resellerDfsFeeRatesLoaders.submit = false;
  },

  [Types.UPDATE_RESELLER_DFS_FEE_RATE]: (state) => {
    state.resellerDfsFeeRatesLoaders.submit = true;
  },
  [Types.UPDATE_RESELLER_DFS_FEE_RATE_SUCCESS]: (state, action) => {
    state.resellerDfsFeeRatesLoaders.submit = false;
    state.resellerDfsFeeRates = [
      ...state.resellerDfsFeeRates.map((sp) =>
        sp.id === action.payload.data.id ? action.payload.data : sp
      ),
    ];
  },
  [Types.UPDATE_RESELLER_DFS_FEE_RATE_FAIL]: (state) => {
    state.resellerDfsFeeRatesLoaders.submit = false;
  },
});
