import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useFormContext } from "react-hook-form";
import _ from "lodash";
import { Button, LargeModal } from "../../App";
import { DescriptionPanel } from "../Messages";
import { UsersTable } from "../Users";
import {
  InitialUserStateProps,
  fetchAllGroups,
  fetchContacts,
} from "../../../domains/Users";
import { config } from "../../../constants";
import { ErrorMessage } from "../../App/FormComponents";

interface GetContactsModalProps {
  isOpen: boolean;
  onClose: () => void;
  onChange: (value: string) => void;
}

const GetContactsModal: FC<GetContactsModalProps> = ({
  isOpen,
  onClose,
  onChange,
}) => {
  const [selectedGroup, setSelectedGroup] = useState<any>({});
  const dispatch = useDispatch();
  const { groups, groupPagination, contacts, contactPagination, userLoader } =
    useSelector((state: { users: InitialUserStateProps }) => state.users);

  const handleSelectGroup = (group: any) => setSelectedGroup(group);

  useEffect(() => {
    const fetchGroupAsync = async () => await dispatch(fetchAllGroups());
    fetchGroupAsync();
  }, [dispatch]);

  useEffect(() => {
    const fetchContactsAsync = async () => {
      if (!_.isEmpty(selectedGroup)) {
        await dispatch(fetchContacts(selectedGroup.id));
      }
    };

    fetchContactsAsync();
  }, [dispatch, selectedGroup]);

  const handleGroupPagination = (page: number) => {
    dispatch(
      fetchAllGroups({
        page: page - 1,
        size: groupPagination.size,
      })
    );
  };

  const handleContactPagination = (page: number) => {
    dispatch(
      fetchContacts(selectedGroup.id, {
        page: page - 1,
        size: contactPagination.size,
      })
    );
  };

  return (
    <LargeModal modalTitle="Pick Contacts" isOpen={isOpen} onClose={onClose}>
      {_.isEmpty(selectedGroup) ? (
        <UsersTable
          title={config.users.campaignSelectableGroups.title}
          columns={config.users.campaignSelectableGroups.listColumns}
          data={groups}
          pagination={groupPagination}
          loading={userLoader.fetchAllGroups}
          onPaginationChange={handleGroupPagination}
          onClickGroup={handleSelectGroup}
        />
      ) : (
        <UsersTable
          title={config.users.campaignContacts.title}
          columns={config.users.campaignContacts.listColumns}
          data={contacts}
          pagination={contactPagination}
          loading={userLoader.fetchContacts}
          onPaginationChange={handleContactPagination}
          onChange={onChange}
        />
      )}
    </LargeModal>
  );
};

export const PickContacts: FC = () => {
  const {
    formState: { errors },
    watch,
    control,
  } = useFormContext();
  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => setOpenModal(false);
  const handleClick = () => setOpenModal(true);
  const formContacts = watch("contacts");

  const handleContacts = (contact: string) => {
    let contacts = [...formContacts];
    if (contacts.some((c: string) => c === contact)) {
      contacts.filter((c) => c !== contact);
    } else {
      contacts = [...contacts, contact];
    }
    return contacts;
  };

  return (
    <>
      <Controller
        name="contacts"
        control={control}
        render={({ field: { onChange } }) => {
          return (
            <div>
              {openModal && (
                <GetContactsModal
                  isOpen={openModal}
                  onClose={handleClose}
                  onChange={(group: string) => {
                    const groups = handleContacts(group);
                    onChange(groups);
                  }}
                />
              )}
            </div>
          );
        }}
      />
      <DescriptionPanel label="Contacts">
        <div className="grid grid-cols-1">
          <div>
            <Button onClick={handleClick} variant="primary" size="md">
              Select
            </Button>
          </div>
          <div className="space-x-1 space-y-1">
            {!_.isEmpty(formContacts) &&
              formContacts.map((group: string, i: number) => {
                const parsedContact = JSON.parse(group) || {};
                return (
                  <span
                    key={i}
                    className="mt-3 inline-flex items-center px-3 py-1 rounded-full text-sm font-medium cursor-pointer mb-2 bg-white border border-gray-600 text-gray-800"
                  >
                    {parsedContact?.lastName && (
                      <b className="pr-1">{parsedContact?.lastName}</b>
                    )}
                    {parsedContact?.firstName}
                  </span>
                );
              })}
            <ErrorMessage error={errors?.contacts?.message} />
          </div>
        </div>
      </DescriptionPanel>
    </>
  );
};
