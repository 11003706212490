import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTemplates, InitialMessageState } from "../../../domains/Messages";
import { LargeModal } from "../../App";
import { DescriptionPanel } from ".";
import { TemplatesTable } from "./TemplatesTable";
import { config } from "../../../constants";

interface TemplatesModalProps {
  isOpen: boolean;
  onClose: () => void;
  onChange: (value: string) => void;
}

export const TemplatesModal: FC<TemplatesModalProps> = ({
  isOpen,
  onClose,
  onChange,
}) => {
  const dispatch = useDispatch();
  const { messageLoaders, templates, templatesPagination } = useSelector(
    (state: { message: InitialMessageState }) => state.message
  );

  useEffect(() => {
    const fetchTemplatesAsync = () => {
      dispatch(fetchTemplates());
    };
    fetchTemplatesAsync();
  }, [dispatch]);

  const handlePagination = (page: number) => {
    dispatch(
      fetchTemplates({
        page: page - 1,
        size: templatesPagination.size,
      })
    );
  };

  return (
    <LargeModal modalTitle="Pick a template" isOpen={isOpen} onClose={onClose}>
      <TemplatesTable
        columns={config.messages.editorTemplates.listColumns}
        onChange={onChange}
        pagination={templatesPagination}
        data={templates}
        loading={messageLoaders.fetchTemplates}
        onPaginationChange={handlePagination}
        SubComponent={(value: any) => {
          return (
            <div className="w-full pt-2 pb-4 px-4">
              <DescriptionPanel
                label="Body"
                description={value?.row?.original?.body}
              />
            </div>
          );
        }}
      />
    </LargeModal>
  );
};
