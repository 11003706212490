import { FC, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import {
  TextField,
  TextArea,
  CheckBox2,
  Select,
} from "../../App/FormComponents";
import { Modal } from "../../App";
import { openNotification } from "../../../domains/App";
import { schemaValidation } from "../../../constants";
import {
  createIOWallet,
  InitialIOWalletState,
  toggleIOWalletModal,
} from "../../../domains/IOWallets";
import {
  fetchBatchAllTypes,
  InitialBatchTypeState,
} from "../../../domains/BatchType";
import {
  fetchServiceProviders,
  InitialServiceProviderState,
} from "../../../domains/ServiceProvider";
import { fetchDfsTypes, InitialDfsTypeState } from "../../../domains/DfsType";

interface IOWallet {
  name: string;
  description: string;
  enabled: boolean;
  available: boolean;
  minimumValue: number;
  maximumValue: number;
  sp: any;
  batchType: any;
  dfsType: any;
}

export const IOWalletForm: FC = () => {
  const dispatch = useDispatch();
  const { iowalletModals, iowalletsLoaders } = useSelector(
    (state: { iowallet: InitialIOWalletState }) => state.iowallet
  );
  const { batchTypes } = useSelector(
    (state: { batchType: InitialBatchTypeState }) => state.batchType
  );
  const { serviceProviders } = useSelector(
    (state: { serviceProvider: InitialServiceProviderState }) =>
      state.serviceProvider
  );
  const { dfsTypes } = useSelector(
    (state: { dfsType: InitialDfsTypeState }) => state.dfsType
  );

  useEffect(() => {
    const fetchServiceProvidersAsync = async () =>
      await dispatch(fetchServiceProviders());
    const fetchBatchTypesAsync = async () =>
      await dispatch(fetchBatchAllTypes());
    const fetchDfsTypesAsync = async () => await dispatch(fetchDfsTypes());

    fetchDfsTypesAsync();
    fetchServiceProvidersAsync();
    fetchBatchTypesAsync();
  }, [dispatch]);

  const { control, handleSubmit, reset } = useForm<IOWallet>({
    resolver: joiResolver(schemaValidation.IOWalletSchema),
    defaultValues: {
      description: "",
      minimumValue: 0,
      maximumValue: 0,
      name: "",
      enabled: false,
      available: false,
    },
  });

  const onClose = async () => {
    reset();
    await dispatch(
      toggleIOWalletModal({
        addIOWallet: false,
      })
    );
  };
  const batchTypeSelectOptions = batchTypes.map((f) => ({
    label: f.name,
    value: f,
  }));
  const serviceProviderSelectOptions = serviceProviders.map((f) => ({
    label: f.name,
    value: f,
  }));
  const dfsTypeOptions = dfsTypes.map((f) => ({
    label: f.name,
    value: f,
  }));

  const onSubmit: SubmitHandler<IOWallet> = async (props) => {
    try {
      await dispatch(createIOWallet(props));
      await onClose();
      await dispatch(
        openNotification({
          openNotification: true,
          type: "success",
          message: "Service provider added!",
        })
      );
    } catch (e) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Something went wrong",
        })
      );
    }
  };
  return (
    <Modal
      formId="iowallets_form"
      btnType="submit"
      modalTitle="Add a iowallet"
      isOpen={iowalletModals.addIOWallet}
      onClose={onClose}
      btnLoading={iowalletsLoaders.submit}
    >
      <form
        id="iowallets_form"
        className="space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="name"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="name"
              label="Name"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />

        <Controller
          control={control}
          name="description"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextArea
              id="description"
              label="Description"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <div className="grid grid-cols-2 text-center">
          <Controller
            control={control}
            name="enabled"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <CheckBox2
                name="enabled"
                label="Enabled"
                value={value}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="available"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <CheckBox2
                name="available"
                label="Available"
                value={value}
                onChange={onChange}
              />
            )}
          />
        </div>

        <div className="grid grid-cols-2  ">
          <div className="mr-1">
            <Controller
              control={control}
              name="minimumValue"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  id="minimuValue"
                  label="Minimum Value"
                  type="number"
                  value={value}
                  error={error?.message}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="ml-1">
            <Controller
              control={control}
              name="maximumValue"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  id="maximumValue"
                  label="Maximum Value"
                  type="number"
                  value={value}
                  error={error?.message}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </div>

        <div className="grid grid-cols-2">
          <div className="mr-2">
            <Controller
              control={control}
              name="sp"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Select
                  id="sp"
                  label="Service Provider"
                  selectedOption={value}
                  error={error?.message}
                  onChange={onChange}
                  options={serviceProviderSelectOptions}
                />
              )}
            />
          </div>

          <div className="ml-2">
            <Controller
              control={control}
              name="dfsType"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Select
                  id="dfsType"
                  label="IOWallet Type"
                  selectedOption={value}
                  error={error?.message}
                  onChange={onChange}
                  options={dfsTypeOptions}
                />
              )}
            />
          </div>
          <div className="col-span-2">
            <Controller
              control={control}
              name="batchType"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Select
                  id="batchType"
                  label="Batch Type"
                  selectedOption={value}
                  error={error?.message}
                  onChange={onChange}
                  options={batchTypeSelectOptions}
                />
              )}
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};
