import { FC, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { TextField } from "../../App/FormComponents";
import { Modal } from "../../App";
import {
  InitialBatchTypeState,
  toggleBatchTypeModal,
  updateBatchType,
} from "../../../domains/BatchType";
import { openNotification } from "../../../domains/App";
import { schemaValidation } from "../../../constants";
import _ from "lodash";

interface BatchType {
  name: string;
}

interface EditBatchTypeFormProps {
  batchType: any;
}

export const EditBatchTypeForm: FC<EditBatchTypeFormProps> = ({
  batchType,
}) => {
  const dispatch = useDispatch();
  const { batchTypeModals, batchTypesLoaders } = useSelector(
    (state: { batchType: InitialBatchTypeState }) => state.batchType
  );
  const { control, handleSubmit, reset } = useForm<BatchType>({
    resolver: joiResolver(schemaValidation.BatchTypeSchema),
    defaultValues: {
      name: "",
    },
  });

  useEffect(() => {
    if (!_.isEmpty(batchType)) {
      reset({
        name: batchType?.name || "",
      });
    }
  }, [batchType, reset]);

  const onClose = async () => {
    reset();
    await dispatch(
      toggleBatchTypeModal({
        editBatchType: false,
      })
    );
  };

  const onSubmit: SubmitHandler<BatchType> = async (props) => {
    try {
      await dispatch(updateBatchType(batchType?.id, props));
      await onClose();
      await dispatch(
        openNotification({
          openNotification: true,
          type: "success",
          message: "Batch Type updated!",
        })
      );
    } catch (e) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Something went wrong",
        })
      );
    }
  };
  return (
    <Modal
      formId="edit_batch_type_form"
      btnType="submit"
      modalTitle="Edit Batch Type"
      isOpen={batchTypeModals.editBatchType}
      onClose={onClose}
      btnLoading={batchTypesLoaders.submit}
    >
      <form
        id="edit_batch_type_form"
        className="space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="name"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="name"
              label="Name"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
      </form>
    </Modal>
  );
};
