import { Types } from "./actionTypes";
import { getURL } from "../../utils";

export const togglePaymentGatewayModal =
  (payload: any) => async (dispatch: any) =>
    dispatch({
      type: Types.TOGGLE_PAYMENTGATEWAY_MODALS,
      payload,
    });
export const fetchAllPaymentGateways =
  (pagination?: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.FETCH_PAYMENTGATEWAYS,
      payload: {
        request: {
          method: "GET",
          // url: getURL("/api/payment-gateways", pagination),
          url: getURL("/io-wallets/v1/payment-gateways", { size: 100 }),
          useCache: true,
        },
      },
    });
export const fetchPaymentGateways =
  (pagination?: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.FETCH_PAYMENTGATEWAYS,
      payload: {
        request: {
          method: "GET",
          // url: getURL("/api/payment-gateways", pagination),
          url: getURL("/io-wallets/v1/payment-gateways", { size: 100 }),
          useCache: true,
        },
      },
    });

export const createPaymentGateway = (data: any) => async (dispatch: any) => {
  await dispatch({
    type: Types.CREATE_PAYMENTGATEWAY,
    payload: {
      request: {
        // url: `/api/payment-gateways`,
        url: `/wallets/v1/payment-gateways`,
        method: "POST",
        data,
      },
    },
  });
};

export const updatePaymentGateway =
  (id: string, data: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.UPDATE_PAYMENTGATEWAY,
      payload: {
        request: {
          // url: `/api/payment-gateways/${templateId}`,
          url: `/wallets/v1/payment-gateways/${id}`,
          method: "PUT",
          data,
        },
      },
    });

export const deletePaymentGateway =
  (spProperty: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.DELETE_PAYMENTGATEWAY,
      payload: {
        request: {
          // url: `/api/payment-gateways/${spProperty.id}`,
          url: `/wallets/v1/payment-gateways/${spProperty.id}`,
          method: "DELETE",
          deleteProp: spProperty,
        },
      },
    });
