import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { Controller, useFormContext } from "react-hook-form";
import { DescriptionPanel } from "../Messages";
import { Button } from "../../App";
import { TextField, Select } from "../../App/FormComponents";
import { operations, InitialMessageState } from "../../../domains/Messages";
import { SelectorCard } from "./SelectorCard";

interface SelectorProps {
  formValue: string;
  label: string;
}

const emptySelector = {
  key: "",
  operator: "",
  value: "",
};

export const Selectors: FC<SelectorProps> = ({ formValue, label }) => {
  const [selectorForm, setSelectorForm] = useState(emptySelector);
  const { templateVariables } = useSelector(
    (state: { message: InitialMessageState }) => state.message
  );
  const { control } = useFormContext();

  return (
    <DescriptionPanel label={label}>
      <Controller
        name={formValue}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          const handleChange = () => {
            onChange([...value, selectorForm]);
            setSelectorForm(emptySelector);
          };
          const handleRemoveSelector = (selector: any) => {
            onChange([...value?.filter((v: any) => v?.key !== selector.key)]);
          };
          const errorMessage: string | undefined = (error as any)?.[0]?.message;
          return (
            <div className="flex flex-wrap space-y-2">
              <Select
                id="key"
                label="Key"
                options={[
                  {
                    value: "no_selection",
                    label: "No selection",
                    unavailable: true,
                  },
                  ...templateVariables.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  })),
                ]}
                selectedOption={selectorForm?.key}
                onChange={(option) =>
                  setSelectorForm({
                    ...selectorForm,
                    key: option,
                  })
                }
              />
              <Select
                id="operator"
                label="Operator"
                options={operations}
                selectedOption={selectorForm?.operator}
                onChange={(operator) =>
                  setSelectorForm({
                    ...selectorForm,
                    operator,
                  })
                }
              />
              <TextField
                id="value"
                label="Value"
                type="text"
                value={selectorForm?.value}
                onChange={(value) => {
                  setSelectorForm({
                    ...selectorForm,
                    value,
                  });
                }}
              />
              <div className="flex justify-end w-full">
                <Button variant="secondary" onClick={handleChange}>
                  Add
                </Button>
              </div>
              <div className="flex flex-wrap space-x-1 space-y-2 w-full">
                {value?.map((selector: any) => (
                  <SelectorCard
                    key={selector?.id}
                    selector={selector}
                    handleRemoveSelector={handleRemoveSelector}
                  />
                ))}
              </div>
              {errorMessage && (
                <p className="mt-2 text-sm text-red-600">{errorMessage}</p>
              )}
            </div>
          );
        }}
      />
    </DescriptionPanel>
  );
};
