import { FC } from "react";

interface CardProps {
  onPaginationChange?: (page: number) => void;
  title?: string;
  name?: any;
  name1?: any;
  name2?: any;
  onEdit?: (page: number) => void;
  SubComponent?: React.FC<{}>;
  TopIcon?: React.FC<any>;
  Icon?: React.FC<{}>;
  height?: any;
  iconColor?:any
}

export const Card: FC<CardProps> = ({
  name,
  name1,
  name2,
  SubComponent,
  TopIcon,
  Icon,
  height,
  iconColor
}) => {
  return (
    <>
      <div className={`p-3 bg-white tanda-shadow rounded-xl ${height}`}>
        <div className="flex justify-between">
          <div className="flex">
            {Icon && (
              <div
                className={`h-16 w-16 bg-green-100 rounded-full center-contents ${iconColor}`}
              >
                <Icon />
              </div>
            )}
            <div className="px-2 pb-3 flex flex-col justify-center align-middle  ">
              <p className="font-bold mb-2 capitalize">{name}</p>
              <p className="text-xs mb-1">{name1}</p>
              <p className="text-xs">{name2}</p>
            </div>
          </div>
          <div className="text-tandaGreen text-xs">
            {TopIcon && <TopIcon />}
          </div>
        </div>

        {SubComponent && (
          <div>
            {/* <hr className="divide-y divide-gray-400 hover:divide-y-8 my-2 -mx-16 -ml-16" /> */}
            <SubComponent />
          </div>
        )}
      </div>
    </>
  );
};
