import { joiResolver } from "@hookform/resolvers/joi";
import { FC } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { schemaValidation } from "../../../constants";
import { openNotification } from "../../../domains/App";
import {
  createCollectionWallets,
  InitialWalletState,
  toggleWalletModal,
} from "../../../domains/Reseller/Wallet";
import { Modal } from "../../App";
import { TextField } from "../../App/FormComponents";

interface ResellerDfsFeeRate {
  name: string;
  ipnUrl: string;
  username: string;
  password: string;
}

export const CollectionWalletForm: FC = () => {
  const dispatch = useDispatch();
  const { walletModals, walletsLoaders } = useSelector(
    (state: { wallet: InitialWalletState }) => state.wallet
  );
  const { resellerId = "" } = useParams<{ resellerId?: string }>();
  const { control, handleSubmit, reset } = useForm<ResellerDfsFeeRate>({
    resolver: joiResolver(schemaValidation.CollectionWalletSchema),
    defaultValues: {
      name: "",
      ipnUrl: "",
      username: "",
      password: "",
    },
  });

  const onClose = async () => {
    reset();
    await dispatch(
      toggleWalletModal({
        addCollectionWallet: false,
      })
    );
  };

  const onSubmit: SubmitHandler<ResellerDfsFeeRate> = async (props) => {
    try {
      await dispatch(createCollectionWallets(resellerId, props));
      await onClose();
      await dispatch(
        openNotification({
          openNotification: true,
          type: "success",
          message: "Sub Wallet added!",
        })
      );
    } catch (e) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Something went wrong",
        })
      );
    }
  };
  return (
    <Modal
      formId="collection_wallet_form"
      btnType="submit"
      modalTitle="Add a Sub Wallet"
      isOpen={walletModals.addCollectionWallet}
      onClose={onClose}
      btnLoading={walletsLoaders.submit}
    >
      <form
        id="collection_wallet_form"
        className="space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="name"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="name"
              label="Name"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />

        <Controller
          control={control}
          name="ipnUrl"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="ipnUrl"
              label="IPN Url"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="username"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="username"
              label="Username"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="password"
              label="Password"
              type="password"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
      </form>
    </Modal>
  );
};
