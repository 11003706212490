import { FC } from "react";
import { CloudUploadIcon, SparklesIcon } from "@heroicons/react/outline";
import _ from "lodash";
import classNames from "classnames";

interface UploadProps {
  acceptedFileTypes?: string;
  id: string;
  description?: string;
  label?: string;
  onFileChange?: (e: any) => void;
  uploadedFile?: any;
}

export const Upload: FC<UploadProps> = ({
  acceptedFileTypes = ".csv",
  id,
  label = "Upload a file",
  description = "CSV up to 10MB",
  onFileChange,
  uploadedFile,
}) => {
  const uploadIsEmpty = _.isEmpty(uploadedFile?.name);
  const CurrentIcon = uploadIsEmpty ? CloudUploadIcon : SparklesIcon;
  return (
    <div>
      <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
        <div className="space-y-1 text-center">
          <CurrentIcon
            className={classNames("mx-auto h-8 w-8  mb-4", {
              "text-gray-600": uploadIsEmpty,
              "animate-bounce text-tandaPurple": !uploadIsEmpty,
            })}
          />
          <div className="flex text-sm text-gray-600">
            <label
              htmlFor={id}
              className="relative cursor-pointer bg-white rounded-md font-medium text-tandaPurple hover:text-tandaPurpleLight focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-tandaPurpleLight"
            >
              <span>
                {uploadIsEmpty ? label : `File ${uploadedFile?.name} uploaded`}
              </span>
              <input
                id={id}
                name={id}
                type="file"
                className="sr-only"
                accept={acceptedFileTypes}
                onChange={(e) => onFileChange?.(e)}
              />
            </label>
            {uploadIsEmpty && <p className="pl-1">or drag and drop</p>}
          </div>
          {uploadIsEmpty && (
            <p className="text-xs text-gray-500">{description}</p>
          )}
        </div>
      </div>
    </div>
  );
};
