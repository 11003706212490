import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import dayJs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import {
  InitialUserStateProps,
  toggleUserModal,
  deleteContact,
  deleteGroup,
} from "../../../domains/Users";
import { isNil } from "lodash";
import { EditContactForm } from "./EditContact";
import { EditGroupForm } from "./EditGroup";
import { Table, Loader, DeleteModal, Button } from "../../App";
import { PaginationProps } from "../../../domains/App";
import { CheckBox } from "../../App/FormComponents";

interface UsersTableProps {
  columns: any[];
  data: any[];
  onPaginationChange?: (page: number) => void;
  onChange?: (value: string) => void;
  onClickGroup?: (value: any) => void;
  pagination: PaginationProps;
  loading: boolean;
  title?: string;
}

export const UsersTable: FC<UsersTableProps> = ({
  columns,
  data,
  pagination,
  loading,
  title,
  onChange = (value) => {},
  onClickGroup,
  onPaginationChange,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isGroup = columns.some((column) => column.id === "groupName");

  const { userModals, userLoader } = useSelector(
    (state: { users: InitialUserStateProps }) => state.users
  );
  const [selected, setSelected] = useState<any>();
  const [selectedId, setSelectedId] = useState<string>();

  const goToPath = (row: any) => () => {
    if (onClickGroup) {
      onClickGroup(row);
    } else {
      history.push(`/messages/groups/${row.id}`);
    }
  };

  const handleOpenModal = (id: string) => {
    setSelectedId(id);
    dispatch(
      toggleUserModal({
        deleteModal: true,
      })
    );
  };
  const handleEditModal = (value: any) => {
    setSelected(value);
    const key = isGroup ? "editGroup" : "editContact";
    dispatch(
      toggleUserModal({
        [key]: true,
      })
    );
  };
  const handleCloseModal = () =>
    dispatch(
      toggleUserModal({
        deleteModal: false,
      })
    );

  const onDeleteUserContext = async () => {
    const deleteHandler = isGroup ? deleteGroup : deleteContact;
    if (!isNil(selectedId)) {
      await dispatch(deleteHandler({ id: selectedId }));
    }
    handleCloseModal();
  };

  const getUserColumns = () => {
    const availableColumns = [
      {
        id: "groupName",
        Header: "Group",
        accessor: (row: any) => (
          <div>
            <span
              className="cursor-pointer underline text-tandaPurple"
              onClick={goToPath(row)}
            >
              {row?.name}
            </span>
          </div>
        ),
      },
      {
        id: "groupDescription",
        Header: "Description",
        accessor: (row: any) => {
          return <p className="truncate w-96">{row?.description}</p>;
        },
      },
      {
        id: "name",
        Header: "Name",
        accessor: (row: any) => (
          <span>
            {row?.lastName && <b>{row?.lastName} </b>}
            {row?.firstName}
          </span>
        ),
      },
      {
        id: "phoneNumber",
        Header: "Phone",
        accessor: "phoneNumber",
      },
      {
        id: "email",
        Header: "Email",
        accessor: "emailAddress",
      },
      {
        id: "lastModified",
        Header: "Last Updated On",
        accessor: (row: any) => {
          return (
            <span>{dayJs(row?.lastModified).format("YYYY-MM-DD hh:mm A")}</span>
          );
        },
      },
      {
        id: "actions",
        accessor: (row: any) => {
          return (
            <div className="flex flex-row items-center space-x-2">
              <Button
                onClick={() => handleOpenModal(row.id)}
                size="xs"
                variant="danger"
              >
                Delete
              </Button>
              <Button
                onClick={() => handleEditModal(row)}
                size="xs"
                variant="primary"
              >
                Edit
              </Button>
            </div>
          );
        },
      },
      {
        id: "select_group",
        accessor: (row: any) => {
          return (
            <CheckBox
              name="groups"
              onChange={onChange}
              value={JSON.stringify(row)}
            />
          );
        },
      },
      {
        id: "select_contact",
        accessor: (row: any) => {
          return (
            <CheckBox
              name="contacts"
              onChange={onChange}
              value={JSON.stringify(row)}
            />
          );
        },
      },
    ];

    return columns.map((column) =>
      availableColumns.find(
        (availableColumn) => availableColumn.id === column.id
      )
    );
  };

  if (loading) {
    return (
      <div className="mt-4 flex flex-row items-center justify-center w-full h-60 bg-white rounded-lg">
        <Loader />
      </div>
    );
  }
  return (
    <>
      <DeleteModal
        isOpen={userModals.deleteModal}
        onClose={handleCloseModal}
        onSubmit={onDeleteUserContext}
        message={`Are you sure you want to delete this ${
          isGroup ? "group" : "contact"
        }?`}
        loading={userLoader.submit}
      />
      <EditContactForm contact={selected} />
      <EditGroupForm group={selected} />
      <Table
        title={title}
        columns={getUserColumns()}
        data={data}
        pageCount={pagination.totalPages}
        currentPage={pagination.number}
        onPaginationChange={onPaginationChange}
      />
    </>
  );
};
