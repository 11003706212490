import _ from "lodash";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCollectionWallets,
  fetchPrimaryCollectionWallet,
  InitialWalletState,
  toggleWalletModal,
  updateCollectionsSearchParams,
} from "../../../domains/Reseller/Wallet";
import {
  Button,
  Layout,
  Loader,
  SecondTable,
  SuccessOrErrorState,
} from "../../App";

import { useHistory, useParams } from "react-router-dom";
import { ResellerTabs } from "..";
import { CollectionWalletForm } from "./CollectionWalletsForm";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { openNotification } from "../../../domains/App";
import { TextField } from "../../App/FormComponents";
import {
  fetchOrgSubWalletBalances,
  InitialSubWalletsState,
} from "../../../domains/SubWallets";
import { formatAmount } from "../../../utils/app";
import { CreditCollectionWalletForm } from "./CreditCollectionWallet";
import { DebitCollectionWalletForm } from "./DebitCollectionWallet";
import { EditCollectionWalletForm } from "./EditCollectionWallet";

interface SearchParams {
  page: number;
  size: number;
  since: any;
  till: any;
  q: string;
}

export const CollectionBalancesPage: FC = () => {
  const [selectedCollectionWallet, setSelectedcollectionWallet] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  let {
    primaryCollectionWallet,
    collectionWallets,
    walletsLoaders,
    collectionWalletsPagination,
  } = useSelector(
    (state: { resellerWallet: InitialWalletState }) => state.resellerWallet
  );

  const { subWalletsLoaders, orgBalances } = useSelector(
    (state: { subWallets: InitialSubWalletsState }) => state.subWallets
  );

  const { resellerId = "" } = useParams<{ resellerId?: string }>();
  let defaultSince =
    dayjs(new Date().setDate(new Date().getDate() - 1))
      .toISOString()
      .split(".")[0] + "Z";
  let defaultTill = dayjs(new Date()).toISOString().split(".")[0] + "Z";

  useEffect(() => {
    const fetchWalletsAsync = async () =>
      await dispatch(
        fetchCollectionWallets(resellerId, {
          page: 0,
          size: 10,
        })
      );

    const fetchPrimaryCollectionWalletAsync = async () =>
      await dispatch(fetchPrimaryCollectionWallet(resellerId));

    const fetchBalances = async () => {
      await dispatch(fetchOrgSubWalletBalances(resellerId));
    };

    fetchWalletsAsync();
    fetchPrimaryCollectionWalletAsync();
    fetchBalances();
  }, [dispatch, resellerId]);

  const handleModal = async () => {
    await dispatch(
      toggleWalletModal({
        addCollectionWallet: true,
      })
    );
  };
  let newCollectionWallets = [...collectionWallets].reverse();
  newCollectionWallets.sort(function (a, b) {
    return a.ordinal < b.ordinal ? -1 : a.ordinal > b.ordinal ? 1 : 0;
  });

  const { control, reset, handleSubmit } = useForm<SearchParams>({
    defaultValues: {
      page: 0,
      size: 10,
      since: defaultSince,
      till: defaultTill,
    },
  });

  const onReferenceSubmit: SubmitHandler<SearchParams> = async (props) => {
    const params = {
      search: props?.q?.trim() ?? "",
    };
    dispatch(updateCollectionsSearchParams(params));
    try {
      await dispatch(fetchCollectionWallets(resellerId, params));
    } catch (e) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Something went wrong",
        })
      );
    }
  };

  const handleView = (row: any) => {
    history.push(
      `/tanda-io/resellers/${row?.org?.id}/sub-wallets/${row.id}/payments`
    );
  };

  const onReset = () => {
    reset();
    dispatch(fetchCollectionWallets(resellerId));
  };

  const handlePagination = async (page: number) => {
    await dispatch(
      fetchCollectionWallets(resellerId, {
        page: page - 1,
        size: 10,
      })
    );
  };

  const handleRefresh = async () => {
    await dispatch(fetchOrgSubWalletBalances(resellerId));
  };

  const handleEditModal = (value: any) => {
    setSelectedcollectionWallet(value);
    dispatch(
      toggleWalletModal({
        editCollectionWallet: true,
      })
    );
  };

  const handleCreditModal = (value: any) => {
    setSelectedcollectionWallet(value);
    dispatch(
      toggleWalletModal({
        creditCollectionWallet: true,
      })
    );
  };

  const handleDebitModal = (value: any) => {
    setSelectedcollectionWallet(value);
    dispatch(
      toggleWalletModal({
        debitCollectionWallet: true,
      })
    );
  };

  return (
    <Layout>
      <CollectionWalletForm />
      <EditCollectionWalletForm wallet={selectedCollectionWallet} />
      <CreditCollectionWalletForm wallet={selectedCollectionWallet} />
      <DebitCollectionWalletForm wallet={selectedCollectionWallet} />
      <ResellerTabs />
      <div className="mt-4">
        {_.isEmpty(newCollectionWallets) &&
        !walletsLoaders.fetchCollectionWallets ? (
          <SuccessOrErrorState state="empty" message="No Sub Wallet Found" />
        ) : (
          <div>
            <div>
              <div className="flex flex-row-reverse mb-4">
                <Button onClick={handleRefresh}>Refresh</Button>
              </div>
              {subWalletsLoaders.fetchOrgBalances ? (
                <div className="mt-4 rounded-lg flex flex-row items-center justify-center w-full h-24">
                  <Loader />
                </div>
              ) : (
                <div className="flex flex-row gap-4 mb-4 w-full">
                  <div className="max-w-md bg-white rounded-lg overflow-hidden p-8 w-full ">
                    <div className="items-start flex flex-col gap-2">
                      <p className="text-3xl font-medium text-gray-600">
                        {orgBalances.countOfWallets}
                      </p>
                      <p className="text-gray-500">Count of Sub Wallets</p>
                    </div>
                  </div>
                  <div className="max-w-md bg-white rounded-lg overflow-hidden p-8 w-full ">
                    <div className="items-start flex flex-col gap-2">
                      <p className="text-3xl font-medium text-gray-600">
                        {formatAmount(orgBalances.sumOfActual)}
                      </p>
                      <p className="text-gray-500">Sum of Sub Wallets Actual</p>
                    </div>
                  </div>
                  <div className="max-w-md bg-white rounded-lg overflow-hidden p-8 w-full ">
                    <div className="items-start flex flex-col gap-2">
                      <p className="text-3xl font-medium text-gray-600">
                        {formatAmount(orgBalances.sumOfAvailable)}
                      </p>
                      <p className="text-gray-500">
                        Sum of Sub Wallets Available
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="flex flex-col lg:flex-row-reverse justify-between items-center">
              <Button variant="primary" onClick={handleModal}>
                Add Sub Wallet
              </Button>

              <form
                id="transaction filters"
                className="space-y-2"
                onSubmit={handleSubmit(onReferenceSubmit)}
              >
                <div className="flex flex-col space-x-2 lg:flex-row justify-between items-center">
                  <Controller
                    control={control}
                    name="q"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        id="q"
                        placeholder="Account"
                        type="text"
                        value={value}
                        error={error?.message}
                        onChange={onChange}
                      />
                    )}
                  />
                  <div className="flex flex-row items-center -mt-1">
                    <div className="mr-2">
                      <Button btnClass="primary" size="md" type="submit">
                        Search
                      </Button>
                    </div>
                    <div>
                      <Button
                        btnClass="primary"
                        size="md"
                        type="reset"
                        onClick={() => onReset()}
                      >
                        Clear
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {walletsLoaders.fetchCollectionWallets ? (
              <div className="mt-4 rounded-lg flex flex-row items-center justify-center w-full h-60 bg-white">
                <Loader />
              </div>
            ) : (
              <div>
                <div>
                  <div>
                    <SecondTable
                      columns={[
                        {
                          id: "name",
                          Header: "Name",
                          accessor: (row: any) => (
                            <div>
                              <span className="text-tandaPurple">
                                {row.name}
                              </span>
                              {primaryCollectionWallet.id === row.id && (
                                <span className="text-tandaRed">
                                  {" (Primary Wallet)"}
                                </span>
                              )}
                            </div>
                          ),
                        },
                        {
                          id: "number",
                          Header: "Number",
                          accessor: (row: any) => {
                            return <span>{row?.account}</span>;
                          },
                        },
                        {
                          id: "actual",
                          Header: "Actual(KES)",
                          accessor: (row: any) => {
                            return <span>{row?.actual}</span>;
                          },
                        },
                        {
                          id: "available",
                          Header: "Available(KES)",
                          accessor: (row: any) => {
                            return <span>{row?.available}</span>;
                          },
                        },
                        {
                          id: "actions",
                          accessor: (row: any) => {
                            return (
                              <div className="flex flex-row items-center space-x-2">
                                <Button
                                  onClick={() => handleView(row)}
                                  size="xs"
                                  variant="primary"
                                >
                                  View
                                </Button>
                                <Button
                                  onClick={() => handleEditModal(row)}
                                  size="xs"
                                  variant="primary"
                                >
                                  Update
                                </Button>
                                <Button
                                  onClick={() => handleCreditModal(row)}
                                  size="xs"
                                  variant="primary"
                                >
                                  Credit
                                </Button>
                                <Button
                                  onClick={() => handleDebitModal(row)}
                                  size="xs"
                                  variant="primary"
                                >
                                  Debit
                                </Button>
                              </div>
                            );
                          },
                        },
                      ]}
                      data={newCollectionWallets}
                      withPagination={true}
                      currentPage={collectionWalletsPagination.number}
                      pageCount={collectionWalletsPagination.totalPages}
                      onPaginationChange={handlePagination}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};
