import { FC } from "react";

interface ToggleButtonProps
{
  name: string;
  value: boolean;
  onChange: (value: boolean) => void;
}

export const ToggleButton: FC<ToggleButtonProps> = ({
  name,
  value,
  onChange,
}) =>
{
  return (
    <div>
      <div className="flex items-center justify-center w-full">
        <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
          {value ?
            <button onClick={() => onChange(!value)}><img src="/images/on-button.png" alt="" /></button> :
            <button onClick={() => onChange(!value)}><img src="/images/switch.png" alt="" /></button>
          }
         
        </div>
      </div >
    </div >
  );
};
