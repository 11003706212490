import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Layout,
  Banners,
  SuccessOrErrorState,
  Tabs,
  GoBackLink,
} from "../../App";
import {
  CommissionFeeRateTable,
  CommissionFeeRateForm,
} from "../CommissionFeeRates";
import {
  fetchAgentCommissionFees,
  InitialCommissionFeeRateState,
  toggleCommissionFeeRateModal,
} from "../../../domains/CommissionFeeRate";
import { config } from "../../../constants";
import _ from "lodash";
import { useParams } from "react-router";
import { TabItemProps } from "../../../domains/App";

export const CommissionFeeRatePage: FC = () => {
  const dispatch = useDispatch();
  const {
    agentCommissionFees,
    commissionFeeRatesLoaders,
    commissionFeeRatesPagination,
  } = useSelector(
    (state: { commissionFeeRate: InitialCommissionFeeRateState }) =>
      state.commissionFeeRate
  );

  const { productId = "" } = useParams<{ productId?: string }>();

  useEffect(() => {
    const fetchCommissionFeeRatesAsync = async () =>
      await dispatch(fetchAgentCommissionFees(productId));

    fetchCommissionFeeRatesAsync();
  }, [dispatch, productId]);

  const handlePagination = (page: number) => {
    dispatch(
      fetchAgentCommissionFees(productId, {
        page: page - 1,
        size: commissionFeeRatesPagination.size,
      })
    );
  };
  const handleModal = async () => {
    await dispatch(
      toggleCommissionFeeRateModal({
        addCommissionFeeRate: true,
      })
    );
  };
  const viewProductTabs: TabItemProps[] = [
    {
      key: "agent_transaction_fee",
      label: "Agent Transaction Fees",
      path: `/products/${productId}/agent-transaction-fees`,
    },
    {
      key: "agent_commission_fee",
      label: "Agent Commission Fees",
      path: `/products/${productId}/agent-commission-fees`,
    },
    {
      key: "customer_transaction_fee",
      label: "Customer Transaction Fees",
      path: `/products/${productId}/customer-transaction-fees`,
    },
    {
      key: "gateway",
      label: "Gateways",
      path: `/products/${productId}/gateways`,
    },
  ];

  return (
    <Layout>
      <div>
        <Tabs tabs={viewProductTabs} />

        <div className="pt-2">
          <Banners
            withAction
            actionLabel="Add Commission Fee Rate"
            onClick={handleModal}
          >
            <div className="ml-1">
              <GoBackLink path="/products" />
            </div>
            <p className="pl-3">Do you want to create a Commision Fee Rate?</p>
          </Banners>
        </div>
      </div>
      <CommissionFeeRateForm productId={productId} />
      <div className="pt-1">
        {_.isEmpty(agentCommissionFees) &&
        !commissionFeeRatesLoaders.fetchCommissionFeeRates ? (
          <SuccessOrErrorState
            state="empty"
            message="No Commission Fee Rates Found"
          />
        ) : (
          <CommissionFeeRateTable
            columns={config.products.commissionFeeRate.listColumns}
            data={agentCommissionFees}
            pagination={commissionFeeRatesPagination}
            loading={commissionFeeRatesLoaders.fetchCommissionFeeRates}
            onPaginationChange={handlePagination}
          />
        )}
      </div>
    </Layout>
  );
};
