import _ from "lodash";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ResellerDfsTable } from ".";
import { config } from "../../../constants";
import {
  fetchResellerDfss,
  InitialResellerDfsState,
} from "../../../domains/Reseller/ResellerDfs";
import { Layout, SuccessOrErrorState } from "../../App";
import { ResellerTabs } from "../ResellerTabs";

export const ResellerDfsPage: FC = () => {
  const dispatch = useDispatch();
  const { resellerDfss, resellerDfssLoaders, resellerDfssPagination } =
    useSelector(
      (state: { resellerDfs: InitialResellerDfsState }) => state.resellerDfs
    );
  const { resellerId = "" } = useParams<{ resellerId?: string }>();

  useEffect(() => {
    const fetchResellerDfssAsync = async () =>
      await dispatch(fetchResellerDfss(resellerId));

    fetchResellerDfssAsync();
  }, [dispatch, resellerId]);

  const handlePagination = (page: number) => {
    dispatch(
      fetchResellerDfss({
        page: page - 1,
        size: resellerDfssPagination.size,
      })
    );
  };
  return (
    <Layout>
      <div className="mb-4">
        <ResellerTabs />
      </div>

      <span className="mt-4">
        <p className="pl-3">Reseller Products</p>
      </span>

      <div className="pt-3">
        {_.isEmpty(resellerDfss) && !resellerDfssLoaders.fetchResellerDfss ? (
          <SuccessOrErrorState
            state="empty"
            message="No Reseller Products Found"
          />
        ) : (
          <ResellerDfsTable
            columns={config.resellerDfs.listColumns}
            data={resellerDfss}
            pagination={resellerDfssPagination}
            loading={resellerDfssLoaders.fetchResellerDfss}
            onPaginationChange={handlePagination}
          />
        )}
      </div>
    </Layout>
  );
};
