import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { TabItemProps } from "../../domains/App";
import { InitialResellerState } from "../../domains/Reseller/Reseller";
import { changeCase, checkValue } from "../../utils/app";
import { GoBackLink, RoundedTabs } from "../App";

export const ResellerTabs: FC = () => {
  const { resellerId = "", organizationId = "" } =
    useParams<{ resellerId?: string; organizationId?: string }>();
  const { selectedReseller } = useSelector(
    (state: { reseller: InitialResellerState }) => state.reseller
  );

  useEffect(() => {
    localStorage.setItem(
      "selectedResellerName",
      JSON.stringify(selectedReseller?.name)
    );
  });

  const selectedResellerName = localStorage.getItem("selectedWallet");

  const viewHOTabs: TabItemProps[] = [
    {
      key: "wallets",
      label: "Wallets",
      path: `/tanda-io/resellers/${resellerId || organizationId}/wallets`,
    },
    {
      key: "payouts",
      label: "Payouts",
      path: `/tanda-io/resellers/${resellerId || organizationId}/payouts`,
    },
    {
      key: "products",
      label: "Products",
      path: `/tanda-io/resellers/${resellerId || organizationId}/io-products`,
    },
    {
      key: "collections_wallets",
      label: "Sub Wallets",
      path: `/tanda-io/resellers/${
        resellerId || organizationId
      }/collections/wallets`,
    },
    {
      key: "collections_history",
      label: "Sub Wallets Activity",
      path: `/tanda-io/resellers/${resellerId || organizationId}/collections`,
    },
    {
      key: "details",
      label: "Account details",
      path: `/tanda-io/resellers/${resellerId || organizationId}/info`,
    },
  ];

  return (
    <>
      <div className="bg-white flex flex-col space-y-2 md:space-y-0 md:flex-row justify-between items-center px-3">
        <div className="font-semibold text-lg">
          {checkValue(
            changeCase(selectedReseller?.name || selectedResellerName)
          )}
        </div>
        <div>
          <RoundedTabs tabs={viewHOTabs} />
        </div>
        <div className="flex items-center space-x-2">
          <GoBackLink path="/tanda-io/resellers" label="Back" />
        </div>
      </div>
    </>
  );
};
