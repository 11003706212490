import dayjs from "dayjs";
import _ from "lodash";
import { FC, useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { IOTransactionTable } from ".";
import { config } from "../../../constants";
import { openNotification } from "../../../domains/App";
import {
  fetchTransaction,
  InitialIOWalletState,
} from "../../../domains/IOWallets";
import { updateSearchParams } from "../../../domains/Loan";
import { Button, Layout, SuccessOrErrorState } from "../../App";
import { DateTime, Select, TextField } from "../../App/FormComponents";
import { DescriptionPanel } from "../../Messaging";

interface SearchParams {
  search: string;
  status: string;
  page: number;
  size: number;
  since: string;
  till: string;
}

export const IOTransactionPage: FC = () => {
  const dispatch = useDispatch();
  const { ioTransactions, iowalletsLoaders } = useSelector(
    (state: { iowallet: InitialIOWalletState }) => state.iowallet
  );

  const { control, handleSubmit } = useForm<SearchParams>({
    defaultValues: {
      search: "",
      status: "",
      page: 0,
      size: 10,
      since: "2018-05-17T10:25:33",
    },
  });

  const onSubmit: SubmitHandler<SearchParams> = async (props) => {
    const params = {
      search: props.search,
      status: props.status,
      page: 0,
      size: 10,
      since: dayjs(props?.since)?.toISOString().split(".")[0] || "",
      till: dayjs(props?.till)?.toISOString().split(".")[0] || "",
    };
    dispatch(updateSearchParams(params));
    try {
      await dispatch(fetchTransaction(params));
    } catch (e) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Something went wrong",
        })
      );
    }
  };

  useEffect(() => {
    const fetchTransactionAsyc = async () => await dispatch(fetchTransaction());

    fetchTransactionAsyc();
  }, [dispatch]);

  return (
    <Layout>
      <>
        <form
          id="filter_form"
          className="space-y-2"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="pb-2 flex space-x-2 justify-between">
            <Controller
              control={control}
              name="search"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  id="search"
                  label="Search"
                  type="text"
                  placeholder="Enter SPs AGGs Ps and PTYPEs to search"
                  value={value}
                  error={error?.message}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="status"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Select
                  id="status"
                  label="Status"
                  selectedOption={value}
                  error={error?.message}
                  onChange={onChange}
                  options={[
                    {
                      value: "0",
                      label: "Pending",
                    },
                    {
                      value: "1",
                      label: "Success",
                    },
                    {
                      value: "2",
                      label: "Failed",
                    },
                  ]}
                />
              )}
            />
            <Controller
              control={control}
              name="since"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <DateTime
                  id="since"
                  label="Since"
                  type="datetime-local"
                  placeholder="Enter time since"
                  value={value}
                  error={error?.message}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="till"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <DateTime
                  id="till"
                  label="Till"
                  type="datetime-local"
                  placeholder="Enter till"
                  value={value}
                  error={error?.message}
                  onChange={onChange}
                />
              )}
            />

            <section className="md:pt-6 flex">
              <div className="mr-2">
                <Button btnClass="primary" size="md" type="submit">
                  Filter
                </Button>
              </div>
              <div>
                <Button btnClass="primary" size="md" type="reset">
                  Clear
                </Button>
              </div>
            </section>
          </div>
        </form>
      </>
      <div className="pt-2">
        {_.isEmpty(ioTransactions) && !iowalletsLoaders.fetchTransactions ? (
          <SuccessOrErrorState
            state="empty"
            message="No IO Transaction Found"
          />
        ) : (
          <IOTransactionTable
            title={config.ioTransaction.title}
            columns={config.ioTransaction.listColumns}
            data={ioTransactions}
            loading={iowalletsLoaders.fetchTransactions}
            SubComponent={(value: any) => {
              return (
                <>
                  <div className="w-full pt-2 pb-4 ml-7 px-4 grid grid-cols-2">
                    <DescriptionPanel
                      label="Receipt Number"
                      description={value?.row?.original?.receiptNumber}
                    />
                    <DescriptionPanel
                      label="Transaction Ref Number"
                      description={value?.row?.original?.transactionRefNumber}
                    />
                    <DescriptionPanel
                      label="Status"
                      description={`${value?.row?.original?.statusCode}+(${value?.row?.original?.statusMessage})`}
                    />
                    <DescriptionPanel
                      label="Product Type Name"
                      description={value?.row?.original?.productTypeName}
                    />
                    <DescriptionPanel
                      label="Batch Type Name"
                      description={value?.row?.original?.batchTypeName}
                    />
                    <DescriptionPanel
                      label="Service Provider"
                      description={value?.row?.original?.serviceProviderName}
                    />
                    <DescriptionPanel
                      label="Integration Name"
                      description={value?.row?.original?.intgName}
                    />
                    <DescriptionPanel
                      label="Is Status Queryable?"
                      description={
                        value?.row?.original?.isStatusQueryable ? "Yes" : "No"
                      }
                    />
                    <DescriptionPanel
                      label="Completed on"
                      description={dayjs(
                        value?.row?.original?.datetimeCompleted
                      ).format("YYYY-MM-DD hh:mm A")}
                    />
                  </div>
                </>
              );
            }}
          />
        )}
      </div>
    </Layout>
  );
};
