import { FC } from "react";
import { ChevronLeftIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import classNames from "classnames";

interface GoBackLinkProps {
  path: string;
  label?: string;
}

export const GoBackLink: FC<GoBackLinkProps> = ({
  path,
  label = "Go Back",
}) => {
  return (
    <div className="flex items-center ml-2 space-x-1">
      <ChevronLeftIcon className="flex-shrink-0 h-6 w-6" aria-hidden="true" />
      <Link className={classNames("text-lg hover:text-gray-700")} to={path}>
        {label}
      </Link>
    </div>
  );
};
