import { createReducer } from "@reduxjs/toolkit";
import { Types } from "./actionTypes";
import { PaginationProps } from "../App";

export interface InitialProductState {
  cache: Record<string, any>;
  products: any[];
  productsPagination: PaginationProps;
  productModals: any;
  productsLoaders: any;
  selectedProduct: any;
}

const initialState: InitialProductState = {
  products: [],
  productsPagination: {
    number: 0,
    totalPages: 1,
    size: 10,
    totalElements: 0,
  },

  productModals: {
    addProduct: false,
    editProduct: false,
    deleteProduct: false,
    disableProduct: false,
    disableEnableProduct: false,
  },
  productsLoaders: {
    submit: false,
    fetchProducts: false,
    fetchTrasactionFees: false,
  },

  cache: {
    emailRequests: {},
    smsRequests: {},
    inAppRequests: {},
  },
  selectedProduct: {},
};

export const productsReducer = createReducer(initialState, {
  [Types.UPDATE_SELECTED_PRODUCT]: (state, action) => {
    state.selectedProduct = action.payload;
  },
  [Types.TOGGLE_PRODUCT_MODALS]: (state, action) => {
    state.productModals = {
      ...state.productModals,
      ...action.payload,
    };
  },

  [Types.FETCH_PRODUCTS]: (state) => {
    state.productsLoaders.fetchProducts = true;
  },
  [Types.FETCH_PRODUCTS_SUCCESS]: (state, action) =>
    // {
    //   const {
    //     data,
    //   } = action.payload;
    //   state.productsLoaders.fetchProducts = false;
    //   state.products = data;
    // },
    {
      const {
        data: { content, page },
      } = action.payload;
      state.productsLoaders.fetchProducts = false;
      state.products = content;
      state.productsPagination = {
        number: page.number,
        size: page.size,
        totalPages: page.totalPages,
        totalElements: page.totalElements,
      };
    },
  [Types.FETCH_PRODUCTS_FAIL]: (state) => {
    state.productsLoaders.fetchProducts = false;
  },

  [Types.CREATE_PRODUCT]: (state) => {
    state.productsLoaders.submit = true;
  },
  [Types.CREATE_PRODUCT_SUCCESS]: (state, action) => {
    state.productsLoaders.submit = false;
    state.products = [action.payload.data, ...state.products];
  },
  [Types.CREATE_PRODUCT_FAIL]: (state) => {
    state.productsLoaders.submit = false;
  },

  [Types.UPDATE_PRODUCT]: (state) => {
    state.productsLoaders.submit = true;
  },

  [Types.UPDATE_PRODUCT_SUCCESS]: (state, action) => {
    const {
      previousAction: {
        payload: {
          request: {
            product: { id },
            product,
          },
        },
      },
    } = action.meta;
    state.productsLoaders.submit = false;
    state.products = [
      ...state.products.map((pr) => (pr.id === id ? product : pr)),
    ];
  },
  [Types.UPDATE_PRODUCT_FAIL]: (state) => {
    state.productsLoaders.submit = false;
  },

  [Types.CHANGE_PRODUCT_STATUS]: (state) => {
    state.productsLoaders.submit = true;
  },

  [Types.CHANGE_PRODUCT_STATUS_SUCCESS]: (state, action) => {
    const {
      previousAction: {
        payload: {
          request: {
            selectedProduct: { id },
            selectedProduct,
          },
        },
      },
    } = action.meta;
    state.productsLoaders.submit = false;
    state.products = [
      ...state.products.map((product) =>
        product.id === id ? selectedProduct : product
      ),
    ];
  },
  [Types.CHANGE_PRODUCT_STATUS_FAIL]: (state) => {
    state.productsLoaders.submit = false;
  },
});
