import { FC, Fragment } from "react";
import { MenuAlt2Icon } from "@heroicons/react/outline";
import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toggleSidebar, UserMenuNavigationProps } from "../../domains/App";
import { Avatar } from ".";
import { InitialAuthStateProps } from "../../domains/Auth";
import { NavbarContext } from "./NavbarContext";

interface NavbarProps {
  navigation: UserMenuNavigationProps[];
}

export const Navbar: FC<NavbarProps> = ({ navigation }) => {
  const dispatch = useDispatch();
  const user = useSelector(
    (state: { auth: InitialAuthStateProps }) => state.auth.authUser
  );

  const handleOpenSidebar = () => dispatch(toggleSidebar(true));

  return (
    <div className="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
      <button
        className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-tandaPurple md:hidden"
        onClick={handleOpenSidebar}
      >
        <span className="sr-only">Open sidebar</span>
        <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
      </button>
      <div className="flex-1 px-4 flex justify-between">
        <div className="flex-1 flex items-center">
          <NavbarContext />
        </div>
        <div className="ml-4 flex items-center md:ml-6">
          {/* Profile dropdown */}
          <Menu as="div" className="ml-3 relative">
            {({ open }) => (
              <>
                <div>
                  <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-tandaPurple">
                    <span className="sr-only">Open user menu</span>
                    <Avatar size={10} name={user?.displayName} />
                  </Menu.Button>
                </div>
                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    {navigation.map((navigate) => (
                      <Menu.Item key={navigate.label}>
                        {({ active }) => (
                          <Link
                            to={navigate.path}
                            className={classNames(
                              "block px-4 py-2 text-sm text-gray-700",
                              {
                                "bg-gray-100": active,
                              }
                            )}
                          >
                            {navigate.label}
                          </Link>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
      </div>
    </div>
  );
};
