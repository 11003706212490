import { FC, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Modal, Upload } from "../../App";
import {
  InitialUserStateProps,
  toggleUserModal,
  uploadContacts,
  fetchContacts,
} from "../../../domains/Users";
import { openNotification } from "../../../domains/App";
import _ from "lodash";

export const ContactUpload: FC = () => {
  const dispatch = useDispatch();
  const { userModals, userLoader } = useSelector(
    (state: { users: InitialUserStateProps }) => state.users
  );
  const { groupId } = useParams<{ groupId: string }>();
  const [uploadedFile, setUploadedFile] = useState<any>();

  const onClose = async () => {
    setUploadedFile(null);
    await dispatch(
      toggleUserModal({
        contactUpload: false,
      })
    );
  };
  const onFileChange = (e: any) => {
    setUploadedFile(e.target.files[0]);
  };

  const onSubmit = async () => {
    try {
      if (_.isEmpty(uploadedFile?.name)) {
        throw Error("No file selected for upload");
      }
      const formData = new FormData();
      formData.append("file", uploadedFile, uploadedFile.name);
      await dispatch(
        uploadContacts({
          groupId,
          data: formData,
        })
      );
      await dispatch(fetchContacts(groupId));
      await dispatch(
        openNotification({
          openNotification: true,
          type: "success",
          message: "Contacts added!",
        })
      );
    } catch (e: any) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: e?.message || "Something went wrong",
        })
      );
    }
    await onClose();
  };

  return (
    <Modal
      modalTitle="Upload Contacts"
      isOpen={userModals.contactUpload}
      onClose={onClose}
      onSubmit={onSubmit}
      btnLoading={userLoader.submit}
    >
      <Upload
        id="upload_contacts"
        label="Upload CSV(only)"
        uploadedFile={uploadedFile}
        onFileChange={onFileChange}
      />
    </Modal>
  );
};
