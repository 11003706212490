import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Banners, SuccessOrErrorState, GoBackLink } from "../../App";
import { WithdrawalTable } from "./WithdrawalTable";
import { WithdrawalForm } from "./WithdrawalForm";
import {
  fetchWithdrawals,
  InitialWithdrawalState,
} from "../../../domains/Withdrawal";
import { config } from "../../../constants";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { DescriptionPanel } from "../../Messaging";
import dayjs from "dayjs";

export const WithdrawalPage: FC = () => {
  const dispatch = useDispatch();
  const { withdrawals, withdrawalsLoaders, withdrawalsPagination } =
    useSelector(
      (state: { withdrawal: InitialWithdrawalState }) => state.withdrawal
    );

  const { productId = "" } = useParams<{ productId?: string }>();
  useEffect(() => {
    const fetchWithdrawalsAsync = async () =>
      await dispatch(fetchWithdrawals(productId));

    fetchWithdrawalsAsync();
  }, [dispatch, productId]);

  const handlePagination = (page: number) => {
    dispatch(
      fetchWithdrawals(productId, {
        page: page - 1,
        size: withdrawalsPagination.size,
      })
    );
  };

  return (
    <Layout>
      <div>
        <Banners>
          <GoBackLink path="/products/bcws" />
          <p className="pl-3">Business Client Wallets Withdrawals?</p>
        </Banners>
      </div>
      <WithdrawalForm bSWallet={productId} />
      <div className="pt-3">
        {_.isEmpty(withdrawals) && !withdrawalsLoaders.fetchWithdrawals ? (
          <SuccessOrErrorState
            state="empty"
            message="No Business Client Wallets Withdrawals Found"
          />
        ) : (
          <WithdrawalTable
            columns={config.bswallets.withdrawals.listColumns}
            data={withdrawals}
            pagination={withdrawalsPagination}
            loading={withdrawalsLoaders.fetchWithdrawals}
            onPaginationChange={handlePagination}
            title={config.bswallets.withdrawals.title}
            SubComponent={(value: any) => {
              return (
                <>
                  <div className="w-full pt-2 pb-4 ml-7 px-4 grid grid-cols-2">
                    <DescriptionPanel
                      label="Available Balance Before"
                      description={"KES ".concat(
                        value?.row?.original?.availableBalanceBefore
                      )}
                    />
                    <DescriptionPanel
                      label="Destination Account Type"
                      description={value?.row?.original?.destinationAccountType}
                    />

                    <DescriptionPanel
                      label="Status Code"
                      description={value?.row?.original?.status}
                    />
                    <DescriptionPanel
                      label="Service Provider Code"
                      description={value?.row?.original?.spCode}
                    />
                    <DescriptionPanel
                      label="Cost"
                      description={value?.row?.original?.cost}
                    />
                    <DescriptionPanel
                      label="Message"
                      description={value?.row?.original?.message}
                    />
                    <DescriptionPanel
                      label="Transaction Ref"
                      description={value?.row?.original?.transactionRef}
                    />
                    <DescriptionPanel
                      label="Date Fulfilled"
                      description={dayjs(
                        value?.row?.original?.lastModified
                      ).format("YYYY-MM-DD hh:mm A")}
                    />
                    <DescriptionPanel
                      label="Narration"
                      description={value?.row?.original?.narration}
                    />
                  </div>
                </>
              );
            }}
          />
        )}
      </div>
    </Layout>
  );
};
