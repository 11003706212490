import { createReducer } from "@reduxjs/toolkit";
import { Types } from "./actionTypes";
import { PaginationProps } from "../App";
import _ from "lodash";

export interface InitialTransactionFeeRateState {
  cache: Record<string, any>;
  agentTransactionFees: any[];
  selectedTransactionFeeRate: any;
  transactionFeeRatesPagination: PaginationProps;
  transactionFeeRateModals: any;
  transactionFeeRatesLoaders: any;
}

const initialState: InitialTransactionFeeRateState = {
  agentTransactionFees: [],
  transactionFeeRatesPagination: {
    number: 0,
    totalPages: 1,
    size: 10,
    totalElements: 0,
  },
  selectedTransactionFeeRate: {},
  transactionFeeRatesLoaders: {
    submit: false,
    fetchTransactionFeeRates: false,
  },
  transactionFeeRateModals: {
    addTransactionFeeRate: false,
    editTransactionFeeRate: false,
    deleteTransactionFeeRate: false,
    disableTransactionFeeRate: false,
  },
  cache: {},
};

export const transactionFeeRatesReducer = createReducer(initialState, {
  [Types.TOGGLE_TRANSACTIONFEERATE_MODALS]: (state, action) => {
    state.transactionFeeRateModals = {
      ...state.transactionFeeRateModals,
      ...action.payload,
    };
  },

  [Types.FETCH_AGENT_TRANS_FEES]: (state) => {
    state.transactionFeeRatesLoaders.fetchTransactionFeeRates = true;
  },
  [Types.FETCH_AGENT_TRANS_FEES_SUCCESS]: (state, action) => {
    const {
      data: { content, page },
    } = action.payload;
    state.transactionFeeRatesLoaders.fetchTransactionFeeRates = false;
    state.agentTransactionFees = content;
    state.transactionFeeRatesPagination = {
      number: page.number,
      size: page.size,
      totalPages: page.totalPages,
      totalElements: page.totalElements,
    };
  },
  [Types.FETCH_AGENT_TRANS_FEES_FAIL]: (state) => {
    state.transactionFeeRatesLoaders.fetchTransactionFeeRates = false;
  },

  [Types.CREATE_TRANSACTIONFEERATE]: (state) => {
    state.transactionFeeRatesLoaders.submit = true;
  },
  [Types.CREATE_TRANSACTIONFEERATE_SUCCESS]: (state, action) => {
    state.transactionFeeRatesLoaders.submit = false;
    state.agentTransactionFees = [
      action.payload.data,
      ...state.agentTransactionFees,
    ];
  },
  [Types.CREATE_TRANSACTIONFEERATE_FAIL]: (state) => {
    state.transactionFeeRatesLoaders.submit = false;
  },

  [Types.DELETE_TRANSACTIONFEERATE]: (state) => {
    state.transactionFeeRatesLoaders.submit = true;
  },
  [Types.DELETE_TRANSACTIONFEERATE_SUCCESS]: (state, action) => {
    const {
      previousAction: {
        payload: {
          request: {
            deleteProp: { id },
          },
        },
      },
    } = action.meta;
    state.agentTransactionFees = [
      ...state.agentTransactionFees.filter(
        (agentTransactionFee) => agentTransactionFee.id !== id
      ),
    ];
    if (_.isEmpty(state.agentTransactionFees)) {
      state.transactionFeeRatesPagination.number = 0;
    }
    state.transactionFeeRatesLoaders.submit = false;
  },
  [Types.DELETE_TRANSACTIONFEERATE_FAIL]: (state) => {
    state.transactionFeeRatesLoaders.submit = false;
  },

  [Types.UPDATE_TRANSACTIONFEERATE]: (state) => {
    state.transactionFeeRatesLoaders.submit = true;
  },
  [Types.UPDATE_TRANSACTIONFEERATE_SUCCESS]: (state, action) => {
    const {
      previousAction: {
        payload: {
          request: {
            transaction: { id },
            transaction,
          },
        },
      },
    } = action.meta;
    state.transactionFeeRatesLoaders.submit = false;
    state.agentTransactionFees = [
      ...state.agentTransactionFees.map((agentTransactionFee) =>
        agentTransactionFee.id === id ? transaction : agentTransactionFee
      ),
    ];
  },
  [Types.UPDATE_TRANSACTIONFEERATE_FAIL]: (state) => {
    state.transactionFeeRatesLoaders.submit = false;
  },

  [Types.CHANGE_TRANS_STATUS]: (state) => {
    state.transactionFeeRatesLoaders.submit = true;
  },

  [Types.CHANGE_TRANS_STATUS_SUCCESS]: (state, action) => {
    const {
      previousAction: {
        payload: {
          request: {
            selectedTransactionFeeRate: { id },
            selectedTransactionFeeRate,
          },
        },
      },
    } = action.meta;
    state.transactionFeeRatesLoaders.submit = false;
    state.agentTransactionFees = [
      ...state.agentTransactionFees.map((agentTransactionFee) =>
        agentTransactionFee.id === id
          ? selectedTransactionFeeRate
          : agentTransactionFee
      ),
    ];
  },
  [Types.CHANGE_TRANS_STATUS_FAIL]: (state) => {
    state.transactionFeeRatesLoaders.submit = false;
  },
});
