export const Types: Record<string, string> = {
  TOGGLE_TRANSACTIONFEERATE_MODALS: "TOGGLE_TRANSACTIONFEERATE_MODALS",

  FETCH_TRANSACTIONFEERATES: "FETCH_TRANSACTIONFEERATES",
  FETCH_TRANSACTIONFEERATES_SUCCESS: "FETCH_TRANSACTIONFEERATES_SUCCESS",
  FETCH_TRANSACTIONFEERATES_FAIL: "FETCH_TRANSACTIONFEERATES_FAIL",

  CREATE_TRANSACTIONFEERATE: "CREATE_TRANSACTIONFEERATE",
  CREATE_TRANSACTIONFEERATE_SUCCESS: "CREATE_TRANSACTIONFEERATE_SUCCESS",
  CREATE_TRANSACTIONFEERATE_FAIL: "CREATE_TRANSACTIONFEERATE_FAIL",

  UPDATE_TRANSACTIONFEERATE: "UPDATE_TRANSACTIONFEERATE",
  UPDATE_TRANSACTIONFEERATE_SUCCESS: "UPDATE_TRANSACTIONFEERATE_SUCCESS",
  UPDATE_TRANSACTIONFEERATE_FAIL: "UPDATE_TRANSACTIONFEERATE_FAIL",

  DELETE_TRANSACTIONFEERATE: "DELETE_TRANSACTIONFEERATE",
  DELETE_TRANSACTIONFEERATE_SUCCESS: "DELETE_TRANSACTIONFEERATE_SUCCESS",
  DELETE_TRANSACTIONFEERATE_FAIL: "DELETE_TRANSACTIONFEERATE_FAIL",

  FETCH_AGENT_TRANS_FEES: "FETCH_AGENT_TRANS_FEES",
  FETCH_AGENT_TRANS_FEES_SUCCESS: "FETCH_AGENT_TRANS_FEES_SUCCESS",
  FETCH_AGENT_TRANS_FEES_FAIL: "FETCH_AGENT_TRANS_FEES_FAIL",

  CHANGE_TRANS_STATUS: "CHANGE_TRANS_STATUS",
  CHANGE_TRANS_STATUS_SUCCESS: "CHANGE_TRANS_STATUS_SUCCESS",
  CHANGE_TRANS_STATUS_FAIL: "CHANGE_TRANS_STATUS_FAIL",
};
