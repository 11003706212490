import _ from "lodash";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Layout,
  Loader,
  Pagination,
  SuccessOrErrorState,
  Table,
} from "../../App";
import dayjs from "dayjs";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { openNotification } from "../../../domains/App";
import { TextField } from "../../App/FormComponents";
import {
  fetchSubWalletBalances,
  fetchSubWallets,
  InitialSubWalletsState,
} from "../../../domains/SubWallets";
import { formatAmount } from "../../../utils/app";
import { updateSelectedReseller } from "../../../domains/Reseller/Reseller";

interface SearchParams {
  page: number;
  size: number;
  since: any;
  till: any;
  q: string;
}

export const SubWalletsPage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  let defaultSince =
    dayjs(new Date().setDate(new Date().getDate() - 1))
      .toISOString()
      .split(".")[0] + "Z";
  let defaultTill = dayjs(new Date()).toISOString().split(".")[0] + "Z";

  const {
    subWallets,
    subWalletsLoaders,
    subWalletsPagination,
    subWalletBalances,
  } = useSelector(
    (state: { subWallets: InitialSubWalletsState }) => state.subWallets
  );
  const handlePagination = (page: number) => {
    dispatch(
      fetchSubWallets({
        page: page - 1,
        size: 9,
      })
    );
  };

  useEffect(() => {
    const fetchSubWalletBalancesAsync = async () => {
      await dispatch(fetchSubWalletBalances());
    };

    const fetchSubWalletsAsync = async () => {
      await dispatch(
        fetchSubWallets({
          page: 0,
          size: 9,
        })
      );
    };

    fetchSubWalletsAsync();
    fetchSubWalletBalancesAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { control, reset, handleSubmit } = useForm<SearchParams>({
    defaultValues: {
      page: 0,
      size: 9,
      since: defaultSince,
      till: defaultTill,
    },
  });

  const onAccountSubmit: SubmitHandler<SearchParams> = async (props) => {
    const search = props.q;
    try {
      await dispatch(
        fetchSubWallets({
          search,
          page: 0,
          size: 9,
        })
      );
    } catch (error) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Something went wrong",
        })
      );
    }
    // dispatch(updateCollectionsSearchParams(params));
    // try {
    //   await dispatch(fetchCollections(defaultUrl, params));
    // } catch (e) {
    //   dispatch(
    //     openNotification({
    //       openNotification: true,
    //       type: "error",
    //       message: "Something went wrong",
    //     })
    //   );
    // }
  };
  const onReset = () => {
    reset();
    dispatch(
      fetchSubWallets({
        page: 0,
        size: 9,
      })
    );
  };

  const handleRefresh = async () => {
    await dispatch(fetchSubWalletBalances());
  };

  const handleView = (row: any) => {
    dispatch(updateSelectedReseller(row?.org));
    history.push(
      `/tanda-io/resellers/${row?.org?.id}/sub-wallets/${row.id}/payments`
    );
  };

  return (
    <Layout>
      <div>
        <div className="">
          <div className="flex flex-row-reverse mb-4">
            <Button onClick={handleRefresh}>Refresh</Button>
          </div>
          {subWalletsLoaders.fetchSubWalletBalances ? (
            <div className="mt-4 rounded-lg flex flex-row items-center justify-center w-full h-24">
              <Loader />
            </div>
          ) : (
            <div className="flex flex-row gap-4 mb-4 w-full">
              <div className="bg-white rounded-lg overflow-hidden p-8 w-full ">
                <div className="items-start flex flex-col gap-2">
                  <p className="text-3xl font-medium text-gray-600">
                    {subWalletBalances.countOfWallets}
                  </p>
                  <p className="text-gray-500">Count of Sub Wallets</p>
                </div>
              </div>
              <div className="bg-white rounded-lg overflow-hidden p-8 w-full ">
                <div className="items-start flex flex-col gap-2">
                  <p className="text-3xl font-medium text-gray-600">
                    {formatAmount(subWalletBalances.sumOfActual)}
                  </p>
                  <p className="text-gray-500">Sum of Sub Wallets Actual</p>
                </div>
              </div>
              <div className="bg-white rounded-lg overflow-hidden p-8 w-full ">
                <div className="items-start flex flex-col gap-2">
                  <p className="text-3xl font-medium text-gray-600">
                    {formatAmount(subWalletBalances.sumOfAvailable)}
                  </p>
                  <p className="text-gray-500">Sum of Sub Wallets Available</p>
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-col lg:flex-row-reverse justify-between items-center">
            <form
              id="transaction filters"
              className="space-y-2"
              onSubmit={handleSubmit(onAccountSubmit)}
            >
              <div className="flex flex-col space-x-2 lg:flex-row justify-between items-center">
                <Controller
                  control={control}
                  name="q"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      id="q"
                      placeholder="Account"
                      type="text"
                      value={value}
                      error={error?.message}
                      onChange={onChange}
                    />
                  )}
                />
                <div className="flex flex-row items-center -mt-1">
                  <div className="mr-2">
                    <Button btnClass="primary" size="md" type="submit">
                      Search
                    </Button>
                  </div>
                  <div>
                    <Button
                      btnClass="primary"
                      size="md"
                      type="reset"
                      onClick={() => onReset()}
                    >
                      Clear
                    </Button>
                  </div>
                </div>
              </div>
            </form>
            {/* <div className="ml-2 text-lg">List of Transactions</div> */}
          </div>
          {_.isEmpty(subWallets) && !subWalletsLoaders.fetchSubWallets ? (
            <SuccessOrErrorState
              state="empty"
              message={`No Sub Wallets Found`}
            />
          ) : (
            <>
              {subWalletsLoaders.fetchSubWallets ? (
                <div className="mt-4 rounded-lg flex flex-row items-center justify-center w-full h-60 bg-white">
                  <Loader />
                </div>
              ) : (
                <div>
                  <Table
                    columns={[
                      {
                        id: "name",
                        Header: "Name",
                        accessor: (row: any) => (
                          <div>
                            <span
                              className="text-tandaPurple"
                              // onClick={goToPath(row)}
                            >
                              {row.name}
                            </span>
                          </div>
                        ),
                      },
                      {
                        id: "number",
                        Header: "Number",
                        accessor: (row: any) => {
                          return <span>{row?.account}</span>;
                        },
                      },
                      {
                        id: "actual",
                        Header: "Actual(KES)",
                        accessor: (row: any) => {
                          return <span>{row?.actual}</span>;
                        },
                      },
                      {
                        id: "available",
                        Header: "Available(KES)",
                        accessor: (row: any) => {
                          return <span>{row?.available}</span>;
                        },
                      },
                      {
                        id: "actions",
                        accessor: (row: any) => {
                          return (
                            <div className="flex flex-row items-center space-x-2">
                              <Button
                                onClick={() => handleView(row)}
                                size="xs"
                                variant="primary"
                              >
                                View
                              </Button>
                            </div>
                          );
                        },
                      },
                    ]}
                    data={subWallets}
                  />
                  <div>
                    <div className="pb-2 mt-4 flex flex-row-reverse justify-between">
                      <Pagination
                        currentPage={subWalletsPagination.number + 1}
                        totalPages={subWalletsPagination.totalPages}
                        onPaginationChange={handlePagination}
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};
