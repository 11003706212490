export enum MessagingTabs {
  SmsRequests = "smsRequests",
  EmailRequests = "emailRequests",
  InAppRequests = "inAppRequests",
  Outbox = "outbox",
}

export const messageConfig: any = {
  tables: {
    messages: {
      label: "Messages",
      properties: [
        {
          label: "Sender",
          rowData: "from",
        },
        {
          label: "Reciever",
          rowData: "to",
        },
        {
          label: "Request Type",
          rowData: "requestType",
        },
        {
          label: "Status",
          rowData: "status",
        },
      ],
    },
    templates: {
      label: "Message Templates",
      editable: true,
      deleatable: true,
      properties: [
        {
          label: "Name",
          rowData: "name",
        },
        {
          label: "Body",
          rowData: "body",
          truncateSize: 80,
        },
      ],
      viewMore: {
        properties: [
          {
            label: "Name",
            property: "name",
          },
          {
            label: "Body",
            property: "body",
          },
        ],
      },
    },
    smsRequests: {
      label: "Sms Requests",
      properties: [
        {
          label: "Provider Name",
          rowData: "sp.name",
        },
        {
          label: "Body",
          rowData: "body",
          truncateSize: 80,
        },
        {
          label: "Sent At",
          rowData: "dateCreated",
          isDate: true,
        },
      ],
      viewMore: {
        properties: [
          {
            label: "Provider Name",
            property: "sp.name",
          },
          {
            label: "Body",
            property: "body",
          },
          {
            label: "Sent At",
            property: "dateCreated",
          },
        ],
      },
    },
    serviceProviders: {
      label: "Service Providers",
      editable: true,
      properties: [
        {
          label: "Name",
          rowData: "name",
          hasLink: true,
        },
        {
          label: "Api Id",
          rowData: "apiIntgId",
        },
        {
          label: "Api name",
          rowData: "apiIntgName",
        },
        {
          label: "Channel",
          rowData: "channel",
        },
      ],
      viewMore: {
        properties: [
          {
            label: "Name",
            property: "name",
          },
          {
            label: "Api Id",
            property: "apiIntgId",
          },
          {
            label: "Api name",
            property: "apiIntgName",
          },
          {
            label: "Channel",
            property: "channel",
          },
        ],
      },
    },
    spProps: {
      label: "Properties",
      editable: true,
      deleatable: true,
      properties: [
        {
          label: "Name",
          rowData: "name",
        },
        {
          label: "ID",
          rowData: "propId",
        },
        {
          label: "Value",
          rowData: "propValue",
        },
      ],
    },
    templateVariables: {
      label: "Variables",
      singleSelectable: true,
      properties: [
        {
          label: "Name",
          rowData: "name",
        },
        {
          label: "Description",
          rowData: "description",
        },
      ],
    },
    editorTemplates: {
      label: "Message Templates",
      singleSelectable: true,
      properties: [
        {
          label: "Name",
          rowData: "name",
        },
        {
          label: "Body",
          rowData: "body",
          truncateSize: 150,
        },
      ],
    },
    campaignServiceProviders: {
      label: "Service Providers",
      singleSelectable: true,
      properties: [
        {
          label: "Name",
          rowData: "name",
        },
        {
          label: "Api Id",
          rowData: "apiIntgId",
        },
        {
          label: "Api name",
          rowData: "apiIntgName",
        },
        {
          label: "Channel",
          rowData: "channel",
        },
      ],
    },
    emailRequests: {
      label: "Email Requests",
      properties: [
        {
          label: "Provider Name",
          rowData: "sp.name",
        },
        {
          label: "Subject",
          rowData: "subject",
        },
        {
          label: "Body",
          rowData: "body",
          truncateSize: 80,
        },
        {
          label: "Sent At",
          rowData: "dateCreated",
          isDate: true,
        },
      ],
      viewMore: {
        properties: [
          {
            label: "Provider Name",
            property: "sp.name",
          },
          {
            label: "Subject",
            property: "subject",
          },
          {
            label: "Body",
            property: "body",
          },
          {
            label: "Sent At",
            property: "dateCreated",
          },
          {
            label: "CC Emails",
            property: "ccEmails",
          },
          {
            label: "BCC Emails",
            property: `bccEmails`,
          },
          {
            label: "Groups",
            property: "toGroups",
            pathProperty: "name",
          },
        ],
      },
    },
    inAppRequests: {
      label: "In-App Requests",
      properties: [
        {
          label: "Provider Name",
          rowData: "sp.name",
        },
        {
          label: "Subject",
          rowData: "subject",
        },
        {
          label: "Body",
          rowData: "body",
          truncateSize: 80,
        },
        {
          label: "Status",
          rowData: "statusDescription",
        },
        {
          label: "Sent At",
          rowData: "dateCreated",
          isDate: true,
        },
      ],
      viewMore: {
        properties: [
          {
            label: "Provider Name",
            property: "sp.name",
          },
          {
            label: "Subject",
            property: "subject",
          },
          {
            label: "Body",
            property: "body",
          },
          {
            label: "Status",
            property: "statusDescription",
          },
          {
            label: "Sent At",
            property: "dateCreated",
          },
          {
            label: "Groups",
            property: "groups",
            pathProperty: "name",
          },
        ],
      },
    },
  },
  forms: {
    group: [
      {
        type: "default",
        value: "",
        label: "Name",
        width: "full",
      },
      {
        type: "textarea",
        value: "",
        label: "Description",
        width: "full",
      },
    ],
    contact: [
      {
        type: "default",
        value: "",
        label: "First Name",
        width: "full",
      },
      {
        type: "default",
        value: "",
        label: "Middle Name",
        width: "full",
      },
      {
        type: "default",
        value: "",
        label: "Last Name",
        width: "full",
      },
      {
        type: "default",
        value: "",
        label: "Phone Number",
        width: "full",
      },
      {
        type: "default",
        value: "",
        label: "Email Address",
        width: "full",
      },
    ],
    serviceProvider: [
      {
        type: "default",
        value: "",
        label: "Name",
        width: "half",
      },
      {
        type: "default",
        value: "",
        label: "Api IntgId",
        width: "half",

        returnAs: "apiIntgId",
      },
      {
        type: "default",
        value: "",
        label: "Api IntgName",
        width: "half",

        returnAs: "apiIntgName",
      },
      {
        type: "select",
        label: "Channel",
        value: "Sms",
        width: "half",
        options: [
          {
            value: "Sms",
            label: "SMS",
          },
          {
            value: "emails",
            label: "Emails",
          },
        ],
      },
    ],
    template: [
      {
        type: "default",
        value: "",
        label: "Name",
        width: "full",
      },
      {
        type: "customEditor",
        value: "",
        label: "Message",
        width: "full",

        returnAs: "body",
      },
    ],
    editTemplate: [
      {
        type: "default",
        value: "name",
        label: "Name",
        width: "full",
      },
      {
        type: "customEditor",
        value: "body",
        label: "Message",
        width: "full",

        returnAs: "body",
      },
    ],
    editServiceProvider: [
      {
        type: "default",
        value: "name",
        label: "Name",
        width: "half",
      },
      {
        type: "default",
        value: "apiIntgId",
        label: "Api IntgId",
        width: "half",

        returnAs: "apiIntgId",
      },
      {
        type: "default",
        value: "apiIntgName",
        label: "Api IntgName",
        width: "half",

        returnAs: "apiIntgName",
      },
      {
        type: "select",
        label: "Channel",
        value: "channel",
        width: "half",
        options: [
          {
            value: "Sms",
            label: "SMS",
          },
          {
            value: "emails",
            label: "Emails",
          },
        ],
      },
    ],
    editSPProp: [
      {
        type: "default",
        value: "name",
        label: "Name",
      },
      {
        type: "default",
        value: "propId",
        label: "ID",

        returnAs: "propId",
      },
      {
        type: "default",
        value: "propValue",
        label: "Value",
      },
    ],
    spProp: [
      {
        type: "default",
        value: "",
        label: "Name",
      },
      {
        type: "default",
        value: "",
        label: "ID",

        returnAs: "propId",
      },
      {
        type: "default",
        value: "",
        label: "Value",

        returnAs: "propValue",
      },
      {
        type: "textarea",
        value: "",
        label: "Description",
        width: "full",
      },
    ],
  },
};

export const MessageCampaignSteps = [
  "Select Service Provider",
  "Select Contacts",
  "Write Message",
];

export const WeekDays = ["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"];

interface TimePickerConfigProps {
  timeFormat: 24 | 12;
  minuteStep: number;
}

export const TimePickerConfig: TimePickerConfigProps = {
  timeFormat: 12,
  minuteStep: 15,
};
