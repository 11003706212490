import { createReducer } from "@reduxjs/toolkit";
import _ from "lodash";
import { PaginationProps } from "../../App";
import { Types } from "./actionTypes";

export interface InitialResellerDfsCommissionRateState {
  cache: Record<string, any>;
  resellerDfsCommissionRates: any[];
  selectedResellerDfsCommissionRate: any;
  resellerDfsCommissionRatesPagination: PaginationProps;
  resellerDfsCommissionRateModals: any;
  resellerDfsCommissionRatesLoaders: any;
}

const initialState: InitialResellerDfsCommissionRateState = {
  resellerDfsCommissionRates: [],
  resellerDfsCommissionRatesPagination: {
    number: 0,
    totalPages: 0,
    size: 100,
    totalElements: 0,
  },
  selectedResellerDfsCommissionRate: {},
  resellerDfsCommissionRatesLoaders: {
    submit: false,
    fetchResellerDfsCommissionRates: false,
  },
  resellerDfsCommissionRateModals: {
    addResellerDfsCommissionRate: false,
    editResellerDfsCommissionRate: false,
    deleteResellerDfsCommissionRate: false,
  },
  cache: {},
};

export const resellerDfsCommissionRatesReducer = createReducer(initialState, {
  [Types.TOGGLE_RESELLER_DFS_COMMISSION_RATE_MODALS]: (state, action) => {
    state.resellerDfsCommissionRateModals = {
      ...state.resellerDfsCommissionRateModals,
      ...action.payload,
    };
  },

  [Types.FETCH_RESELLER_DFS_COMMISSION_RATES]: (state) => {
    state.resellerDfsCommissionRatesLoaders.fetchResellerDfsCommissionRates =
      true;
  },
  [Types.FETCH_RESELLER_DFS_COMMISSION_RATES_SUCCESS]: (state, action) => {
    const { data } = action.payload;
    state.resellerDfsCommissionRatesLoaders.fetchResellerDfsCommissionRates =
      false;
    state.resellerDfsCommissionRates = data;
  },
  [Types.FETCH_RESELLER_DFS_COMMISSION_RATES_FAIL]: (state) => {
    state.resellerDfsCommissionRatesLoaders.fetchResellerDfsCommissionRates =
      false;
  },

  [Types.CREATE_RESELLER_DFS_COMMISSION_RATE]: (state) => {
    state.resellerDfsCommissionRatesLoaders.submit = true;
  },
  [Types.CREATE_RESELLER_DFS_COMMISSION_RATE_SUCCESS]: (state, action) => {
    state.resellerDfsCommissionRatesLoaders.submit = false;
    state.resellerDfsCommissionRates = [
      action.payload.data,
      ...state.resellerDfsCommissionRates,
    ];
  },
  [Types.CREATE_RESELLER_DFS_COMMISSION_RATE_FAIL]: (state) => {
    state.resellerDfsCommissionRatesLoaders.submit = false;
  },

  [Types.DELETE_RESELLER_DFS_COMMISSION_RATE]: (state) => {
    state.resellerDfsCommissionRatesLoaders.submit = true;
  },
  [Types.DELETE_RESELLER_DFS_COMMISSION_RATE_SUCCESS]: (state, action) => {
    const {
      previousAction: {
        payload: {
          request: {
            deleteProp: { id },
          },
        },
      },
    } = action.meta;
    state.resellerDfsCommissionRates = [
      ...state.resellerDfsCommissionRates.filter(
        (resellerDfsCommissionRate) => resellerDfsCommissionRate.id !== id
      ),
    ];
    if (_.isEmpty(state.resellerDfsCommissionRates)) {
      state.resellerDfsCommissionRatesPagination.number = 0;
    }
    state.resellerDfsCommissionRatesLoaders.submit = false;
  },
  [Types.DELETE_RESELLER_DFS_COMMISSION_RATE_FAIL]: (state) => {
    state.resellerDfsCommissionRatesLoaders.submit = false;
  },

  [Types.UPDATE_RESELLER_DFS_COMMISSION_RATE]: (state) => {
    state.resellerDfsCommissionRatesLoaders.submit = true;
  },
  [Types.UPDATE_RESELLER_DFS_COMMISSION_RATE_SUCCESS]: (state, action) => {
    state.resellerDfsCommissionRatesLoaders.submit = false;
    state.resellerDfsCommissionRates = [
      ...state.resellerDfsCommissionRates.map((sp) =>
        sp.id === action.payload.data.id ? action.payload.data : sp
      ),
    ];
  },
  [Types.UPDATE_RESELLER_DFS_COMMISSION_RATE_FAIL]: (state) => {
    state.resellerDfsCommissionRatesLoaders.submit = false;
  },
});
