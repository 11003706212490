import { Types } from "./actionTypes";
import { getURL } from "../../utils";

export const fetchAllGroups = (pagination?: any) => async (dispatch: any) => {
  await dispatch({
    type: Types.FETCH_GROUPS,
    payload: {
      request: {
        method: "GET",
        url: getURL("/mc-messaging/v1/groups", pagination),
        useCache: true,
      },
    },
  });
};

export const fetchContacts =
  (groupId: any, pagination?: any) => async (dispatch: any) => {
    await dispatch({
      type: Types.FETCH_CONTACTS,
      payload: {
        request: {
          method: "GET",
          url: getURL(
            `/mc-messaging/v1/groups/${groupId}/contacts`,
            pagination
          ),
          useCache: true,
        },
      },
    });
  };

export const createGroup = (data: any) => async (dispatch: any) => {
  await dispatch({
    type: Types.CREATE_GROUP,
    payload: {
      request: {
        url: "/mc-messaging/v1/groups",
        method: "POST",
        data,
      },
    },
  });
};

export const createContact =
  ({ groupId, data }: any) =>
  async (dispatch: any) => {
    await dispatch({
      type: Types.CREATE_CONTACT,
      payload: {
        request: {
          url: `/mc-messaging/v1/groups/${groupId}/contacts`,
          method: "POST",
          data,
        },
      },
    });
  };

export const deleteContact = (contact: any) => async (dispatch: any) =>
  await dispatch({
    type: Types.DELETE_CONTACT,
    payload: {
      request: {
        url: `/mc-messaging/v1/contacts/${contact.id}`,
        method: "DELETE",
      },
    },
  });

export const deleteGroup = (group: any) => async (dispatch: any) =>
  await dispatch({
    type: Types.DELETE_GROUP,
    payload: {
      request: {
        url: `/mc-messaging/v1/groups/${group.id}`,
        method: "DELETE",
      },
    },
  });

export const updateUserTab = (value: string) => async (dispatch: any) =>
  await dispatch({
    type: Types.CHANGE_USERS_TABS,
    payload: value,
  });

export const toggleContacts = (user: any) => async (dispatch: any) =>
  dispatch({
    type: Types.TOGGLE_SELECTED_CONTACT,
    payload: {
      user,
    },
  });

export const toggleGroups = (user: any) => async (dispatch: any) =>
  dispatch({
    type: Types.TOGGLE_SELECTED_GROUP,
    payload: {
      user,
    },
  });

export const toggleUserModal = (payload: any) => async (dispatch: any) =>
  await dispatch({
    type: Types.TOGGLE_USER_MODAL,
    payload,
  });

export const updateSelectedUser =
  ({ userType, user }: any) =>
  async (dispatch: any) =>
    await dispatch({
      type: Types[`update_selected_${userType}`.toUpperCase()],
      payload: user,
    });

export const uploadContacts =
  ({ groupId, data }: any) =>
  async (dispatch: any) => {
    await dispatch({
      type: Types.UPLOAD_CONTACTS,
      payload: {
        request: {
          url: `/mc-messaging/v1/groups/${groupId}/uploads`,
          method: "POST",
          data,
        },
      },
    });
  };

export const updateUploadedContacts = (payload: any) => async (dispatch: any) =>
  await dispatch({
    type: Types.UPDATE_UPLOAD_CONTACTS,
    payload,
  });

export const fetchGroup = (groupId: any) => async (dispatch: any) =>
  await dispatch({
    type: Types.FETCH_INDIVIDUAL_GROUP,
    payload: {
      request: {
        method: "GET",
        url: `/mc-messaging/v1/groups/${groupId}`,
        useCache: true,
      },
    },
  });

export const updateGroup =
  (groupId: string, data: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.UPDATE_GROUP,
      payload: {
        request: {
          url: `/mc-messaging/v1/groups/${groupId}`,
          method: "PUT",
          data,
        },
      },
    });

export const updateContact =
  (contactId: string, data: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.UPDATE_CONTACT,
      payload: {
        request: {
          url: `/mc-messaging/v1/contacts/${contactId}`,
          method: "PUT",
          data,
        },
      },
    });

export const clearContacts = () => async (dispatch: any) =>
  dispatch({
    type: Types.CLEAR_CONTACTS,
  });
