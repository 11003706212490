import { joiResolver } from "@hookform/resolvers/joi";
import { FC } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { schemaValidation } from "../../../constants";
import { openNotification } from "../../../domains/App";
import {
  createResellerDfsCommissionRate,
  InitialResellerDfsCommissionRateState,
  toggleResellerDfsCommissionRateModal,
} from "../../../domains/Reseller/ResellerDfsCommissionRate";
import { Modal } from "../../App";
import { CheckBox2, Select, TextField } from "../../App/FormComponents";

interface ResellerDfsCommissionRate {
  type: string;
  starting: number;
  ending: number;
  amount: number;
  enabled: boolean;
}

export const ResellerDfsCommissionRateForm: FC = () => {
  const dispatch = useDispatch();
  const { resellerDfsCommissionRateModals, resellerDfsCommissionRatesLoaders } =
    useSelector(
      (state: {
        resellerDfsCommissionRate: InitialResellerDfsCommissionRateState;
      }) => state.resellerDfsCommissionRate
    );
  const { dfsId = "" } = useParams<{ dfsId?: string }>();

  const { control, handleSubmit, reset } = useForm<ResellerDfsCommissionRate>({
    resolver: joiResolver(schemaValidation.ResellerDfsRateSchema),
    defaultValues: {
      type: "",
      amount: 0,
      starting: 0,
      ending: 0,
      enabled: false,
    },
  });

  const onClose = async () => {
    reset();
    await dispatch(
      toggleResellerDfsCommissionRateModal({
        addResellerDfsCommissionRate: false,
      })
    );
  };

  const onSubmit: SubmitHandler<ResellerDfsCommissionRate> = async (props) => {
    try {
      await dispatch(createResellerDfsCommissionRate(dfsId, props));
      await onClose();
      await dispatch(
        openNotification({
          openNotification: true,
          type: "success",
          message: "Reseller Product Commission Rate added!",
        })
      );
    } catch (e) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Something went wrong",
        })
      );
    }
  };
  return (
    <Modal
      formId="reseller_dfs_commission_rate_form"
      btnType="submit"
      modalTitle="Add a Reseller Product Commission Rate"
      isOpen={resellerDfsCommissionRateModals.addResellerDfsCommissionRate}
      onClose={onClose}
      btnLoading={resellerDfsCommissionRatesLoaders.submit}
    >
      <form
        id="reseller_dfs_commission_rate_form"
        className="space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="type"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Select
              id="type"
              label="Fee Type"
              selectedOption={value}
              error={error?.message}
              onChange={onChange}
              options={[
                {
                  value: "PERCENTAGE",
                  label: "PERCENTAGE",
                },
                {
                  value: "ABS_VAL",
                  label: "ABS_VAL",
                },
                {
                  value: "NONE",
                  label: "NONE",
                },
              ]}
            />
          )}
        />

        <Controller
          control={control}
          name="starting"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="starting"
              label="Starting"
              type="number"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="ending"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="ending"
              label="Ending"
              type="number"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="amount"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="amount"
              label="Amount"
              type="number"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="enabled"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <CheckBox2
              name="enabled"
              label="Enabled"
              value={value}
              onChange={onChange}
            />
          )}
        />
      </form>
    </Modal>
  );
};
