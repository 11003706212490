export const Types: Record<string, string> = {
  CHANGE_WITHDRAWALACCOUNTS_TABS: "CHANGE_WITHDRAWALACCOUNTS_TABS",
  TOGGLE_WITHDRAWALACCOUNT_MODALS: "TOGGLE_WITHDRAWALACCOUNT_MODALS",

  FETCH_WITHDRAWALACCOUNTS: "FETCH_WITHDRAWALACCOUNTS",
  FETCH_WITHDRAWALACCOUNTS_SUCCESS: "FETCH_WITHDRAWALACCOUNTS_SUCCESS",
  FETCH_WITHDRAWALACCOUNTS_FAIL: "FETCH_WITHDRAWALACCOUNTS_FAIL",

  CREATE_WITHDRAWALACCOUNT: "CREATE_WITHDRAWALACCOUNT",
  CREATE_WITHDRAWALACCOUNT_SUCCESS: "CREATE_WITHDRAWALACCOUNT_SUCCESS",
  CREATE_WITHDRAWALACCOUNT_FAIL: "CREATE_WITHDRAWALACCOUNT_FAIL",

  UPDATE_WITHDRAWALACCOUNT: "UPDATE_WITHDRAWALACCOUNT",
  UPDATE_WITHDRAWALACCOUNT_SUCCESS: "UPDATE_WITHDRAWALACCOUNT_SUCCESS",
  UPDATE_WITHDRAWALACCOUNT_FAIL: "UPDATE_WITHDRAWALACCOUNT_FAIL",
};
