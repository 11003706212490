import { createReducer } from "@reduxjs/toolkit";
import { Types } from "./actionTypes";
import { NotificationProps } from "./types";

export interface InitialAppStateProps {
  openSidebar: false;
  notification: NotificationProps;
}

const initialState: InitialAppStateProps = {
  openSidebar: false,
  notification: {
    openNotification: false,
    message: "",
    type: "error",
  },
};

const reducer = createReducer(initialState, {
  [Types.TOGGLE_SIDEBAR]: (state, action) => {
    const {
      payload: { openSidebar },
    } = action;
    return {
      ...state,
      openSidebar,
    };
  },

  [Types.ADD_NOTIFICATION]: (state, action) => {
    state.notification = {
      ...action.payload,
    };
  },
  [Types.REMOVE_NOTIFICATION]: (state, action) => {
    state.notification = {
      openNotification: false,
      message: "",
      type: "error",
    };
  },
});

export const appReducer = reducer;
