import { FC, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { CheckBox2, Select, TextField } from "../../App/FormComponents";
import { Modal } from "../../App";
import {
  InitialCustomerTransactionFeeRateState,
  toggleCustomerTransactionFeeRateModal,
  updateCustomerTransactionFeeRate,
} from "../../../domains/CustomerTransactionFeeRate";
import { openNotification } from "../../../domains/App";
import { schemaValidation } from "../../../constants";
import _ from "lodash";

interface CustomerTransactionFeeRate {
  feeType: string;
  feeAmount: number;
  startingInclusive: number;
  endingInclusive: number;
  enabled: boolean;
  collectionType: string;
}

interface EditCustomerTransactionFeeRateFormProps {
  customerTransactionFeeRate: any;
}

export const EditCustomerTransactionFeeRateForm: FC<EditCustomerTransactionFeeRateFormProps> =
  ({ customerTransactionFeeRate }) => {
    const dispatch = useDispatch();
    const {
      customerTransactionFeeRateModals,
      customerTransactionFeeRatesLoaders,
    } = useSelector(
      (state: {
        customerTransactionFeeRate: InitialCustomerTransactionFeeRateState;
      }) => state.customerTransactionFeeRate
    );
    const { control, handleSubmit, reset } =
      useForm<CustomerTransactionFeeRate>({
        resolver: joiResolver(
          schemaValidation.CustomerTransactionFeeRateSchema
        ),
        defaultValues: {
          feeType: "",
          feeAmount: 0,
          startingInclusive: 0,
          endingInclusive: 0,
        },
      });

    useEffect(() => {
      if (!_.isEmpty(customerTransactionFeeRate)) {
        reset({
          feeType: customerTransactionFeeRate?.feeType || "",
          feeAmount: customerTransactionFeeRate?.feeAmount || "",
          startingInclusive:
            customerTransactionFeeRate?.startingInclusive || "",
          endingInclusive: customerTransactionFeeRate?.endingInclusive || "",
          enabled: customerTransactionFeeRate?.enabled || false,
          collectionType: customerTransactionFeeRate?.collectionType || "",
        });
      }
    }, [customerTransactionFeeRate, reset]);

    const onClose = async () => {
      reset();
      await dispatch(
        toggleCustomerTransactionFeeRateModal({
          editCustomerTransactionFeeRate: false,
        })
      );
    };

    const onSubmit: SubmitHandler<CustomerTransactionFeeRate> = async (
      props
    ) => {
      try {
        customerTransactionFeeRate = {
          ...customerTransactionFeeRate,
          feeType: props?.feeType,
          feeAmount: props?.feeAmount,
          startingInclusive: props?.startingInclusive,
          endingInclusive: props?.endingInclusive,
          enabled: props?.enabled,
          collectionType: props?.collectionType,
        };
        await dispatch(
          updateCustomerTransactionFeeRate(customerTransactionFeeRate, props)
        );
        await onClose();
        await dispatch(
          openNotification({
            openNotification: true,
            type: "success",
            message: "Customer Transaction Fee updated!",
          })
        );
      } catch (e) {
        dispatch(
          openNotification({
            openNotification: true,
            type: "error",
            message: "Something went wrong",
          })
        );
      }
    };
    return (
      <Modal
        formId="customer_transaction_fee_form"
        btnType="submit"
        modalTitle="Edit Customer Transaction Fee"
        isOpen={customerTransactionFeeRateModals.editCustomerTransactionFeeRate}
        onClose={onClose}
        btnLoading={customerTransactionFeeRatesLoaders.submit}
      >
        <form
          id="customer_transaction_fee_form"
          className="space-y-2"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            control={control}
            name="feeType"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Select
                id="feeType"
                label="Fee Type"
                selectedOption={value}
                error={error?.message}
                onChange={onChange}
                options={[
                  {
                    value: "PERCENTAGE",
                    label: "PERCENTAGE",
                  },
                  {
                    value: "ABS_VAL",
                    label: "ABS_VAL",
                  },
                  // {
                  //   value: "RATIO",
                  //   label: "RATIO",
                  // },
                  {
                    value: "NONE",
                    label: "NONE",
                  },
                ]}
              />
            )}
          />
          <Controller
            control={control}
            name="feeAmount"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id="feeAmount"
                label="Fee Amount"
                type="text"
                value={value}
                error={error?.message}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="startingInclusive"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id="startingInclusive"
                label="Starting Inclusive"
                type="text"
                value={value}
                error={error?.message}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="endingInclusive"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id="endingInclusive"
                label="Ending Inclusive"
                type="text"
                value={value}
                error={error?.message}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="enabled"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <CheckBox2
                label="Enabled"
                value={value}
                onChange={onChange}
                name="enabled"
              />
            )}
          />
          <Controller
            control={control}
            name="collectionType"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Select
                id="collectionType"
                label="Collection Type"
                selectedOption={value}
                error={error?.message}
                onChange={onChange}
                options={[
                  {
                    value: "MARK_DOWN",
                    label: "MARK_DOWN",
                  },
                  {
                    value: "MARK_UP",
                    label: "MARK_UP",
                  },
                ]}
              />
            )}
          />
        </form>
      </Modal>
    );
  };
