import { AltAuthLayout, LoginForm } from ".";

export const LoginPage = () => {
  return (
    <AltAuthLayout
      message="Sign in to your account"
      showRegistrationLink={true}
    >
      <LoginForm />
    </AltAuthLayout>
  );
};
