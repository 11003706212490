import { FC } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { schemaValidation } from "../../constants";
import { authLogin, InitialAuthStateProps } from "../../domains/Auth";
import { openNotification } from "../../domains/App";
import { Button } from "../App";

interface LoginInput {
  email: string;
  password: string;
}

export const LoginForm: FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginInput>({
    resolver: joiResolver(schemaValidation.LoginSchema),
  });
  const dispatch = useDispatch();
  const { authLoader } = useSelector(
    (state: { auth: InitialAuthStateProps }) => state.auth
  );
  const history = useHistory();

  const onSubmit: SubmitHandler<LoginInput> = async (data) => {
    try {
      await dispatch(authLogin(data));
      history.push("/messages");
    } catch (error) {
      await dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Invalid email or password",
        })
      );
    }
  };

  return (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <label
          htmlFor="email"
          className="block text-md font-medium text-gray-700"
        >
          Email address
        </label>
        <div className="mt-1">
          <input
            id="email"
            type="email"
            autoComplete="email"
            className="appearance-none block w-full px-3 py-4 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-tandaGreen focus:border-tandaGreen md:text-md"
            {...register("email")}
            aria-describedby="email-error"
          />
        </div>
        {errors.email && (
          <p className="mt-2 text-sm text-red-600" id="email-error">
            {errors.email?.message}
          </p>
        )}
      </div>
      <div>
        <label
          htmlFor="password"
          className="block text-md font-medium text-gray-700"
        >
          Password
        </label>
        <div className="mt-1">
          <input
            id="password"
            type="password"
            autoComplete="current-password"
            className="appearance-none block w-full px-3 py-4 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-tandaGreen focus:border-tandaGreen md:text-md"
            {...register("password")}
            aria-describedby="password-error"
          />
        </div>
        {errors.password && (
          <p className="mt-2 text-sm text-red-600" id="password-error">
            {errors.password?.message}
          </p>
        )}
      </div>
      <div>
        <Button
          btnClass="w-full flex justify-center py-4 px-4 border border-transparent rounded-md shadow-sm text-base font-medium"
          loading={authLoader.submit}
          type="submit"
          variant="secondary"
        >
          Login
        </Button>
      </div>
    </form>
  );
};
