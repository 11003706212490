import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader, Button, Table } from "../../App";
import { PaginationProps } from "../../../domains/App";
import { RadioBox } from "../../App/FormComponents";
import { EditCommissionFeeRateForm } from "../CommissionFeeRates";
import {
  changeStatus,
  deleteCommissionFeeRate,
  InitialCommissionFeeRateState,
  toggleCommissionFeeRateModal,
} from "../../../domains/CommissionFeeRate";
import { ToggleModal } from "../../App/ToggleModal";
import { InitialProductState } from "../../../domains/Products";
import { ToggleButton } from "../../App/FormComponents/ToggleButton";

interface CommissionFeeRateTableProps {
  control?: any;
  columns: any[];
  data: any[];
  onChange?: (value: string) => void;
  onPaginationChange?: (page: number) => void;
  pagination: PaginationProps;
  loading: boolean;
}

export const CommissionFeeRateTable: FC<CommissionFeeRateTableProps> = ({
  columns,
  data,
  onChange = (value) => {},
  onPaginationChange,
  pagination,
  loading,
}) => {
  const dispatch = useDispatch();
  let [selectedCommissionFeeRate, setSelectedCommissionFeeRate] =
    useState<any>();

  const { commissionFeeRateModals, commissionFeeRatesLoaders } = useSelector(
    (state: { commissionFeeRate: InitialCommissionFeeRateState }) =>
      state.commissionFeeRate
  );
  const { selectedProduct } = useSelector(
    (state: { product: InitialProductState }) => state.product
  );
  const handleOpenDeleteModal = (id: string) => {
    setSelectedCommissionFeeRate(id);
    dispatch(
      toggleCommissionFeeRateModal({
        deleteCommissionFeeRate: true,
      })
    );
  };

  const handleCloseDeleteModal = () =>
    dispatch(
      toggleCommissionFeeRateModal({
        deleteCommissionFeeRate: false,
      })
    );

  const handleOpenDisableModal = (id: string) => {
    setSelectedCommissionFeeRate(id);
    dispatch(
      toggleCommissionFeeRateModal({
        disableCommissionFeeRate: true,
      })
    );
  };

  const handleCloseDisableModal = () =>
    dispatch(
      toggleCommissionFeeRateModal({
        disableCommissionFeeRate: false,
      })
    );
  const onDelete = async () => {
    if (!!selectedCommissionFeeRate) {
      await dispatch(
        deleteCommissionFeeRate({ id: selectedCommissionFeeRate })
      );
    }
    handleCloseDeleteModal();
  };
  const onToggleStatus = async () => {
    const status = {
      enabled: !selectedCommissionFeeRate.enabled,
    };
    selectedCommissionFeeRate = {
      ...selectedCommissionFeeRate,
      enabled: !selectedCommissionFeeRate.enabled,
    };
    if (!!selectedCommissionFeeRate) {
      await dispatch(changeStatus(selectedCommissionFeeRate, status));
    }
    handleCloseDisableModal();
  };

  const status = selectedCommissionFeeRate?.enabled ? "Disable" : "Enable";
  const message =
    "Are you sure you want to " + status + " this Transaction Fee Rate?";

  const handleEditModal = (value: any) => {
    setSelectedCommissionFeeRate(value);
    dispatch(
      toggleCommissionFeeRateModal({
        editCommissionFeeRate: true,
      })
    );
  };

  const getColumns = () => {
    const availableColumns = [
      {
        id: "feeType",
        Header: "Fee Type",
        accessor: "feeType",
      },
      {
        id: "feeType",
        Header: "Fee Type",
        accessor: "feeType",
      },
      {
        id: "feeAmount",
        Header: "Fee Amount(KES)",
        accessor: (row: any) => {
          return <span>{(row?.feeAmount).toFixed(2)}</span>;
        },
      },
      {
        id: "startingInclusive",
        Header: "Starting Inclusive(KES)",
        accessor: (row: any) => {
          return <span>{(row?.startingInclusive).toFixed(2)}</span>;
        },
      },
      {
        id: "endingInclusive",
        Header: "Ending Inclusive(KES)",
        accessor: (row: any) => {
          return <span>{(row?.endingInclusive).toFixed(2)}</span>;
        },
      },
      {
        id: "enabled",
        Header: "Status",
        accessor: (row: any) => {
          return <span>{row?.enabled ? "Enabled" : "Disabled"}</span>;
        },
      },

      {
        id: "actions",
        accessor: (row: any) => {
          return (
            <div className="flex flex-row items-center space-x-2">
              {row?.enabled ? (
                <ToggleButton
                  onChange={() => handleOpenDisableModal(row)}
                  name={"status"}
                  value={row?.enabled}
                >
                  Disable
                </ToggleButton>
              ) : (
                <ToggleButton
                  onChange={() => handleOpenDisableModal(row)}
                  name={"status"}
                  value={row?.enabled}
                >
                  Disable
                </ToggleButton>
              )}
              <Button
                onClick={() => handleOpenDeleteModal(row.id)}
                size="xs"
                variant="danger"
              >
                Delete
              </Button>
              <Button
                onClick={() => handleEditModal(row)}
                size="xs"
                variant="primary"
              >
                Edit
              </Button>
            </div>
          );
        },
      },
      {
        id: "select",
        accessor: (row: any) => {
          return (
            <RadioBox
              name="commissionFeeRate"
              onChange={onChange}
              value={JSON.stringify(row)}
            />
          );
        },
      },
    ];

    return columns.map((column) =>
      availableColumns.find(
        (availableColumn) => availableColumn.id === column.id
      )
    );
  };

  if (loading) {
    return (
      <div className="mt-4 rounded-lg flex flex-row items-center justify-center w-full h-60 bg-white">
        <Loader />
      </div>
    );
  }
  return (
    <>
      <ToggleModal
        isOpen={commissionFeeRateModals.deleteCommissionFeeRate}
        onClose={handleCloseDeleteModal}
        onSubmit={onDelete}
        message="Are you sure you want to delete this Commission Fee Rate?"
        title="Delete"
        loading={commissionFeeRatesLoaders.submit}
      />
      <ToggleModal
        isOpen={commissionFeeRateModals.disableCommissionFeeRate}
        onClose={handleCloseDisableModal}
        onSubmit={onToggleStatus}
        message={message}
        title={status}
        loading={commissionFeeRatesLoaders.submit}
      />
      <EditCommissionFeeRateForm
        commissionFeeRate={selectedCommissionFeeRate}
      />

      <Table
        title={selectedProduct.name}
        columns={getColumns()}
        data={data}
        pageCount={pagination.totalPages}
        currentPage={pagination.number}
        onPaginationChange={onPaginationChange}
      />
    </>
  );
};
