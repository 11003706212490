import { createReducer } from "@reduxjs/toolkit";
import { Types } from "./actionTypes";

export interface InitialReportState {
  cache: Record<string, any>;
  reportsLoaders: any;
  reportsModels: any;
}

const initialState: InitialReportState = {
  reportsLoaders: {
    submit: false,
    fetchStatement: false,
  },
  reportsModels: {
    downloadStatement: false,
  },
  cache: {},
};

export const reportReducer = createReducer(initialState, {
  [Types.TOGGLE_REPORT_MODALS]: (state, action) => {
    state.reportsModels = {
      ...state.reportsModels,
      ...action.payload,
    };
  },
});
