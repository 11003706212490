import { FC } from "react";
import classNames from "classnames";

interface TextAreaProps {
  error?: string;
  id: string;
  label?: string;
  rows?: number;
  placeholder?: string;
  onChange?: (value: any) => void;
  inputClass?: string;
  value?: string;
}

export const TextArea: FC<TextAreaProps> = ({
  error,
  id,
  inputClass = "",
  label,
  placeholder,
  rows = 4,
  onChange,
  value,
}) => {
  return (
    <div className="mb-2 w-full">
      {label && (
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <div className="mt-1">
        <textarea
          id={id}
          rows={rows}
          className={classNames(
            "shadow-sm block w-full sm:text-sm border rounded-md",
            {
              "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500":
                !!error,
              "focus:ring-tandaPurple focus:border-tandaPurple border-gray-300":
                !error,
            },
            inputClass
          )}
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange?.(e.target.value)}
        />
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
          {error}
        </p>
      )}
    </div>
  );
};
