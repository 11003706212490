import { FC, Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  XIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from "@heroicons/react/outline";
import classNames from "classnames";
import { useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Disclosure } from "@headlessui/react";
import {
  InitialAppStateProps,
  NavigationProps,
  toggleSidebar,
} from "../../../domains/App";
import { matchSidebarPaths } from "../../../utils";

interface SmallScreenDeviceSidebarProps {
  navigation: NavigationProps[];
  iconMapping: Record<
    string,
    FC<{
      className: string;
    }>
  >
}

export const SmallScreenDeviceSidebar: FC<SmallScreenDeviceSidebarProps> = ({
  navigation, iconMapping
}) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const sidebarOpen = useSelector(
    (state: { app: InitialAppStateProps }) => state.app.openSidebar
  );
  const handleToggleSidebar = (openSidebar: boolean) =>
    dispatch(toggleSidebar(openSidebar));
  const handleCloseSidebar = () => handleToggleSidebar(false);
  let defaultFocusRef = useRef(null);

  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 flex z-40 md:hidden"
        open={sidebarOpen}
        onClose={handleToggleSidebar}
        initialFocus={defaultFocusRef}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-tandaPurple">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={handleCloseSidebar}
                  ref={defaultFocusRef}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 flex items-center px-4">
              <img
                className="h-16 w-auto"
                src="/images/logo-lg.png"
                alt="Workflow"
              />
            </div>
            <div className="mt-5 flex-1 h-0 overflow-y-auto">
              <nav className="px-2 space-y-1">
                {navigation.map((navigate) => {
                  const pathMatches = matchSidebarPaths(
                    pathname,
                    navigate.path
                  );
                  const pathIncludes = pathname.includes(navigate.path);
                  const Icon = iconMapping[navigate.icon];
                  if (navigate.children) {
                    return (
                      <Disclosure
                        as="div"
                        key={navigate.label}
                        className="space-y-1"
                      >
                        {({ open }) => (
                          <>
                            <Disclosure.Button
                              className={classNames(
                                "group flex items-center px-2 py-2 text-sm font-medium rounded-md w-full",
                                {
                                  "bg-tandaPurple2 text-white": pathIncludes,
                                  "text-gray-300 hover:bg-gray-800 hover:text-white":
                                    !pathIncludes,
                                }
                              )}
                            >
                              <Icon
                                className={classNames(
                                  "mr-3 flex-shrink-0 h-6 w-6",
                                  {
                                    "text-gray-500": !pathIncludes,
                                    "text-gray-300 group-hover:text-gray-400":
                                      pathIncludes,
                                  }
                                )}
                                aria-hidden="true"
                              />
                              {navigate.label}
                              {open ? (
                                <ChevronUpIcon
                                  className={classNames(
                                    "ml-auto flex-shrink-0 h-4 w-4",
                                    {
                                      "text-gray-500": !pathIncludes,
                                      "text-gray-300 group-hover:text-gray-400":
                                        pathIncludes,
                                    }
                                  )}
                                />
                              ) : (
                                <ChevronDownIcon
                                  className={classNames(
                                    "ml-auto flex-shrink-0 h-4 w-4",
                                    {
                                      "text-gray-500": !pathIncludes,
                                      "text-gray-300 group-hover:text-gray-400":
                                        pathIncludes,
                                    }
                                  )}
                                />
                              )}
                            </Disclosure.Button>
                            <Disclosure.Panel className="space-y-1">
                              {navigate.children?.map((item) => {
                                const pathMatches = matchSidebarPaths(
                                  pathname,
                                  item.path
                                );
                                return (
                                  <Link
                                    key={item.label}
                                    to={item.path}
                                    className={classNames(
                                      "group flex items-center pl-11 pr-2 py-2 text-sm font-medium rounded-md",
                                      {
                                        "bg-tandaPurple2 text-white":
                                          pathMatches,
                                        "text-gray-300 hover:bg-gray-800 hover:text-white":
                                          !pathMatches,
                                      }
                                    )}
                                  >
                                    {item.label}
                                  </Link>
                                );
                              })}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    );
                  }
                  return (
                    <Link
                      key={navigate.label}
                      to={navigate.path}
                      className={classNames(
                        "group flex items-center px-2 py-2 text-sm font-medium rounded-md",
                        {
                          "bg-gray-900 text-white": pathMatches,
                          "text-gray-300 hover:bg-gray-700 hover:text-white":
                            !pathMatches,
                        }
                      )}
                    >
                      <Icon
                        className={classNames("mr-3 flex-shrink-0 h-6 w-6", {
                          "text-gray-500": pathMatches,
                          "text-gray-400 group-hover:text-gray-500":
                            !pathMatches,
                        })}
                        aria-hidden="true"
                      />
                      {navigate.label}
                    </Link>
                  );
                })}
              </nav>
            </div>
          </div>
        </Transition.Child>
        <div className="flex-shrink-0 w-14" aria-hidden="true">
          {/* Dummy element to force sidebar to shrink to fit close icon */}
        </div>
      </Dialog>
    </Transition.Root>
  );
};
