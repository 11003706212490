import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { Loader } from "../../App";
import { EditBSWalletForm } from "./BSWalletEdit";
import {
  toggleBSWalletModal,
  updateSelectedBSWallet,
} from "../../../domains/BSWallet";
import { useHistory } from "react-router-dom";
import {
  CodeIcon,
  LibraryIcon,
  DeviceMobileIcon,
  PencilAltIcon,
  EyeIcon,
  SwitchVerticalIcon,
} from "@heroicons/react/outline";
import { toggleWithdrawalModal } from "../../../domains/Withdrawal";
import { WithdrawalForm } from "../Withdrawal";

interface BSWalletTableProps {
  data: any[];
  loading: boolean;
  title: string;
}

export const BSWalletTable: FC<BSWalletTableProps> = ({ data, loading }) => {
  const dispatch = useDispatch();
  const [selectedBSWallet, setSelectedBSWallet] = useState<any>();
  const history = useHistory();

  const handleEditModal = (value: any) => {
    setSelectedBSWallet(value);
    dispatch(
      toggleBSWalletModal({
        editBSWallet: true,
      })
    );
  };
  const handleModal = async (row: any) => {
    setSelectedBSWallet(row);
    await dispatch(
      toggleWithdrawalModal({
        addWithdrawal: true,
      })
    );
  };
  const goToPath = (bswallet: any) => () => {
    dispatch(updateSelectedBSWallet(bswallet));
    history.push(`/products/bcws/${bswallet.id}/withdrawals`);
  };
  const getIcons = (type: any) => {
    switch (type) {
      case "THIRD_PARTY_WALLET":
        return <CodeIcon className="h-6" />;
      case "BANK_ACCOUNT":
        return <LibraryIcon className="h-6" />;
      default:
        return <DeviceMobileIcon className="h-6" />;
    }
  };

  if (loading) {
    return (
      <div className="mt-4 rounded-lg flex flex-row items-center justify-center w-full h-60 bg-white">
        <Loader />
      </div>
    );
  }
  return (
    <>
      <EditBSWalletForm bSWallet={selectedBSWallet} />
      <WithdrawalForm bSWallet={selectedBSWallet} />

      <>
        <div className="grid mx-2 md:grid-cols-2 lg:grid-cols-2 gap-3">
          {data.map((row: any) => (
            <div className="h-50 p-3 bg-white shadow-sm rounded">
              <div className="flex justify-between">
                <div className="flex">
                  <div className="has-tooltip h-16 w-16 bg-green-100  tandaIconBackground rounded-full center-contents">
                    {getIcons(row.type)}
                    <span className="tooltip rounded shadow-md p-1 bg-gray-100 text-tandaRed mt-16 ml-10">
                      {row?.type}
                    </span>
                  </div>
                  <div
                    id="tooltip-hover"
                    role="tooltip"
                    className="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip dark:bg-gray-700"
                  >
                    Tooltip content
                    <div className="tooltip-arrow" data-popper-arrow></div>
                  </div>
                  <div className="px-2 pb-3">
                    <p className="font-bold mb-2">{row?.api?.name}</p>
                    <p className="text-xs mb-1">
                      {row?.name}({row.number})
                    </p>
                  </div>
                </div>
                <div
                  className="text-tandaGreen text-xs mt-2 mr-3"
                  onClick={() => {
                    handleEditModal(row);
                  }}
                >
                  <PencilAltIcon className="h-4 w-5 cursor-pointer" />
                </div>
              </div>
              <div className="flex flex-row justify-between ml-8 -mt-2">
                <div className="px-2 pb-1 ml-8">
                  <p className="font-medium mb-1">Actual</p>
                  <p className="text-xs mb-1">KES {row?.actual}</p>
                </div>
                <div className="px-2 pb-1 ml-8">
                  <p className="font-medium mb-1">Available</p>
                  <p className="text-xs mb-1">KES {row?.available}</p>
                </div>
              </div>
              <hr className="divide-y divide-gray-400 hover:divide-y-8 -mx-3" />
              <div className="flex py-2 lg:ml-16 space-x-1 lg:space-x-5">
                <div className="center-contents">
                  <div className="flex justify-left">
                    {row?.withdrawable ? (
                      <button
                        className="ml-1 px-4 py-1 text-sm font-medium inline-flex items-center border
                       border-transparent rounded-3xl
                       shadow-sm text-white bg-tandaRed
                      hover:bg-red-700 focus:outline-none 
                      focus:ring-2 focus:ring-offset-2 
                      focus:ring-red-500"
                        onClick={() => {
                          handleModal(row);
                        }}
                      >
                        <div className="center-contents">
                          <SwitchVerticalIcon className="h-4 mr-1 w-4" />
                        </div>
                        Withdraw
                      </button>
                    ) : (
                      <button
                        className="ml-1 px-4 py-1 text-sm font-medium inline-flex items-center border border-transparent 
                        rounded-3xl shadow-sm text-white bg-gray-400 hover:bg-gray-700 
                        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        onClick={() => {}}
                      >
                        <div className="center-contents">
                          <SwitchVerticalIcon className="h-4 mr-1 w-4" />
                        </div>
                        Withdraw
                      </button>
                    )}
                  </div>
                </div>
                <div
                  className="font-bold text-xs text-gray-500 cursor-pointer hover:text-tandaGreen flex"
                  onClick={goToPath(row)}
                >
                  <div className="center-contents">
                    <EyeIcon className="h-4 mr-1 w-4" />
                  </div>
                  <div className="center-contents">View</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    </>
  );
};
