export const Types: Record<string, string> = {
  FETCH_SUB_WALLETS: "FETCH_SUB_WALLETS",
  FETCH_SUB_WALLETS_SUCCESS: "FETCH_SUB_WALLETS_SUCCESS",
  FETCH_SUB_WALLETS_FAIL: "FETCH_SUB_WALLETS_FAIL",

  FETCH_SUB_WALLET_BALANCES: "FETCH_SUB_WALLET_BALANCES",
  FETCH_SUB_WALLET_BALANCES_SUCCESS: "FETCH_SUB_WALLET_BALANCES_SUCCESS",
  FETCH_SUB_WALLET_BALANCES_FAIL: "FETCH_SUB_WALLET_BALANCES_FAIL",

  FETCH_ORG_SUB_WALLET_BALANCES: "FETCH_ORG_SUB_WALLET_BALANCES",
  FETCH_ORG_SUB_WALLET_BALANCES_SUCCESS:
    "FETCH_ORG_SUB_WALLET_BALANCES_SUCCESS",
  FETCH_ORG_SUB_WALLET_BALANCES_FAIL: "FETCH_ORG_SUB_WALLET_BALANCES_FAIL",
};
