import { FC } from "react";
import { Modal } from "./Modal";

interface DeleteModalProps
{
  isOpen: boolean;
  loading?: boolean;
  message: string;
  onClose: () => void;
  onSubmit: () => void;
}

export const DeleteModal: FC<DeleteModalProps> = ({
  isOpen,
  loading = false,
  message,
  onClose,
  onSubmit,
}) =>
{
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      modalTitle="Delete"
      btnVariant="danger"
      btnLoading={loading}
      onSubmit={onSubmit}
      submitBtnLabel="Delete"
    >
      <p className="text-sm text-gray-900 sm:mt-0 sm:col-span-2">{message}</p>
    </Modal>
  );
};
