import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader, Button, Table } from "../../App";
import { PaginationProps } from "../../../domains/App";
import { RadioBox } from "../../App/FormComponents";
import { EditProductForm } from "./EditProduct";
import {
  changeStatusProduct,
  InitialProductState,
  toggleProductModal,
  updateSelectedProduct,
} from "../../../domains/Products";
import { ToggleModal } from "../../App/ToggleModal";
import { useHistory } from "react-router";
import { ToggleButton } from "../../App/FormComponents/ToggleButton";

interface ProductTableProps {
  control?: any;
  columns: any[];
  data: any[];
  onChange?: (value: string) => void;
  onPaginationChange?: (page: number) => void;
  pagination: PaginationProps;
  loading: boolean;
  title: string;
}

export const ProductTable: FC<ProductTableProps> = ({
  columns,
  data,
  onChange = (value) => {},
  onPaginationChange,
  pagination,
  loading,
  title,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let [selectedProduct, setSelectedProduct] = useState<any>();

  const { productModals, productsLoaders } = useSelector(
    (state: { product: InitialProductState }) => state.product
  );

  const handleOpenModal = (id: string) => {
    setSelectedProduct(id);
    dispatch(
      toggleProductModal({
        disableProduct: true,
      })
    );
  };

  const goToPath = (product: any) => () => {
    dispatch(updateSelectedProduct(product));
    history.push(`/products/${product.id}/agent-transaction-fees`);
  };

  const handleCloseModal = () =>
    dispatch(
      toggleProductModal({
        disableProduct: false,
      })
    );
  const handleEditModal = (value: any) => {
    setSelectedProduct(value);
    dispatch(
      toggleProductModal({
        editProduct: true,
      })
    );
  };

  const onToggleStatus = async () => {
    if (!!selectedProduct) {
      const status = {
        enabled: !selectedProduct.enabled,
        available: !selectedProduct.available,
      };
      selectedProduct = {
        ...selectedProduct,
        enabled: !selectedProduct.enabled,
        available: !selectedProduct.available,
      };
      await dispatch(changeStatusProduct(selectedProduct, status));
    }
    handleCloseModal();
  };

  const status = "Change availability";
  const message = "Are you sure you want to " + status + " this product?";

  const getColumns = () => {
    const availableColumns = [
      {
        id: "name",
        Header: "Name",
        accessor: (row: any) => (
          <div>
            <span
              className="cursor-pointer underline text-tandaPurple"
              onClick={goToPath(row)}
            >
              {row.name}
            </span>
          </div>
        ),
      },
      {
        id: "minimunValue",
        Header: "Minimum(KES)",
        accessor: (row: any) => {
          return <span>{(row?.minimunValue).toFixed(2)}</span>;
        },
      },
      {
        id: "maximumValue",
        Header: "Maximum(KES)",
        accessor: (row: any) => {
          return <span>{(row?.maximumValue).toFixed(2)}</span>;
        },
      },
      {
        id: "sp",
        Header: "Service provider",
        accessor: (row: any) => {
          return <span>{row?.serviceProvider?.name}</span>;
        },
      },
      {
        id: "actions",
        accessor: (row: any) => {
          return (
            <div className="flex flex-row items-center space-x-2">
              <Button
                onClick={() => handleEditModal(row)}
                size="xs"
                variant="primary"
              >
                Edit
              </Button>
              {row?.available ? (
                <ToggleButton
                  onChange={() => handleOpenModal(row)}
                  name={"status"}
                  value={row?.enabled}
                >
                  Unavailable?
                </ToggleButton>
              ) : (
                <ToggleButton
                  onChange={() => handleOpenModal(row)}
                  name={"status"}
                  value={row?.enabled}
                >
                  Available?
                </ToggleButton>
              )}
            </div>
          );
        },
      },
      {
        id: "select",
        accessor: (row: any) => {
          return (
            <RadioBox
              name="serviceProvider"
              onChange={onChange}
              value={JSON.stringify(row)}
            />
          );
        },
      },
    ];

    return columns.map((column) =>
      availableColumns.find(
        (availableColumn) => availableColumn.id === column.id
      )
    );
  };

  if (loading) {
    return (
      <div className="mt-4 rounded-lg flex flex-row items-center justify-center w-full h-60 bg-white">
        <Loader />
      </div>
    );
  }
  return (
    <>
      <ToggleModal
        isOpen={productModals.disableProduct}
        onClose={handleCloseModal}
        onSubmit={onToggleStatus}
        message={message}
        title={status}
        loading={productsLoaders.submit}
      />
      <EditProductForm product={selectedProduct} />

      <Table
        title={title}
        columns={getColumns()}
        data={data}
        pageCount={pagination.totalPages}
        currentPage={pagination.number}
        onPaginationChange={onPaginationChange}
      />
    </>
  );
};
