import _ from "lodash";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCollections,
  InitialWalletState,
  toggleWalletModal,
  updateCollectionsSearchParams,
} from "../../../domains/Reseller/Wallet";
import { Button, Layout, Pagination, SuccessOrErrorState } from "../../App";

import { DownloadIcon } from "@heroicons/react/outline";
import dayjs from "dayjs";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { config } from "../../../constants";
import { openNotification } from "../../../domains/App";
import { TextField } from "../../App/FormComponents";
import { DateTimePicker } from "../../App/FormComponents/DateTimePicker";
import { DescriptionPanel } from "../../Messaging";
import { DownloadStatementForm } from "../Payouts/DownloadStatementForm";
import { ResellerTabs } from "../ResellerTabs";
import { CollectionsTable } from "./CollectionsTable";

interface SearchParams {
  page: number;
  size: number;
  since: any;
  till: any;
  q: string;
}

export const SubWalletCollectionsPage: FC = () => {
  const dispatch = useDispatch();
  let defaultSince =
    dayjs(new Date().setDate(new Date().getDate() - 1))
      .toISOString()
      .split(".")[0] + "Z";
  let defaultTill = dayjs(new Date()).toISOString().split(".")[0] + "Z";
  const params = useParams<any>();

  const defaultUrl = `/io-wallets/v1/organizations/${params.resellerId}/sub-wallets/${params.subWalletId}/payments`;

  const {
    searchCollectionsParams,
    collections,
    walletsLoaders,
    walletsPagination,
  } = useSelector(
    (state: { resellerWallet: InitialWalletState }) => state.resellerWallet
  );
  const handlePagination = (page: number) => {
    dispatch(
      fetchCollections(defaultUrl, {
        page: page - 1,
        size: walletsPagination.size,
        since: searchCollectionsParams?.since || defaultSince,
        till: searchCollectionsParams?.till || defaultTill,
      })
    );
  };

  useEffect(() => {
    const fetchCollectionAsync = async () =>
      await dispatch(
        fetchCollections(defaultUrl, {
          page: 0,
          size: 10,
          since: defaultSince,
          till: defaultTill,
        })
      );
    fetchCollectionAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { control, reset, handleSubmit } = useForm<SearchParams>({
    defaultValues: {
      page: 0,
      size: 10,
      since: defaultSince,
      till: defaultTill,
    },
  });
  const onSubmit: SubmitHandler<SearchParams> = async (props) => {
    const params = {
      page: 0,
      size: 10,
      since: dayjs(props.since).toISOString().split(".")[0] + "Z",
      till: dayjs(props.till).toISOString().split(".")[0] + "Z",
    };
    dispatch(updateCollectionsSearchParams(params));
    try {
      await dispatch(fetchCollections(defaultUrl, params));
    } catch (e) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Something went wrong",
        })
      );
    }
  };

  const onReferenceSubmit: SubmitHandler<SearchParams> = async (props) => {
    const params = {
      search: props?.q?.trim() ?? "",
    };
    dispatch(updateCollectionsSearchParams(params));
    try {
      await dispatch(fetchCollections(defaultUrl, params));
    } catch (e) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Something went wrong",
        })
      );
    }
  };

  const onReset = () => {
    reset();
    dispatch(
      fetchCollections(defaultUrl, {
        page: 0,
        size: 10,
        since: defaultSince,
        till: defaultTill,
      })
    );
  };
  const handleModal = async () => {
    await dispatch(
      toggleWalletModal({
        downloadPayoutsStatement: true,
      })
    );
  };
  return (
    <Layout>
      <div>
        <ResellerTabs />
        <DownloadStatementForm type="Collections" />
        <div className="mt-4">
          <div className="flex flex-col lg:flex-row-reverse justify-between items-center">
            <Button
              variant="danger"
              size="sm"
              type="reset"
              onClick={() => handleModal()}
            >
              <DownloadIcon className="h-4 w-4 mr-3 " /> Statement
            </Button>
            <form
              id="transaction filters"
              className="space-y-2"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="flex flex-col lg:flex-row lg:space-x-2 justify-between items-center">
                <Controller
                  control={control}
                  name="since"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <DateTimePicker
                      id="since"
                      label="Since:"
                      placeholder="Enter time since"
                      value={value}
                      error={error?.message}
                      onChange={onChange}
                      onClick={handleSubmit(onSubmit)}
                      includeTime={true}
                      max={defaultTill}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="till"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <DateTimePicker
                      id="till"
                      label="Until:   "
                      placeholder="Enter till"
                      value={value}
                      error={error?.message}
                      onChange={onChange}
                      onClick={handleSubmit(onSubmit)}
                      includeTime={true}
                      max={defaultTill}
                    />
                  )}
                />
              </div>
            </form>
            <form
              id="transaction filters"
              className="space-y-2"
              onSubmit={handleSubmit(onReferenceSubmit)}
            >
              <div className="flex flex-col space-x-2 lg:flex-row justify-between items-center">
                <Controller
                  control={control}
                  name="q"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      id="q"
                      placeholder="Reference | Transaction ID"
                      type="text"
                      value={value}
                      error={error?.message}
                      onChange={onChange}
                    />
                  )}
                />
                <div className="flex flex-row items-center -mt-1">
                  <div className="mr-2">
                    <Button btnClass="primary" size="md" type="submit">
                      Search
                    </Button>
                  </div>
                  <div>
                    <Button
                      btnClass="primary"
                      size="md"
                      type="reset"
                      onClick={() => onReset()}
                    >
                      Clear
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          {_.isEmpty(collections) && !walletsLoaders.fetchCollections ? (
            <SuccessOrErrorState
              state="empty"
              message={`No Available Collections Found`}
            />
          ) : (
            <div>
              <CollectionsTable
                title={config.walletCollections.title}
                columns={config.walletCollections.listColumns}
                data={collections}
                pagination={walletsPagination}
                onPaginationChange={handlePagination}
                loading={walletsLoaders.fetchCollections}
                SubComponent={(value: any) => {
                  return (
                    <>
                      <div className="w-full pt-2 pb-4 ml-7 px-4 grid grid-cols-2">
                        <DescriptionPanel
                          label="Available Type"
                          description={
                            "KES " +
                            (value.row?.original?.availableBefore).toFixed(2)
                          }
                        />
                        <DescriptionPanel
                          label="Account Type"
                          description={value?.row?.original?.type?.name}
                        />
                        <DescriptionPanel
                          label="Product"
                          description={value?.row?.original?.product?.name}
                        />
                        <DescriptionPanel
                          label="Message"
                          description={value?.row?.original?.message}
                        />
                        <DescriptionPanel
                          label="Date Created"
                          description={dayjs(
                            value?.row?.original?.dateCreated
                          ).format("YYYY-MM-DD hh:mm A")}
                        />
                        <DescriptionPanel
                          label="Date Fulfilled"
                          description={dayjs(
                            value?.row?.original?.lastModified
                          ).format("YYYY-MM-DD hh:mm A")}
                        />
                      </div>
                    </>
                  );
                }}
              />
              <div>
                <div className="pb-2 mt-4 flex flex-row-reverse justify-between">
                  <Pagination
                    currentPage={walletsPagination.number + 1}
                    totalPages={walletsPagination.totalPages}
                    onPaginationChange={handlePagination}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};
