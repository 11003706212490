import { getURL } from "../../../utils";
import { Types } from "./actionTypes";

export const toggleWalletModal = (payload: any) => async (dispatch: any) =>
  dispatch({
    type: Types.TOGGLE_WALLET_MODALS,
    payload,
  });

export const updateSelectedWallet = (payload: any) => async (dispatch: any) => {
  await dispatch({
    type: Types.UPDATE_SELECTED_WALLET,
    payload,
  });
};

export const updatePayoutsSearchParams =
  (payload: any) => async (dispatch: any) => {
    await dispatch({
      type: Types.UPDATE_PAYOUTS_SEARCH_PARAMS,
      payload,
    });
  };

export const updateCollectionsSearchParams =
  (payload: any) => async (dispatch: any) => {
    await dispatch({
      type: Types.UPDATE_COLLECTIONS_SEARCH_PARAMS,
      payload,
    });
  };

export const fetchWallets =
  (resellerId: any, pagination?: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.FETCH_WALLET,
      payload: {
        request: {
          method: "GET",
          url: getURL(
            `/io-wallets/v1/orgs/${resellerId}/balances?accountTypes=01,02`,
            pagination
          ),
          useCache: true,
        },
      },
    });

export const fetchCollectionWallet =
  (resellerId: any, pagination?: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.FETCH_COLLECTION_WALLET,
      payload: {
        request: {
          method: "GET",
          url: getURL(
            `/io-wallets/v1/resellers/${resellerId}/collections/wallet`,
            pagination
          ),
          useCache: true,
        },
      },
    });
export const fetchPayments = (pagination?: any) => async (dispatch: any) =>
  await dispatch({
    type: Types.FETCH_WALLET_PAYMENTS,
    payload: {
      request: {
        method: "GET",
        url: getURL(`/io-wallets/v1/wallets/payments`, pagination),
        useCache: true,
      },
    },
  });

export const fetchOrganizationPayments =
  (orgId: string, pagination?: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.FETCH_WALLET_PAYMENTS,
      payload: {
        request: {
          method: "GET",
          url: getURL(
            `/io-wallets/v1/organizations/${orgId}/payments`,
            pagination
          ),
          useCache: true,
        },
      },
    });
export const fetchPaymentStatus =
  (resellerId: any, requestId?: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.FETCH_PAYMENT_STATUS,
      payload: {
        request: {
          method: "GET",
          url: `/io/v1/organizations/${resellerId}/requests/${requestId}`,
          useCache: true,
        },
      },
    });

export const fetchCollections =
  (url: any, pagination?: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.FETCH_WALLET_COLLECTIONS,
      payload: {
        request: {
          method: "GET",
          url: getURL(`${url}`, pagination),
          useCache: true,
        },
      },
    });

export const updateWallet = (p: any, data: any) => async (dispatch: any) =>
  await dispatch({
    type: Types.UPDATE_RESELLER_WALLET,
    payload: {
      request: {
        url: `/io-wallets/v1/resellers/${p?.id}/collections/wallet`,
        method: "PUT",
        data,
        p,
      },
    },
  });

export const fetchPrimaryCollectionWallet =
  (organizationId: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.FETCH_PRIMARY_COLLECTION_WALLET,
      payload: {
        request: {
          method: "GET",
          url: `/io-wallets/v1/organizations/${organizationId}/sub-wallets/primary`,
          useCache: true,
        },
      },
    });

export const fetchCollectionWallets =
  (resellerId: any, pagination?: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.FETCH_COLLECTION_WALLETS,
      payload: {
        request: {
          method: "GET",
          url: getURL(
            `/io-wallets/v1/organizations/${resellerId}/sub-wallets`,
            pagination
          ),
          useCache: true,
        },
      },
    });
export const createCollectionWallets =
  (resellerId: any, data: any) => async (dispatch: any) => {
    await dispatch({
      type: Types.CREATE_COLLECTION_WALLET,
      payload: {
        request: {
          url: `/io-wallets/v1/resellers/${resellerId}/wallets`,
          method: "POST",
          data,
        },
      },
    });
  };

export const updateCollectionWallet =
  (resellerId: any, wallet: any, data: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.UPDATE_COLLECTION_WALLET,
      payload: {
        request: {
          url: `/io-wallets/v1/resellers/${resellerId}/wallets/${wallet?.id}`,
          method: "PATCH",
          data,
          wallet,
        },
      },
    });

export const creditCollectionWallet =
  (resellerId: any, wallet: any, data: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.CREDIT_COLLECTION_WALLET,
      payload: {
        request: {
          url: `/io-wallets/v1/resellers/${resellerId}/wallets/${wallet?.id}/credits`,
          method: "POST",
          data,
          wallet,
        },
      },
    });
export const debitCollectionWallet =
  (resellerId: any, wallet: any, data: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.DEBIT_COLLECTION_WALLET,
      payload: {
        request: {
          url: `/io-wallets/v1/resellers/${resellerId}/wallets/${wallet?.id}/debits`,
          method: "POST",
          data,
          wallet,
        },
      },
    });
