import { FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { TextField } from "../../App/FormComponents";
import { Modal, TemplateEditor } from "../../App";
import {
  InitialMessageState,
  toggleMessagingModal,
  createTemplate,
} from "../../../domains/Messages";
import { openNotification } from "../../../domains/App";
import { schemaValidation } from "../../../constants";

interface TemplateInput {
  name: string;
  body: string;
}

interface CreateTemplateFormProps {}

export const CreateTemplateForm: FC<CreateTemplateFormProps> = () => {
  const dispatch = useDispatch();
  const { messageModals, messageLoaders } = useSelector(
    (state: { message: InitialMessageState }) => state.message
  );
  const { control, handleSubmit, reset } = useForm<TemplateInput>({
    resolver: joiResolver(schemaValidation.TemplateSchema),
    defaultValues: {
      name: "",
      body: "",
    },
  });

  const onClose = async () => {
    reset();
    await dispatch(
      toggleMessagingModal({
        addTemplates: false,
      })
    );
  };

  const onSubmit: SubmitHandler<TemplateInput> = async (data) => {
    try {
      await dispatch(createTemplate(data));
      await onClose();
      await dispatch(
        openNotification({
          openNotification: true,
          type: "success",
          message: "Template created!",
        })
      );
    } catch (e) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Something went wrong",
        })
      );
    }
  };

  return (
    <Modal
      formId="create_template_form"
      btnType="submit"
      modalTitle="Create Template"
      isOpen={messageModals.addTemplates}
      onClose={onClose}
      btnLoading={messageLoaders.submit}
    >
      <form
        id="create_template_form"
        className="space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="name"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="name"
              label="Name"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="body"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TemplateEditor
              id="body"
              label="Body"
              value={value}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />
      </form>
    </Modal>
  );
};
