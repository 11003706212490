import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  grantApiClientRole,
  InitialResellerState,
  revokeApiClientRole,
  updateSelectedApiClientRoles,
  updateSelectedRole,
} from "../../../domains/Reseller/Reseller";

export const Roles: FC<{ role: any }> = ({ role }) => {
  const dispatch = useDispatch();
  let { selectedReseller, selectedApiClient } = useSelector(
    (state: { reseller: InitialResellerState }) => state.reseller
  );

  const onRevoke = async (roleId: string) => {
    dispatch(updateSelectedApiClientRoles(roleId));
    dispatch(updateSelectedRole(role));
    dispatch(
      revokeApiClientRole(selectedReseller?.id, selectedApiClient?.id, roleId)
    );
  };

  const onGrant = (roleId: string) => {
    dispatch(updateSelectedApiClientRoles(roleId));
    dispatch(updateSelectedRole(role));
    dispatch(
      grantApiClientRole(selectedReseller?.id, selectedApiClient?.id, roleId)
    );
  };

  return (
    <div>
      <div>
        <div className="mx-6 text-gray-600 font-light rounded">
          <div className="py-1 sm:grid sm:py-2 sm:grid-cols-3 sm:gap-2 border-b border-gray-100">
            <dt className="text-sm font-medium text-gray-500">{role.name}</dt>
            <dd className="mt-1 flex cursor-pointer text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <div>
                <div className="flex space-x-5">
                  {role.enabled ? (
                    <div className="center-contents">
                      {/* {!_.isEmpty(selectedRole) &&
                        selectedRole?.id === role.id &&
                        resellersLoaders.revokeRole && <Loader size={5} />} */}
                      <div
                        onClick={() => onRevoke(role?.id)}
                        className="font-bold center-contents rounded-full px-5 py-2 bg-red-50 text-tandaRed text-xs"
                      >
                        Revoke
                      </div>
                    </div>
                  ) : (
                    <div className="center-contents">
                      {/* {!_.isEmpty(selectedRole) &&
                        selectedRole?.id === role.id &&
                        resellersLoaders.grantRole && <Loader size={5} />} */}
                      <div
                        onClick={() => onGrant(role?.id)}
                        className="font-bold center-contents rounded-full px-5 py-2 bg-blue-50 text-tandaPurple text-xs"
                      >
                        Grant
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </dd>
          </div>
        </div>
      </div>
    </div>
  );
};
