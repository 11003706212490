import { FC } from "react";
import classNames from "classnames";
import { ExclamationCircleIcon } from "@heroicons/react/solid";

interface DateTimeProps {
  error?: string;
  endAddOnText?: string;
  EndButtonIcon?: FC<{ className: string }>;
  endButtonText?: string;
  EndIcon?: FC<{ className: string }>;
  id: string;
  label?: string;
  optional?: boolean;
  placeholder?: string;
  type: string;
  startAddOnText?: string;
  StartIcon?: FC<{ className: string }>;
  withEndButton?: boolean;
  onClick?: () => void;
  onChange?: (value: any) => void;
  inputClass?: string;
  value?: string | number;
  min?: string;
  max?: string;
}

export const DateTime: FC<DateTimeProps> = ({
  error,
  endAddOnText,
  EndButtonIcon,
  endButtonText,
  EndIcon,
  id,
  inputClass = "",
  label,
  optional,
  placeholder,
  startAddOnText,
  StartIcon,
  type,
  withEndButton,
  onClick,
  onChange,
  value,
  min,
  max,
}) => {
  return (
    <div className="mb-2 w-full">
      <div className="flex justify-between">
        {label && (
          <label
            htmlFor={id}
            className="block text-sm font-medium text-gray-700"
          >
            {label}
          </label>
        )}
        {optional && (
          <span className="text-sm text-gray-500" id="email-optional">
            Optional
          </span>
        )}
      </div>
      <div
        className={classNames("mt-1 rounded-md shadow-sm", {
          relative: !withEndButton,
          flex: !!withEndButton,
        })}
      >
        <div className="relative flex items-stretch flex-grow focus-within:z-10">
          {StartIcon && (
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <StartIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
          )}
          {startAddOnText && (
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span className="text-gray-500 sm:text-sm">{startAddOnText}</span>
            </div>
          )}
          <input
            type={type}
            name={label}
            id={id}
            min={min}
            max={max}
            className={classNames("block w-full sm:text-sm", {
              "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500":
                !!error,
              "focus:ring-tandaPurple focus:border-tandaPurple border-gray-300":
                !error,
              "rounded-none rounded-l-md": !!withEndButton,
              "rounded-md": !withEndButton,
              "pl-10": !!StartIcon || !!startAddOnText,
              inputClass,
            })}
            placeholder={placeholder}
            aria-describedby={`${label}-end-addon`}
            onChange={(e) => onChange?.(e.target.value)}
            value={value}
          />
          {error && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          )}
          {EndIcon && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              {<EndIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
            </div>
          )}
          {endAddOnText && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span
                className="text-gray-500 sm:text-sm"
                id={`${label}-end-addon`}
              >
                {endAddOnText}
              </span>
            </div>
          )}
        </div>
        {withEndButton && (
          <button
            onClick={onClick}
            className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-tandaPurple focus:border-tandaPurple"
          >
            {EndButtonIcon && (
              <EndButtonIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            )}
            <span>{endButtonText}</span>
          </button>
        )}
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
          {error}
        </p>
      )}
    </div>
  );
};
