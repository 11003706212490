import { FC } from "react";
import { SpeakerphoneIcon } from "@heroicons/react/outline";
import { Button } from ".";

interface BannersProps {
  actionLabel?: string;
  withAction?: boolean;
  onClick?: () => void;
}

export const Banners: FC<BannersProps> = ({
  actionLabel,
  children,
  onClick,
  withAction,
}) => {
  return (
    <div className="bg-gray-200 shadow-inner rounded">
      <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between flex-wrap">
          <div className="w-0 flex-1 flex items-center">
            <span className="flex p-2 rounded-lg bg-gray-800">
              <SpeakerphoneIcon
                className="h-6 w-6 text-white"
                aria-hidden="true"
              />
            </span>
            {children}
          </div>
          <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
            {withAction && (
              <Button variant="primary" onClick={() => onClick?.()}>
                {actionLabel}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
