import { FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { TextField } from "../../App/FormComponents";
import { Modal } from "../../App";
import { openNotification } from "../../../domains/App";
import { schemaValidation } from "../../../constants";
import {
  createServiceProvider,
  InitialServiceProviderState,
  toggleServiceProviderModal,
} from "../../../domains/ServiceProvider";

interface ServiceProvider {
  code: string;
  name: string;
}

export const ServiceProviderForm: FC = () => {
  const dispatch = useDispatch();
  const { serviceProviderModals, serviceProvidersLoaders } = useSelector(
    (state: { serviceProvider: InitialServiceProviderState }) =>
      state.serviceProvider
  );
  const { control, handleSubmit, reset } = useForm<ServiceProvider>({
    resolver: joiResolver(schemaValidation.ServiceProviderSchema),
    defaultValues: {
      code: "",
      name: "",
    },
  });

  const onClose = async () => {
    reset();
    await dispatch(
      toggleServiceProviderModal({
        addServiceProvider: false,
      })
    );
  };

  const onSubmit: SubmitHandler<ServiceProvider> = async (props) => {
    try {
      await dispatch(createServiceProvider(props));
      await onClose();
      await dispatch(
        openNotification({
          openNotification: true,
          type: "success",
          message: "Service Provider added!",
        })
      );
    } catch (e) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Something went wrong",
        })
      );
    }
  };
  return (
    <Modal
      formId="service_provider_form"
      btnType="submit"
      modalTitle="Add a Service Provider"
      isOpen={serviceProviderModals.addServiceProvider}
      onClose={onClose}
      btnLoading={serviceProvidersLoaders.submit}
    >
      <form
        id="service_provider_form"
        className="space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="code"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="code"
              label="Code"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />

        <Controller
          control={control}
          name="name"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="name"
              label="Name"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
      </form>
    </Modal>
  );
};
