export const Types: Record<string, string> = {
  CHANGE_WITHDRAWALS_TABS: "CHANGE_WITHDRAWALS_TABS",
  TOGGLE_WITHDRAWAL_MODALS: "TOGGLE_WITHDRAWAL_MODALS",

  FETCH_WITHDRAWALS: "FETCH_WITHDRAWALS",
  FETCH_WITHDRAWALS_SUCCESS: "FETCH_WITHDRAWALS_SUCCESS",
  FETCH_WITHDRAWALS_FAIL: "FETCH_WITHDRAWALS_FAIL",

  CREATE_WITHDRAWAL: "CREATE_WITHDRAWAL",
  CREATE_WITHDRAWAL_SUCCESS: "CREATE_WITHDRAWAL_SUCCESS",
  CREATE_WITHDRAWAL_FAIL: "CREATE_WITHDRAWAL_FAIL",

  UPDATE_SELECTED_WITHDRAWAL: "UPDATE_SELECTED_WITHDRAWAL",
  UPDATE_SELECTED_WITHDRAWAL_SUCCESS: "UPDATE_SELECTED_WITHDRAWAL_SUCCESS",
  UPDATE_SELECTED_WITHDRAWAL_FAIL: "UPDATE_SELECTED_WITHDRAWAL_FAIL",
};
