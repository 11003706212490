import { Types } from "./actionTypes";
import { getURL } from "../../utils";

export const toggleIntgModal = (payload: any) => async (dispatch: any) =>
  dispatch({
    type: Types.TOGGLE_INTG_MODALS,
    payload,
  });

export const fetchProductIOIntgs =
  (productId: string, pagination?: any) => async (dispatch: any) => {
    await dispatch({
      type: Types.FETCH_PRODUCT_IOINTGS,
      payload: {
        request: {
          url: getURL(
            `/io-wallets/v1/dfses/${productId}/dfs-payment-gateways`,
            pagination
          ),
          method: "GET",
        },
      },
    });
  };

export const createIntg =
  (productId: any, data: any) => async (dispatch: any) => {
    await dispatch({
      type: Types.CREATE_INTG,
      payload: {
        request: {
          // url: `/api/dfs-payment-gateways`,
          url: `/wallets/v1/dfses/${productId}/dfs-payment-gateways`,
          method: "POST",
          data,
        },
      },
    });
  };

export const updateIntg = (intg: any, data: any) => async (dispatch: any) =>
  await dispatch({
    type: Types.UPDATE_INTG,
    payload: {
      request: {
        url: `/wallets/v1/dfs-payment-gateways/${intg.id}`,
        method: "PUT",
        data,
        intg,
      },
    },
  });

export const deleteIntg = (intg: any) => async (dispatch: any) =>
  await dispatch({
    type: Types.DELETE_INTG,
    payload: {
      request: {
        url: `/wallets/v1/dfs-payment-gateways/${intg.id}`,
        method: "DELETE",
        deleteProp: intg,
      },
    },
  });

export const changeStatus =
  (selectedIntg: any, status: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.CHANGE_INTG_STATUS,
      payload: {
        request: {
          url: `/io-wallets/v1/dfs-payment-gateways/${selectedIntg.id}`,
          method: "PATCH",
          data: status,
          selectedIntg,
        },
      },
    });
