import { Types } from "./actionTypes";

export const authLogin = (data: any) => async (dispatch: any) => {
  const formData = new FormData();
  formData.set("grant_type", "client_credentials");
  return await dispatch({
    type: Types.AUTHENTICATE,
    payload: {
      request: {
        url: "/oauth/token",
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Basic ${btoa(`${data.email}:${data.password}`)}`,
        },
        data: formData,
      },
    },
  });
};

export const logout = () => async (dispatch: any) =>
  await dispatch({
    type: Types.UN_AUTHENTICATE,
  });
