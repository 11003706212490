import { FC } from "react";
import { CheckCircleIcon, ExclamationIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { NotificationProps, closeNotification } from "../../domains/App";

const IconMapper: Record<
  string,
  null | {
    Icon: FC<any>;
    color: string;
  }
> = {
  general: null,
  success: {
    Icon: CheckCircleIcon,
    color: "green",
  },
  warning: {
    Icon: ExclamationIcon,
    color: "orange",
  },
  error: {
    Icon: XIcon,
    color: "red",
  },
};

const defaultTitle: Record<string, string> = {
  general: "Info",
  success: "Success",
  warning: "Attention needed",
  error: "Something went wrong",
};

interface NotificationPopupProps {
  notification: NotificationProps;
}

export const NotificationPopup: FC<NotificationPopupProps> = ({
  notification,
}) => {
  const dispatch = useDispatch();
  const mappedIcon = IconMapper[notification.type];
  const Icon = mappedIcon?.Icon;
  const color = mappedIcon?.color;
  const title = defaultTitle[notification.type];

  const handleCloseNotification = () => dispatch(closeNotification());

  return (
    <div className="p-4">
      <div className="flex items-start">
        <div className="flex-shrink-0">
          {Icon && (
            <div
              className={classNames(
                "mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10",
                `bg-${color}-100`
              )}
            >
              <Icon
                className={classNames(
                  "h-6 w-6 text-red-600",
                  `text-${color}-600`
                )}
                aria-hidden="true"
              />
            </div>
          )}
        </div>
        <div className="ml-3 w-0 flex-1 pt-0.5">
          <p className="text-sm font-medium text-gray-900">{title}</p>
          <p className="mt-1 text-sm text-gray-500">{notification.message}</p>
        </div>
        <div className="ml-4 flex-shrink-0 flex">
          <button
            className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-tandaPurple"
            onClick={handleCloseNotification}
          >
            <span className="sr-only">Close</span>
            <XIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
};
