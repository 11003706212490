import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Loader, DeleteModal, Button } from "../../App";
import {
  InitialMessageState,
  toggleMessagingModal,
  deleteSpProps,
} from "../../../domains/Messages";
import { EditServicePropsForm } from "./EditServiceProps";

interface ServicePropsTableProps {
  data: any[];
  loading?: boolean;
  title?: string;
}

export const ServicePropsTable: FC<ServicePropsTableProps> = ({
  data,
  loading = false,
  title,
}) => {
  const dispatch = useDispatch();
  const [selectedServiceProp, setSelectedServiceProp] = useState<any>();

  const { messageModals, messageLoaders } = useSelector(
    (state: { message: InitialMessageState }) => state.message
  );

  const handleOpenModal = (id: string) => {
    setSelectedServiceProp(id);
    dispatch(
      toggleMessagingModal({
        deleteServiceProps: true,
      })
    );
  };
  const handleEditModal = (value: any) => {
    setSelectedServiceProp(value);
    dispatch(
      toggleMessagingModal({
        editServiceProps: true,
      })
    );
  };
  const handleCloseModal = () =>
    dispatch(
      toggleMessagingModal({
        deleteServiceProps: false,
      })
    );

  const onDeleteServiceProp = async () => {
    if (!!selectedServiceProp) {
      await dispatch(deleteSpProps({ id: selectedServiceProp }));
    }
    handleCloseModal();
  };

  const columns = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Id",
      accessor: "propId",
    },
    {
      Header: "Value",
      accessor: "propValue",
    },
    {
      id: "actions",
      accessor: (row: any) => {
        return (
          <div className="flex flex-row items-center space-x-2">
            <Button
              onClick={() => handleOpenModal(row.id)}
              size="xs"
              variant="danger"
            >
              Delete
            </Button>
            <Button
              onClick={() => handleEditModal(row)}
              size="xs"
              variant="primary"
            >
              Edit
            </Button>
          </div>
        );
      },
    },
  ];
  if (loading) {
    return (
      <div className="mt-4 rounded-lg flex flex-row items-center justify-center w-full h-60 bg-white">
        <Loader />
      </div>
    );
  }
  return (
    <>
      <DeleteModal
        isOpen={messageModals.deleteServiceProps}
        onClose={handleCloseModal}
        onSubmit={onDeleteServiceProp}
        message="Are you sure you want to delete this prop?"
        loading={messageLoaders.submit}
      />
      <EditServicePropsForm spProps={selectedServiceProp} />
      <Table
        title={title}
        columns={columns}
        data={data}
        pageCount={data?.length}
        withPagination={false}
      />
    </>
  );
};
