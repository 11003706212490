import { FC, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { TextField } from "../../App/FormComponents";
import { Modal } from "../../App";
import {
  InitialWithdrawalState,
  toggleWithdrawalModal,
} from "../../../domains/Withdrawal";
import { openNotification } from "../../../domains/App";
import { schemaValidation } from "../../../constants";
import _ from "lodash";

interface Withdrawal {
  name: string;
}

interface EditWithdrawalFormProps {
  withdrawal: any;
}

export const EditWithdrawalForm: FC<EditWithdrawalFormProps> = ({
  withdrawal,
}) => {
  const dispatch = useDispatch();
  const { withdrawalModals, withdrawalsLoaders } = useSelector(
    (state: { withdrawal: InitialWithdrawalState }) => state.withdrawal
  );
  const { control, handleSubmit, reset } = useForm<Withdrawal>({
    resolver: joiResolver(schemaValidation.WithdrawalSchema),
    defaultValues: {
      name: "",
    },
  });

  useEffect(() => {
    if (!_.isEmpty(withdrawal)) {
      reset({
        name: withdrawal?.name || "",
      });
    }
  }, [withdrawal, reset]);

  const onClose = async () => {
    reset();
    await dispatch(
      toggleWithdrawalModal({
        editWithdrawal: false,
      })
    );
  };

  const onSubmit: SubmitHandler<Withdrawal> = async (props) => {
    try {
      // await dispatch(updateWithdrawal(withdrawal?.id, props));
      await onClose();
      await dispatch(
        openNotification({
          openNotification: true,
          type: "success",
          message: "Business client wallet withdrawal updated!",
        })
      );
    } catch (e) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Something went wrong",
        })
      );
    }
  };
  return (
    <Modal
      formId="edit_batch_type_form"
      btnType="submit"
      modalTitle="Edit Batch Type"
      isOpen={withdrawalModals.editWithdrawal}
      onClose={onClose}
      btnLoading={withdrawalsLoaders.submit}
    >
      <form
        id="edit_batch_type_form"
        className="space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="name"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="name"
              label="Name"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
      </form>
    </Modal>
  );
};
