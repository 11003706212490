import { FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Layout, SuccessOrErrorState, GoBackLink, Banners } from "../../App";
import { ServicePropsTable } from "./ServicePropsTable";
import {
  InitialMessageState,
  toggleMessagingModal,
} from "../../../domains/Messages";
import { ServicePropsForm } from "./ServicePropsForm";

export const ServicePropsPage: FC = () => {
  const { selectedServiceProvider } = useSelector(
    (state: { message: InitialMessageState }) => state.message
  );
  const dispatch = useDispatch();
  const { spId = "" } = useParams<{ spId?: string }>();
  const handleServiceProvider = async () => {
    await dispatch(
      toggleMessagingModal({
        addServiceProps: true,
      })
    );
  };

  return (
    <Layout>
      <Banners
        withAction
        actionLabel="Create Props"
        onClick={handleServiceProvider}
      >
        <GoBackLink path="/messages/service-providers" />
      </Banners>
      <ServicePropsForm spPropsId={spId} />
      <div className="pt-4">
        {!!selectedServiceProvider?.props ? (
          <ServicePropsTable
            title={selectedServiceProvider?.apiIntgName}
            data={selectedServiceProvider?.props}
          />
        ) : (
          <SuccessOrErrorState
            message={`No props available for ${selectedServiceProvider?.apiIntgName}`}
            state="empty"
          />
        )}
      </div>
    </Layout>
  );
};
