import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Table, Loader, Button, DeleteModal } from "../../App";
import { PaginationProps } from "../../../domains/App";
import {
  updateSelectedServiceProvider,
  toggleMessagingModal,
  InitialMessageState,
  deleteServiceProvider,
} from "../../../domains/Messages";
import { RadioBox } from "../../App/FormComponents";
import { EditServiceProviderForm } from "./EditServiceProvider";

interface ServiceProviderTableProps {
  control?: any;
  columns: any[];
  data: any[];
  onChange?: (value: string) => void;
  onPaginationChange?: (page: number) => void;
  pagination: PaginationProps;
  loading: boolean;
}

export const ServiceProviderTable: FC<ServiceProviderTableProps> = ({
  columns,
  data,
  onChange = (value) => {},
  onPaginationChange,
  pagination,
  loading,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedServiceProvider, setSelectedServiceProvider] = useState<any>();

  const { messageModals, messageLoaders } = useSelector(
    (state: { message: InitialMessageState }) => state.message
  );

  const goToPath = (sp: any) => () => {
    dispatch(updateSelectedServiceProvider(sp));
    history.push(`/messages/service-providers/${sp.id}/props`);
  };

  const handleOpenModal = (id: string) => {
    setSelectedServiceProvider(id);
    dispatch(
      toggleMessagingModal({
        deleteServiceProvider: true,
      })
    );
  };
  const handleEditModal = (value: any) => {
    setSelectedServiceProvider(value);
    dispatch(
      toggleMessagingModal({
        editServiceProvider: true,
      })
    );
  };
  const handleCloseModal = () =>
    dispatch(
      toggleMessagingModal({
        deleteServiceProvider: false,
      })
    );
  const onDeleteServiceProvider = async () => {
    if (!!selectedServiceProvider) {
      await dispatch(deleteServiceProvider({ id: selectedServiceProvider }));
    }
    handleCloseModal();
  };

  const getColumns = () => {
    const availableColumns = [
      {
        id: "name",
        Header: "Name",
        accessor: (row: any) => (
          <div>
            <span
              className="cursor-pointer underline text-tandaPurple"
              onClick={goToPath(row)}
            >
              {row.name}
            </span>
          </div>
        ),
      },
      {
        id: "viewName",
        Header: "Name",
        accessor: "name",
      },
      {
        id: "apiId",
        Header: "Api Id",
        accessor: "apiIntgId",
      },
      {
        id: "apiName",
        Header: "Api name",
        accessor: "apiIntgName",
      },
      {
        id: "channel",
        Header: "Channel",
        accessor: "channel",
      },
      {
        id: "actions",
        accessor: (row: any) => {
          return (
            <div className="flex flex-row items-center space-x-2">
              <Button
                onClick={() => handleOpenModal(row.id)}
                size="xs"
                variant="danger"
              >
                Delete
              </Button>
              <Button
                onClick={() => handleEditModal(row)}
                size="xs"
                variant="primary"
              >
                Edit
              </Button>
            </div>
          );
        },
      },
      {
        id: "select",
        accessor: (row: any) => {
          return (
            <RadioBox
              name="serviceProvider"
              onChange={onChange}
              value={JSON.stringify(row)}
            />
          );
        },
      },
    ];

    return columns.map((column) =>
      availableColumns.find(
        (availableColumn) => availableColumn.id === column.id
      )
    );
  };

  if (loading) {
    return (
      <div className="mt-4 rounded-lg flex flex-row items-center justify-center w-full h-60 bg-white">
        <Loader />
      </div>
    );
  }
  return (
    <>
      <DeleteModal
        isOpen={messageModals.deleteServiceProvider}
        onClose={handleCloseModal}
        onSubmit={onDeleteServiceProvider}
        message="Are you sure you want to delete this service provider?"
        loading={messageLoaders.submit}
      />
      <EditServiceProviderForm serviceProvider={selectedServiceProvider} />
      <Table
        columns={getColumns()}
        data={data}
        pageCount={pagination.totalPages}
        currentPage={pagination.number}
        onPaginationChange={onPaginationChange}
      />
    </>
  );
};
