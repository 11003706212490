import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Layout, SuccessOrErrorState, ActionBadge } from "../../App";
import { viewMessagesTabs, config } from "../../../constants";
import {
  fetchSmsRequests,
  InitialMessageState,
} from "../../../domains/Messages";
import _ from "lodash";
import { MessagesTable } from "./MessagesTable";
import { DescriptionPanel } from "./DescriptionPanel";

export const ViewSmsPage: FC = () => {
  const dispatch = useDispatch();
  const { messageLoaders, smsRequests, smsRequestsPagination } = useSelector(
    (state: { message: InitialMessageState }) => state.message
  );

  useEffect(() => {
    const fetchSmsAsync = () => {
      dispatch(fetchSmsRequests());
    };
    fetchSmsAsync();
  }, [dispatch]);

  const handlePagination = (page: number) => {
    dispatch(
      fetchSmsRequests({
        page: page - 1,
        size: smsRequestsPagination.size,
      })
    );
  };

  return (
    <Layout>
      <div>
        <Tabs tabs={viewMessagesTabs} />
      </div>
      <div className="pt-4">
        {_.isEmpty(smsRequests) && !messageLoaders.fetchSmsRequests ? (
          <SuccessOrErrorState state="empty" message="No SMS Request Found" />
        ) : (
          <MessagesTable
            columns={config.messages.sms.listColumns}
            pagination={smsRequestsPagination}
            data={smsRequests}
            loading={messageLoaders.fetchSmsRequests}
            onPaginationChange={handlePagination}
            SubComponent={(value: any) => {
              return (
                <>
                  <div className="w-full pt-2 pb-4 px-4">
                    <DescriptionPanel
                      label="Body"
                      description={value?.row?.original?.body}
                    />
                    <DescriptionPanel label="Receiver (Groups)">
                      <ActionBadge
                        options={[
                          ...(value?.row?.original?.groups?.map?.(
                            (group: any) => ({
                              id: group?.id,
                              label: group?.name,
                            })
                          ) || []),
                        ]}
                      />
                    </DescriptionPanel>
                    <DescriptionPanel label="PhoneNumbers">
                      <ActionBadge
                        options={[
                          ...(value?.row?.original?.phoneNumbers?.map?.(
                            (phone: string, indx: number) => ({
                              id: indx,
                              label: phone,
                            })
                          ) || []),
                        ]}
                      />
                    </DescriptionPanel>
                  </div>
                </>
              );
            }}
          />
        )}
      </div>
    </Layout>
  );
};
