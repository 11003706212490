export const Types: Record<string, string> = {
  TOGGLE_WALLET_MODALS: "TOGGLE_WALLET_MODALS",

  UPDATE_PAYOUTS_SEARCH_PARAMS: "UPDATE_PAYOUTS_SEARCH_PARAMS",
  UPDATE_COLLECTIONS_SEARCH_PARAMS: "UPDATE_COLLECTIONS_SEARCH_PARAMS",

  FETCH_WALLET: "FETCH_WALLET",
  FETCH_WALLET_SUCCESS: "FETCH_WALLET_SUCCESS",
  FETCH_WALLET_FAIL: "FETCH_WALLET_FAIL",

  FETCH_COLLECTION_WALLET: "FETCH_COLLECTION_WALLET",
  FETCH_COLLECTION_WALLET_SUCCESS: "FETCH_COLLECTION_WALLET_SUCCESS",
  FETCH_COLLECTION_WALLET_FAIL: "FETCH_COLLECTION_WALLET_FAIL",

  FETCH_WALLET_PAYMENTS: "FETCH_WALLET_PAYMENTS",
  FETCH_WALLET_PAYMENTS_SUCCESS: "FETCH_WALLET_PAYMENTS_SUCCESS",
  FETCH_WALLET_PAYMENTS_FAIL: "FETCH_WALLET_PAYMENTS_FAIL",

  FETCH_ORG_PAYMENTS: "FETCH_ORG_PAYMENTS",
  FETCH_ORG_PAYMENTS_SUCCESS: "FETCH_ORG_PAYMENTS_SUCCESS",
  FETCH_ORG_PAYMENTS_FAIL: "FETCH_ORG_PAYMENTS_FAIL",

  FETCH_PAYMENT_STATUS: "FETCH_PAYMENT_STATUS",
  FETCH_PAYMENT_STATUS_SUCCESS: "FETCH_PAYMENT_STATUS_SUCCESS",
  FETCH_PAYMENT_STATUS_FAIL: "FETCH_PAYMENT_STATUS_FAIL",

  FETCH_WALLET_COLLECTIONS: "FETCH_WALLET_COLLECTIONS",
  FETCH_WALLET_COLLECTIONS_SUCCESS: "FETCH_WALLET_COLLECTIONS_SUCCESS",
  FETCH_WALLET_COLLECTIONS_FAIL: "FETCH_WALLET_COLLECTIONS_FAIL",

  UPDATE_RESELLER_WALLET: "UPDATE_RESELLER_WALLET",
  UPDATE_RESELLER_WALLET_SUCCESS: "UPDATE_RESELLER_WALLET_SUCCESS",
  UPDATE_RESELLER_WALLET_FAIL: "UPDATE_RESELLER_WALLET_FAIL",

  FETCH_PRIMARY_COLLECTION_WALLET: "FETCH_PRIMARY_COLLECTION_WALLET",
  FETCH_PRIMARY_COLLECTION_WALLET_SUCCESS:
    "FETCH_PRIMARY_COLLECTION_WALLET_SUCCESS",
  FETCH_PRIMARY_COLLECTION_WALLET_FAIL: "FETCH_PRIMARY_COLLECTION_WALLET_FAIL",

  FETCH_COLLECTION_WALLETS: "FETCH_COLLECTION_WALLETS",
  FETCH_COLLECTION_WALLETS_SUCCESS: "FETCH_COLLECTION_WALLETS_SUCCESS",
  FETCH_COLLECTION_WALLETS_FAIL: "FETCH_COLLECTION_WALLETS_FAIL",

  CREATE_COLLECTION_WALLET: "CREATE_COLLECTION_WALLET",
  CREATE_COLLECTION_WALLET_SUCCESS: "CREATE_COLLECTION_WALLET_SUCCESS",
  CREATE_COLLECTION_WALLET_FAIL: "CREATE_COLLECTION_WALLET_FAIL",

  UPDATE_COLLECTION_WALLET: "UPDATE_COLLECTION_WALLET",
  UPDATE_COLLECTION_WALLET_SUCCESS: "UPDATE_COLLECTION_WALLET_SUCCESS",
  UPDATE_COLLECTION_WALLET_FAIL: "UPDATE_COLLECTION_WALLET_FAIL",

  CREDIT_COLLECTION_WALLET: "CREDIT_COLLECTION_WALLET",
  CREDIT_COLLECTION_WALLET_SUCCESS: "CREDIT_COLLECTION_WALLET_SUCCESS",
  CREDIT_COLLECTION_WALLET_FAIL: "CREDIT_COLLECTION_WALLET_FAIL",

  DEBIT_COLLECTION_WALLET: "DEBIT_COLLECTION_WALLET",
  DEBIT_COLLECTION_WALLET_SUCCESS: "DEBIT_COLLECTION_WALLET_SUCCESS",
  DEBIT_COLLECTION_WALLET_FAIL: "DEBIT_COLLECTION_WALLET_FAIL",
};
