import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  InitialWalletState,
  toggleWalletModal,
} from "../../../domains/Reseller/Wallet";
import { Drawer } from "../../App";
import { QueryStatus } from "./QueryStatus";
import { TransactionDetails } from "./TransactionDetails";

interface PayoutDetailsProps {
  payoutDetails: any;
}

export const PayoutDetails: FC<PayoutDetailsProps> = ({ payoutDetails }) => {
  const dispatch = useDispatch();
  const [openTab, setOpenTab] = useState(1);
  const { walletModals } = useSelector(
    (state: { resellerWallet: InitialWalletState }) => state.resellerWallet
  );
  const onClose = async () => {
    setOpenTab(1);
    await dispatch(
      toggleWalletModal({
        showDetails: false,
      })
    );
  };

  return (
    <Drawer
      isOpen={walletModals.showDetails}
      onClose={onClose}
      drawerTitle="Transaction Description"
      //   key={payoutDetails.id}
    >
      <>
        <div className="flex flex-wrap -mt-10">
          <div className="w-full">
            <ul
              className="flex mb-0 list-none flex-wrap flex-row"
              role="tablist"
            >
              <li className="-mb-px mr-2 last:mr-0 text-center">
                <a
                  className={
                    "inline-block p-2 rounded-t-lg border-b-4 border-transparent hover:text-tandaGreen hover:border-tandaGreen hover:border-b-4" +
                    (openTab === 1
                      ? "text-tandaGreen border-b-2 border-tandaGreen"
                      : "text-gray-600 bg-white")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(1);
                  }}
                  data-toggle="tab"
                  href="#link1"
                  role="tablist"
                >
                  Basic Info
                </a>
              </li>
              <li className="-mb-px mr-2 last:mr-0 text-center">
                <a
                  className={
                    "inline-block p-2 rounded-t-lg border-b-4 border-transparent hover:text-tandaGreen hover:border-tandaGreen hover:border-4 dark:hover:text-gray-300" +
                    (openTab === 2
                      ? "text-tandaGreen border-b-2 border-tandaGreen"
                      : "text-gray-600 ")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(2);
                  }}
                  data-toggle="tab"
                  href="#link2"
                  role="tablist"
                >
                  More Info
                </a>
              </li>
            </ul>
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
              <div className="px-4 py-5 flex-auto">
                <div className="tab-content tab-space">
                  <div
                    className={openTab === 1 ? "block" : "hidden"}
                    id="link1"
                  >
                    <TransactionDetails payoutDetails={payoutDetails} />
                  </div>
                  <div
                    className={openTab === 2 ? "block" : "hidden"}
                    id="link2"
                  >
                    <QueryStatus payoutDetails={payoutDetails} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Drawer>
  );
};
