import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Layout,
  Banners,
  SuccessOrErrorState,
  Tabs,
  GoBackLink,
} from "../../App";
import { IntgTable, IntgForm } from "../Integration";

import {
  fetchProductIntgs,
  InitialIntgState,
  toggleIntgModal,
} from "../../../domains/Integration";
import { config } from "../../../constants";
import _ from "lodash";
import { useParams } from "react-router";
import { TabItemProps } from "../../../domains/App";

export const IntgPage: FC = () => {
  const dispatch = useDispatch();
  const { productIntgs, intgsLoaders, intgsPagination } = useSelector(
    (state: { intg: InitialIntgState }) => state.intg
  );
  const { productId = "" } = useParams<{ productId?: string }>();

  useEffect(() => {
    const fetchProductIntgsAsync = async () =>
      await dispatch(fetchProductIntgs(productId));

    fetchProductIntgsAsync();
  }, [dispatch, productId]);

  const handlePagination = (page: number) => {
    dispatch(
      fetchProductIntgs(productId, {
        page: page - 1,
        size: intgsPagination.size,
      })
    );
  };

  const handleModal = async () => {
    await dispatch(
      toggleIntgModal({
        addIntg: true,
      })
    );
  };
  const viewProductTabs: TabItemProps[] = [
    {
      key: "agent_transaction_fee",
      label: "Agent Transaction Fees",
      path: `/products/${productId}/agent-transaction-fees`,
    },
    {
      key: "agent_commission_fee",
      label: "Agent Commission Fees",
      path: `/products/${productId}/agent-commission-fees`,
    },
    {
      key: "customer_transaction_fee",
      label: "Customer Transaction Fees",
      path: `/products/${productId}/customer-transaction-fees`,
    },
    {
      key: "gateway",
      label: "Gateways",
      path: `/products/${productId}/gateways`,
    },
  ];

  return (
    <Layout>
      <div>
        <Tabs tabs={viewProductTabs} />
        <div className="pt-2">
          <Banners withAction actionLabel="Add Gateway" onClick={handleModal}>
            <div className="ml-1">
              <GoBackLink path="/products" />
            </div>
            <p className="pl-3">Do you want to create a Gateway?</p>
          </Banners>
        </div>
      </div>
      <IntgForm productId={productId} />
      <div className="pt-2">
        {_.isEmpty(productIntgs) && !intgsLoaders.fetchIntgs ? (
          <SuccessOrErrorState state="empty" message="No Integrations Found" />
        ) : (
          <IntgTable
            columns={config.products.intgs.listColumns}
            data={productIntgs}
            pagination={intgsPagination}
            loading={intgsLoaders.fetchIntgs}
            onPaginationChange={handlePagination}
          />
        )}
      </div>
    </Layout>
  );
};
