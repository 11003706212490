import dayjs from "dayjs";
import { FC, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { openNotification } from "../../../domains/App";
import {
  InitialWalletState,
  toggleWalletModal,
} from "../../../domains/Reseller/Wallet";
import API from "../../../utils/api";
import { LargeFormModal } from "../../App";
import { DateTimePicker } from "../../App/FormComponents/DateTimePicker";

interface FilterParams {
  since: string;
  till: string;
}
interface DownloadStatementFormProps {
  type: string;
}

export const DownloadStatementForm: FC<DownloadStatementFormProps> = ({
  type,
}) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  let defaultSince =
    dayjs(new Date().setDate(new Date().getDate() - 1))
      .toISOString()
      .split(".")[0] + "Z";
  let defaultTill = dayjs(new Date()).toISOString().split(".")[0] + "Z";
  const { resellerId = "" } = useParams<{ resellerId?: string }>();
  const { walletModals } = useSelector(
    (state: { resellerWallet: InitialWalletState }) => state.resellerWallet
  );
  const { control, reset, handleSubmit } = useForm<FilterParams>({
    defaultValues: {
      since: defaultSince,
      till: defaultTill,
    },
  });
  const onClose = async () => {
    reset();
    await dispatch(
      toggleWalletModal({
        downloadPayoutsStatement: false,
      })
    );
  };

  const onSubmit: SubmitHandler<FilterParams> = async (props) => {
    setLoader(true);
    if (type === "Payouts") {
      try {
        const params = {
          since: dayjs(props.since).toISOString().split(".")[0] + "Z",
          till: dayjs(props.till).toISOString().split(".")[0] + "Z",
        };

        API.get(`/io-wallets/v1/resellers/${resellerId}/statements/payouts`, {
          responseType: "blob",
          params: params,
        })
          .then((response) => {
            if (response.status === 200) {
              let fileName = response.headers["content-disposition"]
                .split("filename=")[1]
                .split(".")[0];
              let extension = response.headers["content-disposition"]
                .split(".")[1]
                .split(";")[0];
              const link = document.createElement("a");
              const url = URL.createObjectURL(response.data);
              let name = `${fileName}.${extension}`;
              link.href = url;
              link.download = name.replaceAll('"', "");
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              setLoader(false);
              dispatch(
                openNotification({
                  openNotification: true,
                  type: "success",
                  message: "Payouts Statement downloaded!",
                })
              );
            }
            onClose();
          })
          .catch((err) => {
            setLoader(false);
            dispatch(
              openNotification({
                openNotification: true,
                type: "error",
                message: `Invalid timestamp range, you can only download a month's or less worth of reports.`,
              })
            );
          });
      } catch (e: any) {
        dispatch(
          openNotification({
            openNotification: true,
            type: "error",
            message: `Invalid timestamp range, you can only download a month's or less worth of reports.`,
          })
        );
      }
    } else {
      try {
        const params = {
          since: dayjs(props.since).toISOString().split(".")[0] + "Z",
          till: dayjs(props.till).toISOString().split(".")[0] + "Z",
        };

        API.get(
          `/io-wallets/v1/resellers/${resellerId}/statements/collections`,
          {
            responseType: "blob",
            params: params,
          }
        )
          .then((response) => {
            if (response.status === 200) {
              let fileName = response.headers["content-disposition"]
                .split("filename=")[1]
                .split(".")[0];
              let extension = response.headers["content-disposition"]
                .split(".")[1]
                .split(";")[0];
              const link = document.createElement("a");
              const url = URL.createObjectURL(response.data);
              let name = `${fileName}.${extension}`;
              link.href = url;
              link.download = name.replaceAll('"', "");
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              setLoader(false);
              dispatch(
                openNotification({
                  openNotification: true,
                  type: "success",
                  message: "Collections Statement downloaded!",
                })
              );
            }
            onClose();
          })
          .catch((err) => {
            setLoader(false);
            dispatch(
              openNotification({
                openNotification: true,
                type: "error",
                message: `Invalid timestamp range, you can only download a month's or less worth of reports.`,
              })
            );
          });
      } catch (e: any) {
        if (e.error.response.status === 400) {
          dispatch(
            openNotification({
              openNotification: true,
              type: "error",
              message: `Invalid timestamp range, you can only download a month's or less worth of reports.`,
            })
          );
        } else {
          dispatch(
            openNotification({
              openNotification: true,
              type: "error",
              message: `Something went wrong`,
            })
          );
        }
      }
    }
  };

  return (
    <LargeFormModal
      formId="statement_form"
      btnType="submit"
      modalTitle={`Download ${type}   Statements`}
      isOpen={walletModals.downloadPayoutsStatement}
      onClose={onClose}
      btnLoading={loader}
    >
      <form
        id="statement_form"
        className="space-y-2 py-6 px-20"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex space-x-4 -pl-3">
          <Controller
            control={control}
            name="since"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <DateTimePicker
                id="since"
                label="Since"
                placeholder="Since"
                value={value}
                error={error?.message}
                onChange={onChange}
                onClick={onChange}
                includeTime={true}
              />
            )}
          />

          <Controller
            control={control}
            name="till"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <DateTimePicker
                id="till"
                label="Until"
                placeholder="Until"
                value={value}
                error={error?.message}
                onChange={onChange}
                onClick={onChange}
                includeTime={true}
              />
            )}
          />
        </div>
      </form>
    </LargeFormModal>
  );
};
