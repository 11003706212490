import dayjs from "dayjs";
import _ from "lodash";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { LoanTransactionPageTable } from ".";
import { config } from "../../constants";
import { fetchLoanTransactions, InitialLoanState } from "../../domains/Loan";
import { Banners, GoBackLink, Layout, SuccessOrErrorState } from "../App";
import { DescriptionPanel } from "../Messaging";

export const LoanTransactionPage: FC = () => {
  const dispatch = useDispatch();
  const { loansTransaction, selectedLoan, loansLoaders } = useSelector(
    (state: { loan: InitialLoanState }) => state.loan
  );
  const { loanId = "" } = useParams<{ loanId?: string }>();

  useEffect(() => {
    const fetchLoanTransaction = async () =>
      await dispatch(fetchLoanTransactions(loanId));

    fetchLoanTransaction();
  }, [dispatch, loanId]);

  return (
    <Layout>
      <Banners>
        <div className="ml-1">
          <GoBackLink path="/loans" />
        </div>
        <p className="pl-3">{`${selectedLoan?.agentName} ${config.loanTransaction.title}`}</p>
      </Banners>
      <div className="pt-2">
        {_.isEmpty(loansTransaction) && !loansLoaders.fetchLoanTransactions ? (
          <SuccessOrErrorState
            state="empty"
            message="No Loan Transactions Found"
          />
        ) : (
          <LoanTransactionPageTable
            title={config.loanTransaction.title}
            columns={config.loanTransaction.listColumns}
            data={loansTransaction}
            loading={loansLoaders.fetchLoanTransactions}
            SubComponent={(value: any) => {
              return (
                <>
                  <div className="w-full pt-2 pb-4 ml-7 px-4 grid grid-cols-2">
                    <DescriptionPanel
                      label="Account Type"
                      description={value?.row?.original?.accountTypeName}
                    />
                    <DescriptionPanel
                      label="Service Provider"
                      description={value?.row?.original?.spId}
                    />
                    <DescriptionPanel
                      label="Status Code"
                      description={value?.row?.original?.status}
                    />
                    <DescriptionPanel
                      label="Message"
                      description={value?.row?.original?.message}
                    />
                    <DescriptionPanel
                      label="Transaction Ref"
                      description={value?.row?.original?.transactionRef}
                    />
                    <DescriptionPanel
                      label="Date Fulfilled"
                      description={dayjs(
                        value?.row?.original?.dateFulfilled
                      ).format("YYYY-MM-DD hh:mm A")}
                    />
                  </div>
                </>
              );
            }}
          />
        )}
      </div>
    </Layout>
  );
};
