import _ from "lodash";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ResellerDfsFeeRateTable } from ".";
import { config } from "../../../constants";
import {
  fetchResellerDfsFeeRates,
  InitialResellerDfsFeeRateState,
  toggleResellerDfsFeeRateModal,
} from "../../../domains/Reseller/ResellerDfsFeeRate";
import { Banners, Layout, SuccessOrErrorState } from "../../App";
import { ResellerDfsTabs } from "../ResellerDfsTabs";
import { ResellerDfsFeeRateForm } from "./ResellerDfsFeeRateForm";

export const ResellerDfsFeeRatePage: FC = () => {
  const dispatch = useDispatch();
  const { resellerDfsFeeRates, resellerDfsFeeRatesLoaders } = useSelector(
    (state: { resellerDfsFeeRate: InitialResellerDfsFeeRateState }) =>
      state.resellerDfsFeeRate
  );
  const { dfsId = "" } = useParams<{ dfsId?: string }>();

  useEffect(() => {
    const fetchResellerDfsFeeRatesAsync = async () =>
      await dispatch(fetchResellerDfsFeeRates(dfsId));

    fetchResellerDfsFeeRatesAsync();
  }, [dispatch, dfsId]);
  const handleModal = async () => {
    await dispatch(
      toggleResellerDfsFeeRateModal({
        addResellerDfsFeeRate: true,
      })
    );
  };

  return (
    <Layout>
      <ResellerDfsTabs />

      <div className="mt-4">
        <Banners withAction actionLabel="Add Fee Rate" onClick={handleModal}>
          <p className="pl-3">Reseller Product Fee Rates</p>
        </Banners>
      </div>
      <ResellerDfsFeeRateForm />
      <div className="pt-3">
        {_.isEmpty(resellerDfsFeeRates) &&
        !resellerDfsFeeRatesLoaders.fetchResellerDfsFeeRates ? (
          <SuccessOrErrorState state="empty" message="No Fee Rate Found" />
        ) : (
          <ResellerDfsFeeRateTable
            columns={config.resellerDfsRate.listColumns}
            data={resellerDfsFeeRates}
            loading={resellerDfsFeeRatesLoaders.fetchResellerDfsFeeRates}
          />
        )}
      </div>
    </Layout>
  );
};
