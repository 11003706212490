import Joi from "joi";

const PhoneRegex = /^(?:254)?(7(?:(?:[12][0-9])|(?:0[0-8])|(9[0-2]))[0-9]{6})$/;

const passwordValidator = Joi.string().min(5).label("Password");
const emailValidator = Joi.string()
  .email({ tlds: { allow: false } })
  .label("Email Address");
const phoneNumberValidator = Joi.string()
  .pattern(PhoneRegex)
  .label("Phone Number")
  .messages({
    "string.pattern.base":
      "Invalid phone number, it should be of the format 254701123456",
  });

const LoginSchema = Joi.object({
  email: emailValidator,
  password: passwordValidator,
});

const ContactSchema = Joi.object({
  firstName: Joi.string().label("First Name"),
  lastName: Joi.string().label("Last Name"),
  middleName: Joi.string().optional().label("Middle Name"),
  emailAddress: emailValidator,
  phoneNumber: phoneNumberValidator,
});

const GroupSchema = Joi.object({
  name: Joi.string().label("Name").min(8).max(256),
  description: Joi.string().label("Description").min(16).max(512),
});
const ReversalSchema = Joi.object({
  reversalReference: Joi.string().label("Reference").min(8).max(256),
});

const ServiceProviderSchema = Joi.object({
  apiIntgId: Joi.string().label("Api IntgId").min(2).max(64),
  apiIntgName: Joi.string().label("Api IntgName").min(2).max(256),
  channel: Joi.string().label("Channel"),
  code: Joi.string().label("Code").min(2).max(64),
  name: Joi.string().label("Name").min(8).max(32),
});

const ServicePropsSchema = Joi.object({
  name: Joi.string().label("Name").min(8).max(128),
  description: Joi.string().label("Description").max(512),
  propId: Joi.string().label("PropId"),
  propValue: Joi.string().label("PropValue").min(1).max(256),
});

const CreateEmailCampaignSchema = Joi.object({
  serviceProvider: Joi.string().label("Service Provider"),
  subject: Joi.string().label("Subject").max(256),
  body: Joi.string().max(160).label("Body"),
  toGroups: Joi.array().label("To Groups"),
  toEmails: Joi.array()
    .items(
      Joi.object({
        value: emailValidator,
        label: Joi.string(),
      })
    )
    .label("To Emails"),
  bccGroups: Joi.array().label("BCC Groups"),
  bccEmails: Joi.array()
    .items(
      Joi.object({
        value: emailValidator,
        label: Joi.string(),
      })
    )
    .label("BCC Emails"),
  ccGroups: Joi.array().label("CC Groups"),
  ccEmails: Joi.array()
    .items(
      Joi.object({
        value: emailValidator,
        label: Joi.string(),
      })
    )
    .label("CC Emails"),
});
const CreateSmsCampaignSchema = Joi.object({
  serviceProvider: Joi.string().label("Service Provider"),
  subject: Joi.string().label("Subject").max(256),
  body: Joi.string().max(160).label("Body"),
  groups: Joi.array().label("Groups"),
  contacts: Joi.array().label("Contacts"),
  phoneNumbers: Joi.array()
    .items(
      Joi.object({
        value: phoneNumberValidator,
        label: Joi.string(),
      })
    )
    .label("Phone Numbers"),
});
const CreateInAppCampaignSchema = Joi.object({
  serviceProvider: Joi.string().label("Service Provider"),
  subject: Joi.string().label("Subject").max(256),
  body: Joi.string().max(160).label("Body"),
  groups: Joi.array().label("Groups"),
});

const TemplateSchema = Joi.object({
  name: Joi.string().max(32).label("Subject"),
  body: Joi.string().max(160).label("Body"),
});
const ProductSchema = Joi.object({
  name: Joi.string().label("Name").min(2).max(32),
  description: Joi.string().label("Description").min(2).max(64),
  enabled: Joi.boolean().label("Enabled"),
  available: Joi.boolean().label("Available"),
  minimumValue: Joi.number().label("Minimum Value"),
  maximumValue: Joi.number().label("Maximum Value"),
  batchType: Joi.any().label("Batch Type"),
  sp: Joi.any().label("Service Provider"),
  dfsType: Joi.any().label("Product Type"),
});
const ResellerSchema = Joi.object({
  name: Joi.string().label("Name").min(2).max(128).required(),
  firstName: Joi.string().label("First name").min(2).max(64).required(),
  otherNames: Joi.string().label("Other names").min(2).max(64).required(),
  emailAddress: emailValidator,
  phoneNumber: Joi.string().min(9).max(9).label("Phone Number").messages({
    "string.pattern.base":
      "Invalid phone number, it should be of the format 254701123456",
  }),
  nationalId: Joi.string().label("National ID").min(2).max(64).required(),
  officePhysicalAddress: Joi.string()
    .label("Street Address")
    .min(16)
    .max(255)
    .required(),
  officePostalAddress: Joi.string()
    .label("Office Postal Address")
    .min(16)
    .max(255)
    .required(),
  businessNumber: Joi.string()
    .label("Business Number")
    .min(2)
    .max(64)
    .required(),
});

const WithdrawalSchema = Joi.object({
  amount: Joi.number().label("Amount"),
  destinationAccount: Joi.any().label("Destination Account"),
  channel: Joi.string().label("Channel"),
  narration: Joi.string().label("Narration"),
});

const BSWalletSchema = Joi.object({
  name: Joi.string().label("Name").min(2).max(32),
  withdrawable: Joi.boolean().label("Withdrawable"),
  number: Joi.string().label("Number"),
  type: Joi.string().label("Type"),
  paymentGateway: Joi.any().label("Payment Gateway"),
});

const ProductServiceProviderSchema = Joi.object({
  code: Joi.string().label("Code").min(2).max(32),
  name: Joi.string().label("Name"),
});

const BatchTypeSchema = Joi.object({
  name: Joi.string().label("Name").min(2).max(32),
});

const CommissionFeeRateSchema = Joi.object({
  feeType: Joi.string().label("Fee Type").min(2).max(32),
  feeAmount: Joi.number().label("Fee Amount"),
  startingInclusive: Joi.number().label("Starting Inclusive"),
  endingInclusive: Joi.number().label("Ending Inclusive"),
  enabled: Joi.boolean().label("Enabled"),
  collectionType: Joi.string().label("Collection Type"),
});

const TransactionFeeRateSchema = Joi.object({
  feeType: Joi.string().label("Fee Type").min(2).max(32),
  feeAmount: Joi.number().label("Fee Amount"),
  startingInclusive: Joi.number().label("Starting Inclusive"),
  endingInclusive: Joi.number().label("Ending Inclusive"),
  enabled: Joi.boolean().label("Enabled"),
  collectionType: Joi.string().label("Collection Type"),
});

const CustomerTransactionFeeRateSchema = Joi.object({
  feeType: Joi.string().label("Fee Type").min(2).max(32),
  feeAmount: Joi.number().label("Fee Amount"),
  startingInclusive: Joi.number().label("Starting Inclusive"),
  endingInclusive: Joi.number().label("Ending Inclusive"),
  enabled: Joi.boolean().label("Enabled"),
  collectionType: Joi.string().label("Collection Type"),
});

const IntgSchema = Joi.object({
  paymentGatewayId: Joi.string().label("Payment Gateway"),
  commissionFeeRate: Joi.number().label("Commission Fee Rate"),
  commissionFeeRateType: Joi.string().label("Commission Fee Rate Type"),
  transactionFeeRate: Joi.number().label("Transaction Fee Rate"),
  transactionFeeRateType: Joi.string().label("Transaction Fee Rate Type"),
  enabled: Joi.boolean().label("Enabled"),
  minimumValue: Joi.number().label("Minimum Value"),
  maximumValue: Joi.number().label("Maximum Value"),
});
const IOWalletSchema = Joi.object({
  name: Joi.string().label("Name").min(2).max(32),
  description: Joi.string().label("Description").min(2).max(64),
  enabled: Joi.boolean().label("Enabled"),
  available: Joi.boolean().label("Available"),
  minimumValue: Joi.number().label("Minimum Value"),
  maximumValue: Joi.number().label("Maximum Value"),
  batchType: Joi.any().label("Batch Type"),
  sp: Joi.any().label("Service Provider"),
  dfsType: Joi.any().label("IOWallet Type"),
});

const EventListenerSchema = Joi.object({
  name: Joi.string().max(64).label("Name"),
  eventType: Joi.string().label("EventType"),
  initiatorTypes: Joi.array().label("InitiatorTypes"),
  selectorTypes: Joi.array()
    .items(
      Joi.object({
        key: Joi.string().label("Key"),
        operator: Joi.string().label("Operator"),
        value: Joi.string().max(128).label("Value"),
      })
    )
    .label("Selector Types"),
  smsBody: Joi.string().max(160).label("Body"),
  smsServiceProvider: Joi.string().label("Service Provider"),
  emailBody: Joi.string().max(1024).label("Body"),
  emailSubject: Joi.string().max(128).label("Subject"),
  emailServiceProvider: Joi.string().label("Service Provider"),
  inAppBody: Joi.string().max(512).label("Body"),
  inAppSubject: Joi.string().max(64).label("Subject"),
  inAppRecipient: Joi.string().max(64).label("Recipient"),
  inAppServiceProvider: Joi.string().label("Service Provider"),
  systemBody: Joi.string().max(1024).label("Body"),
  systemSubject: Joi.string().max(128).label("Subject"),
  systemTo: emailValidator,
  systemCC: Joi.array()
    .items(
      Joi.object({
        value: emailValidator,
        label: Joi.string(),
      })
    )
    .label("CC Emails"),
  systemBCC: Joi.array()
    .items(
      Joi.object({
        value: emailValidator,
        label: Joi.string(),
      })
    )
    .label("BCC Emails"),
  systemServiceProvider: Joi.string().label("Service Provider"),
  customerBody: Joi.string().max(1024).label("Body"),
  customerServiceProvider: Joi.string().label("Service Provider"),
});

const CollectionWalletSchema = Joi.object({
  name: Joi.string().label("Name"),
  ipnUrl: Joi.string().label("IPN Url"),
  username: Joi.string().min(8).max(64).label("User name"),
  password: Joi.string().min(8).max(64).label("Password"),
});

const ResellerDfsSchema = Joi.object({
  available: Joi.boolean().label("Available"),
  minimumValue: Joi.number().label("Minimum Value"),
  maximumValue: Joi.number().label("Maximum Value"),
});

const ResellerDfsRateSchema = Joi.object({
  type: Joi.string().label("Type"),
  starting: Joi.number().label("Starting"),
  ending: Joi.number().label("Ending"),
  amount: Joi.number().label("Amount"),
  enabled: Joi.boolean().label("Enabled"),
});

const ResellerDfsGatewaySchema = Joi.object({
  id: Joi.string().label("Payment Gateway"),
  starting: Joi.number().label("Starting"),
  ending: Joi.number().label("Ending"),
  amount: Joi.number().label("Amount"),
  enabled: Joi.boolean().label("Enabled"),
});

const ApiClientSchema = Joi.object({
  name: Joi.string().label("Name"),
  publicKey: Joi.string().label("Public Key"),
});

const CreditDebitCollectionWalletSchema = Joi.object({
  amount: Joi.number().label("Amount").min(1).max(1000000),
});
export const schemaValidation = {
  ApiClientSchema,
  ContactSchema,
  CreateEmailCampaignSchema,
  CreateInAppCampaignSchema,
  CreateSmsCampaignSchema,
  EventListenerSchema,
  GroupSchema,
  ReversalSchema,
  LoginSchema,
  ServiceProviderSchema,
  ServicePropsSchema,
  TemplateSchema,
  ProductSchema,
  ResellerSchema,
  BSWalletSchema,
  WithdrawalSchema,
  IOWalletSchema,
  ProductServiceProviderSchema,
  TransactionFeeRateSchema,
  CommissionFeeRateSchema,
  BatchTypeSchema,
  CustomerTransactionFeeRateSchema,
  IntgSchema,
  CollectionWalletSchema,
  ResellerDfsSchema,
  ResellerDfsRateSchema,
  ResellerDfsGatewaySchema,
  CreditDebitCollectionWalletSchema,
};
