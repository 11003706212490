import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import { Button, LargeModal } from "../../App";
import { DescriptionPanel } from "../Messages";
import { ServiceProviderTable } from "../Sps";
import {
  InitialMessageState,
  fetchServiceProviders,
} from "../../../domains/Messages";
import { config } from "../../../constants";
import { ErrorMessage } from "../../App/FormComponents";

interface ServiceProviderModalProps {
  isOpen: boolean;
  onClose: () => void;
  onChange: (value: string) => void;
}

const ServiceProviderModal: FC<ServiceProviderModalProps> = ({
  isOpen,
  onClose,
  onChange,
}) => {
  const dispatch = useDispatch();
  const { serviceProviders, messageLoaders, serviceProvidersPagination } =
    useSelector((state: { message: InitialMessageState }) => state.message);

  useEffect(() => {
    const fetchServiceProvidersAsync = async () =>
      await dispatch(fetchServiceProviders());

    fetchServiceProvidersAsync();
  }, [dispatch]);

  const handlePagination = (page: number) => {
    dispatch(
      fetchServiceProviders({
        page: page - 1,
        size: serviceProvidersPagination.size,
      })
    );
  };
  return (
    <LargeModal
      modalTitle="Pick a service provider"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ServiceProviderTable
        columns={config.serviceProvider.select.listColumns}
        data={serviceProviders}
        pagination={serviceProvidersPagination}
        loading={messageLoaders.fetchServiceProviders}
        onPaginationChange={handlePagination}
        onChange={onChange}
      />
    </LargeModal>
  );
};

export const PickServiceProvider: FC<{
  id?: string;
  label?: string;
}> = ({ id = "serviceProvider", label = "Service provider" }) => {
  const {
    formState: { errors },
    watch,
    control,
  } = useFormContext();
  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => setOpenModal(false);
  const handleClick = () => setOpenModal(true);
  const formSP = watch(id);
  const serviceProvider = _.isEmpty(formSP) ? {} : JSON.parse(formSP);

  return (
    <>
      <Controller
        name={id}
        control={control}
        render={({ field: { onChange } }) => {
          return (
            <div>
              {openModal && (
                <ServiceProviderModal
                  isOpen={openModal}
                  onClose={handleClose}
                  onChange={onChange}
                />
              )}
            </div>
          );
        }}
      />

      <DescriptionPanel label={label}>
        <div className="grid grid-cols-1">
          <div>
            <Button onClick={handleClick} variant="primary" size="md">
              Select
            </Button>
          </div>
          <div>
            {!_.isEmpty(serviceProvider) && (
              <span className="mt-3 inline-flex items-center px-3 py-1 rounded-full text-sm font-medium cursor-pointer mb-2 bg-white border border-gray-600 text-gray-800">
                {serviceProvider?.name} - {serviceProvider?.channel}
              </span>
            )}
            <ErrorMessage error={errors?.[id]?.message} />
          </div>
        </div>
      </DescriptionPanel>
    </>
  );
};
