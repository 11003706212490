import dayjs from "dayjs";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { PaginationProps } from "../../domains/App";
import { updateSelectedLoan } from "../../domains/Loan";
import { Button, ExpandTable, Loader } from "../App";
import { RadioBox } from "../App/FormComponents";

interface LoanTableProps {
  control?: any;
  columns: any[];
  data: any[];
  onChange?: (value: string) => void;
  onPaginationChange?: (page: number) => void;
  pagination: PaginationProps;
  loading: boolean;
  title: string;
  SubComponent?: React.FC<{ row: any }>;
  Filter?: React.FC<{}>;
}

export const LoanTable: FC<LoanTableProps> = ({
  columns,
  data,
  onChange = (value) => {},
  onPaginationChange,
  pagination,
  loading,
  SubComponent,
  Filter,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const goToPath = (loan: any) => () => {
    dispatch(updateSelectedLoan(loan));
    history.push(`/loans/${loan.id}/transactions`);
  };

  const getColumns = () => {
    const availableColumns = [
      {
        id: "correlatorId",
        Header: "Id",
        accessor: (row: any) => (
          <div>
            <span
              className="cursor-pointer underline text-tandaPurple"
              onClick={goToPath(row)}
            >
              {row.correlatorId}
            </span>
          </div>
        ),
      },
      {
        id: "shopName",
        Header: "Shop",
        accessor: "shopName",
      },
      {
        id: "initialPrincipal",
        Header: "Amount(KES)",
        accessor: (row: any) => {
          return <span>{(row?.initialPrincipal).toFixed(2)}</span>;
        },
      },
      {
        id: "dateCreated",
        Header: "Date Disbursed",
        accessor: (row: any) => {
          return (
            <span>{dayjs(row?.dateCreated).format("YYYY-MM-DD hh:mm A")}</span>
          );
        },
      },

      {
        id: "status",
        Header: "Status",
        accessor: "status",
      },
      {
        id: "actions",
        accessor: (row: any) => {
          return (
            <div className="flex flex-row items-center space-x-2">
              <Button size="xs" variant="primary">
                Edit
              </Button>
            </div>
          );
        },
      },
      {
        id: "select",
        accessor: (row: any) => {
          return (
            <RadioBox
              name="loan"
              onChange={onChange}
              value={JSON.stringify(row)}
            />
          );
        },
      },
    ];

    return columns.map((column) =>
      availableColumns.find(
        (availableColumn) => availableColumn.id === column.id
      )
    );
  };

  if (loading) {
    return (
      <div className="mt-4 rounded-lg flex flex-row items-center justify-center w-full h-60 bg-white">
        <Loader />
      </div>
    );
  }
  return (
    <>
      <ExpandTable
        // title={title}
        columns={getColumns()}
        data={data}
        pageCount={pagination.totalPages}
        currentPage={pagination.number}
        onPaginationChange={onPaginationChange}
        SubComponent={SubComponent}
        Filter={Filter}
      />
    </>
  );
};
