import { createReducer } from "@reduxjs/toolkit";
import { Types } from "./actionTypes";
import { PaginationProps } from "../App";
import _ from "lodash";

export interface InitialDfsTypeState {
  cache: Record<string, any>;
  dfsTypes: any[];
  selectedDfsType: any;
  dfsTypesPagination: PaginationProps;
  dfsTypeModals: any;
  dfsTypesLoaders: any;
}

const initialState: InitialDfsTypeState = {
  dfsTypes: [],
  dfsTypesPagination: {
    number: 0,
    totalPages: 1,
    size: 10,
    totalElements: 0,
  },
  selectedDfsType: {},
  dfsTypesLoaders: {
    submit: false,
    fetchDfsTypes: false,
  },
  dfsTypeModals: {
    addDfsType: false,
    editDfsType: false,
    deleteDfsType: false,
  },
  cache: {},
};

export const dfsTypesReducer = createReducer(initialState, {
  [Types.TOGGLE_DFSTYPE_MODALS]: (state, action) => {
    state.dfsTypeModals = {
      ...state.dfsTypeModals,
      ...action.payload,
    };
  },

  [Types.FETCH_DFSTYPES]: (state) => {
    state.dfsTypesLoaders.fetchDfsTypes = true;
  },
  [Types.FETCH_DFSTYPES_SUCCESS]: (state, action) =>
    // {
    //   const {
    //     data
    //   } = action.payload;
    //   state.dfsTypesLoaders.fetchDfsTypes = false;
    //   state.dfsTypes = data;
    // },
    {
      const {
        data: { content, page },
      } = action.payload;
      state.dfsTypesLoaders.fetchDfsTypes = false;
      state.dfsTypes = content;
      state.dfsTypesPagination = {
        number: page.number,
        size: page.size,
        totalPages: page.totalPages,
        totalElements: page.totalElements,
      };
    },
  [Types.FETCH_DFSTYPES_FAIL]: (state) => {
    state.dfsTypesLoaders.fetchDfsTypes = false;
  },

  [Types.CREATE_DFSTYPE]: (state) => {
    state.dfsTypesLoaders.submit = true;
  },
  [Types.CREATE_DFSTYPE_SUCCESS]: (state, action) => {
    state.dfsTypesLoaders.submit = false;
    state.dfsTypes = [action.payload.data, ...state.dfsTypes];
  },
  [Types.CREATE_DFSTYPE_FAIL]: (state) => {
    state.dfsTypesLoaders.submit = false;
  },

  [Types.DELETE_DFSTYPE]: (state) => {
    state.dfsTypesLoaders.submit = true;
  },
  [Types.DELETE_DFSTYPE_SUCCESS]: (state, action) => {
    const {
      previousAction: {
        payload: {
          request: {
            deleteProp: { id },
          },
        },
      },
    } = action.meta;
    state.dfsTypes = [...state.dfsTypes.filter((dfsType) => dfsType.id !== id)];
    if (_.isEmpty(state.dfsTypes)) {
      state.dfsTypesPagination.number = 0;
    }
    state.dfsTypesLoaders.submit = false;
  },
  [Types.DELETE_DFSTYPE_FAIL]: (state) => {
    state.dfsTypesLoaders.submit = false;
  },

  [Types.UPDATE_DFSTYPE]: (state) => {
    state.dfsTypesLoaders.submit = true;
  },
  [Types.UPDATE_DFSTYPE_SUCCESS]: (state, action) => {
    state.dfsTypesLoaders.submit = false;
    state.dfsTypes = [
      ...state.dfsTypes.map((sp) =>
        sp.id === action.payload.data.id ? action.payload.data : sp
      ),
    ];
  },
  [Types.UPDATE_DFSTYPE_FAIL]: (state) => {
    state.dfsTypesLoaders.submit = false;
  },
});
