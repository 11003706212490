import { Types } from "./actionTypes";
import { getURL } from "../../utils";

export const toggleTransactionFeeRateModal =
  (payload: any) => async (dispatch: any) =>
    dispatch({
      type: Types.TOGGLE_TRANSACTIONFEERATE_MODALS,
      payload,
    });

export const fetchAgentTransactionFees =
  (productId: string, pagination?: any) => async (dispatch: any) => {
    await dispatch({
      type: Types.FETCH_AGENT_TRANS_FEES,
      payload: {
        request: {
          method: "GET",
          url: getURL(
            `/wallets/v1/dfses/${productId}/agent-transaction-fees`,
            pagination
          ),
        },
      },
    });
  };

export const createTransactionFeeRate =
  (productId: any, data: any) => async (dispatch: any) => {
    await dispatch({
      type: Types.CREATE_TRANSACTIONFEERATE,
      payload: {
        request: {
          url: `/wallets/v1/dfses/${productId}/agent-transaction-fees`,
          method: "POST",
          data,
        },
      },
    });
  };

export const updateTransactionFeeRate =
  (transaction: any, data: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.UPDATE_TRANSACTIONFEERATE,
      payload: {
        request: {
          url: `/wallets/v1/agent-transaction-fees/${transaction.id}`,
          method: "PUT",
          data,
          transaction,
        },
      },
    });

export const deleteTransactionFeeRate =
  (transaction: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.DELETE_TRANSACTIONFEERATE,
      payload: {
        request: {
          url: `/wallets/v1/agent-transaction-fees/${transaction.id}`,
          method: "DELETE",
          deleteProp: transaction,
        },
      },
    });

export const changeStatus =
  (selectedTransactionFeeRate: any, status: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.CHANGE_TRANS_STATUS,
      payload: {
        request: {
          url: `/wallets/v1/agent-transaction-fees/${selectedTransactionFeeRate.id}`,
          method: "PATCH",
          data: status,
          selectedTransactionFeeRate,
        },
      },
    });
