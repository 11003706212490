import { combineReducers } from "@reduxjs/toolkit";
import { appReducer } from "./App";
import { authReducer } from "./Auth";
import { batchTypesReducer } from "./BatchType/reducer";
import { bswalletsReducer } from "./BSWallet";
import { commissionFeeRatesReducer } from "./CommissionFeeRate/reducer";
import { customerTransactionFeeRatesReducer } from "./CustomerTransactionFeeRate/reducer";
import { dfsTypesReducer } from "./DfsType/reducer";
import { intgsReducer } from "./Integration/reducer";
import { iointgsReducer } from "./IOIntegration/reducer";
import { iowalletsReducer } from "./IOWallets";
import { loansReducer } from "./Loan/reducer";
import { messagesReducer } from "./Messages/reducer";
import { paymentGatewaysReducer } from "./PaymentGateway";
import { productsReducer } from "./Products/reducer";
import { reportReducer } from "./Reports/reducer";
import { resellerReducer } from "./Reseller/Reseller/reducer";
import { resellerDfsReducer } from "./Reseller/ResellerDfs";
import { resellerDfsCommissionRatesReducer } from "./Reseller/ResellerDfsCommissionRate/reducer";
import { resellerDfsFeeRatesReducer } from "./Reseller/ResellerDfsFeeRate/reducer";
import { resellerDfsGatewaysReducer } from "./Reseller/ResellerDfsGateway/reducer";
import { resellerWalletReducer } from "./Reseller/Wallet";
import { serviceProvidersReducer } from "./ServiceProvider/reducer";
import { transactionFeeRatesReducer } from "./TransactionFeeRate/reducer";
import { usersReducer } from "./Users";
import { withdrawalsReducer } from "./Withdrawal/reducer";
import { withdrawalAccountsReducer } from "./WithdrawalAccount/reducer";
import { subWalletsReducer } from "./SubWallets/reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  app: appReducer,
  users: usersReducer,
  message: messagesReducer,
  product: productsReducer,
  iowallet: iowalletsReducer,
  iointgs: iointgsReducer,
  bswallet: bswalletsReducer,
  withdrawal: withdrawalsReducer,
  withdrawalAccount: withdrawalAccountsReducer,
  batchType: batchTypesReducer,
  serviceProvider: serviceProvidersReducer,
  transactionFeeRate: transactionFeeRatesReducer,
  commissionFeeRate: commissionFeeRatesReducer,
  customerTransactionFeeRate: customerTransactionFeeRatesReducer,
  intg: intgsReducer,
  dfsType: dfsTypesReducer,
  paymentGateway: paymentGatewaysReducer,
  loan: loansReducer,
  reseller: resellerReducer,
  wallet: resellerWalletReducer,
  resellerWallet: resellerWalletReducer,
  resellerDfs: resellerDfsReducer,
  resellerDfsCommissionRate: resellerDfsCommissionRatesReducer,
  resellerDfsFeeRate: resellerDfsFeeRatesReducer,
  resellerDfsGateway: resellerDfsGatewaysReducer,
  report: reportReducer,
  subWallets: subWalletsReducer,
});

export default rootReducer;
