import { FC, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { TextField, Select } from "../../App/FormComponents";
import { Modal } from "../../App";
import {
  InitialMessageState,
  toggleMessagingModal,
  updateSpProps,
} from "../../../domains/Messages";
import { openNotification } from "../../../domains/App";
import { schemaValidation } from "../../../constants";
import _ from "lodash";

interface ServiceProvider {
  name: string;
  description: string;
  propId: string;
  propValue: string;
}

interface ServiceProviderPropsFormProps {
  spProps: any;
}

export const EditServicePropsForm: FC<ServiceProviderPropsFormProps> = ({
  spProps,
}) => {
  const dispatch = useDispatch();
  const { messageModals, messageLoaders, templateVariables } = useSelector(
    (state: { message: InitialMessageState }) => state.message
  );
  const { control, handleSubmit, reset } = useForm<ServiceProvider>({
    resolver: joiResolver(schemaValidation.ServicePropsSchema),
    defaultValues: {
      name: "",
      description: "",
      propId: "",
      propValue: "",
    },
  });

  useEffect(() => {
    if (!_.isEmpty(spProps)) {
      reset({
        name: spProps?.name || "",
        description: spProps?.description || "",
        propId: spProps?.propId || "",
        propValue: spProps?.propValue || "",
      });
    }
  }, [spProps, reset]);

  const onClose = async () => {
    reset();
    await dispatch(
      toggleMessagingModal({
        editServiceProps: false,
      })
    );
  };
  const onSubmit: SubmitHandler<ServiceProvider> = async (data) => {
    try {
      await dispatch(updateSpProps(spProps.id, data));
      await onClose();
      await dispatch(
        openNotification({
          openNotification: true,
          type: "success",
          message: "Service props updated!",
        })
      );
    } catch (e) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Something went wrong",
        })
      );
    }
  };

  return (
    <Modal
      formId="edit_service_provider_props_form"
      btnType="submit"
      modalTitle="Edit props"
      isOpen={messageModals.editServiceProps}
      onClose={onClose}
      btnLoading={messageLoaders.submit}
    >
      <form
        id="edit_service_provider_props_form"
        className="space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="name"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="name"
              label="Name"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="description"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="description"
              label="Description"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="propId"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Select
              id="propId"
              label="PropId"
              selectedOption={value}
              error={error?.message}
              onChange={onChange}
              options={[
                ...templateVariables.map(({ id, name }) => ({
                  value: id,
                  label: name,
                })),
              ]}
            />
          )}
        />
        <Controller
          control={control}
          name="propValue"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="propValue"
              label="PropValue"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
      </form>
    </Modal>
  );
};
