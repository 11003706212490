import { FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { TextField, TextArea } from "../../App/FormComponents";
import { Modal } from "../../App";
import {
  InitialUserStateProps,
  toggleUserModal,
  createGroup,
} from "../../../domains/Users";
import { openNotification } from "../../../domains/App";
import { schemaValidation } from "../../../constants";

interface ContactInput {
  name: string;
  description: string;
}

export const GroupForm: FC = () => {
  const dispatch = useDispatch();
  const { userModals, userLoader } = useSelector(
    (state: { users: InitialUserStateProps }) => state.users
  );
  const { control, handleSubmit, reset } = useForm<ContactInput>({
    resolver: joiResolver(schemaValidation.GroupSchema),
    defaultValues: {
      name: "",
      description: "",
    },
  });

  const onClose = async () => {
    reset();
    await dispatch(
      toggleUserModal({
        groupModal: false,
      })
    );
  };

  const onSubmit: SubmitHandler<ContactInput> = async (data) => {
    try {
      await dispatch(createGroup(data));
      await onClose();
      await dispatch(
        openNotification({
          openNotification: true,
          type: "success",
          message: "Group added!",
        })
      );
    } catch (e) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Something went wrong",
        })
      );
    }
  };

  return (
    <Modal
      formId="group_form"
      btnType="submit"
      modalTitle="Add a Group"
      isOpen={userModals.groupModal}
      onClose={onClose}
      btnLoading={userLoader.submit}
    >
      <form
        id="group_form"
        className="space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="name"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="name"
              label="Name"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="description"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextArea
              id="description"
              label="Description"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
      </form>
    </Modal>
  );
};
