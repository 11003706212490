import { FC, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Button } from ".";

interface ModalProps
{
  btnType?: "submit" | "button";
  btnVariant?: "primary" | "danger" | "default" | "secondary";
  btnLoading?: boolean;
  isOpen: boolean;
  formId?: string;
  modalTitle: string;
  onClose: () => void;
  submitBtnLabel?: string;
  onSubmit?: () => void;
}

export const Modal: FC<ModalProps> = ({
  btnType = "button",
  btnLoading,
  btnVariant = "primary",
  isOpen,
  formId,
  modalTitle,
  onClose,
  children,
  submitBtnLabel = "Submit",
  onSubmit,
}) =>
{
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={isOpen}
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-screen-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <div className="relative">
                    <div
                      className="absolute inset-0 flex items-center"
                      aria-hidden="true"
                    >
                      <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="relative flex justify-center">
                      <span className="px-3 bg-white text-lg font-medium text-gray-900">
                        {modalTitle}
                      </span>
                    </div>
                  </div>
                  <div className="mt-2">{children}</div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <Button
                  form={formId}
                  type={btnType}
                  variant={btnVariant}
                  onClick={onSubmit}
                  loading={btnLoading}
                >
                  {submitBtnLabel}
                </Button>
                <span className="ml-2">
                  <Button onClick={onClose} btnClass="mr-3" variant="default">
                    Cancel
                  </Button>
                </span>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
