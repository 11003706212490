import { EyeIcon } from "@heroicons/react/outline";
import { FC } from "react";
import { checkValue } from "../../utils/app";
import { Card } from "../App";

interface StatementCardProps {
  name: string;
  description: string;
  SubComponent?: React.FC<{}>;
}

export const StatementCard: FC<StatementCardProps> = ({
  name,
  description,
  SubComponent,
}) => {
  return (
    <>
      <Card
        key={name}
        height="h-30"
        name={checkValue(name)}
        name1={checkValue(description)}
        TopIcon={() => {
          return (
            <>
              <EyeIcon className="h-4 text-tandaGreen w-4" />
            </>
          );
        }}
        SubComponent={SubComponent}
      />
    </>
  );
};
