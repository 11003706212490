export const Types: Record<string, string> = {
  CHANGE_PRODUCTS_TABS: "CHANGE_PRODUCTS_TABS",
  TOGGLE_PRODUCT_MODALS: "TOGGLE_PRODUCT_MODALS",

  FETCH_PRODUCTS: "FETCH_PRODUCTS",
  FETCH_PRODUCTS_SUCCESS: "FETCH_PRODUCTS_SUCCESS",
  FETCH_PRODUCTS_FAIL: "FETCH_PRODUCTS_FAIL",

  CREATE_PRODUCT: "CREATE_PRODUCT",
  CREATE_PRODUCT_SUCCESS: "CREATE_PRODUCT_SUCCESS",
  CREATE_PRODUCT_FAIL: "CREATE_PRODUCT_FAIL",

  UPDATE_PRODUCT: "UPDATE_PRODUCT",
  UPDATE_PRODUCT_SUCCESS: "UPDATE_PRODUCT_SUCCESS",
  UPDATE_PRODUCT_FAIL: "UPDATE_PRODUCT_FAIL",

  DELETE_PRODUCT_: "DELETE_PRODUCT",
  DELETE_PRODUCT_SUCCESS: "DELETE_PRODUCT_SUCCESS",
  DELETE_PRODUCT_FAIL: "DELETE_PRODUCT_FAIL",

  CHANGE_PRODUCT_STATUS: "CHANGE_PRODUCT_STATUS",
  CHANGE_PRODUCT_STATUS_SUCCESS: "CHANGE_PRODUCT_STATUS_SUCCESS",
  CHANGE_PRODUCT_STATUS_FAIL: "CHANGE_PRODUCT_STATUS_FAIL",

  UPDATE_SELECTED_PRODUCT: "UPDATE_SELECTED_PRODUCT",
  UPDATE_SELECTED_PRODUCT_SUCCESS: "UPDATE_SELECTED_PRODUCT_SUCCESS",
  UPDATE_SELECTED_PRODUCT_FAIL: "UPDATE_SELECTED_PRODUCT_FAIL",

  FETCH_PRODUCT_TRASACTIONS: "FETCH_PRODUCT_TRASACTIONS",
  FETCH_PRODUCT_TRASACTIONS_SUCCESS: "FETCH_PRODUCT_TRASACTIONS_SUCCESS",
  FETCH_PRODUCT_TRASACTIONS_FAIL: "FETCH_PRODUCT_TRASACTIONS_FAIL",
};
