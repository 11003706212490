export const Types: Record<string, string> = {
  TOGGLE_RESELLER_DFS_GATEWAY_MODALS: "TOGGLE_RESELLER_DFS_GATEWAY_MODALS",

  FETCH_RESELLER_GATEWAYS: "FETCH_RESELLER_GATEWAYS",
  FETCH_RESELLER_GATEWAYS_SUCCESS: "FETCH_RESELLER_GATEWAYS_SUCCESS",
  FETCH_RESELLER_GATEWAYS_FAIL: "FETCH_RESELLER_GATEWAYS_FAIL",

  FETCH_RESELLER_DFS_GATEWAY: "FETCH_RESELLER_DFS_GATEWAY",
  FETCH_RESELLER_DFS_GATEWAY_SUCCESS: "FETCH_RESELLER_DFS_GATEWAY_SUCCESS",
  FETCH_RESELLER_DFS_GATEWAY_FAIL: "FETCH_RESELLER_DFS_GATEWAY_FAIL",

  CREATE_RESELLER_DFS_GATEWAY: "CREATE_RESELLER_DFS_GATEWAY",
  CREATE_RESELLER_DFS_GATEWAY_SUCCESS: "CREATE_RESELLER_DFS_GATEWAY_SUCCESS",
  CREATE_RESELLER_DFS_GATEWAY_FAIL: "CREATE_RESELLER_DFS_GATEWAY_FAIL",

  UPDATE_RESELLER_DFS_GATEWAY: "UPDATE_RESELLER_DFS_GATEWAY",
  UPDATE_RESELLER_DFS_GATEWAY_SUCCESS: "UPDATE_RESELLER_DFS_GATEWAY_SUCCESS",
  UPDATE_RESELLER_DFS_GATEWAY_FAIL: "UPDATE_RESELLER_DFS_GATEWAY_FAIL",

  DELETE_RESELLER_DFS_GATEWAY: "DELETE_RESELLER_DFS_GATEWAY",
  DELETE_RESELLER_DFS_GATEWAY_SUCCESS: "DELETE_RESELLER_DFS_GATEWAY_SUCCESS",
  DELETE_RESELLER_DFS_GATEWAY_FAIL: "DELETE_RESELLER_DFS_GATEWAY_FAIL",
};
