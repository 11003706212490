import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchResellerApiClientRoles,
  InitialResellerState,
  toggleResellerModal,
} from "../../../domains/Reseller/Reseller";
import { Drawer, Loader } from "../../App";
import { Roles } from "./Roles";

export const ApiClientRoles = () => {
  const dispatch = useDispatch();
  const params = useParams<any>();
  let { resellerModals, selectedApiClient, apiClientRoles, resellersLoaders } =
    useSelector((state: { reseller: InitialResellerState }) => state.reseller);

  const closeDetailsDrawer = async () => {
    await dispatch(
      toggleResellerModal({
        viewClientRoles: false,
      })
    );
  };

  useEffect(() => {
    const fetchApiClientRolesAsync = async () => {
      dispatch(
        fetchResellerApiClientRoles(params?.resellerId, selectedApiClient?.id)
      );
    };
    if (resellerModals.viewClientRoles) {
      fetchApiClientRolesAsync();
    }
  }, [
    dispatch,
    params?.resellerId,
    resellerModals.viewClientRoles,
    selectedApiClient?.id,
  ]);

  return (
    <div>
      <Drawer
        isOpen={resellerModals.viewClientRoles}
        drawerTitle={"View Roles"}
        onClose={closeDetailsDrawer}
      >
        {resellersLoaders?.fetchApiClientRoles ? (
          <>
            <div className="mt-4 rounded-lg flex flex-row items-center justify-center w-full h-24">
              <Loader />
            </div>
          </>
        ) : (
          <div>
            {apiClientRoles.map((role: any) => (
              <div key={role?.id}>
                <Roles role={role} />
              </div>
            ))}
          </div>
        )}
      </Drawer>
    </div>
  );
};
