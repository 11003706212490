import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import {
  fetchEventListeners,
  InitialMessageState,
} from "../../../domains/Messages";
import { Banners, Layout, SuccessOrErrorState } from "../../App";
import { EventListenersTable } from "./EventListenersTable";

export const ViewEventListenersPage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { eventListeners, eventListenersPagination, messageLoaders } =
    useSelector((state: { message: InitialMessageState }) => state.message);

  useEffect(() => {
    const fetchEventListenersAsync = async () =>
      await dispatch(fetchEventListeners());

    fetchEventListenersAsync();
  }, [dispatch]);

  const handlePagination = (page: number) => {
    dispatch(
      fetchEventListeners({
        page: page - 1,
        size: eventListenersPagination.size,
      })
    );
  };

  const handleClick = async () => {
    history.push("/messages/create-event-listeners");
  };

  return (
    <Layout>
      <Banners
        withAction
        actionLabel="Create Event Listener"
        onClick={handleClick}
      >
        <p className="pl-3">Do you want to create an event-listener?</p>
      </Banners>
      <div className="pt-6">
        {_.isEmpty(eventListeners) && !messageLoaders.fetchEventListeners ? (
          <SuccessOrErrorState
            state="empty"
            message="No Event Listeners Found"
          />
        ) : (
          <EventListenersTable
            data={eventListeners}
            pagination={eventListenersPagination}
            loading={messageLoaders.fetchEventListeners}
            onPaginationChange={handlePagination}
          />
        )}
      </div>
    </Layout>
  );
};
