export const Types: Record<string, string> = {
  TOGGLE_COMMISSIONFEERATE_MODALS: "TOGGLE_COMMISSIONFEERATE_MODALS",

  FETCH_COMMISSIONFEERATES: "FETCH_COMMISSIONFEERATES",
  FETCH_COMMISSIONFEERATES_SUCCESS: "FETCH_COMMISSIONFEERATES_SUCCESS",
  FETCH_COMMISSIONFEERATES_FAIL: "FETCH_COMMISSIONFEERATES_FAIL",

  CREATE_COMMISSIONFEERATE: "CREATE_COMMISSIONFEERATE",
  CREATE_COMMISSIONFEERATE_SUCCESS: "CREATE_COMMISSIONFEERATE_SUCCESS",
  CREATE_COMMISSIONFEERATE_FAIL: "CREATE_COMMISSIONFEERATE_FAIL",

  UPDATE_COMMISSIONFEERATE: "UPDATE_COMMISSIONFEERATE",
  UPDATE_COMMISSIONFEERATE_SUCCESS: "UPDATE_COMMISSIONFEERATE_SUCCESS",
  UPDATE_COMMISSIONFEERATE_FAIL: "UPDATE_COMMISSIONFEERATE_FAIL",

  DELETE_COMMISSIONFEERATE: "DELETE_COMMISSIONFEERATE",
  DELETE_COMMISSIONFEERATE_SUCCESS: "DELETE_COMMISSIONFEERATE_SUCCESS",
  DELETE_COMMISSIONFEERATE_FAIL: "DELETE_COMMISSIONFEERATE_FAIL",

  FETCH_AGENT_COMM_FEES: "FETCH_AGENT_COMM_FEES",
  FETCH_AGENT_COMM_FEES_SUCCESS: "FETCH_AGENT_COMM_FEES_SUCCESS",
  FETCH_AGENT_COMM_FEES_FAIL: "FETCH_AGENT_COMM_FEES_FAIL",

  CHANGE_AGENT_COMM_STATUS: "CHANGE_AGENT_COMM_STATUS",
  CHANGE_AGENT_COMM_STATUS_SUCCESS: "CHANGE_AGENT_COMM_STATUS_SUCCESS",
  CHANGE_AGENT_COMM_STATUS_FAIL: "CHANGE_AGENT_COMM_STATUS_FAIL",
};
