import { useState, FC, KeyboardEvent } from "react";
import CreatableSelect from "react-select/creatable";

const components = {
  DropdownIndicator: null,
};

const createOption = (label: string) => ({
  label,
  value: label,
});

interface Option {
  value: string;
  label: string;
}

interface SelectCreatableProps {
  error?: string;
  id: string;
  placeholder?: string;
  values: Option[];
  onChange: (values: Option[]) => void;
}

export const SelectCreatable: FC<SelectCreatableProps> = ({
  error,
  id,
  placeholder = "Type something and press enter...",
  values,
  onChange,
}) => {
  const [inputValue, setInputValue] = useState("");
  const handleChange = (value: any, actionMeta: any) => {
    onChange(value);
  };
  const handleInputChange = (inputValue: string) => setInputValue(inputValue);
  const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        onChange([...values, createOption(inputValue)]);
        setInputValue("");
        event.preventDefault();
        return;
      default:
        return;
    }
  };
  return (
    <div className="mb-2 w-full">
      <CreatableSelect
        id={id}
        className="rounded-md shadow-sm tanda-remove-input-ring"
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        value={values}
        styles={{
          input: (provided, state) => ({
            ...provided,
            borderRadius: "0.375rem",
            borderColor: "rgba(209, 213, 219, var(--tw-border-opacity))",
          }),
          placeholder: (provided, state) => ({
            ...provided,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
          }),
        }}
      />
      {error && (
        <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
          {error}
        </p>
      )}
    </div>
  );
};
