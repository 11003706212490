import _ from "lodash";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchWallets,
  InitialWalletState,
} from "../../../domains/Reseller/Wallet";
import { Layout, SuccessOrErrorState } from "../../App";

import { useParams } from "react-router-dom";
import { ResellerTabs } from "../";
import { config } from "../../../constants";
import { WalletCard } from "./WalletCard";

export const ResellerWalletPage: FC = () => {
  const dispatch = useDispatch();
  let { wallet, walletsLoaders } = useSelector(
    (state: { resellerWallet: InitialWalletState }) => state.resellerWallet
  );

  const { resellerId = "" } = useParams<{ resellerId?: string }>();
  useEffect(() => {
    const fetchWalletsAsync = async () =>
      await dispatch(fetchWallets(resellerId));

    fetchWalletsAsync();
  }, [dispatch, resellerId]);

  return (
    <Layout>
      <ResellerTabs />
      <div className="mt-4">
        {_.isEmpty(wallet) && !walletsLoaders.fetchWallets ? (
          <SuccessOrErrorState
            state="empty"
            message="No Reseller Wallet Found"
          />
        ) : (
          <WalletCard
            wallet={wallet}
            title={config.walletPayment.title}
            loading={walletsLoaders.fetchWallets}
          />
        )}
      </div>
    </Layout>
  );
};
