export const Types: Record<string, string> = {
  CHANGE_IOWALLETS_TABS: "CHANGE_IOWALLETS_TABS",
  TOGGLE_IOWALLET_MODALS: "TOGGLE_IOWALLET_MODALS",

  FETCH_IOWALLETS: "FETCH_IOWALLETS",
  FETCH_IOWALLETS_SUCCESS: "FETCH_IOWALLETS_SUCCESS",
  FETCH_IOWALLETS_FAIL: "FETCH_IOWALLETS_FAIL",

  FETCH_TRANSACTION: "FETCH_TRANSACTION",
  FETCH_TRANSACTION_SUCCESS: "FETCH_TRANSACTION_SUCCESS",
  FETCH_TRANSACTION_FAIL: "FETCH_TRANSACTION_FAIL",

  CREATE_IOWALLET: "CREATE_IOWALLET",
  CREATE_IOWALLET_SUCCESS: "CREATE_IOWALLET_SUCCESS",
  CREATE_IOWALLET_FAIL: "CREATE_IOWALLET_FAIL",

  UPDATE_IOWALLET: "UPDATE_IOWALLET",
  UPDATE_IOWALLET_SUCCESS: "UPDATE_IOWALLET_SUCCESS",
  UPDATE_IOWALLET_FAIL: "UPDATE_IOWALLET_FAIL",

  DELETE_IOWALLET_: "DELETE_IOWALLET",
  DELETE_IOWALLET_SUCCESS: "DELETE_IOWALLET_SUCCESS",
  DELETE_IOWALLET_FAIL: "DELETE_IOWALLET_FAIL",

  CHANGE_IOWALLET_STATUS: "CHANGE_IOWALLET_STATUS",
  CHANGE_IOWALLET_STATUS_SUCCESS: "CHANGE_IOWALLET_STATUS_SUCCESS",
  CHANGE_IOWALLET_STATUS_FAIL: "CHANGE_IOWALLET_STATUS_FAIL",

  UPDATE_SELECTED_IOWALLET: "UPDATE_SELECTED_IOWALLET",
  UPDATE_SELECTED_IOWALLET_SUCCESS: "UPDATE_SELECTED_IOWALLET_SUCCESS",
  UPDATE_SELECTED_IOWALLET_FAIL: "UPDATE_SELECTED_IOWALLET_FAIL",

  FETCH_IOWALLET_TRASACTIONS: "FETCH_IOWALLET_TRASACTIONS",
  FETCH_IOWALLET_TRASACTIONS_SUCCESS: "FETCH_IOWALLET_TRASACTIONS_SUCCESS",
  FETCH_IOWALLET_TRASACTIONS_FAIL: "FETCH_IOWALLET_TRASACTIONS_FAIL",
};
