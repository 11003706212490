import { FC } from "react";
interface RadioBoxProps {
  name: string;
  value: string;
  onChange: (value: string) => void;
}

export const RadioBox: FC<RadioBoxProps> = ({ name, value, onChange }) => {
  return (
    <div className="mb-2">
      <div className="flex items-center">
        <input
          name={name}
          type="radio"
          className="focus:ring-tandaPurple h-4 w-4 text-tandaPurple border-gray-300"
          onChange={() => onChange(value)}
        />
      </div>
    </div>
  );
};
