import { createReducer } from "@reduxjs/toolkit";
import { Types } from "./actionTypes";
import { removeAuthToken, setAuthToken } from "../../utils/auth";

export interface InitialAuthStateProps {
  isAuthenticated: boolean;
  authUser: any;
  authToken: null | string;
  authLoader: Record<string, boolean>;
}

const initialState: InitialAuthStateProps = {
  isAuthenticated: false,
  authUser: {},
  authToken: null,
  authLoader: {
    submit: false,
  },
};

export const authReducer = createReducer(initialState, {
  [Types.AUTHENTICATE]: (state, action) => {
    return {
      ...state,
      authLoader: {
        submit: true,
      },
    };
  },
  [Types.AUTHENTICATE_SUCCESS]: (state, action) => {
    const { access_token, jti, scope } = action.payload.data;
    setAuthToken(access_token);
    state.isAuthenticated = true;
    state.authToken = access_token;
    state.authUser = {
      jti,
      scope,
    };
    state.authLoader.submit = false;
  },
  [Types.AUTHENTICATE_FAIL]: (state, action) => {
    state.authLoader.submit = false;
  },

  [Types.UN_AUTHENTICATE]: (state, action) => {
    state.isAuthenticated = false;
    state.authUser = {};
    state.authToken = null;
    removeAuthToken();
  },
});
