import { Types } from "./actionTypes";
import { getURL } from "../../utils";

export const toggleDfsTypeModal = (payload: any) => async (dispatch: any) =>
  dispatch({
    type: Types.TOGGLE_DFSTYPE_MODALS,
    payload,
  });

export const fetchDfsTypes = (pagination?: any) => async (dispatch: any) =>
  await dispatch({
    type: Types.FETCH_DFSTYPES,
    payload: {
      request: {
        method: "GET",
        // url: getURL("/api/dfs-types", pagination),
        url: getURL("/wallets/v1/dfs-types", pagination),
        useCache: true,
      },
    },
  });

export const createDfsType = (data: any) => async (dispatch: any) => {
  await dispatch({
    type: Types.CREATE_DFSTYPE,
    payload: {
      request: {
        // url: `/api/dfs-types`,
        url: `/wallets/v1/dfs-types`,
        method: "POST",
        data,
      },
    },
  });
};

export const updateDfsType = (id: string, data: any) => async (dispatch: any) =>
  await dispatch({
    type: Types.UPDATE_DFSTYPE,
    payload: {
      request: {
        // url: `/api/dfs-types/${templateId}`,
        url: `/wallets/v1/dfs-types/${id}`,
        method: "PUT",
        data,
      },
    },
  });

export const deleteDfsType = (spProperty: any) => async (dispatch: any) =>
  await dispatch({
    type: Types.DELETE_DFSTYPE,
    payload: {
      request: {
        // url: `/api/dfs-types/${spProperty.id}`,
        url: `/wallets/v1/dfs-types/${spProperty.id}`,
        method: "DELETE",
        deleteProp: spProperty,
      },
    },
  });
