import { FC, Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import classNames from "classnames";

interface Option {
  value?: string;
  label?: string;
  unavailable?: boolean;
}
interface SelectProps {
  error?: string;
  id?: string;
  label?: string;
  options: Option[];
  optional?: boolean;
  onChange: (option: any) => void;
  selectedOption: string;
}

export const Select: FC<SelectProps> = ({
  error,
  id,
  label,
  options,
  optional,
  onChange,
  selectedOption,
}) => {
  const getCurrentOption = () => {
    if (!!selectedOption) {
      return options.find((option) => option.value === selectedOption);
    }
    return options?.[0];
  };
  const handleChange = (option: Option) => {
    onChange(option.value);
  };
  const currentOption = getCurrentOption();
  return (
    <div className="mb-2 w-full">
      <Listbox value={currentOption} onChange={handleChange}>
        {({ open }) => (
          <>
            {label && (
              <Listbox.Label className="block text-sm font-medium text-gray-700">
                <div className="flex flex-row justify-between items-center">
                  <span>{label}</span>
                  {optional && (
                    <span className="text-sm text-gray-500" id="email-optional">
                      Optional
                    </span>
                  )}
                </div>
              </Listbox.Label>
            )}
            <div className="mt-1 relative">
              <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-tandaPurple focus:border-tandaPurple sm:text-sm">
                <span className="block truncate">{currentOption?.label}</span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <SelectorIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  static
                  className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                >
                  {options.map((option) => {
                    const selected = option.value === selectedOption;
                    return (
                      <Listbox.Option
                        key={option.value}
                        value={option}
                        disabled={!!option.unavailable}
                        className={({ active }) =>
                          classNames(
                            "cursor-default select-none relative py-2 pl-3 pr-9",
                            {
                              "text-white bg-tandaPurple": !!active,
                              "text-gray-900": !active,
                            }
                          )
                        }
                      >
                        {({ active }) => (
                          <>
                            <span
                              className={classNames("block truncate", {
                                "font-semibold": !!selected,
                                "font-normal": !selected,
                              })}
                            >
                              {option.label}
                            </span>
                            {selected && (
                              <span
                                className={classNames(
                                  "absolute inset-y-0 right-0 flex items-center pr-4",
                                  {
                                    "text-white": !!active,
                                    "text-tandaPurple": !active,
                                  }
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            )}
                          </>
                        )}
                      </Listbox.Option>
                    );
                  })}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      {error && (
        <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
          {error}
        </p>
      )}
    </div>
  );
};
