import { createReducer } from "@reduxjs/toolkit";
import { Types } from "./actionTypes";

export interface InitialWithdrawalAccountState {
  cache: Record<string, any>;
  withdrawalAccounts: any[];
  withdrawalAccountModals: any;
  withdrawalAccountsLoaders: any;
  selectedWithdrawalAccount: any;
}

const initialState: InitialWithdrawalAccountState = {
  withdrawalAccounts: [],

  withdrawalAccountModals: {
    addWithdrawalAccount: false,
    editWithdrawalAccount: false,
    deleteWithdrawalAccount: false,
    disableWithdrawalAccount: false,
    disableEnableWithdrawalAccount: false,
  },
  withdrawalAccountsLoaders: {
    submit: false,
    fetchWithdrawalAccounts: false,
    fetchTrasactionFees: false,
  },

  cache: {
    emailRequests: {},
    smsRequests: {},
    inAppRequests: {},
  },
  selectedWithdrawalAccount: {},
};

export const withdrawalAccountsReducer = createReducer(initialState, {
  [Types.UPDATE_SELECTED_WITHDRAWALACCOUNT]: (state, action) => {
    state.selectedWithdrawalAccount = action.payload;
  },
  [Types.TOGGLE_WITHDRAWALACCOUNT_MODALS]: (state, action) => {
    state.withdrawalAccountModals = {
      ...state.withdrawalAccountModals,
      ...action.payload,
    };
  },

  [Types.FETCH_WITHDRAWALACCOUNTS]: (state) => {
    state.withdrawalAccountsLoaders.fetchWithdrawalAccounts = true;
  },
  [Types.FETCH_WITHDRAWALACCOUNTS_SUCCESS]: (state, action) => {
    const { data } = action.payload;
    state.withdrawalAccountsLoaders.fetchWithdrawalAccounts = false;
    state.withdrawalAccounts = data;
  },
  [Types.FETCH_WITHDRAWALACCOUNTS_FAIL]: (state) => {
    state.withdrawalAccountsLoaders.fetchWithdrawalAccounts = false;
  },

  [Types.CREATE_WITHDRAWALACCOUNT]: (state) => {
    state.withdrawalAccountsLoaders.submit = true;
  },
  [Types.CREATE_WITHDRAWALACCOUNT_SUCCESS]: (state, action) => {
    state.withdrawalAccountsLoaders.submit = false;
    state.withdrawalAccounts = [
      action.payload.data,
      ...state.withdrawalAccounts,
    ];
  },
  [Types.CREATE_WITHDRAWALACCOUNT_FAIL]: (state) => {
    state.withdrawalAccountsLoaders.submit = false;
  },
});
