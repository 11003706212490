import { joiResolver } from "@hookform/resolvers/joi";
import _ from "lodash";
import { FC, useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { schemaValidation } from "../../../constants";
import { openNotification } from "../../../domains/App";
import {
  InitialResellerDfsState,
  toggleResellerDfsModal,
  updateResellerDfs,
} from "../../../domains/Reseller/ResellerDfs";
import { Modal } from "../../App";
import { CheckBox2, TextField } from "../../App/FormComponents";

interface ResellerDfs {
  available: boolean;
  minimumValue: number;
  maximumValue: number;
}

interface EditResellerDfsFormProps {
  resellerDfs: any;
}

export const EditResellerDfsForm: FC<EditResellerDfsFormProps> = ({
  resellerDfs,
}) => {
  const dispatch = useDispatch();
  const { resellerDfsModals, resellerDfssLoaders } = useSelector(
    (state: { resellerDfs: InitialResellerDfsState }) => state.resellerDfs
  );
  const { resellerId = "" } = useParams<{ resellerId?: string }>();

  const { control, handleSubmit, reset } = useForm<ResellerDfs>({
    resolver: joiResolver(schemaValidation.ResellerDfsSchema),
    defaultValues: {
      available: false,
      minimumValue: 0,
      maximumValue: 0,
    },
  });

  useEffect(() => {
    if (!_.isEmpty(resellerDfs)) {
      reset({
        available: resellerDfs?.available || false,
        minimumValue: resellerDfs?.minimumValue || 0,
        maximumValue: resellerDfs?.maximumValue || 0,
      });
    }
  }, [resellerDfs, reset]);

  const onClose = async () => {
    reset();
    await dispatch(
      toggleResellerDfsModal({
        editResellerDfs: false,
      })
    );
  };

  const onSubmit: SubmitHandler<ResellerDfs> = async (props) => {
    try {
      resellerDfs = {
        ...resellerDfs,
        available: props,
        minimumValue: props,
        maximumValue: props,
      };
      await dispatch(updateResellerDfs(resellerId, resellerDfs, props));
      await onClose();
      await dispatch(
        openNotification({
          openNotification: true,
          type: "success",
          message: "Reseller Product updated!",
        })
      );
    } catch (e) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Something went wrong",
        })
      );
    }
  };
  return (
    <Modal
      formId="edit_reseller_dfs_form"
      btnType="submit"
      modalTitle="Edit Reseller Product"
      isOpen={resellerDfsModals.editResellerDfs}
      onClose={onClose}
      btnLoading={resellerDfssLoaders.submit}
    >
      <form
        id="edit_reseller_dfs_form"
        className="space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="available"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <CheckBox2
              name="available"
              label="Available"
              value={value}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="minimumValue"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="minimumValue"
              label="Minimum Value"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />{" "}
        <Controller
          control={control}
          name="maximumValue"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="maximumValue"
              label="Maximum Value"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
      </form>
    </Modal>
  );
};
