import {
  NavigationProps,
  TabItemProps,
  UserMenuNavigationProps,
} from "../domains/App";

export const sidebarNavigation: NavigationProps[] = [
  {
    label: "Messaging",
    path: "/messages",
    icon: "dashboard",
    children: [
      {
        label: "Campaigns",
        path: "/messages",
      },
      {
        label: "Contacts",
        path: "/messages/groups",
      },
      {
        label: "Service Providers",
        path: "/messages/service-providers",
      },
      {
        label: "Event Listeners",
        path: "/messages/event-listeners",
      },
      {
        label: "Create Campaign",
        path: "/messages/create-campaign/sms",
      },
      {
        label: "Templates",
        path: "/messages/templates",
      },
    ],
  },
  {
    label: "Settings",
    path: "/products",
    icon: "product",
    children: [
      {
        label: "Products",
        path: "/products",
      },
      {
        label: "Service Providers",
        path: "/products/service-providers",
      },
      {
        label: "Batch Types",
        path: "/products/batch-types",
      },
      {
        label: "BCWs",
        path: "/products/bcws",
      },
    ],
  },
  {
    label: "Loans",
    path: "/loans",
    icon: "loans",
    children: [
      {
        label: "Loan Book",
        path: "/loans",
      },
    ],
  },
  {
    label: "Tanda I/O",
    path: "/tanda-io",
    icon: "wallet",
    children: [
      {
        label: "Products",
        path: "/tanda-io/io-products",
      },
      {
        label: "Resellers",
        path: "/tanda-io/resellers",
      },
      {
        label: "Reseller activity",
        path: "/tanda-io/payouts",
      },
      {
        label: "Sub wallet activity",
        path: "/tanda-io/sub-wallet-activity",
      },
      {
        label: "Sub wallets",
        path: "/tanda-io/sub-wallets",
      },
    ],
  },
  {
    label: "Reports",
    path: "/reports",
    icon: "report",
    children: [
      {
        label: "SME v2",
        path: "/reports/sme-statements",
      },
      {
        label: "iO v2",
        path: "/reports/io-statements",
      },
    ],
  },
];

export const userMenuNavigation: UserMenuNavigationProps[] = [
  { label: "Sign out", path: "/logout" },
];

export const LOCAL_STORE_KEY = "TANDA:DUKA_APP";

export const viewMessagesTabs: TabItemProps[] = [
  {
    key: "sms_request",
    label: "Sms",
    path: "/messages",
  },
  {
    key: "email_requests",
    label: "Email",
    path: "/messages/email-requests",
  },
  {
    key: "in_app_requests",
    label: "In-App",
    path: "/messages/in-app-requests",
  },
  {
    key: "outbox",
    label: "Outbox",
    path: "/messages/outbox",
  },
];

export const createCampaignTabs: TabItemProps[] = [
  {
    key: "sms_request",
    label: "Sms",
    path: "/messages/create-campaign/sms",
  },
  {
    key: "email_requests",
    label: "Email",
    path: "/messages/create-campaign/email",
  },
  {
    key: "in_app_requests",
    label: "In-App",
    path: "/messages/create-campaign/in-app",
  },
];

export const viewProductsTabs: TabItemProps[] = [
  {
    key: "customer-transaction-fee-rates",
    label: "Sms",
    path: "/products/3/customer-transaction-fee-rates",
  },
  {
    key: "transaction-fee-rates",
    label: "Email",
    path: "/products/3/transaction-fee-rates",
  },
  {
    key: "commission-fee-rates",
    label: "In-App",
    path: "/messages/in-app-requests",
  },
  {
    key: "dfs-payment-gateways",
    label: "Outbox",
    path: "/wallets/v1/dfses/3/dfs-payment-gateways",
  },
];
