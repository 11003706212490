import { FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useParams } from "react-router-dom";
import { TextField } from "../../App/FormComponents";
import { Modal } from "../../App";
import {
  InitialUserStateProps,
  toggleUserModal,
  createContact,
} from "../../../domains/Users";
import { openNotification } from "../../../domains/App";
import { schemaValidation } from "../../../constants";

interface ContactInput {
  firstName: string;
  lastName: string;
  middleName: string;
  emailAddress: string;
  phoneNumber: string;
}

export const ContactForm: FC = () => {
  const dispatch = useDispatch();
  const { userModals, userLoader } = useSelector(
    (state: { users: InitialUserStateProps }) => state.users
  );
  const { control, handleSubmit, reset } = useForm<ContactInput>({
    resolver: joiResolver(schemaValidation.ContactSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      middleName: undefined,
      emailAddress: "",
      phoneNumber: "",
    },
  });
  const { groupId } = useParams<{ groupId: string }>();

  const onClose = async () => {
    reset();
    await dispatch(
      toggleUserModal({
        contactModal: false,
      })
    );
  };

  const onSubmit: SubmitHandler<ContactInput> = async ({
    phoneNumber,
    ...rest
  }) => {
    try {
      await dispatch(
        createContact({
          groupId,
          data: {
            ...rest,
            phoneNumber: `254${phoneNumber}`,
          },
        })
      );
      await onClose();
      await dispatch(
        openNotification({
          openNotification: true,
          type: "success",
          message: "Contact added!",
        })
      );
    } catch (e) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Something went wrong",
        })
      );
    }
  };

  return (
    <Modal
      formId="contact_form"
      btnType="submit"
      modalTitle="Add a Contact"
      isOpen={userModals.contactModal}
      onClose={onClose}
      btnLoading={userLoader.submit}
    >
      <form
        id="contact_form"
        className="space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="firstName"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="firstName"
              label="First Name"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="middleName"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="middleName"
              label="Middle Name"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="lastName"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="lastName"
              label="Last Name"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="emailAddress"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="emailAddress"
              label="Email Address"
              type="email"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="phoneNumber"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="phoneNumber"
              label="Phone Number"
              startAddOnText="254"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
      </form>
    </Modal>
  );
};
