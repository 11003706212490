import { createReducer } from "@reduxjs/toolkit";
import { Types } from "./actionTypes";
import { PaginationProps } from "../App";
import _ from "lodash";

export interface InitialCommissionFeeRateState {
  cache: Record<string, any>;
  agentCommissionFees: any[];
  selectedCommissionFeeRate: any;
  commissionFeeRatesPagination: PaginationProps;
  commissionFeeRateModals: any;
  commissionFeeRatesLoaders: any;
}

const initialState: InitialCommissionFeeRateState = {
  agentCommissionFees: [],
  commissionFeeRatesPagination: {
    number: 0,
    totalPages: 1,
    size: 10,
    totalElements: 0,
  },
  selectedCommissionFeeRate: {},
  commissionFeeRatesLoaders: {
    submit: false,
    fetchCommissionFeeRates: false,
  },
  commissionFeeRateModals: {
    addCommissionFeeRate: false,
    editCommissionFeeRate: false,
    deleteCommissionFeeRate: false,
    disableCommissionFeeRate: false,
  },
  cache: {},
};

export const commissionFeeRatesReducer = createReducer(initialState, {
  [Types.TOGGLE_COMMISSIONFEERATE_MODALS]: (state, action) => {
    state.commissionFeeRateModals = {
      ...state.commissionFeeRateModals,
      ...action.payload,
    };
  },

  [Types.FETCH_AGENT_COMM_FEES]: (state) => {
    state.commissionFeeRatesLoaders.fetchCommissionFeeRates = true;
  },
  [Types.FETCH_AGENT_COMM_FEES_SUCCESS]: (state, action) => {
    const {
      data: { content, page },
    } = action.payload;
    state.commissionFeeRatesLoaders.fetchCommissionFeeRates = false;
    state.agentCommissionFees = content;
    state.commissionFeeRatesPagination = {
      number: page.number,
      size: page.size,
      totalPages: page.totalPages,
      totalElements: page.totalElements,
    };
  },
  [Types.FETCH_AGENT_COMM_FEES_FAIL]: (state) => {
    state.commissionFeeRatesLoaders.fetchCommissionFeeRates = false;
  },

  [Types.CREATE_COMMISSIONFEERATE]: (state) => {
    state.commissionFeeRatesLoaders.submit = true;
  },
  [Types.CREATE_COMMISSIONFEERATE_SUCCESS]: (state, action) => {
    state.commissionFeeRatesLoaders.submit = false;
    state.agentCommissionFees = [
      action.payload.data,
      ...state.agentCommissionFees,
    ];
  },
  [Types.CREATE_COMMISSIONFEERATE_FAIL]: (state) => {
    state.commissionFeeRatesLoaders.submit = false;
  },

  [Types.DELETE_COMMISSIONFEERATE]: (state) => {
    state.commissionFeeRatesLoaders.submit = true;
  },
  [Types.DELETE_COMMISSIONFEERATE_SUCCESS]: (state, action) => {
    const {
      previousAction: {
        payload: {
          request: {
            deleteProp: { id },
          },
        },
      },
    } = action.meta;
    state.agentCommissionFees = [
      ...state.agentCommissionFees.filter(
        (commissionFeeRate) => commissionFeeRate.id !== id
      ),
    ];
    if (_.isEmpty(state.agentCommissionFees)) {
      state.commissionFeeRatesPagination.number = 0;
    }
    state.commissionFeeRatesLoaders.submit = false;
  },
  [Types.DELETE_COMMISSIONFEERATE_FAIL]: (state) => {
    state.commissionFeeRatesLoaders.submit = false;
  },

  [Types.UPDATE_COMMISSIONFEERATE]: (state) => {
    state.commissionFeeRatesLoaders.submit = true;
  },
  [Types.UPDATE_COMMISSIONFEERATE_SUCCESS]: (state, action) => {
    const {
      previousAction: {
        payload: {
          request: {
            commissionFeeRate: { id },
            commissionFeeRate,
          },
        },
      },
    } = action.meta;
    state.commissionFeeRatesLoaders.submit = false;
    state.agentCommissionFees = [
      ...state.agentCommissionFees.map((agentCommissionFee) =>
        agentCommissionFee.id === id ? commissionFeeRate : agentCommissionFee
      ),
    ];
  },
  [Types.UPDATE_COMMISSIONFEERATE_FAIL]: (state) => {
    state.commissionFeeRatesLoaders.submit = false;
  },

  [Types.CHANGE_AGENT_COMM_STATUS]: (state) => {
    state.commissionFeeRatesLoaders.submit = true;
  },

  [Types.CHANGE_AGENT_COMM_STATUS_SUCCESS]: (state, action) => {
    const {
      previousAction: {
        payload: {
          request: {
            selectedAgentCommissionFee: { id },
            selectedAgentCommissionFee,
          },
        },
      },
    } = action.meta;
    state.commissionFeeRatesLoaders.submit = false;
    state.agentCommissionFees = [
      ...state.agentCommissionFees.map((agentCommissionFee) =>
        agentCommissionFee.id === id
          ? selectedAgentCommissionFee
          : agentCommissionFee
      ),
    ];
  },
  [Types.CHANGE_AGENT_COMM_STATUS_FAIL]: (state) => {
    state.commissionFeeRatesLoaders.submit = false;
  },
});
