import { createReducer } from "@reduxjs/toolkit";
import { Types } from "./actionTypes";
import { PaginationProps } from "../../App";
import { API_CLIENT_ROLES } from "../../../utils/app";

export interface InitialResellerState {
  cache: Record<string, any>;
  resellers: any[];
  reseller: any;
  resellersPagination: PaginationProps;
  resellerModals: any;
  resellersLoaders: any;
  selectedReseller: any;
  balances: any;
  apiClients: any[];
  apiClientsPagination: PaginationProps;
  apiClientRoles: any[];
  selectedApiClient: any;
  selectedRole: any;
}

const initialState: InitialResellerState = {
  apiClients: [],
  apiClientRoles: [],
  selectedApiClient: {},
  resellers: [],
  reseller: {},
  balances: {},
  resellersPagination: {
    number: 0,
    totalPages: 1,
    size: 9,
    totalElements: 0,
  },
  apiClientsPagination: {
    number: 0,
    totalPages: 1,
    size: 9,
    totalElements: 0,
  },
  resellerModals: {
    addReseller: false,
    editReseller: false,
    deleteReseller: false,
    disableReseller: false,
    disableEnableReseller: false,
    showDetails: false,
    addClient: false,
    editClient: false,
    deleteClient: false,
    viewClient: false,
    viewClientRoles: false,
  },
  resellersLoaders: {
    submit: false,
    fetchResellers: false,
    fetchReseller: false,
    fetchTrasactionFees: false,
    fetchBalances: false,
    fetchApiClients: false,
    fetchApiClientRoles: false,
    grantRole: false,
    revokeRole: false,
  },

  cache: {
    emailRequests: {},
    smsRequests: {},
    inAppRequests: {},
  },
  selectedReseller: {},
  selectedRole: {},
};

export const resellerReducer = createReducer(initialState, {
  [Types.UPDATE_SELECTED_RESELLER]: (state, action) => {
    state.selectedReseller = action.payload;
  },
  [Types.TOGGLE_RESELLER_MODALS]: (state, action) => {
    state.resellerModals = {
      ...state.resellerModals,
      ...action.payload,
    };
  },

  [Types.FETCH_RESELLERS]: (state) => {
    state.resellersLoaders.fetchResellers = true;
  },
  [Types.FETCH_RESELLERS_SUCCESS]: (state, action) => {
    const {
      data: { content, page },
    } = action.payload;
    state.resellersLoaders.fetchResellers = false;
    state.resellers = content;
    state.resellersPagination = {
      number: page.number,
      size: page.size,
      totalPages: page.totalPages,
      totalElements: page.totalElements,
    };
  },
  [Types.FETCH_RESELLERS_FAIL]: (state) => {
    state.resellersLoaders.fetchResellers = false;
  },

  [Types.FETCH_RESELLER]: (state) => {
    state.resellersLoaders.fetchReseller = true;
  },
  [Types.FETCH_RESELLER_SUCCESS]: (state, action) => {
    const { data } = action.payload;
    state.resellersLoaders.fetchReseller = false;
    state.reseller = data;
    state.selectedReseller = data;
  },
  [Types.FETCH_RESELLER_FAIL]: (state) => {
    state.resellersLoaders.fetchReseller = false;
  },

  [Types.CREATE_RESELLER]: (state) => {
    state.resellersLoaders.submit = true;
  },
  [Types.CREATE_RESELLER_SUCCESS]: (state, action) => {
    state.resellersLoaders.submit = false;
    state.resellers = [action.payload.data, ...state.resellers];
  },
  [Types.CREATE_RESELLER_FAIL]: (state) => {
    state.resellersLoaders.submit = false;
  },

  [Types.UPDATE_RESELLER]: (state) => {
    state.resellersLoaders.submit = true;
  },

  [Types.UPDATE_RESELLER_SUCCESS]: (state, action) => {
    const {
      previousAction: {
        payload: {
          request: {
            reseller: { id },
            reseller,
          },
        },
      },
    } = action.meta;
    state.resellersLoaders.submit = false;
    state.resellers = [
      ...state.resellers.map((pr) => (pr.id === id ? reseller : pr)),
    ];
  },
  [Types.UPDATE_RESELLER_FAIL]: (state) => {
    state.resellersLoaders.submit = false;
  },

  [Types.CHANGE_RESELLER_STATUS]: (state) => {
    state.resellersLoaders.submit = true;
  },

  [Types.CHANGE_RESELLER_STATUS_SUCCESS]: (state, action) => {
    const {
      previousAction: {
        payload: {
          request: {
            selectedReseller: { id },
            selectedReseller,
          },
        },
      },
    } = action.meta;
    state.resellersLoaders.submit = false;
    state.resellers = [
      ...state.resellers.map((reseller) =>
        reseller.id === id ? selectedReseller : reseller
      ),
    ];
  },
  [Types.CHANGE_RESELLER_STATUS_FAIL]: (state) => {
    state.resellersLoaders.submit = false;
  },

  [Types.FETCH_RESELLERS_BALANCES]: (state) => {
    state.resellersLoaders.fetchBalances = true;
  },
  [Types.FETCH_RESELLERS_BALANCES_SUCCESS]: (state, action) => {
    const { data } = action.payload;
    state.resellersLoaders.fetchBalances = false;
    state.balances = data;
  },
  [Types.FETCH_RESELLERS_BALANCES_FAIL]: (state) => {
    state.resellersLoaders.fetchBalances = false;
  },

  [Types.FETCH_RESELLER_API_CLIENTS]: (state) => {
    state.resellersLoaders.fetchApiClients = true;
  },
  [Types.FETCH_RESELLER_API_CLIENTS_SUCCESS]: (state, action) => {
    const { data } = action.payload;
    state.resellersLoaders.fetchApiClients = false;
    state.apiClients = data;
  },
  [Types.FETCH_RESELLER_API_CLIENTS_FAIL]: (state) => {
    state.resellersLoaders.fetchApiClients = false;
  },

  [Types.FETCH_RESELLER_API_CLIENT_ROLES]: (state) => {
    state.resellersLoaders.fetchApiClientRoles = true;
  },
  [Types.FETCH_RESELLER_API_CLIENT_ROLES_SUCCESS]: (state, action) => {
    const { data } = action.payload;
    state.resellersLoaders.fetchApiClientRoles = false;
    const roleTypeIds: any[] = data.map((role: any) => role.id);
    state.apiClientRoles = API_CLIENT_ROLES.map((role: any) => ({
      ...role,
      enabled: roleTypeIds?.includes(role?.id),
    }));
  },
  [Types.FETCH_RESELLER_API_CLIENT_ROLES_FAIL]: (state) => {
    state.resellersLoaders.fetchApiClientRoles = false;
  },

  [Types.UPDATE_SELECTED_API_CLIENT]: (state, action) => {
    state.selectedApiClient = action.payload;
  },

  [Types.UPDATE_SELECTED_API_CLIENT_ROLES]: (state, action) => {
    state.apiClientRoles = state.apiClientRoles?.map((role) => {
      if (role?.id !== action.payload) {
        return role;
      }
      return {
        ...role,
        enabled: !role.enabled,
      };
    });
  },

  [Types.GRANT_API_CLIENT_ROLE]: (state) => {
    state.resellersLoaders.grantRole = true;
  },
  [Types.GRANT_API_CLIENT_ROLE_SUCCESS]: (state, action) => {
    state.resellersLoaders.grantRole = false;
  },
  [Types.GRANT_API_CLIENT_ROLE_FAIL]: (state) => {
    state.resellersLoaders.grantRole = false;
  },

  [Types.REVOKE_API_CLIENT_ROLE]: (state) => {
    state.resellersLoaders.revokeRole = true;
  },
  [Types.REVOKE_API_CLIENT_ROLE_SUCCESS]: (state, action) => {},
  [Types.REVOKE_API_CLIENT_ROLE_FAIL]: (state) => {
    state.resellersLoaders.revokeRole = false;
  },

  [Types.UPDATE_SELECTED_ROLE]: (state, action) => {
    state.selectedRole = action.payload;
  },
});
