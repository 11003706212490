import { joiResolver } from "@hookform/resolvers/joi";
import { FC } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { schemaValidation } from "../../../constants";
import { openNotification } from "../../../domains/App";
import {
  createResellerDfsFeeRate,
  InitialResellerDfsFeeRateState,
  toggleResellerDfsFeeRateModal,
} from "../../../domains/Reseller/ResellerDfsFeeRate";
import { Modal } from "../../App";
import { CheckBox2, Select, TextField } from "../../App/FormComponents";

interface ResellerDfsFeeRate {
  type: string;
  starting: number;
  ending: number;
  amount: number;
  enabled: boolean;
}

export const ResellerDfsFeeRateForm: FC = () => {
  const dispatch = useDispatch();
  const { resellerDfsFeeRateModals, resellerDfsFeeRatesLoaders } = useSelector(
    (state: { resellerDfsFeeRate: InitialResellerDfsFeeRateState }) =>
      state.resellerDfsFeeRate
  );
  const { dfsId = "" } = useParams<{ dfsId?: string }>();
  const { control, handleSubmit, reset } = useForm<ResellerDfsFeeRate>({
    resolver: joiResolver(schemaValidation.ResellerDfsRateSchema),
    defaultValues: {
      type: "",
      amount: 0,
      starting: 0,
      ending: 0,
      enabled: false,
    },
  });

  const onClose = async () => {
    reset();
    await dispatch(
      toggleResellerDfsFeeRateModal({
        addResellerDfsFeeRate: false,
      })
    );
  };

  const onSubmit: SubmitHandler<ResellerDfsFeeRate> = async (props) => {
    try {
      await dispatch(createResellerDfsFeeRate(dfsId, props));
      await onClose();
      await dispatch(
        openNotification({
          openNotification: true,
          type: "success",
          message: "Product Fee Rate added!",
        })
      );
    } catch (e) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Something went wrong",
        })
      );
    }
  };
  return (
    <Modal
      formId="reseller_dfs_fee_rate_form"
      btnType="submit"
      modalTitle="Add a Reseller Product Fee Rate"
      isOpen={resellerDfsFeeRateModals.addResellerDfsFeeRate}
      onClose={onClose}
      btnLoading={resellerDfsFeeRatesLoaders.submit}
    >
      <form
        id="reseller_dfs_fee_rate_form"
        className="space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="type"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Select
              id="type"
              label="Fee Type"
              selectedOption={value}
              error={error?.message}
              onChange={onChange}
              options={[
                {
                  value: "PERCENTAGE",
                  label: "PERCENTAGE",
                },
                {
                  value: "ABS_VAL",
                  label: "ABS_VAL",
                },
                {
                  value: "NONE",
                  label: "NONE",
                },
              ]}
            />
          )}
        />

        <Controller
          control={control}
          name="starting"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="starting"
              label="Starting"
              type="number"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="ending"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="ending"
              label="Ending"
              type="number"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="amount"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="amount"
              label="Amount"
              type="number"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="enabled"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <CheckBox2
              name="enabled"
              label="Enabled"
              value={value}
              onChange={onChange}
            />
          )}
        />
      </form>
    </Modal>
  );
};
