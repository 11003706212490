import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { TextField } from "../../App/FormComponents";
import { Modal } from "../../App";
import { openNotification } from "../../../domains/App";
import { schemaValidation } from "../../../constants";
import {
  fetchResellers,
  InitialResellerState,
  toggleResellerModal,
} from "../../../domains/Reseller/Reseller";
import API from "../../../utils/api";

interface FormData {
  name: string;
  firstName: string;
  otherNames: string;
  emailAddress: string;
  phoneNumber: string;
  nationalId: string;
  officePhysicalAddress: string;
  officePostalAddress: string;
  businessNumber: string;
}

function generateRandomString(length: any) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  return result;
}

export const ResellerForm: FC = () => {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  let { resellerModals } = useSelector(
    (state: { reseller: InitialResellerState }) => state.reseller
  );
  const { control, handleSubmit, reset } = useForm<FormData>({
    resolver: joiResolver(schemaValidation.ResellerSchema),
    defaultValues: {
      name: "",
      firstName: "",
      otherNames: "",
      emailAddress: "",
      phoneNumber: "",
      nationalId: "",
      officePhysicalAddress: "",
      officePostalAddress: "",
      businessNumber: "",
    },
  });

  const onClose = async () => {
    reset();
    await dispatch(
      toggleResellerModal({
        addReseller: false,
      })
    );
  };
  const onSubmit: SubmitHandler<FormData> = async (props) => {
    try {
      let url = "/io-accounts/v2/organizations";
      let username = props.firstName + generateRandomString(3) + "001";
      let resellerData = {
        name: props.name,
        owner: {
          userName: username,
          firstName: props.firstName,
          otherNames: props.otherNames,
          emailAddress: props.emailAddress,
          phoneNumber: "254" + props.phoneNumber,
          nationalId: props.nationalId,
          officePhysicalAddress: props.officePhysicalAddress,
          officePostalAddress: props.officePostalAddress,
        },
        ids: [
          {
            typeId: "002",
            idNumber: props.businessNumber,
          },
        ],
      };
      setSubmitting(true);
      API.post(url, resellerData)
        .then((response: any) => {
          setSubmitting(false);
          dispatch(fetchResellers({ size: 12 }));
          onClose();
          openNotification({
            openNotification: true,
            type: "success",
            message: "Request submitted successfully",
          });
        })
        .catch((e) => {
          setSubmitting(false);
          // onClose();
          dispatch(
            openNotification({
              openNotification: true,
              type: "error",
              message: "Something went wrong",
            })
          );
        });
    } catch (e) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Something went wrong",
        })
      );
    }
  };
  return (
    <Modal
      formId="reseller_form"
      btnType="submit"
      modalTitle="Create Reseller Account"
      isOpen={resellerModals.addReseller}
      onClose={onClose}
      btnLoading={submitting}
    >
      <form
        id="reseller_form"
        className="space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mb-6">
          <Controller
            control={control}
            name="name"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id="name"
                label="Account name*"
                type="text"
                value={value}
                error={error?.message}
                onChange={onChange}
              />
            )}
          />
        </div>
        <h5 className="text-gray-400 font-weight-bold">
          Nominated director details
        </h5>
        <div className="grid gap-2 grid-cols-2">
          <Controller
            control={control}
            name="firstName"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id="firstName"
                label="First name*"
                type="text"
                value={value}
                error={error?.message}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="otherNames"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id="otherNames"
                label="Other names*"
                type="text"
                value={value}
                error={error?.message}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="emailAddress"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                placeholder="johndoe@gmail.com"
                id="emailAddress"
                label="Email*"
                type="text"
                value={value}
                error={error?.message}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="phoneNumber"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id="phoneNumber"
                label="Phone*"
                startAddOnText="254"
                type="text"
                value={value}
                error={error?.message}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="nationalId"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id="nationalId"
                label="National ID*"
                type="text"
                value={value}
                error={error?.message}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="officePhysicalAddress"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id="officePhysicalAddress"
                label="Street Address*"
                type="text"
                value={value}
                error={error?.message}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="officePostalAddress"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id="officePostalAddress"
                label="Postal Address*"
                type="text"
                value={value}
                error={error?.message}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className="mt-6">
          <h5 className="text-gray-400 mb-4 font-weight-bold">
            Business details
          </h5>
          <Controller
            control={control}
            name="businessNumber"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id="businessNumber"
                label="Business Reg No*"
                type="text"
                value={value}
                error={error?.message}
                onChange={onChange}
              />
            )}
          />
        </div>
        <p>Fields marked with * are required</p>
      </form>
    </Modal>
  );
};
