import { Types } from "./actionTypes";
import { getURL } from "../../utils";

export const toggleCommissionFeeRateModal =
  (payload: any) => async (dispatch: any) =>
    dispatch({
      type: Types.TOGGLE_COMMISSIONFEERATE_MODALS,
      payload,
    });

export const fetchCommissionFeeRates =
  (pagination?: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.FETCH_COMMISSIONFEERATES,
      payload: {
        request: {
          method: "GET",
          url: getURL("/api/agent-commission-fees", pagination),
          useCache: true,
        },
      },
    });

export const fetchAgentCommissionFees =
  (productId: string, pagination?: any) => async (dispatch: any) => {
    await dispatch({
      type: Types.FETCH_AGENT_COMM_FEES,
      payload: {
        request: {
          method: "GET",
          url: getURL(
            `wallets/v1/dfses/${productId}/agent-commission-fees`,
            pagination
          ),
        },
      },
    });
  };

export const createCommissionFeeRate =
  (productId: any, data: any) => async (dispatch: any) => {
    await dispatch({
      type: Types.CREATE_COMMISSIONFEERATE,
      payload: {
        request: {
          url: `wallets/v1/dfses/${productId}/agent-commission-fees`,
          method: "POST",
          data,
        },
      },
    });
  };

export const updateCommissionFeeRate =
  (commissionFeeRate: any, data: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.UPDATE_COMMISSIONFEERATE,
      payload: {
        request: {
          url: `wallets/v1/agent-commission-fees/${commissionFeeRate.id}`,
          method: "PUT",
          data,
          commissionFeeRate,
        },
      },
    });

export const deleteCommissionFeeRate =
  (spProperty: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.DELETE_COMMISSIONFEERATE,
      payload: {
        request: {
          url: `/wallets/v1/agent-commission-fees/${spProperty.id}`,
          method: "DELETE",
          deleteProp: spProperty,
        },
      },
    });

export const changeStatus =
  (selectedAgentCommissionFee: any, status: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.CHANGE_AGENT_COMM_STATUS,
      payload: {
        request: {
          url: `/wallets/v1/agent-commission-fees/${selectedAgentCommissionFee.id}`,
          method: "PATCH",
          data: status,
          selectedAgentCommissionFee,
        },
      },
    });
