import { FC } from "react";
import classNames from "classnames";

interface ActionBadgeProps {
  title?: string;
  options: {
    id: string;
    label: string;
  }[];
}

export const ActionBadge: FC<ActionBadgeProps> = ({ title, options }) => {
  return (
    <div className="mb-2">
      {title && (
        <legend className="block text-sm font-medium text-gray-700">
          {title}
        </legend>
      )}
      <div className="mt-1 flex flex-wrap space-x-4">
        {options.map((option) => {
          return (
            <span
              className={classNames(
                "inline-flex items-center px-3 py-1 rounded-full text-sm font-medium cursor-pointer mb-2 bg-gray-100 text-gray-800"
              )}
            >
              {option.label}
            </span>
          );
        })}
      </div>
    </div>
  );
};
