import { FC, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { CheckBox2, Select, TextField } from "../../App/FormComponents";
import { Modal } from "../../App";
import {
  InitialIntgState,
  toggleIntgModal,
  updateIntg,
} from "../../../domains/Integration";
import { openNotification } from "../../../domains/App";
import { schemaValidation } from "../../../constants";
import _ from "lodash";
import {
  fetchPaymentGateways,
  InitialPaymentGatewayState,
} from "../../../domains/PaymentGateway";

interface Intg {
  paymentGatewayId: string;
  enabled: boolean;
  commissionFeeRate: number;
  commissionFeeRateType: string;
  transactionFeeRate: number;
  transactionFeeRateType: string;
  minimumValue: number;
  maximumValue: number;
}

interface EditIntgFormProps {
  intg: any;
}

export const EditIntgForm: FC<EditIntgFormProps> = ({ intg }) => {
  const dispatch = useDispatch();
  const { intgModals, intgsLoaders } = useSelector(
    (state: { intg: InitialIntgState }) => state.intg
  );
  const { paymentGateways } = useSelector(
    (state: { paymentGateway: InitialPaymentGatewayState }) =>
      state.paymentGateway
  );
  const { control, handleSubmit, reset } = useForm<Intg>({
    resolver: joiResolver(schemaValidation.IntgSchema),
    defaultValues: {
      paymentGatewayId: "",
      commissionFeeRate: 0,
      commissionFeeRateType: "",
      transactionFeeRate: 0,
      transactionFeeRateType: "",
      enabled: false,
      minimumValue: 0,
      maximumValue: 0,
    },
  });

  useEffect(() => {
    const fetchPaymentGatewayssAsync = async () =>
      await dispatch(fetchPaymentGateways());

    fetchPaymentGatewayssAsync();
  }, [dispatch]);

  useEffect(() => {
    if (!_.isEmpty(intg)) {
      reset({
        paymentGatewayId: intg?.paymentGatewayId || "",
        commissionFeeRate: intg?.commissionFeeRate || 0,
        commissionFeeRateType: intg?.commissionFeeRateType || "",
        transactionFeeRate: intg?.transactionFeeRate || 0,
        transactionFeeRateType: intg?.transactionFeeRateType || "",
        enabled: intg?.enabled || false,
        minimumValue: intg?.minimumValue || 0,
        maximumValue: intg?.maximumValue || 0,
      });
    }
  }, [intg, reset]);

  const onClose = async () => {
    reset();
    await dispatch(
      toggleIntgModal({
        editIntg: false,
      })
    );
  };
  const paymentGatewayOptions = paymentGateways.map((f: any) => ({
    label: f.name,
    value: f.id,
  }));

  const onSubmit: SubmitHandler<Intg> = async (props) => {
    intg = {
      ...intg,
      paymentGatewayId: props?.paymentGatewayId,
      commissionFeeRate: props?.commissionFeeRate,
      commissionFeeRateType: props?.commissionFeeRateType,
      transactionFeeRate: props?.transactionFeeRate,
      transactionFeeRateType: props?.transactionFeeRateType,
      enabled: props?.enabled,
      minimumValue: props?.minimumValue,
      maximumValue: props?.maximumValue,
    };
    try {
      await dispatch(updateIntg(intg, props));
      await onClose();
      await dispatch(
        openNotification({
          openNotification: true,
          type: "success",
          message: "Intg updated!",
        })
      );
    } catch (e) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Something went wrong",
        })
      );
    }
  };
  return (
    <Modal
      formId="edit_intg_form"
      btnType="submit"
      modalTitle="Edit intg"
      isOpen={intgModals.editIntg}
      onClose={onClose}
      btnLoading={intgsLoaders.submit}
    >
      <form
        id="edit_intg_form"
        className="space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="paymentGatewayId"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Select
              id="paymentGatewayId"
              label="Payment Gateway"
              selectedOption={value}
              error={error?.message}
              onChange={onChange}
              options={paymentGatewayOptions}
            />
          )}
        />
        <Controller
          control={control}
          name="enabled"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <CheckBox2
              name="enabled"
              label="Enabled"
              value={value}
              onChange={onChange}
            />
          )}
        />

        <div className="grid grid-cols-2 ">
          <div className="mr-1">
            <Controller
              control={control}
              name="commissionFeeRateType"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Select
                  id="commissionFeeRateType"
                  label="Commission Fee Rate Type"
                  selectedOption={value}
                  error={error?.message}
                  onChange={onChange}
                  options={[
                    {
                      value: "PERCENTAGE",
                      label: "PERCENTAGE",
                    },
                    {
                      value: "ABS_VAL",
                      label: "ABS_VAL",
                    },
                    // {
                    //   value: "RATIO",
                    //   label: "RATIO",
                    // },
                    {
                      value: "NONE",
                      label: "NONE",
                    },
                  ]}
                />
              )}
            />
          </div>
          <div className="ml-1">
            <Controller
              control={control}
              name="transactionFeeRateType"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Select
                  id="transactionFeeRateType"
                  label="Transaction Fee Rate Type"
                  selectedOption={value}
                  error={error?.message}
                  onChange={onChange}
                  options={[
                    {
                      value: "PERCENTAGE",
                      label: "PERCENTAGE",
                    },
                    {
                      value: "ABS_VAL",
                      label: "ABS_VAL",
                    },
                    {
                      value: "RATIO",
                      label: "RATIO",
                    },
                    {
                      value: "NONE",
                      label: "NONE",
                    },
                  ]}
                />
              )}
            />
          </div>
          <div className="mr-1">
            <Controller
              control={control}
              name="commissionFeeRate"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  id="commissionFeeRate"
                  label="Commission Fee Rate"
                  type="number"
                  value={value}
                  error={error?.message}
                  onChange={onChange}
                />
              )}
            />
          </div>

          <div className="ml-1">
            <Controller
              control={control}
              name="transactionFeeRate"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  id="transactionFeeRate"
                  label="Transaction Fee Rate"
                  type="number"
                  value={value}
                  error={error?.message}
                  onChange={onChange}
                />
              )}
            />
          </div>

          <div className="mr-1">
            <Controller
              control={control}
              name="minimumValue"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  id="minimunValue"
                  label="Minimum Value"
                  type="number"
                  value={value}
                  error={error?.message}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="ml-1">
            <Controller
              control={control}
              name="maximumValue"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  id="maximumValue"
                  label="Maximum Value"
                  type="number"
                  value={value}
                  error={error?.message}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};
