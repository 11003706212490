import { createReducer } from "@reduxjs/toolkit";
import { Types } from "./actionTypes";
import { PaginationProps } from "../App";
import _ from "lodash";

export interface InitialBatchTypeState {
  cache: Record<string, any>;
  batchTypes: any[];
  selectedBatchType: any;
  batchTypesPagination: PaginationProps;
  batchTypeModals: any;
  batchTypesLoaders: any;
}

const initialState: InitialBatchTypeState = {
  batchTypes: [],
  batchTypesPagination: {
    number: 0,
    totalPages: 0,
    size: 100,
    totalElements: 0,
  },
  selectedBatchType: {},
  batchTypesLoaders: {
    submit: false,
    fetchBatchTypes: false,
  },
  batchTypeModals: {
    addBatchType: false,
    editBatchType: false,
    deleteBatchType: false,
  },
  cache: {},
};

export const batchTypesReducer = createReducer(initialState, {
  [Types.TOGGLE_BATCHTYPE_MODALS]: (state, action) => {
    state.batchTypeModals = {
      ...state.batchTypeModals,
      ...action.payload,
    };
  },

  [Types.FETCH_BATCHTYPES]: (state) => {
    state.batchTypesLoaders.fetchBatchTypes = true;
  },
  [Types.FETCH_BATCHTYPES_SUCCESS]: (state, action) => {
    const {
      data: { content, page },
    } = action.payload;
    state.batchTypesLoaders.fetchBatchTypes = false;
    state.batchTypes = content;
    state.batchTypesPagination = {
      number: page.number,
      size: page.size,
      totalPages: page.totalPages,
      totalElements: page.totalElements,
    };
  },
  [Types.FETCH_BATCHTYPES_FAIL]: (state) => {
    state.batchTypesLoaders.fetchBatchTypes = false;
  },

  [Types.CREATE_BATCHTYPE]: (state) => {
    state.batchTypesLoaders.submit = true;
  },
  [Types.CREATE_BATCHTYPE_SUCCESS]: (state, action) => {
    state.batchTypesLoaders.submit = false;
    state.batchTypes = [action.payload.data, ...state.batchTypes];
  },
  [Types.CREATE_BATCHTYPE_FAIL]: (state) => {
    state.batchTypesLoaders.submit = false;
  },

  [Types.DELETE_BATCHTYPE]: (state) => {
    state.batchTypesLoaders.submit = true;
  },
  [Types.DELETE_BATCHTYPE_SUCCESS]: (state, action) => {
    const {
      previousAction: {
        payload: {
          request: {
            deleteProp: { id },
          },
        },
      },
    } = action.meta;
    state.batchTypes = [
      ...state.batchTypes.filter((batchType) => batchType.id !== id),
    ];
    if (_.isEmpty(state.batchTypes)) {
      state.batchTypesPagination.number = 0;
    }
    state.batchTypesLoaders.submit = false;
  },
  [Types.DELETE_BATCHTYPE_FAIL]: (state) => {
    state.batchTypesLoaders.submit = false;
  },

  [Types.UPDATE_BATCHTYPE]: (state) => {
    state.batchTypesLoaders.submit = true;
  },
  [Types.UPDATE_BATCHTYPE_SUCCESS]: (state, action) => {
    state.batchTypesLoaders.submit = false;
    state.batchTypes = [
      ...state.batchTypes.map((sp) =>
        sp.id === action.payload.data.id ? action.payload.data : sp
      ),
    ];
  },
  [Types.UPDATE_BATCHTYPE_FAIL]: (state) => {
    state.batchTypesLoaders.submit = false;
  },
});
