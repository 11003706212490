import { Types } from "./actionTypes";
import { getURL } from "../../utils";

export const updateMessageTab = (payload: any) => async (dispatch: any) =>
  await dispatch({
    type: Types.CHANGE_MESSAGE_TABS,
    payload,
  });

export const fetchMessages = (pagination?: any) => async (dispatch: any) =>
  await dispatch({
    type: Types.FETCH_MESSAGES,
    payload: {
      request: {
        method: "GET",
        url: getURL("/mc-messaging/v1/messages", pagination),
        useCache: true,
      },
    },
  });

export const fetchSmsRequests = (pagination?: any) => async (dispatch: any) =>
  await dispatch({
    type: Types.FETCH_SMS_REQUESTS,
    payload: {
      request: {
        method: "GET",
        url: getURL("/mc-messaging/v1/sms-campaigns", pagination),
        useCache: true,
      },
    },
  });

export const fetchTemplates = (pagination?: any) => async (dispatch: any) =>
  await dispatch({
    type: Types.FETCH_TEMPLATES,
    payload: {
      request: {
        method: "GET",
        url: getURL("/mc-messaging/v1/message-templates", pagination),
        useCache: true,
      },
    },
  });

export const toggleMessagingModal = (payload: any) => async (dispatch: any) =>
  dispatch({
    type: Types.TOGGLE_MESSAGE_MODALS,
    payload,
  });

export const fetchServiceProviders =
  (pagination?: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.FETCH_SERVICE_PROVIDERS,
      payload: {
        request: {
          method: "GET",
          url: getURL("/mc-messaging/v1/sps", pagination),
          useCache: true,
        },
      },
    });

export const createServiceProvider = (data: any) => async (dispatch: any) => {
  await dispatch({
    type: Types.CREATE_SERVICE_PROVIDER,
    payload: {
      request: {
        url: `/mc-messaging/v1/sps`,
        method: "POST",
        data,
      },
    },
  });
};

export const createTemplate = (data: any) => async (dispatch: any) => {
  await dispatch({
    type: Types.CREATE_MESSAGE_TEMPLATE,
    payload: {
      request: {
        url: `/mc-messaging/v1/message-templates`,
        method: "POST",
        data,
      },
    },
  });
};

export const deleteTemplate = (template: any) => async (dispatch: any) =>
  await dispatch({
    type: Types.DELETE_MESSAGE_TEMPLATE,
    payload: {
      request: {
        url: `/mc-messaging/v1/message-templates/${template.id}`,
        method: "DELETE",
        deleteProp: template,
      },
    },
  });

export const updateTemplate =
  (templateId: string, data: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.UPDATE_MESSAGE_TEMPLATE,
      payload: {
        request: {
          url: `/mc-messaging/v1/message-templates/${templateId}`,
          method: "PUT",
          data,
        },
      },
    });

export const fetchTemplateVariables =
  (pagination?: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.FETCH_TEMPLATE_VARIABLES,
      payload: {
        request: {
          method: "GET",
          url: getURL("/mc-messaging/v1/template-variables", pagination),
          useCache: true,
        },
      },
    });

export const deleteServiceProvider = (sp: any) => async (dispatch: any) =>
  await dispatch({
    type: Types.DELETE_SP,
    payload: {
      request: {
        url: `/mc-messaging/v1/sps/${sp.id}`,
        method: "DELETE",
        deleteProp: sp,
      },
    },
  });

export const updateServiceProvider =
  (spId: string, data: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.UPDATE_SP,
      payload: {
        request: {
          url: `/mc-messaging/v1/sps/${spId}`,
          method: "PUT",
          data,
        },
      },
    });

export const updateSpProps =
  (spPropertyId: string, data: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.UPDATE_SP_PROPS,
      payload: {
        request: {
          url: `/mc-messaging/v1/sp-props/${spPropertyId}`,
          method: "PUT",
        },
      },
      data,
    });

export const deleteSpProps = (spProperty: any) => async (dispatch: any) =>
  await dispatch({
    type: Types.DELETE_SP_PROPS,
    payload: {
      request: {
        url: `/mc-messaging/v1/sp-props/${spProperty.id}`,
        method: "DELETE",
        deleteProp: spProperty,
      },
    },
  });

export const fetchSpProps = (spId: string) => async (dispatch: any) => {
  await dispatch({
    type: Types.FETCH_SP_PROPS,
    payload: {
      request: {
        url: `/mc-messaging/v1/sps/${spId}/props`,
        method: "GET",
      },
    },
  });
};

export const createSpProps =
  (spId: string, data: any) => async (dispatch: any) => {
    await dispatch({
      type: Types.CREATE_SP_PROPS,
      payload: {
        request: {
          url: `/mc-messaging/v1/sps/${spId}/props`,
          method: "POST",
          data,
        },
      },
    });
  };

export const updateSelectedServiceProvider =
  (payload: any) => async (dispatch: any) => {
    await dispatch({
      type: Types.UPDATE_SELECTED_SP,
      payload,
    });
  };

export const fetchEmailRequests = (pagination?: any) => async (dispatch: any) =>
  await dispatch({
    type: Types.FETCH_EMAIL_REQUESTS,
    payload: {
      request: {
        method: "GET",
        url: getURL("/mc-messaging/v1/email-campaigns", pagination),
        useCache: true,
      },
    },
  });

export const fetchInAppRequests = (pagination?: any) => async (dispatch: any) =>
  await dispatch({
    type: Types.FETCH_IN_APP_REQUESTS,
    payload: {
      request: {
        method: "GET",
        url: getURL("/mc-messaging/v1/inapp-campaigns", pagination),
        useCache: true,
      },
    },
  });

const handleMessageRequest =
  (dispatchType: any, requestUrl: any) =>
  (data: any) =>
  async (dispatch: any) =>
    await dispatch({
      type: dispatchType,
      payload: {
        request: {
          url: requestUrl,
          method: "POST",
          data,
        },
      },
    });

export const createEmailCampaign = handleMessageRequest(
  Types.CREATE_EMAIL_REQUESTS,
  "/mc-messaging/v1/email-campaigns"
);
export const createInAppCampaign = handleMessageRequest(
  Types.CREATE_IN_APP_REQUESTS,
  "/mc-messaging/v1/inapp-campaigns"
);
export const createSmsCampaign = handleMessageRequest(
  Types.CREATE_SMS_REQUESTS,
  "/mc-messaging/v1/sms-campaigns"
);
export const handleSaveToCache =
  (actionType: string, payload: any) => async (dispatch: any) => {
    dispatch({
      type: actionType,
      payload,
    });
  };

export const fetchEventListeners =
  (pagination?: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.FETCH_EVENT_LISTENERS,
      payload: {
        request: {
          method: "GET",
          url: getURL("/mc-messaging/v1/event-listeners", pagination),
          useCache: true,
        },
      },
    });

export const createEventListener = (data: any) => async (dispatch: any) => {
  await dispatch({
    type: Types.CREATE_EVENT_LISTENER,
    payload: {
      request: {
        url: `/mc-messaging/v1/event-listeners`,
        method: "POST",
        data,
      },
    },
  });
};

export const updateEventListener =
  (eventListenerId: string, data: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.UPDATE_EVENT_LISTENER,
      payload: {
        request: {
          url: `/mc-messaging/v1/event-listeners/${eventListenerId}`,
          method: "PUT",
        },
      },
      data,
    });
