export const Types: Record<string, string> = {
  TOGGLE_INTG_MODALS: "TOGGLE_INTG_MODALS",

  FETCH_INTGS: "FETCH_INTGS",
  FETCH_INTGS_SUCCESS: "FETCH_INTGS_SUCCESS",
  FETCH_INTGS_FAIL: "FETCH_INTGS_FAIL",


  CREATE_INTG: "CREATE_INTG",
  CREATE_INTG_SUCCESS: "CREATE_INTG_SUCCESS",
  CREATE_INTG_FAIL: "CREATE_INTG_FAIL",

  UPDATE_INTG: "UPDATE_INTG",
  UPDATE_INTG_SUCCESS: "UPDATE_INTG_SUCCESS",
  UPDATE_INTG_FAIL: "UPDATE_INTG_FAIL",

  DELETE_INTG: "DELETE_INTG",
  DELETE_INTG_SUCCESS: "DELETE_INTG_SUCCESS",
  DELETE_INTG_FAIL: "DELETE_INTG_FAIL",

  FETCH_PRODUCT_INTGS: "FETCH_PRODUCT_INTGS",
  FETCH_PRODUCT_INTGS_SUCCESS: "FETCH_PRODUCT_INTGS_SUCCESS",
  FETCH_PRODUCT_INTGS_FAIL: "FETCH_PRODUCT_INTGS_FAIL",

 CHANGE_INTG_STATUS: "CHANGE_STATUS",
 CHANGE_INTG_STATUS_SUCCESS: "CHANGE_STATUS_SUCCESS",
 CHANGE_INTG_STATUS_FAIL: "CHANGE_STATUS_FAIL",

};
