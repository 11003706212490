import { CubeTransparentIcon, EyeIcon } from "@heroicons/react/outline";
import _ from "lodash";
import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  deleteResellerDfsGateway,
  InitialResellerDfsGatewayState,
  toggleResellerDfsGatewayModal,
} from "../../../domains/Reseller/ResellerDfsGateway";
import { checkValue } from "../../../utils/app";
import { Card, DeleteModal, Loader } from "../../App";
import { EditResellerDfsGatewayForm } from "./EditResellerDfsGateway";

interface ResellerDfsGatewayCardProps {
  data: any;
    paymentGateways: any;
  loading: boolean;
}

export const ResellerDfsGatewayCard: FC<ResellerDfsGatewayCardProps> = ({
  data,
    paymentGateways,
  loading,
}) => {
  const dispatch = useDispatch();
  const { dfsId = "" } = useParams<{ dfsId?: string }>();
  const [selectedResellerDfsGateway, setSelectedResellerDfsGateway] =
    useState<any>();
  const { resellerDfsGatewayModals, resellerDfsGatewaysLoaders } = useSelector(
    (state: { resellerDfsGateway: InitialResellerDfsGatewayState }) =>
      state.resellerDfsGateway
  );

  const handleEditModal = (value: any) => {
    setSelectedResellerDfsGateway(value);
    dispatch(
      toggleResellerDfsGatewayModal({
        editResellerDfsGateway: true,
      })
    );
  };
  const handleOpenModal = (id: string) => {
    setSelectedResellerDfsGateway(id);
    dispatch(
      toggleResellerDfsGatewayModal({
        deleteResellerDfsGateway: true,
      })
    );
  };

  const handleCloseModal = () =>
    dispatch(
      toggleResellerDfsGatewayModal({
        deleteResellerDfsGateway: false,
      })
    );

  const onDeleteTemplate = async () => {
    if (!_.isNil(selectedResellerDfsGateway)) {
      await dispatch(
        deleteResellerDfsGateway(dfsId, {
          id: selectedResellerDfsGateway,
        })
      );
    }
    handleCloseModal();
  };

  if (loading) {
    return (
      <div className="mt-4 rounded-lg flex flex-row items-center justify-center w-full h-60 bg-white">
        <Loader />
      </div>
    );
  }
  return (
    <>
      <DeleteModal
        isOpen={resellerDfsGatewayModals.deleteResellerDfsGateway}
        onClose={handleCloseModal}
        onSubmit={onDeleteTemplate}
        message="Are you sure you want to delete this product gateway?"
        loading={resellerDfsGatewaysLoaders.submit}
      />
      <EditResellerDfsGatewayForm
          paymentGateways={paymentGateways}
        resellerDfsGateway={selectedResellerDfsGateway}
      />

      <div className="my-2 grid grid-cols-1  md:grid-cols-2 mt-4 lg:grid-cols-3 gap-3">
        <Card
          key={data.id}
          height="h-30"
          name={checkValue(data?.id)}
          name1={checkValue(data?.name)}
          TopIcon={() => {
            return (
              <>
                <EyeIcon className="h-4 text-tandaGreen w-4" />
              </>
            );
          }}
          Icon={() => {
            return (
              <>
                <CubeTransparentIcon className="h-6 text-tandaGreen w-6" />
              </>
            );
          }}
          SubComponent={() => {
            return (
              <div className=" flex py-2 lg:ml-16 space-x-1 lg:space-x-5 ">
                <button
                  onClick={() => handleEditModal(data)}
                  className="action-buttons btn-danger text-xs px-4"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleOpenModal(data?.id)}
                  className="action-buttons btn-danger text-xs px-4"
                >
                  Delete
                </button>
              </div>
            );
          }}
        />
      </div>
    </>
  );
};
