import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { Loader, Button, Table } from "../../App";
import { PaginationProps } from "../../../domains/App";
import { RadioBox } from "../../App/FormComponents";
import { EditBatchTypeForm } from "./BatchTypeEdit";
import { toggleBatchTypeModal } from "../../../domains/BatchType";

interface BatchTypeTableProps {
  control?: any;
  columns: any[];
  data: any[];
  onChange?: (value: string) => void;
  onPaginationChange?: (page: number) => void;
  pagination: PaginationProps;
  loading: boolean;
}

export const BatchTypeTable: FC<BatchTypeTableProps> = ({
  columns,
  data,
  onChange = (value) => {},
  onPaginationChange,
  pagination,
  loading,
}) => {
  const dispatch = useDispatch();
  const [selectedBatchType, setSelectedBatchType] = useState<any>();

  const handleEditModal = (value: any) => {
    setSelectedBatchType(value);
    dispatch(
      toggleBatchTypeModal({
        editBatchType: true,
      })
    );
  };

  const getColumns = () => {
    const availableColumns = [
      {
        id: "name",
        Header: "Name",
        accessor: "name",
      },
      {
        id: "actions",
        accessor: (row: any) => {
          return (
            <div className="flex flex-row items-center space-x-2">
              <Button
                onClick={() => handleEditModal(row)}
                size="xs"
                variant="primary"
              >
                Edit
              </Button>
            </div>
          );
        },
      },
      {
        id: "select",
        accessor: (row: any) => {
          return (
            <RadioBox
              name="batchType"
              onChange={onChange}
              value={JSON.stringify(row)}
            />
          );
        },
      },
    ];

    return columns.map((column) =>
      availableColumns.find(
        (availableColumn) => availableColumn.id === column.id
      )
    );
  };

  if (loading) {
    return (
      <div className="mt-4 rounded-lg flex flex-row items-center justify-center w-full h-60 bg-white">
        <Loader />
      </div>
    );
  }
  return (
    <>
      <EditBatchTypeForm batchType={selectedBatchType} />

      <Table
        title="Batch Types"
        columns={getColumns()}
        data={data}
        pageCount={pagination.totalPages}
        currentPage={pagination.number}
        onPaginationChange={onPaginationChange}
      />
    </>
  );
};
