import { FC, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { TextField } from "../../App/FormComponents";
import { Modal } from "../../App";
import {
  InitialUserStateProps,
  toggleUserModal,
  updateContact,
} from "../../../domains/Users";
import { openNotification } from "../../../domains/App";
import { schemaValidation } from "../../../constants";
import _ from "lodash";

interface ContactInput {
  firstName: string;
  lastName: string;
  middleName: string;
  emailAddress: string;
  phoneNumber: string;
}

interface EditContactFormProps {
  contact: any;
}

export const EditContactForm: FC<EditContactFormProps> = ({ contact }) => {
  const dispatch = useDispatch();
  const { userModals, userLoader } = useSelector(
    (state: { users: InitialUserStateProps }) => state.users
  );
  const { control, handleSubmit, reset } = useForm<ContactInput>({
    resolver: joiResolver(schemaValidation.ContactSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      middleName: undefined,
      emailAddress: "",
      phoneNumber: "",
    },
  });

  useEffect(() => {
    if (!_.isEmpty(contact)) {
      reset({
        firstName: contact?.firstName || "",
        lastName: contact?.lastName || "",
        middleName: contact?.middleName || undefined,
        emailAddress: contact?.emailAddress || "",
        phoneNumber: contact?.phoneNumber || "",
      });
    }
  }, [contact, reset]);

  const onClose = async () => {
    reset();
    await dispatch(
      toggleUserModal({
        editContact: false,
      })
    );
  };

  const onSubmit: SubmitHandler<ContactInput> = async (data) => {
    try {
      await dispatch(updateContact(contact?.id, data));
      await onClose();
      await dispatch(
        openNotification({
          openNotification: true,
          type: "success",
          message: "Contact updated!",
        })
      );
    } catch (e) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Something went wrong",
        })
      );
    }
  };

  return (
    <Modal
      formId="edit_contact_form"
      btnType="submit"
      modalTitle="Update Contact"
      isOpen={userModals.editContact}
      onClose={onClose}
      btnLoading={userLoader.submit}
    >
      <form
        id="edit_contact_form"
        className="space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="firstName"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="firstName"
              label="First Name"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="middleName"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="middleName"
              label="Middle Name"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="lastName"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="lastName"
              label="Last Name"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="emailAddress"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="emailAddress"
              label="Email Address"
              type="email"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="phoneNumber"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="phoneNumber"
              label="Phone Number"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
      </form>
    </Modal>
  );
};
