import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Layout, SuccessOrErrorState, ActionBadge } from "../../App";
import { viewMessagesTabs, config } from "../../../constants";
import {
  fetchEmailRequests,
  InitialMessageState,
} from "../../../domains/Messages";
import _ from "lodash";
import { MessagesTable } from "./MessagesTable";
import { DescriptionPanel } from "./DescriptionPanel";

export const ViewEmailsPage: FC = () => {
  const dispatch = useDispatch();
  const { messageLoaders, emailRequests, emailRequestsPagination } =
    useSelector((state: { message: InitialMessageState }) => state.message);

  useEffect(() => {
    const fetchEmailsAsync = () => {
      dispatch(fetchEmailRequests());
    };
    fetchEmailsAsync();
  }, [dispatch]);

  const handlePagination = (page: number) => {
    dispatch(
      fetchEmailRequests({
        page: page - 1,
        size: emailRequestsPagination.size,
      })
    );
  };

  return (
    <Layout>
      <div className="px-4 sm:px-6 md:px-0">
        <Tabs tabs={viewMessagesTabs} />
      </div>
      <div className="pt-6">
        {_.isEmpty(emailRequests) && !messageLoaders.fetchEmailRequests ? (
          <SuccessOrErrorState
            state="empty"
            message="No Email Requests Found"
          />
        ) : (
          <MessagesTable
            columns={config.messages.email.listColumns}
            pagination={emailRequestsPagination}
            data={emailRequests}
            loading={messageLoaders.fetchEmailRequests}
            onPaginationChange={handlePagination}
            SubComponent={(value: any) => {
              return (
                <>
                  <div className="w-full pt-2 pb-4 px-4">
                    <DescriptionPanel
                      label="Body"
                      description={value?.row?.original?.body}
                    />
                    <DescriptionPanel label="Receiver (Groups)">
                      <ActionBadge
                        options={[
                          ...(value?.row?.original?.toGroups?.map?.(
                            (group: any) => ({
                              id: group?.id,
                              label: group?.name,
                            })
                          ) || []),
                        ]}
                      />
                    </DescriptionPanel>
                    <DescriptionPanel label="Receiver (Emails)">
                      <ActionBadge
                        options={[
                          ...(value?.row?.original?.toEmails?.map?.(
                            (email: any, indx: number) => ({
                              id: indx,
                              label: email,
                            })
                          ) || []),
                        ]}
                      />
                    </DescriptionPanel>
                    <DescriptionPanel label="CC (Groups)">
                      <ActionBadge
                        options={[
                          ...(value?.row?.original?.ccGroups?.map?.(
                            (group: any) => ({
                              id: group?.id,
                              label: group?.name,
                            })
                          ) || []),
                        ]}
                      />
                    </DescriptionPanel>
                    <DescriptionPanel label="CC (Emails)">
                      <ActionBadge
                        options={[
                          ...(value?.row?.original?.ccEmails?.map?.(
                            (email: any, indx: number) => ({
                              id: indx,
                              label: email,
                            })
                          ) || []),
                        ]}
                      />
                    </DescriptionPanel>
                    <DescriptionPanel label="BCC (Groups)">
                      <ActionBadge
                        options={[
                          ...(value?.row?.original?.bccGroups?.map?.(
                            (group: any) => ({
                              id: group?.id,
                              label: group?.name,
                            })
                          ) || []),
                        ]}
                      />
                    </DescriptionPanel>
                    <DescriptionPanel label="BCC (Emails)">
                      <ActionBadge
                        options={[
                          ...(value?.row?.original?.bccEmails?.map?.(
                            (email: any, indx: number) => ({
                              id: indx,
                              label: email,
                            })
                          ) || []),
                        ]}
                      />
                    </DescriptionPanel>
                  </div>
                </>
              );
            }}
          />
        )}
      </div>
    </Layout>
  );
};
