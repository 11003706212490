import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../domains/Auth";

export const Logout = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleLogout = async () => {
      await dispatch(logout());
      history.push("/login");
    };
    handleLogout();
  }, [history, dispatch]);
  return <div />;
};
