import { FC, useState } from "react";
import { Loader, ExpandTable } from "../../App";
import { PaginationProps } from "../../../domains/App";
import { RadioBox } from "../../App/FormComponents";
import { EditWithdrawalForm } from "./WithdrawalEdit";
import dayjs from "dayjs";

interface WithdrawalTableProps {
  control?: any;
  columns: any[];
  data: any[];
  onChange?: (value: string) => void;
  onPaginationChange?: (page: number) => void;
  pagination: PaginationProps;
  loading: boolean;
  title: string;
  SubComponent?: React.FC<{ row: any }>;
}

export const WithdrawalTable: FC<WithdrawalTableProps> = ({
  columns,
  data,
  onChange = (value) => {},
  onPaginationChange,
  pagination,
  loading,
  SubComponent,
  title,
}) => {
  const [selectedWithdrawal] = useState<any>();

  const getColumns = () => {
    const availableColumns = [
      {
        id: "amount",
        Header: "Amount(KES)",
        accessor: "amount",
      },
      {
        id: "spName",
        Header: "Service Provider",
        accessor: "spName",
      },
      {
        id: "channel",
        Header: "Channel",
        accessor: "channel",
      },
      {
        id: "destinationAccountName",
        Header: "Account",
        accessor: (row: any) => {
          return (
            row?.destinationAccountName +
            " (" +
            row?.destinationAccountNumber +
            ")"
          );
        },
      },
      {
        id: "dateCreated",
        Header: "Date Created",
        accessor: (row: any) => {
          return dayjs(row?.dateCreated).format("YYYY-MM-DD hh:mm A");
        },
      },
      {
        id: "select",
        accessor: (row: any) => {
          return (
            <RadioBox
              name="Withdrawal"
              onChange={onChange}
              value={JSON.stringify(row)}
            />
          );
        },
      },
    ];

    return columns.map((column) =>
      availableColumns.find(
        (availableColumn) => availableColumn.id === column.id
      )
    );
  };

  if (loading) {
    return (
      <div className="mt-4 rounded-lg flex flex-row items-center justify-center w-full h-60 bg-white">
        <Loader />
      </div>
    );
  }
  return (
    <>
      <EditWithdrawalForm withdrawal={selectedWithdrawal} />

      <ExpandTable
        title={title}
        columns={getColumns()}
        data={data}
        pageCount={pagination.totalPages}
        currentPage={pagination.number}
        onPaginationChange={onPaginationChange}
        SubComponent={SubComponent}
      />
    </>
  );
};
