import { Types } from "./actionTypes";

export const toggleResellerDfsFeeRateModal =
  (payload: any) => async (dispatch: any) =>
    dispatch({
      type: Types.TOGGLE_RESELLER_DFS_FEE_RATE_MODALS,
      payload,
    });

export const fetchResellerDfsFeeRates =
  (resellerDfsId: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.FETCH_RESELLER_DFS_FEE_RATES,
      payload: {
        request: {
          method: "GET",
          url: `/io-wallets/v1/reseller-dfses/${resellerDfsId}/fee-rates`,
          useCache: true,
        },
      },
    });

export const createResellerDfsFeeRate =
  (resellerDfsId: any, data: any) => async (dispatch: any) => {
    await dispatch({
      type: Types.CREATE_RESELLER_DFS_FEE_RATE,
      payload: {
        request: {
          url: `/io-wallets/v1/reseller-dfses/${resellerDfsId}/fee-rates`,
          method: "POST",
          data,
        },
      },
    });
  };

export const updateResellerDfsFeeRate =
  (resellerDfsId: any, resellerDfsFeeRateId: string, data: any) =>
  async (dispatch: any) =>
    await dispatch({
      type: Types.UPDATE_RESELLER_DFS_FEE_RATE,
      payload: {
        request: {
          url: `/io-wallets/v1/reseller-dfses/${resellerDfsId}/fee-rates/${resellerDfsFeeRateId}`,
          method: "PATCH",
          data,
        },
      },
    });

export const deleteResellerDfsFeeRate =
  (resellerDfsId: any, resellerDfsFeeRate: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.DELETE_RESELLER_DFS_FEE_RATE,
      payload: {
        request: {
          url: `/io-wallets/v1/reseller-dfses/${resellerDfsId}/fee-rates/${resellerDfsFeeRate?.id}`,
          method: "DELETE",
          deleteProp: resellerDfsFeeRate,
        },
      },
    });
