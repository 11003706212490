import { createReducer } from "@reduxjs/toolkit";
import { Types } from "./actionTypes";
import { PaginationProps } from "../App";
import _ from "lodash";

export interface InitialServiceProviderState {
  cache: Record<string, any>;
  serviceProviders: any[];
  selectedServiceProvider: any;
  serviceProvidersPagination: PaginationProps;
  serviceProviderModals: any;
  serviceProvidersLoaders: any;
}

const initialState: InitialServiceProviderState = {
  serviceProviders: [],
  serviceProvidersPagination: {
    number: 0,
    totalPages: 1,
    size: 10,
    totalElements: 0,
  },
  selectedServiceProvider: {},
  serviceProvidersLoaders: {
    submit: false,
    fetchServiceProviders: false,
  },
  serviceProviderModals: {
    addServiceProvider: false,
    editServiceProvider: false,
    deleteServiceProvider: false,
  },
  cache: {},
};

export const serviceProvidersReducer = createReducer(initialState, {
  [Types.TOGGLE_SERVICEPROVIDER_MODALS]: (state, action) => {
    state.serviceProviderModals = {
      ...state.serviceProviderModals,
      ...action.payload,
    };
  },

  [Types.FETCH_SERVICEPROVIDERS]: (state) => {
    state.serviceProvidersLoaders.fetchServiceProviders = true;
  },
  [Types.FETCH_SERVICEPROVIDERS_SUCCESS]: (state, action) =>
    // {
    //   const {
    //     data,
    //   } = action.payload;
    //   state.serviceProvidersLoaders.fetchServiceProviders = false;
    //   state.serviceProviders = data;
    // },
    {
      const {
        data: { content, page },
      } = action.payload;
      state.serviceProvidersLoaders.fetchServiceProviders = false;
      state.serviceProviders = content;
      state.serviceProvidersPagination = {
        number: page.number,
        size: page.size,
        totalPages: page.totalPages,
        totalElements: page.totalElements,
      };
    },
  [Types.FETCH_SERVICEPROVIDERS_FAIL]: (state) => {
    state.serviceProvidersLoaders.fetchServiceProviders = false;
  },

  [Types.CREATE_SERVICEPROVIDER]: (state) => {
    state.serviceProvidersLoaders.submit = true;
  },
  [Types.CREATE_SERVICEPROVIDER_SUCCESS]: (state, action) => {
    state.serviceProvidersLoaders.submit = false;
    state.serviceProviders = [action.payload.data, ...state.serviceProviders];
  },
  [Types.CREATE_SERVICEPROVIDER_FAIL]: (state) => {
    state.serviceProvidersLoaders.submit = false;
  },

  [Types.DELETE_SERVICEPROVIDER]: (state) => {
    state.serviceProvidersLoaders.submit = true;
  },
  [Types.DELETE_SERVICEPROVIDER_SUCCESS]: (state, action) => {
    const {
      previousAction: {
        payload: {
          request: {
            deleteProp: { id },
          },
        },
      },
    } = action.meta;
    state.serviceProviders = [
      ...state.serviceProviders.filter(
        (serviceProvider) => serviceProvider.id !== id
      ),
    ];
    if (_.isEmpty(state.serviceProviders)) {
      state.serviceProvidersPagination.number = 0;
    }
    state.serviceProvidersLoaders.submit = false;
  },
  [Types.DELETE_SERVICEPROVIDER_FAIL]: (state) => {
    state.serviceProvidersLoaders.submit = false;
  },

  [Types.UPDATE_SERVICEPROVIDER]: (state) => {
    state.serviceProvidersLoaders.submit = true;
  },
  [Types.UPDATE_SERVICEPROVIDER_SUCCESS]: (state, action) => {
    state.serviceProvidersLoaders.submit = false;
    state.serviceProviders = [
      ...state.serviceProviders.map((sp) =>
        sp.id === action.payload.data.id ? action.payload.data : sp
      ),
    ];
  },
  [Types.UPDATE_SERVICEPROVIDER_FAIL]: (state) => {
    state.serviceProvidersLoaders.submit = false;
  },
});
