import { getURL } from "./../../../utils/string";
import { Types } from "./actionTypes";

export const toggleResellerDfsGatewayModal =
  (payload: any) => async (dispatch: any) =>
    dispatch({
      type: Types.TOGGLE_RESELLER_DFS_GATEWAY_MODALS,
      payload,
    });

export const fetchResellerDfsGateways =
  (resellerDfsId: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.FETCH_RESELLER_DFS_GATEWAY,
      payload: {
        request: {
          method: "GET",
          url: `/io-wallets/v1/reseller-dfses/${resellerDfsId}/gateway`,
          useCache: true,
        },
      },
    });

export const fetchPaymentGateways = () => async (dispatch: any) =>
  await dispatch({
    type: Types.FETCH_RESELLER_GATEWAYS,
    payload: {
      request: {
        method: "GET",
        url: getURL("/io-wallets/v1/payment-gateways", { size: 100 }),
        useCache: true,
      },
    },
  });

export const createResellerDfsGateway =
  (resellerDfsId: any, data: any) => async (dispatch: any) => {
    await dispatch({
      type: Types.CREATE_RESELLER_DFS_GATEWAY,
      payload: {
        request: {
          url: `/io-wallets/v1/reseller-dfses/${resellerDfsId}/gateway`,
          method: "PUT",
          data,
        },
      },
    });
  };

export const updateResellerDfsGateway =
  (resellerDfsId: string, data: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.UPDATE_RESELLER_DFS_GATEWAY,
      payload: {
        request: {
          url: `/io-wallets/v1/reseller-dfses/${resellerDfsId}/gateway`,
          method: "PUT",
          data,
        },
      },
    });

export const deleteResellerDfsGateway =
  (resellerDfsId: any, resellerDfsGateway: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.DELETE_RESELLER_DFS_GATEWAY,
      payload: {
        request: {
          url: `/io-wallets/v1/reseller-dfses/${resellerDfsId}/gateway`,
          method: "DELETE",
          deleteProp: resellerDfsGateway,
        },
      },
    });
