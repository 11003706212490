import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { MessageEditor } from "../../App";
import { DescriptionPanel } from "../Messages";

export const Editor: FC<{
  label?: string;
  id?: string;
}> = ({ id = "body", label = "Body" }) => {
  const { control } = useFormContext();

  return (
    <DescriptionPanel label={label}>
      <Controller
        name={id}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <MessageEditor
              id={id}
              value={value}
              onChange={onChange}
              error={error?.message}
            />
          );
        }}
      />
    </DescriptionPanel>
  );
};
