import { Types } from "./actionTypes";
import { getURL } from "../../utils";

export const toggleBSWalletModal = (payload: any) => async (dispatch: any) =>
  dispatch({
    type: Types.TOGGLE_BSWALLET_MODALS,
    payload,
  });

export const updateSelectedBSWallet =
  (payload: any) => async (dispatch: any) => {
    await dispatch({
      type: Types.UPDATE_SELECTED_BSWALLET,
      payload,
    });
  };

export const fetchBSWallets = (pagination?: any) => async (dispatch: any) =>
  await dispatch({
    type: Types.FETCH_BSWALLETS,
    payload: {
      request: {
        method: "GET",
        url: getURL("/wallets/v1/business-client-wallets", pagination),
        useCache: true,
      },
    },
  });

export const createBSWallet =
  (gatewayId: any, data: any) => async (dispatch: any) => {
    await dispatch({
      type: Types.CREATE_BSWALLET,
      payload: {
        request: {
          url: `/wallets/v1/payment-gateways/${gatewayId}/business-client-wallets`,
          method: "POST",
          data,
        },
      },
    });
  };

export const updateBSWallet =
  (bswallet: any, data: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.UPDATE_BSWALLET,
      payload: {
        request: {
          url: `/wallets/v1/business-client-wallets/${bswallet.id}`,
          method: "PATCH",
          data,
          bswallet,
        },
      },
    });
