import { Types } from "./actionTypes";
import { getURL } from "../../utils";

export const fetchSubWallets = (pagination?: any) => async (dispatch: any) =>
  await dispatch({
    type: Types.FETCH_SUB_WALLETS,
    payload: {
      request: {
        method: "GET",
        url: getURL("io-wallets/v1/sub-wallets", pagination),
        useCache: true,
      },
    },
  });

export const fetchSubWalletBalances =
  (pagination?: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.FETCH_SUB_WALLET_BALANCES,
      payload: {
        request: {
          method: "GET",
          url: getURL("io-wallets/v1/sub-wallets/balances", pagination),
          useCache: true,
        },
      },
    });

export const fetchOrgSubWalletBalances =
  (organizationId: string, pagination?: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.FETCH_ORG_SUB_WALLET_BALANCES,
      payload: {
        request: {
          method: "GET",
          url: getURL(
            `io-wallets/v1/organizations/${organizationId}/sub-wallets/balances`,
            pagination
          ),
          useCache: true,
        },
      },
    });
