import { Types } from "./actionTypes";
import { getURL } from "../../utils";

export const toggleWithdrawalAccountModal =
  (payload: any) => async (dispatch: any) =>
    dispatch({
      type: Types.TOGGLE_WITHDRAWALACCOUNT_MODALS,
      payload,
    });

export const updateSelectedWithdrawalAccount =
  (payload: any) => async (dispatch: any) => {
    await dispatch({
      type: Types.UPDATE_SELECTED_WITHDRAWALACCOUNT,
      payload,
    });
  };

export const fetchWithdrawalAccounts = () => async (dispatch: any) =>
  await dispatch({
    type: Types.FETCH_WITHDRAWALACCOUNTS,
    payload: {
      request: {
        method: "GET",
        url: getURL("/wallets/v1/bcw-withdrawal-accounts"),
        useCache: true,
      },
    },
  });

export const createWithdrawalAccount =
  (gatewayId: any, data: any) => async (dispatch: any) => {
    await dispatch({
      type: Types.CREATE_WITHDRAWALACCOUNT,
      payload: {
        request: {
          url: `/wallets/v1/payment-gateways/${gatewayId}/business-client-wallets`,
          method: "POST",
          data,
        },
      },
    });
  };

export const updateWithdrawalAccount =
  (withdrawalaccount: any, data: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.UPDATE_WITHDRAWALACCOUNT,
      payload: {
        request: {
          url: `/wallets/v1/dfses/${withdrawalaccount.id}`,
          method: "PATCH",
          data,
          withdrawalaccount,
        },
      },
    });
