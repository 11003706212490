import dayjs from "dayjs";
import { FC } from "react";
import { checkValue } from "../../../utils/app";
import { DescriptionPanel } from "../../App";

interface PayoutDetailsProps {
  payoutDetails: any;
}
export const TransactionDetails: FC<PayoutDetailsProps> = ({
  payoutDetails,
}) => {
  const getStatus = (row: any) => {
    switch (row) {
      case "000000":
        return "Success";
      case "000001":
        return "Pending";
      default:
        return "Failed";
    }
  };
  return (
    <div className="w-full">
      <DescriptionPanel label="ID" description={payoutDetails?.id} />
      <DescriptionPanel
        label="Receipt Number"
        description={payoutDetails?.receiptNumber}
      />
      <DescriptionPanel
        label="Products"
        description={payoutDetails?.dfs?.name}
      />
      <DescriptionPanel
        label="Amount"
        description={"KES " + payoutDetails?.amount?.toFixed(2)}
      />
      <DescriptionPanel
        label="Account"
        description={checkValue(payoutDetails?.accountNumber)}
      />
      <DescriptionPanel
        label="Status"
        description={getStatus(payoutDetails?.status)}
      />

      <DescriptionPanel
        label="Completed On"
        description={dayjs(payoutDetails?.dateFulfilled).format(
          "YYYY-MM-DD hh:mm A"
        )}
      />
      <DescriptionPanel
        label="Service Provider"
        description={payoutDetails?.serviceProviderName}
      />
      <DescriptionPanel
        label="Date Initiated"
        description={dayjs(payoutDetails?.dateCreated).format(
          "YYYY-MM-DD hh:mm A"
        )}
      />
      <DescriptionPanel
        label="Integration"
        description={checkValue(payoutDetails?.gateway)}
      />
      <DescriptionPanel
        label="Transaction Ref"
        description={checkValue(payoutDetails?.spReference)}
      />
    </div>
  );
};
