import { Types } from "./actionTypes";
import { getURL } from "../../utils";

export const toggleIOWalletModal = (payload: any) => async (dispatch: any) =>
  dispatch({
    type: Types.TOGGLE_IOWALLET_MODALS,
    payload,
  });

export const updateSelectedIOWallet =
  (payload: any) => async (dispatch: any) => {
    await dispatch({
      type: Types.UPDATE_SELECTED_IOWALLET,
      payload,
    });
  };

export const fetchIOWallets = (pagination?: any) => async (dispatch: any) =>
  await dispatch({
    type: Types.FETCH_IOWALLETS,
    payload: {
      request: {
        method: "GET",
        url: getURL("/io-wallets/v1/dfses", pagination),
        useCache: true,
      },
    },
  });

  export const fetchTransaction = (pagination?: any) => async (dispatch: any) =>
  await dispatch({
    type: Types.FETCH_TRANSACTION,
    payload: {
      request: {
        method: "GET",
        url: getURL("/io-wallets/v1/transactions", pagination),
        useCache: true,
      },
    },
  });


export const createIOWallet = (data: any) => async (dispatch: any) => {
  await dispatch({
    type: Types.CREATE_IOWALLET,
    payload: {
      request: {
        url: `/wallets/v1/dfses`,
        method: "POST",
        data,
      },
    },
  });
};
export const deleteIOWallet = (iowallet: any) => async (dispatch: any) =>
  await dispatch({
    type: Types.DELETE_MESSAGE_TEMPLATE,
    payload: {
      request: {
        url: `/wallets/v1/dfses/${iowallet.id}`,
        method: "DELETE",
        deleteProp: iowallet,
      },
    },
  });

export const updateIOWallet =
  (iowallet: any, data: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.UPDATE_IOWALLET,
      payload: {
        request: {
          url: `/wallets/v1/dfses/${iowallet.id}`,
          method: "PATCH",
          data,
          iowallet,
        },
      },
    });

export const changeStatusIOWallet =
  (selectedIOWallet: any, status: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.CHANGE_IOWALLET_STATUS,
      payload: {
        request: {
          url: `/io-wallets/v1/dfses/${selectedIOWallet.id}`,
          method: "PATCH",
          data: status,
          selectedIOWallet,
        },
      },
    });
