import { FC } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { LoginPage, Logout } from "./Auth";
import {
  AllGroupsPage,
  CreateEmailCampaignPage,
  CreateEventListenersPage,
  CreateInAppCampaignPage,
  CreateSmsCampaignPage,
  GroupOverviewPage,
  ServicePropsPage,
  ServiceProvidersPage,
  ViewEmailsPage,
  ViewEventListenersPage,
  ViewInAppPage,
  ViewOutboxPage,
  ViewSmsPage,
  ViewTemplatesPage,
} from "./Messaging";

import { BSWalletPage } from "./BusinessClient/Wallet";
import { WithdrawalPage } from "./BusinessClient/Withdrawal";
import { LoanPage, LoanTransactionPage } from "./Loans";
import { BatchTypePage } from "./Products/BatchTypes";
import { CommissionFeeRatePage } from "./Products/CommissionFeeRates";
import { CustomerTransactionFeeRatePage } from "./Products/CustomerTransactionFeeRates";
import { IntgPage } from "./Products/Integration";
import { ProductPage } from "./Products/Products/ProductPage";
import { ServiceProviderPage } from "./Products/ServiceProviders";
import { TransactionFeeRatePage } from "./Products/TransactionFeeRates";
import { IoReportPage, SmeReportPage } from "./Reports";
import {
  CollectionBalancesPage,
  CollectionsPage,
  PayoutsPage,
  ResellerResellerPage,
  ResellerWalletPage,
} from "./Reseller";
import { ResellerDfsPage } from "./Reseller/ResellerDfs";
import { ResellerDfsCommissionRatePage } from "./Reseller/ResellerDfsCommissionRate";
import { ResellerDfsFeeRatePage } from "./Reseller/ResellerDfsFeeRate";
import { ResellerDfsGatewayPage } from "./Reseller/ResellerDfsGateway";
import { IOWalletPage } from "./Tanda IO/IOWallet";
import { IOIntgPage } from "./Tanda IO/PaymentGateway";
import { IOTransactionPage } from "./Tanda IO/Transaction";
import { PayoutsActivity } from "./Activity/Payouts";
import { SubWalletsActivityPage } from "./Activity/SubWallets/SubWalletsActivityPage";
import { ResellerInfoPage } from "./Reseller/Reseller";
import { SubWalletsPage } from "./Activity/SubWallets/SubWallets";
import { SubWalletCollectionsPage } from "./Reseller/Collections/SubWalletCollectionsPage";

export const Routes: FC = () => {
  return (
    <Router>
      <Switch>
        <Route path="/logout">
          <Logout />
        </Route>
        <Route exact path="/login">
          <LoginPage />
        </Route>
        <Route exact path="/messages/create-campaign/email">
          <CreateEmailCampaignPage />
        </Route>
        <Route exact path="/messages/create-campaign/sms">
          <CreateSmsCampaignPage />
        </Route>
        <Route exact path="/messages/create-campaign/in-app">
          <CreateInAppCampaignPage />
        </Route>
        <Route exact path="/messages/email-requests">
          <ViewEmailsPage />
        </Route>
        <Route exact path="/messages/in-app-requests">
          <ViewInAppPage />
        </Route>
        <Route exact path="/messages/outbox">
          <ViewOutboxPage />
        </Route>
        <Route path="/messages/templates">
          <ViewTemplatesPage />
        </Route>
        <Route path="/messages/groups/:groupId">
          <GroupOverviewPage />
        </Route>
        <Route exact path="/messages/groups">
          <AllGroupsPage />
        </Route>
        <Route exact path="/messages/service-providers/:spId/props">
          <ServicePropsPage />
        </Route>
        <Route path="/messages/service-providers">
          <ServiceProvidersPage />
        </Route>
        <Route exact path="/messages/create-event-listeners">
          <CreateEventListenersPage />
        </Route>
        <Route exact path="/messages/event-listeners">
          <ViewEventListenersPage />
        </Route>
        <Route path="/messages">
          <ViewSmsPage />
        </Route>
        <Route exact path="/products/batch-types">
          <BatchTypePage />
        </Route>
        <Route exact path="/products/service-providers">
          <ServiceProviderPage />
        </Route>
        <Route exact path="/products/:productId/agent-transaction-fees">
          <TransactionFeeRatePage />
        </Route>
        <Route exact path="/products/:productId/agent-commission-fees">
          <CommissionFeeRatePage />
        </Route>
        <Route exact path="/products/:productId/customer-transaction-fees">
          <CustomerTransactionFeeRatePage />
        </Route>
        <Route exact path="/products/:productId/gateways">
          <IntgPage />
        </Route>
        <Route exact path="/products/bcws">
          <BSWalletPage />
        </Route>
        <Route exact path="/products/bcws/:productId/withdrawals">
          <WithdrawalPage />
        </Route>
        <Route exact path="/tanda-io/:productId/gateways">
          <IOIntgPage />
        </Route>
        <Route exact path="/products">
          <ProductPage />
        </Route>
        <Route exact path="/tanda-io/transactions">
          <IOTransactionPage />
        </Route>
        <Route exact path="/tanda-io/io-products">
          <IOWalletPage />
        </Route>
        <Route exact path="/loans/:loanId/transactions">
          <LoanTransactionPage />
        </Route>
        <Route path="/loans">
          <LoanPage />
        </Route>
        <Route path="/tanda-io/resellers/:resellerId/io-products/:dfsId/gateway">
          <ResellerDfsGatewayPage />
        </Route>
        <Route path="/tanda-io/resellers/:resellerId/io-products/:dfsId/commissions">
          <ResellerDfsCommissionRatePage />
        </Route>
        <Route path="/tanda-io/resellers/:resellerId/io-products/:dfsId/fees">
          <ResellerDfsFeeRatePage />
        </Route>
        <Route path="/tanda-io/resellers/:resellerId/io-products">
          <ResellerDfsPage />
        </Route>
        <Route path="/tanda-io/resellers/:resellerId/payouts">
          <PayoutsPage />
        </Route>
        <Route path="/tanda-io/resellers/:resellerId/collections/wallets">
          <CollectionBalancesPage />
        </Route>
        <Route path="/tanda-io/resellers/:resellerId/sub-wallets/:subWalletId/payments">
          <SubWalletCollectionsPage />
        </Route>
        <Route path="/tanda-io/resellers/:resellerId/collections">
          <CollectionsPage />
        </Route>
        <Route path="/tanda-io/resellers/:resellerId/wallets">
          <ResellerWalletPage />
        </Route>
        <Route path="/tanda-io/resellers/:resellerId/info">
          <ResellerInfoPage />
        </Route>
        <Route path="/tanda-io/resellers">
          <ResellerResellerPage />
        </Route>
        <Route path="/tanda-io/payouts">
          <PayoutsActivity />
        </Route>
        <Route path="/tanda-io/sub-wallet-activity">
          <SubWalletsActivityPage />
        </Route>
        <Route path="/tanda-io/sub-wallets">
          <SubWalletsPage />
        </Route>
        <Route path="/reports/sme-statements">
          <SmeReportPage />
        </Route>
        <Route path="/reports/io-statements">
          <IoReportPage />
        </Route>
        <Route path="/">
          <Redirect to="/messages" />
        </Route>
      </Switch>
    </Router>
  );
};
