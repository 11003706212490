import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationWrapper } from "./components/App";
import { InitialAuthStateProps } from "./domains/Auth";
import {
  InitialMessageState,
  fetchTemplateVariables,
} from "./domains/Messages";
import { Routes } from "./components/Route";

function App() {
  const dispatch = useDispatch();
  const { templateVariablesPagination } = useSelector(
    (state: { message: InitialMessageState }) => state.message
  );
  const { isAuthenticated } = useSelector(
    (state: { auth: InitialAuthStateProps }) => state.auth
  );

  useEffect(() => {
    const handleTemplateVariables = async () => {
      const { number, size, totalPages } = templateVariablesPagination;
      if (isAuthenticated) {
        let currentPage = number;
        while (currentPage < totalPages) {
          await dispatch(
            fetchTemplateVariables({
              page: number,
              size,
            })
          );
          currentPage = currentPage + 1;
        }
      }
    };
    handleTemplateVariables();
  }, [dispatch, isAuthenticated, templateVariablesPagination]);
  return (
    <>
      <Routes />
      <NotificationWrapper />
    </>
  );
}

export default App;
