import _ from "lodash";
import { useState, FC } from "react";
import { useSelector } from "react-redux";
import { InitialMessageState } from "../../../domains/Messages";
import { LargeModal } from "../../App";
import { Badges } from "../../App/FormComponents";

interface VariablesModalProps {
  isOpen: boolean;
  onClose: () => void;
  onVariableChange: (value: string) => void;
}

interface Option {
  id: string;
  label: string;
}

export const VariablesModal: FC<VariablesModalProps> = ({
  isOpen,
  onClose,
  onVariableChange,
}) => {
  const { templateVariables } = useSelector(
    (state: { message: InitialMessageState }) => state.message
  );
  const options =
    templateVariables?.map((variable) => ({
      id: variable?.id,
      label: variable?.name,
    })) || [];

  const [selectedOption, setSelectedOption] = useState<Option | undefined>();
  const handleChange = (option: any) => setSelectedOption(option);

  const handleClose = () => {
    if (!_.isNil(selectedOption)) {
      onVariableChange(selectedOption.id);
    }
    onClose();
  };

  return (
    <LargeModal
      modalTitle="Pick a variable"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <Badges
        options={options}
        onChange={handleChange}
        selectedOption={selectedOption}
      />
    </LargeModal>
  );
};
