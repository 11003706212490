import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Layout, SuccessOrErrorState } from "../../App";
import { viewMessagesTabs, config } from "../../../constants";
import { fetchMessages, InitialMessageState } from "../../../domains/Messages";
import _ from "lodash";
import { MessagesTable } from "./MessagesTable";

export const ViewOutboxPage: FC = () => {
  const dispatch = useDispatch();
  const { messageLoaders, messages, messagesPagination } = useSelector(
    (state: { message: InitialMessageState }) => state.message
  );

  useEffect(() => {
    const fetchMessagesAsync = () => {
      dispatch(fetchMessages());
    };
    fetchMessagesAsync();
  }, [dispatch]);

  const handlePagination = (page: number) => {
    dispatch(
      fetchMessages({
        page: page - 1,
        size: messagesPagination.size,
      })
    );
  };

  return (
    <Layout>
      <div className="px-4 sm:px-6 md:px-0">
        <Tabs tabs={viewMessagesTabs} />
      </div>
      <div className="pt-4">
        {_.isEmpty(messages) && !messageLoaders.fetchMessages ? (
          <SuccessOrErrorState state="empty" message="No Messages Found" />
        ) : (
          <MessagesTable
            columns={config.messages.outbox.listColumns}
            pagination={messagesPagination}
            data={messages}
            loading={messageLoaders.fetchMessages}
            onPaginationChange={handlePagination}
          />
        )}
      </div>
    </Layout>
  );
};
