import dayjs from "dayjs";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { openNotification } from "../../domains/App";
import { InitialLoanState, toggleLoanModal } from "../../domains/Loan";
import API from "../../utils/api";
import { LargeFormModal } from "../App";
import { DateTimePicker } from "../App/FormComponents/DateTimePicker";

interface FilterParams {
  since: string;
  till: string;
}

export const DownloadStatementForm = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  let defaultSince =
    dayjs(new Date().setDate(new Date().getDate() - 1))
      .toISOString()
      .split(".")[0] + "Z";
  let defaultTill = dayjs(new Date()).toISOString().split(".")[0] + "Z";

  const { loansModels } = useSelector(
    (state: { loan: InitialLoanState }) => state.loan
  );
  const { control, reset, handleSubmit } = useForm<FilterParams>({
    defaultValues: {
      since: defaultSince,
      till: defaultTill,
    },
  });
  const onClose = async () => {
    reset();
    await dispatch(
      toggleLoanModal({
        downloadStatement: false,
      })
    );
  };

  const onSubmit: SubmitHandler<FilterParams> = async (props) => {
    setLoader(true);
    try {
      const params = {
        since: dayjs(props.since).toISOString().split(".")[0] + "Z",
        till: dayjs(props.till).toISOString().split(".")[0] + "Z",
      };

      API.get(`/loans/v1/reports/activity`, {
        responseType: "blob",
        params: params,
      })
        .then((response: any) => {
          if (response.status === 200) {
            let fileName = response.headers["content-disposition"]
              .split("filename=")[1]
              .split(".")[0];
            let extension = response.headers["content-disposition"]
              .split(".")[1]
              .split(";")[0];
            const link = document.createElement("a");
            const url = URL.createObjectURL(response.data);
            let name = `${fileName}.${extension}`;
            link.href = url;
            link.download = name.replaceAll('"', "");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setLoader(false);
            dispatch(
              openNotification({
                openNotification: true,
                type: "success",
                message: "Statement downloaded",
              })
            );
          }
          onClose();
        })
        .catch((err: any) => {
          setLoader(false);
          dispatch(
            openNotification({
              openNotification: true,
              type: "error",
              message: `Invalid timestamp range, you can only download a month's or less worth of reports.`,
            })
          );
        });
    } catch (e: any) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: `Invalid timestamp range, you can only download a month's or less worth of reports.`,
        })
      );
    }
  };

  return (
    <LargeFormModal
      formId="statement_form"
      btnType="submit"
      modalTitle="Download Loan Statement"
      isOpen={loansModels.downloadStatement}
      onClose={onClose}
      btnLoading={loader}
    >
      <form
        id="statement_form"
        className="space-y-2 py-6 px-20"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col md:flex-row space-x-4 -pl-3">
          <Controller
            control={control}
            name="since"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <DateTimePicker
                id="since"
                label="Since"
                placeholder="Since"
                value={value}
                error={error?.message}
                onChange={onChange}
                onClick={onChange}
                includeTime={true}
              />
            )}
          />

          <Controller
            control={control}
            name="till"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <DateTimePicker
                id="till"
                label="Until"
                placeholder="Until"
                value={value}
                error={error?.message}
                onChange={onChange}
                onClick={onChange}
                includeTime={true}
              />
            )}
          />
        </div>
      </form>
    </LargeFormModal>
  );
};
