export const Types: Record<string, string> = {
  TOGGLE_RESELLER_DFS_MODALS: "TOGGLE_RESELLER_DFS_MODALS",
  UPDATE_SELECTED_RESELLER_DFS: "UPDATE_SELECTED_RESELLER_DFS",

  FETCH_RESELLER_DFSS: "FETCH_RESELLER_DFSS",
  FETCH_RESELLER_DFSS_SUCCESS: "FETCH_RESELLER_DFSS_SUCCESS",
  FETCH_RESELLER_DFSS_FAIL: "FETCH_RESELLER_DFSS_FAIL",

  CREATE_RESELLER_DFS: "CREATE_RESELLER_DFS",
  CREATE_RESELLER_DFS_SUCCESS: "CREATE_RESELLER_DFS_SUCCESS",
  CREATE_RESELLER_DFS_FAIL: "CREATE_RESELLER_DFS_FAIL",

  UPDATE_RESELLER_DFS: "UPDATE_RESELLER_DFS",
  UPDATE_RESELLER_DFS_SUCCESS: "UPDATE_RESELLER_DFS_SUCCESS",
  UPDATE_RESELLER_DFS_FAIL: "UPDATE_RESELLER_DFS_FAIL",

  DELETE_RESELLER_DFS: "DELETE_RESELLER_DFS",
  DELETE_RESELLER_DFS_SUCCESS: "DELETE_RESELLER_DFS_SUCCESS",
  DELETE_RESELLER_DFS_FAIL: "DELETE_RESELLER_DFS_FAIL",
};
