export const Types: Record<string, string> = {
  TOGGLE_PAYMENTGATEWAY_MODALS: "TOGGLE_PAYMENTGATEWAY_MODALS",

  FETCH_PAYMENTGATEWAYS: "FETCH_PAYMENTGATEWAYS",
  FETCH_PAYMENTGATEWAYS_SUCCESS: "FETCH_PAYMENTGATEWAYS_SUCCESS",
  FETCH_PAYMENTGATEWAYS_FAIL: "FETCH_PAYMENTGATEWAYS_FAIL",

  CREATE_PAYMENTGATEWAY: "CREATE_PAYMENTGATEWAY",
  CREATE_PAYMENTGATEWAY_SUCCESS: "CREATE_PAYMENTGATEWAY_SUCCESS",
  CREATE_PAYMENTGATEWAY_FAIL: "CREATE_PAYMENTGATEWAY_FAIL",

  UPDATE_PAYMENTGATEWAY: "UPDATE_PAYMENTGATEWAY",
  UPDATE_PAYMENTGATEWAY_SUCCESS: "UPDATE_PAYMENTGATEWAY_SUCCESS",
  UPDATE_PAYMENTGATEWAY_FAIL: "UPDATE_PAYMENTGATEWAY_FAIL",

  DELETE_PAYMENTGATEWAY: "DELETE_PAYMENTGATEWAY",
  DELETE_PAYMENTGATEWAY_SUCCESS: "DELETE_PAYMENTGATEWAY_SUCCESS",
  DELETE_PAYMENTGATEWAY_FAIL: "DELETE_PAYMENTGATEWAY_FAIL",
};
