import { Fragment, FC, useEffect } from "react";
import { Transition } from "@headlessui/react";
import { useSelector, useDispatch } from "react-redux";
import { InitialAppStateProps, closeNotification } from "../../domains/App";
import { NotificationPopup } from ".";

export const NotificationWrapper: FC = () => {
  const dispatch = useDispatch();
  const notification = useSelector(
    (state: { app: InitialAppStateProps }) => state.app.notification
  );

  useEffect(() => {
    if (notification.openNotification) {
      setTimeout(() => dispatch(closeNotification()), 3000);
    }
  }, [notification.openNotification, dispatch]);

  return (
    <>
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-40"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {notification?.openNotification && (
            <Transition
              show={notification.openNotification}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                <NotificationPopup
                  notification={
                    notification || { openNotification: false, type: "general" }
                  }
                />
              </div>
            </Transition>
          )}
        </div>
      </div>
    </>
  );
};
