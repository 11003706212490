import dayjs from "dayjs";
import _ from "lodash";
import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { EditResellerDfsForm } from ".";
import { PaginationProps } from "../../../domains/App";
import {
  deleteResellerDfs,
  InitialResellerDfsState,
  toggleResellerDfsModal,
  updateSelectedResellerDfs,
} from "../../../domains/Reseller/ResellerDfs";
import { Button, DeleteModal, Loader, Table } from "../../App";
import { RadioBox } from "../../App/FormComponents";

interface ResellerDfsTableProps {
  control?: any;
  columns: any[];
  data: any[];
  onChange?: (value: string) => void;
  onPaginationChange?: (page: number) => void;
  pagination: PaginationProps;
  loading: boolean;
}

export const ResellerDfsTable: FC<ResellerDfsTableProps> = ({
  columns,
  data,
  onChange = (value) => {},
  onPaginationChange,
  pagination,
  loading,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { resellerId = "" } = useParams<{ resellerId?: string }>();
  const { resellerDfssLoaders, resellerDfsModals } = useSelector(
    (state: { resellerDfs: InitialResellerDfsState }) => state.resellerDfs
  );

  const [selectedResellerDfs, setSelectedResellerDfs] = useState<any>();

  const goToPath = (value: any) => () => {
    dispatch(updateSelectedResellerDfs(value));
    history.push(
      `/tanda-io/resellers/${resellerId}/io-products/${value?.id}/commissions`
    );
  };

  const handleEditModal = (value: any) => {
    setSelectedResellerDfs(value);
    dispatch(
      toggleResellerDfsModal({
        editResellerDfs: true,
      })
    );
  };

  const handleCloseModal = () =>
    dispatch(
      toggleResellerDfsModal({
        deleteResellerDfs: false,
      })
    );

  const onDeleteTemplate = async () => {
    if (!_.isNil(selectedResellerDfs)) {
      await dispatch(
        deleteResellerDfs(resellerId, {
          id: selectedResellerDfs,
        })
      );
    }
    handleCloseModal();
  };

  const getColumns = () => {
    const availableColumns = [
      {
        id: "dfs",
        Header: "Product",
        accessor: (row: any) => (
          <div>
            <span
              className="cursor-pointer underline text-tandaPurple"
              onClick={goToPath(row)}
            >
              {row?.dfs?.name}
            </span>
          </div>
        ),
      },
      {
        id: "minimumValue",
        Header: "Minimum Value(KES)",
        accessor: "minimumValue",
      },
      {
        id: "maximumValue",
        Header: "Maximum Value(KES)",
        accessor: "maximumValue",
      },
      {
        id: "available",
        Header: "Available",
        accessor: (row: any) => {
          return <span>{row?.available ? "Yes" : "No"}</span>;
        },
      },
      {
        id: "dateCreated",
        Header: "Date Created",
        accessor: (row: any) => {
          return (
            <span>{dayjs(row?.dateCreated).format("YYYY-MM-DD hh:mm A")}</span>
          );
        },
      },
      {
        id: "name",
        Header: "Name",
        accessor: "name",
      },
      {
        id: "actions",
        accessor: (row: any) => {
          return (
            <div className="flex flex-row items-center space-x-2">
              <Button
                onClick={() => handleEditModal(row)}
                size="xs"
                variant="primary"
              >
                Edit
              </Button>
              {/* <Button
                onClick={() => handleOpenModal(row?.id)}
                size="xs"
                variant="danger"
              >
                Delete
              </Button> */}
            </div>
          );
        },
      },
      {
        id: "select",
        accessor: (row: any) => {
          return (
            <RadioBox
              name="resellerDfs"
              onChange={onChange}
              value={JSON.stringify(row)}
            />
          );
        },
      },
    ];

    return columns.map((column) =>
      availableColumns.find(
        (availableColumn) => availableColumn.id === column.id
      )
    );
  };

  if (loading) {
    return (
      <div className="mt-4 rounded-lg flex flex-row items-center justify-center w-full h-60 bg-white">
        <Loader />
      </div>
    );
  }
  return (
    <>
      <DeleteModal
        isOpen={resellerDfsModals.deleteResellerDfs}
        onClose={handleCloseModal}
        onSubmit={onDeleteTemplate}
        message="Are you sure you want to delete this product?"
        loading={resellerDfssLoaders.submit}
      />
      <EditResellerDfsForm resellerDfs={selectedResellerDfs} />

      <Table
        columns={getColumns()}
        data={data}
        pageCount={pagination.totalPages}
        currentPage={pagination.number}
        onPaginationChange={onPaginationChange}
      />
    </>
  );
};
