export enum UserTabs {
  Group = "group",
  Contact = "contact",
}

export const PhoneRegex = /^[0-9]{9}$/;

export const userConfig: any = {
  tables: {
    allContacts: {
      label: "All Contacts",
      selectable: true,
      editable: true,
      deleatable: true,
      properties: [
        {
          label: "Contact Name",
          rowData: "{{firstName}} {{lastName}}",
        },
        {
          label: "Phone Number",
          rowData: "phoneNumber",
        },
        {
          label: "Email",
          rowData: "emailAddress",
        },
        {
          label: "Group",
          rowData: "group.name",
        },
      ],
    },
    contacts: {
      label: "Contacts",
      selectable: true,
      editable: true,
      deleatable: true,
      properties: [
        {
          label: "Contact Name",
          rowData: "{{firstName}} {{lastName}}",
        },
        {
          label: "Phone Number",
          rowData: "phoneNumber",
        },
        {
          label: "Email",
          rowData: "emailAddress",
        },
      ],
    },
    groups: {
      label: "Groups",
      selectable: true,
      editable: true,
      deleatable: true,
      properties: [
        {
          label: "Name",
          rowData: "name",
          hasLink: true,
        },
        {
          label: "Description",
          rowData: "description",
        },
      ],
    },
    messagingContacts: {
      label: "Contacts",
      selectable: true,
      properties: [
        {
          label: "Contact Name",
          rowData: "{{firstName}} {{lastName}}",
        },
        {
          label: "Phone Number",
          rowData: "phoneNumber",
        },
        {
          label: "Email",
          rowData: "emailAddress",
        },
        {
          label: "Group",
          rowData: "group.name",
        },
      ],
    },
    messagingGroups: {
      label: "Groups",
      selectable: true,
      properties: [
        {
          label: "Name",
          rowData: "name",
          hasLink: true,
        },
        {
          label: "Description",
          rowData: "description",
        },
      ],
    },
    campaignGroups: {
      label: "Groups",
      selectable: true,
      limited: true,
      properties: [
        {
          label: "Name",
          rowData: "name",
          hasLink: true,
        },
        {
          label: "Description",
          rowData: "description",
        },
      ],
    },
    campaignContacts: {
      label: "Contacts",
      selectable: true,
      limited: true,
      properties: [
        {
          label: "Contact Name",
          rowData: "{{firstName}} {{lastName}}",
        },
        {
          label: "Phone Number",
          rowData: "phoneNumber",
        },
        {
          label: "Email",
          rowData: "emailAddress",
        },
      ],
    },
  },
  forms: {
    group: [
      {
        type: "default",
        value: "",
        label: "Name",
        width: "full",
      },
      {
        type: "textarea",
        value: "",
        label: "Description",
        width: "full",
      },
    ],
    contact: [
      {
        type: "default",
        value: "",
        label: "First Name",
        width: "full",
      },
      {
        type: "default",
        value: "",
        label: "Middle Name",
        width: "full",
      },
      {
        type: "default",
        value: "",
        label: "Last Name",
        width: "full",
      },
      {
        type: "default",
        value: "",
        label: "Phone Number",
        width: "full",
      },
      {
        type: "default",
        value: "",
        label: "Email Address",
        width: "full",
      },
    ],
    editGroup: [
      {
        type: "default",
        value: "name",
        label: "Name",
        width: "full",
      },
      {
        type: "textarea",
        value: "description",
        label: "Description",
        width: "full",
      },
    ],
    editContact: [
      {
        type: "default",
        value: "firstName",
        label: "First Name",
        width: "full",
      },
      {
        type: "default",
        value: "middleName",
        label: "Middle Name",
        width: "full",
      },
      {
        type: "default",
        value: "lastName",
        label: "Last Name",
        width: "full",
      },
      {
        type: "default",
        value: "phoneNumber",
        label: "Phone Number",
        width: "full",
      },
      {
        type: "default",
        value: "emailAddress",
        label: "Email Address",
        width: "full",
      },
    ],
  },
};
