import { createReducer } from "@reduxjs/toolkit";
import { Types } from "./actionTypes";
import { MessagingTabs } from "./types";
import { PaginationProps } from "../App";
import _ from "lodash";

export interface InitialMessageState {
  cache: Record<string, any>;
  messages: any[];
  selectedServiceProvider: any;
  messagesPagination: PaginationProps;
  templates: any[];
  templatesPagination: PaginationProps;
  smsRequests: any[];
  smsRequestsPagination: PaginationProps;
  serviceProviders: any[];
  serviceProvidersPagination: PaginationProps;
  templateVariables: any[];
  templateVariablesPagination: PaginationProps;
  messageTab: string;
  messageModals: any;
  messageLoaders: any;
  emailRequests: any[];
  emailRequestsPagination: PaginationProps;
  inAppRequests: any[];
  inAppRequestsPagination: PaginationProps;
  eventListeners: any[];
  eventListenersPagination: PaginationProps;
}

const initialState: InitialMessageState = {
  messages: [],
  messagesPagination: {
    number: 0,
    totalPages: 1,
    size: 10,
    totalElements: 0,
  },

  selectedServiceProvider: {},

  templates: [],
  templatesPagination: {
    number: 0,
    totalPages: 1,
    size: 10,
    totalElements: 0,
  },

  smsRequests: [],
  smsRequestsPagination: {
    number: 0,
    totalPages: 1,
    size: 10,
    totalElements: 0,
  },

  emailRequests: [],
  emailRequestsPagination: {
    number: 0,
    totalPages: 1,
    size: 10,
    totalElements: 0,
  },

  inAppRequests: [],
  inAppRequestsPagination: {
    number: 0,
    totalPages: 1,
    size: 10,
    totalElements: 0,
  },

  serviceProviders: [],
  serviceProvidersPagination: {
    number: 0,
    totalPages: 1,
    size: 10,
    totalElements: 0,
  },

  templateVariables: [],
  templateVariablesPagination: {
    number: 0,
    totalPages: 1,
    size: 50,
    totalElements: 0,
  },

  messageTab: MessagingTabs.SmsRequests,
  messageModals: {
    addServiceProvider: false,
    addServiceProps: false,
    editServiceProps: false,
    editServiceProvider: false,
    deleteServiceProvider: false,
    deleteServiceProps: false,
    addTemplates: false,
    editTemplates: false,
    deleteTemplates: false,
    deleteEventListener: false,
  },
  messageLoaders: {
    submit: false,
    fetchMessages: false,
    fetchSmsRequests: false,
    fetchTemplates: false,
    fetchServiceProviders: false,
    fetchTemplatesVariables: false,
    fetchSpProps: false,
    fetchEmailRequests: false,
    fetchInAppRequests: false,
    fetchEventListeners: false,
  },

  cache: {
    emailRequests: {},
    smsRequests: {},
    inAppRequests: {},
  },

  eventListeners: [],
  eventListenersPagination: {
    number: 0,
    totalPages: 1,
    size: 10,
    totalElements: 0,
  },
};

export const messagesReducer = createReducer(initialState, {
  [Types.CHANGE_MESSAGE_TABS]: (state, action) => {
    state.messageTab = action.payload;
  },
  [Types.TOGGLE_MESSAGE_MODALS]: (state, action) => {
    state.messageModals = {
      ...state.messageModals,
      ...action.payload,
    };
  },

  [Types.FETCH_MESSAGES]: (state) => {
    state.messageLoaders.fetchMessages = true;
  },
  [Types.FETCH_MESSAGES_SUCCESS]: (state, action) => {
    const {
      data: { content, page },
    } = action.payload;
    state.messageLoaders.fetchMessages = false;
    state.messages = content;
    state.messagesPagination = {
      number: page.number,
      size: page.size,
      totalPages: page.totalPages,
      totalElements: page.totalElements,
    };
  },
  [Types.FETCH_MESSAGES_FAIL]: (state) => {
    state.messageLoaders.fetchMessages = false;
  },

  [Types.FETCH_TEMPLATES]: (state) => {
    state.messageLoaders.fetchTemplates = true;
  },
  [Types.FETCH_TEMPLATES_SUCCESS]: (state, action) => {
    const {
      data: { content, page },
    } = action.payload;
    state.messageLoaders.fetchTemplates = false;
    state.templates = content;
    state.templatesPagination = {
      number: page.number,
      size: page.size,
      totalPages: page.totalPages,
      totalElements: page.totalElements,
    };
  },
  [Types.FETCH_TEMPLATES_FAIL]: (state) => {
    state.messageLoaders.fetchTemplates = false;
  },

  [Types.FETCH_SMS_REQUESTS]: (state) => {
    state.messageLoaders.fetchSmsRequests = true;
  },
  [Types.FETCH_SMS_REQUESTS_SUCCESS]: (state, action) => {
    const {
      data: { content, page },
    } = action.payload;
    state.messageLoaders.fetchSmsRequests = false;
    state.smsRequests = content;
    state.smsRequestsPagination = {
      number: page.number,
      size: page.size,
      totalPages: page.totalPages,
      totalElements: page.totalElements,
    };
  },
  [Types.FETCH_SMS_REQUESTS_FAIL]: (state) => {
    state.messageLoaders.fetchSmsRequests = false;
  },

  [Types.FETCH_SERVICE_PROVIDERS]: (state) => {
    state.messageLoaders.fetchServiceProviders = true;
  },
  [Types.FETCH_SERVICE_PROVIDERS_SUCCESS]: (state, action) => {
    const {
      data: { content, page },
    } = action.payload;
    state.messageLoaders.fetchServiceProviders = false;
    state.serviceProviders = content;
    state.serviceProvidersPagination = {
      number: page.number,
      size: page.size,
      totalPages: page.totalPages,
      totalElements: page.totalElements,
    };
  },
  [Types.FETCH_SERVICE_PROVIDERS_FAIL]: (state) => {
    state.messageLoaders.fetchServiceProviders = false;
  },

  [Types.CREATE_SERVICE_PROVIDER]: (state) => {
    state.messageLoaders.submit = true;
  },
  [Types.CREATE_SERVICE_PROVIDER_SUCCESS]: (state, action) => {
    state.messageLoaders.submit = false;
    state.serviceProviders = [action.payload.data, ...state.serviceProviders];
  },
  [Types.CREATE_SERVICE_PROVIDER_FAIL]: (state) => {
    state.messageLoaders.submit = false;
  },

  [Types.CREATE_MESSAGE_TEMPLATE]: (state) => {
    state.messageLoaders.submit = true;
  },
  [Types.CREATE_MESSAGE_TEMPLATE_SUCCESS]: (state, action) => {
    state.messageLoaders.submit = false;
    state.templates = [action.payload.data, ...state.templates];
  },
  [Types.CREATE_MESSAGE_TEMPLATE_FAIL]: (state) => {
    state.messageLoaders.submit = false;
  },

  [Types.CREATE_SMS_REQUESTS]: (state) => {
    state.messageLoaders.submit = true;
  },
  [Types.CREATE_SMS_REQUESTS_SUCCESS]: (state, action) => {
    state.messageLoaders.submit = false;
    state.smsRequests = [action.payload.data, ...state.smsRequests];
  },
  [Types.CREATE_SMS_REQUESTS_FAIL]: (state) => {
    state.messageLoaders.submit = false;
  },

  [Types.UPDATE_MESSAGE_TEMPLATE]: (state) => {
    state.messageLoaders.submit = true;
  },
  [Types.UPDATE_MESSAGE_TEMPLATE_SUCCESS]: (state, action) => {
    state.messageLoaders.submit = false;
    state.templates = [
      ...state.templates.map((template) =>
        template.id === action.payload.data.id ? action.payload.data : template
      ),
    ];
  },
  [Types.UPDATE_MESSAGE_TEMPLATE_FAIL]: (state) => {
    state.messageLoaders.submit = false;
  },

  [Types.DELETE_MESSAGE_TEMPLATE]: (state) => {
    state.messageLoaders.submit = true;
  },
  [Types.DELETE_MESSAGE_TEMPLATE_SUCCESS]: (state, action) => {
    const {
      previousAction: {
        payload: {
          request: {
            deleteProp: { id },
          },
        },
      },
    } = action.meta;
    state.templates = [
      ...state.templates.filter((template) => template.id !== id),
    ];
    if (_.isEmpty(state.templates)) {
      state.templatesPagination.number = 0;
    }
    state.messageLoaders.submit = false;
  },
  [Types.DELETE_MESSAGE_TEMPLATE_FAIL]: (state) => {
    state.messageLoaders.submit = false;
  },

  [Types.FETCH_TEMPLATE_VARIABLES]: (state) => {
    state.messageLoaders.fetchTemplatesVariables = true;
  },
  [Types.FETCH_TEMPLATE_VARIABLES_SUCCESS]: (state, action) => {
    const {
      data: { content, page },
    } = action.payload;
    state.messageLoaders.fetchTemplatesVariables = false;
    state.templateVariables = _.concat(state.templateVariables, content);
    state.templateVariablesPagination = {
      number: page.number + 1,
      size: page.size,
      totalPages: page.totalPages,
      totalElements: page.totalElements,
    };
  },
  [Types.FETCH_TEMPLATE_VARIABLES_FAIL]: (state) => {
    state.messageLoaders.fetchTemplatesVariables = false;
  },

  [Types.UPDATE_SP]: (state) => {
    state.messageLoaders.submit = true;
  },
  [Types.UPDATE_SP_SUCCESS]: (state, action) => {
    state.messageLoaders.submit = false;
    state.serviceProviders = [
      ...state.serviceProviders.map((sp) =>
        sp.id === action.payload.data.id ? action.payload.data : sp
      ),
    ];
  },
  [Types.UPDATE_SP_FAIL]: (state) => {
    state.messageLoaders.submit = false;
  },

  [Types.DELETE_SP]: (state) => {
    state.messageLoaders.submit = true;
  },
  [Types.DELETE_SP_SUCCESS]: (state, action) => {
    const {
      previousAction: {
        payload: {
          request: {
            deleteProp: { id },
          },
        },
      },
    } = action.meta;
    state.serviceProviders = [
      ...state.serviceProviders.filter((sp) => sp.id !== id),
    ];
    if (_.isEmpty(state.serviceProviders)) {
      state.serviceProvidersPagination.number = 0;
    }
    state.messageLoaders.submit = false;
  },
  [Types.DELETE_SP_FAIL]: (state) => {
    state.messageLoaders.submit = false;
  },

  [Types.CREATE_SP_PROPS]: (state) => {
    state.messageLoaders.submit = true;
  },
  [Types.CREATE_SP_PROPS_SUCCESS]: (state, action) => {
    state.messageLoaders.submit = false;
    state.selectedServiceProvider = {
      ...state.selectedServiceProvider,
      props: [
        action.payload.data,
        ...(state.selectedServiceProvider?.props ?? []),
      ],
    };
  },
  [Types.CREATE_SP_PROPS_FAIL]: (state) => {
    state.messageLoaders.submit = false;
  },

  [Types.FETCH_SP_PROPS]: (state) => {
    state.messageLoaders.fetchSpProps = true;
  },
  [Types.FETCH_SP_PROPS_SUCCESS]: (state, action) => {
    state.messageLoaders.fetchSpProps = false;
  },
  [Types.FETCH_SP_PROPS_FAIL]: (state) => {
    state.messageLoaders.fetchSpProps = false;
  },

  [Types.UPDATE_SP_PROPS]: (state) => {
    state.messageLoaders.submit = true;
  },
  [Types.UPDATE_SP_PROPS_SUCCESS]: (state, action) => {
    state.messageLoaders.submit = false;
    state.selectedServiceProvider = {
      ...state.selectedServiceProvider,
      props: state.selectedServiceProvider?.props.map((prop: any) =>
        prop.id === action.payload.data.id ? action.payload.data : prop
      ),
    };
  },
  [Types.UPDATE_SP_PROPS_FAIL]: (state) => {
    state.messageLoaders.submit = false;
  },

  [Types.DELETE_SP_PROPS]: (state) => {
    state.messageLoaders.submit = true;
  },
  [Types.DELETE_SP_PROPS_SUCCESS]: (state, action) => {
    state.messageLoaders.submit = false;
    const {
      previousAction: {
        payload: {
          request: {
            deleteProp: { id },
          },
        },
      },
    } = action.meta;
    state.selectedServiceProvider = {
      ...state.selectedServiceProvider,
      props: state.selectedServiceProvider?.props.filter(
        (prop: any) => prop.id !== id
      ),
    };
  },
  [Types.DELETE_SP_PROPS_FAIL]: (state) => {
    state.messageLoaders.submit = false;
  },

  [Types.UPDATE_SELECTED_SP]: (state, action) => {
    state.selectedServiceProvider = action.payload;
  },

  [Types.FETCH_EMAIL_REQUESTS]: (state) => {
    state.messageLoaders.fetchEmailRequests = true;
  },
  [Types.FETCH_EMAIL_REQUESTS_SUCCESS]: (state, action) => {
    const {
      data: { content, page },
    } = action.payload;
    state.messageLoaders.fetchEmailRequests = false;
    state.emailRequests = content;
    state.emailRequestsPagination = {
      number: page.number,
      size: page.size,
      totalPages: page.totalPages,
      totalElements: page.totalElements,
    };
  },
  [Types.FETCH_EMAIL_REQUESTS_FAIL]: (state) => {
    state.messageLoaders.fetchEmailRequests = false;
  },

  [Types.FETCH_IN_APP_REQUESTS]: (state) => {
    state.messageLoaders.fetchInAppRequests = true;
  },
  [Types.FETCH_IN_APP_REQUESTS_SUCCESS]: (state, action) => {
    const {
      data: { content, page },
    } = action.payload;
    state.messageLoaders.fetchInAppRequests = false;
    state.inAppRequests = content;
    state.inAppRequestsPagination = {
      number: page.number,
      size: page.size,
      totalPages: page.totalPages,
      totalElements: page.totalElements,
    };
  },
  [Types.FETCH_IN_APP_REQUESTS_FAIL]: (state) => {
    state.messageLoaders.fetchInAppRequests = false;
  },

  [Types.CREATE_EMAIL_REQUESTS]: (state) => {
    state.messageLoaders.submit = true;
  },
  [Types.CREATE_EMAIL_REQUESTS_SUCCESS]: (state, action) => {
    state.messageLoaders.submit = false;
    state.emailRequests = [action.payload.data, ...state.emailRequests];
  },
  [Types.CREATE_EMAIL_REQUESTS_FAIL]: (state) => {
    state.messageLoaders.submit = false;
  },

  [Types.CREATE_IN_APP_REQUESTS]: (state) => {
    state.messageLoaders.submit = true;
  },
  [Types.CREATE_IN_APP_REQUESTS_SUCCESS]: (state, action) => {
    state.messageLoaders.submit = false;
    state.inAppRequests = [action.payload.data, ...state.inAppRequests];
  },
  [Types.CREATE_IN_APP_REQUESTS_FAIL]: (state) => {
    state.messageLoaders.submit = false;
  },

  [Types.CREATE_SMS_REQUEST_CACHE]: (state, action) => {
    state.cache.smsRequests = action.payload;
  },
  [Types.CREATE_EMAIL_REQUEST_CACHE]: (state, action) => {
    state.cache.emailRequests = action.payload;
  },
  [Types.CREATE_IN_APP_REQUEST_CACHE]: (state, action) => {
    state.cache.inAppRequests = action.payload;
  },

  [Types.FETCH_EVENT_LISTENERS]: (state) => {
    state.messageLoaders.fetchEventListeners = true;
  },
  [Types.FETCH_EVENT_LISTENERS_SUCCESS]: (state, action) => {
    const {
      data: { content, page },
    } = action.payload;
    state.messageLoaders.fetchEventListeners = false;
    state.eventListeners = content;
    state.eventListenersPagination = {
      number: page.number + 1,
      size: page.size,
      totalPages: page.totalPages,
      totalElements: page.totalElements,
    };
  },
  [Types.FETCH_EVENT_LISTENERS_FAIL]: (state) => {
    state.messageLoaders.fetchEventListeners = false;
  },

  [Types.CREATE_EVENT_LISTENER]: (state) => {
    state.messageLoaders.submit = true;
  },
  [Types.CREATE_EVENT_LISTENER_SUCCESS]: (state, action) => {
    state.messageLoaders.submit = false;
    state.eventListeners = [action.payload.data, ...state.eventListeners];
  },
  [Types.CREATE_EVENT_LISTENER_FAIL]: (state) => {
    state.messageLoaders.submit = false;
  },

  [Types.UPDATE_EVENT_LISTENER]: (state) => {
    state.messageLoaders.submit = true;
  },
  [Types.UPDATE_EVENT_LISTENER_SUCCESS]: (state, action) => {
    state.messageLoaders.submit = false;
    state.eventListeners = [
      ...state.eventListeners.map((eventListener) =>
        eventListener.id === action.payload.data.id
          ? action.payload.data
          : eventListener
      ),
    ];
  },
  [Types.UPDATE_EVENT_LISTENER_FAIL]: (state) => {
    state.messageLoaders.submit = false;
  },

  [Types.DELETE_EVENT_LISTENER]: (state) => {
    state.messageLoaders.submit = true;
  },
  [Types.DELETE_EVENT_LISTENER_SUCCESS]: (state, action) => {
    const {
      previousAction: {
        payload: {
          request: {
            deleteProp: { id },
          },
        },
      },
    } = action.meta;
    state.eventListeners = [
      ...state.eventListeners.filter(
        (eventListener) => eventListener.id !== id
      ),
    ];
    if (_.isEmpty(state.templates)) {
      state.eventListenersPagination.number = 0;
    }
    state.messageLoaders.submit = false;
  },
  [Types.DELETE_EVENT_LISTENER_FAIL]: (state) => {
    state.messageLoaders.submit = false;
  },
});
