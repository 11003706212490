import { BriefcaseIcon, DownloadIcon } from "@heroicons/react/outline";
import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { DownloadStatementForm, StatementCard } from ".";
import { toggleReportModal } from "../../domains/Reports";
import { Layout } from "../App";

export const IoReportPage: FC = () => {
  const dispatch = useDispatch();
  let [selectUrl, setSelectedUrl] = useState<any>();
  let [selectName, setSelectedName] = useState<any>();

  const data = [
    {
      id: 1,
      name: "Sales Volumes Report",
      description: "Report on Volume of Sales",
      url: "/io-wallets/v1/reports/sales-volumes",
    },
    {
      id: 2,
      name: "Collections Volumes Report",
      description: "Report on Volume of Collections",
      url: "/io-wallets/v1/reports/collections-volumes",
    },
    {
      id: 3,
      name: "Daily Sales Volumes Report",
      description: "Report on Daily Volume of Sales",
      url: "/io-wallets/v1/reports/sales-dayonday",
    },
    {
      id: 4,
      name: "Daily Collections Volumes Report",
      description: "Report on Daily Volume of Collections",
      url: "/io-wallets/v1/reports/collections-dayonday",
    },
  ];

  const handleModal = async ({ name, url }: any) => {
    setSelectedUrl(url);
    setSelectedName(name);
    await dispatch(
      toggleReportModal({
        downloadStatement: true,
      })
    );
  };

  return (
    <Layout>
      <div className="px-4">
        <span className="text-xl font-semibold p-4">Reseller Statements</span>
        <DownloadStatementForm name={selectName} url={selectUrl} />

        <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-4">
          {data.map((value: any) => (
            <StatementCard
              key={value.id}
              name={value.name}
              description={value.description}
              SubComponent={() => {
                return (
                  <div className="ml-2 flex justify-between">
                    <button
                      onClick={() => handleModal(value)}
                      className="action-buttons btn-danger text-xs px-6"
                    >
                      <span className="flex items-center justify-center space-x-1">
                        <DownloadIcon className="h-4 w-4" />
                        <span>Download</span>
                      </span>
                    </button>
                    <span className="rounded-full p-2 bg-green-100">
                      <BriefcaseIcon className="h-4 w-4 text-tandaGreen" />
                    </span>
                  </div>
                );
              }}
            />
          ))}
        </div>
      </div>
    </Layout>
  );
};
