import { PaginationProps } from "./../App/types";
import { createReducer } from "@reduxjs/toolkit";
import { Types } from "./actionTypes";

export interface InitialWithdrawalState {
  cache: Record<string, any>;
  withdrawals: any[];
  withdrawalsPagination: PaginationProps;
  withdrawalModals: any;
  withdrawalsLoaders: any;
  selectedWithdrawal: any;
}

const initialState: InitialWithdrawalState = {
  withdrawals: [],
  withdrawalsPagination: {
    number: 0,
    totalPages: 1,
    size: 10,
    totalElements: 0,
  },

  withdrawalModals: {
    addWithdrawal: false,
    editWithdrawal: false,
    deleteWithdrawal: false,
    disableWithdrawal: false,
    disableEnableWithdrawal: false,
  },
  withdrawalsLoaders: {
    submit: false,
    fetchWithdrawals: false,
    fetchTrasactionFees: false,
  },

  cache: {
    emailRequests: {},
    smsRequests: {},
    inAppRequests: {},
  },
  selectedWithdrawal: {},
};

export const withdrawalsReducer = createReducer(initialState, {
  [Types.UPDATE_SELECTED_WITHDRAWAL]: (state, action) => {
    state.selectedWithdrawal = action.payload;
  },
  [Types.TOGGLE_WITHDRAWAL_MODALS]: (state, action) => {
    state.withdrawalModals = {
      ...state.withdrawalModals,
      ...action.payload,
    };
  },

  [Types.FETCH_WITHDRAWALS]: (state) => {
    state.withdrawalsLoaders.fetchWithdrawals = true;
  },
  [Types.FETCH_WITHDRAWALS_SUCCESS]: (state, action) => {
    const {
      data: { content, page },
    } = action.payload;
    state.withdrawalsLoaders.fetchWithdrawals = false;
    state.withdrawals = content;
    state.withdrawalsPagination = {
      number: page.number,
      size: page.size,
      totalPages: page.totalPages,
      totalElements: page.totalElements,
    };
  },
  [Types.FETCH_WITHDRAWALS_FAIL]: (state) => {
    state.withdrawalsLoaders.fetchWithdrawals = false;
  },

  [Types.CREATE_WITHDRAWAL]: (state) => {
    state.withdrawalsLoaders.submit = true;
  },
  [Types.CREATE_WITHDRAWAL_SUCCESS]: (state, action) => {
    state.withdrawalsLoaders.submit = false;
    state.withdrawals = [action.payload.data, ...state.withdrawals];
  },
  [Types.CREATE_WITHDRAWAL_FAIL]: (state) => {
    state.withdrawalsLoaders.submit = false;
  },

  [Types.UPDATE_WITHDRAWAL]: (state) => {
    state.withdrawalsLoaders.submit = true;
  },

  [Types.UPDATE_WITHDRAWAL_SUCCESS]: (state, action) => {
    const {
      previousAction: {
        payload: {
          request: {
            withdrawal: { id },
            withdrawal,
          },
        },
      },
    } = action.meta;
    state.withdrawalsLoaders.submit = false;
    state.withdrawals = [
      ...state.withdrawals.map((pr) => (pr.id === id ? withdrawal : pr)),
    ];
  },
  [Types.UPDATE_WITHDRAWAL_FAIL]: (state) => {
    state.withdrawalsLoaders.submit = false;
  },
});
