import _ from "lodash";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BSWalletTable } from ".";
import { config } from "../../../constants";
import {
  fetchBSWallets,
  InitialBSWalletState,
  toggleBSWalletModal,
} from "../../../domains/BSWallet";
import { Banners, Layout, SuccessOrErrorState } from "../../App";

export const BSWalletPage: FC = () => {
  const dispatch = useDispatch();
  const { bswallets, bswalletsLoaders } = useSelector(
    (state: { bswallet: InitialBSWalletState }) => state.bswallet
  );

  useEffect(() => {
    const fetchBSWalletsAsync = async () => await dispatch(fetchBSWallets());

    fetchBSWalletsAsync();
  }, [dispatch]);

  const handleModal = async () => {
    await dispatch(
      toggleBSWalletModal({
        addBSWallet: true,
      })
    );
  };

  return (
    <Layout>
      <div>
        <Banners withAction actionLabel="Create BCW" onClick={handleModal}>
          <p className="pl-3">
            Do you want to create a Business Client Wallet?
          </p>
        </Banners>
      </div>
      <div className="pt-3">
        {_.isEmpty(bswallets) && !bswalletsLoaders.fetchBSWallets ? (
          <SuccessOrErrorState
            state="empty"
            message="No Business Client Wallets Found"
          />
        ) : (
          <BSWalletTable
            title={config.bswallets.bswallet.normal.title}
            data={bswallets}
            loading={bswalletsLoaders.fetchBSWallets}
          />
        )}
      </div>
    </Layout>
  );
};
