import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Banners, SuccessOrErrorState } from "../../App";
import { ServiceProviderTable, ServiceProviderForm } from "../ServiceProviders";
import {} from "./ServiceProviderForm";
import {
  fetchServiceProviders,
  InitialServiceProviderState,
} from "../../../domains/ServiceProvider";
import { config } from "../../../constants";
import _ from "lodash";

export const ServiceProviderPage: FC = () => {
  const dispatch = useDispatch();
  const {
    serviceProviders,
    serviceProvidersLoaders,
    serviceProvidersPagination,
  } = useSelector(
    (state: { serviceProvider: InitialServiceProviderState }) =>
      state.serviceProvider
  );

  useEffect(() => {
    const fetchServiceProvidersAsync = async () =>
      await dispatch(fetchServiceProviders());

    fetchServiceProvidersAsync();
  }, [dispatch]);

  const handlePagination = (page: number) => {
    dispatch(
      fetchServiceProviders({
        page: page - 1,
        size: serviceProvidersPagination.size,
      })
    );
  };
  return (
    <Layout>
      <div>
        <Banners actionLabel="Service Provider">
          <p className="pl-3">Products Service Providers</p>
        </Banners>
      </div>
      <ServiceProviderForm />
      <div className="pt-2">
        {_.isEmpty(serviceProviders) &&
        !serviceProvidersLoaders.fetchServiceProviders ? (
          <SuccessOrErrorState
            state="empty"
            message="No Service Providers Found"
          />
        ) : (
          <ServiceProviderTable
            columns={config.products.serviceProvider.listColumns}
            data={serviceProviders}
            pagination={serviceProvidersPagination}
            loading={serviceProvidersLoaders.fetchServiceProviders}
            onPaginationChange={handlePagination}
          />
        )}
      </div>
    </Layout>
  );
};
