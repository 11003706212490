import { FC } from "react";
import { XIcon } from "@heroicons/react/outline";

interface SelectorCardProps {
  handleRemoveSelector?: (selector: any) => any;
  selector: any;
}

export const SelectorCard: FC<SelectorCardProps> = ({
  handleRemoveSelector,
  selector,
}) => {
  return (
    <div className="flex shadow-sm rounded-md">
      <div className="flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md bg-tandaGreen uppercase">
        {selector?.operator}
      </div>
      <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md ">
        <div className="flex-1 px-4 py-2 text-sm trucate">
          <span className="text-gray-900">Key: {selector?.key}</span>
          <p className="text-gray-500">Value: {selector?.value}</p>
        </div>
        <div className="flex-shrink-0 pr-2">
          <button
            type="button"
            className="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-tandaPurpleLight"
            onClick={() => handleRemoveSelector?.(selector)}
          >
            <span className="sr-only">Remove selector</span>
            <XIcon className="w-5 h-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
};
