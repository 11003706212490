export const Types: Record<string, string> = {
  TOGGLE_BATCHTYPE_MODALS: "TOGGLE_BATCHTYPE_MODALS",

  FETCH_BATCHTYPES: "FETCH_BATCHTYPES",
  FETCH_BATCHTYPES_SUCCESS: "FETCH_BATCHTYPES_SUCCESS",
  FETCH_BATCHTYPES_FAIL: "FETCH_BATCHTYPES_FAIL",

  CREATE_BATCHTYPE: "CREATE_BATCHTYPE",
  CREATE_BATCHTYPE_SUCCESS: "CREATE_BATCHTYPE_SUCCESS",
  CREATE_BATCHTYPE_FAIL: "CREATE_BATCHTYPE_FAIL",

  UPDATE_BATCHTYPE: "UPDATE_BATCHTYPE",
  UPDATE_BATCHTYPE_SUCCESS: "UPDATE_BATCHTYPE_SUCCESS",
  UPDATE_BATCHTYPE_FAIL: "UPDATE_BATCHTYPE_FAIL",

  DELETE_BATCHTYPE: "DELETE_BATCHTYPE",
  DELETE_BATCHTYPE_SUCCESS: "DELETE_BATCHTYPE_SUCCESS",
  DELETE_BATCHTYPE_FAIL: "DELETE_BATCHTYPE_FAIL",
};
