import _ from "lodash";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { config } from "../../../constants";
import {
  fetchResellerDfsCommissionRates,
  InitialResellerDfsCommissionRateState,
  toggleResellerDfsCommissionRateModal,
} from "../../../domains/Reseller/ResellerDfsCommissionRate";
import { Banners, Layout, SuccessOrErrorState } from "../../App";
import { ResellerDfsTabs } from "../ResellerDfsTabs";
import { ResellerDfsCommissionRateForm } from "./ResellerDfsCommissionRateForm";
import { ResellerDfsCommissionRateTable } from "./ResellerDfsCommissionRateTable";

export const ResellerDfsCommissionRatePage: FC = () => {
  const dispatch = useDispatch();
  const { resellerDfsCommissionRates, resellerDfsCommissionRatesLoaders } =
    useSelector(
      (state: {
        resellerDfsCommissionRate: InitialResellerDfsCommissionRateState;
      }) => state.resellerDfsCommissionRate
    );
  const { dfsId = "" } = useParams<{ dfsId?: string }>();

  useEffect(() => {
    const fetchResellerDfsCommissionRatesAsync = async () =>
      await dispatch(fetchResellerDfsCommissionRates(dfsId));

    fetchResellerDfsCommissionRatesAsync();
  }, [dispatch, dfsId]);

  const handleModal = async () => {
    await dispatch(
      toggleResellerDfsCommissionRateModal({
        addResellerDfsCommissionRate: true,
      })
    );
  };
  return (
    <Layout>
      <ResellerDfsTabs />
      <div className="bg-white mt-4">
        <Banners
          withAction
          actionLabel="Add Commission Rate"
          onClick={handleModal}
        >
          <p className="pl-3">Reseller Product Commission Rates</p>
        </Banners>
      </div>
      <ResellerDfsCommissionRateForm />
      <div className="pt-3">
        {_.isEmpty(resellerDfsCommissionRates) &&
        !resellerDfsCommissionRatesLoaders.fetchResellerDfsCommissionRates ? (
          <SuccessOrErrorState
            state="empty"
            message="No Product Commission Found"
          />
        ) : (
          <ResellerDfsCommissionRateTable
            columns={config.resellerDfsRate.listColumns}
            data={resellerDfsCommissionRates}
            loading={
              resellerDfsCommissionRatesLoaders.fetchResellerDfsCommissionRates
            }
          />
        )}
      </div>
    </Layout>
  );
};
