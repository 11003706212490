import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import _ from "lodash";
import React, { FC, useMemo } from "react";
import { useExpanded, usePagination, useTable } from "react-table";
import { Pagination } from "./Pagination";

interface TableProps {
  data: any[];
  columns: any[];
  currentPage?: number;
  onPaginationChange?: (page: number) => void;
  pageCount?: number;
  withPagination?: boolean;
  title?: string;
  SubComponent?: React.FC<{ row: any }>;
  Filter?: React.FC<{}>;
}

export const ExpandTable: FC<TableProps> = ({
  data,
  columns,
  currentPage = 0,
  pageCount: controlledPageCount,
  withPagination = true,
  title,
  onPaginationChange,
  SubComponent,
  Filter,
}) => {
  const memoizedColumns = useMemo(() => columns, [columns]);
  const memoizedData = useMemo(() => data, [data]);
  const renderRowSubComponent = React.useCallback(
    ({ row }) => {
      if (_.isNil(SubComponent)) {
        return null;
      }
      return <SubComponent row={row} />;
    },
    [SubComponent]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex },
    visibleColumns,
  } = useTable(
    {
      columns: memoizedColumns,
      data: memoizedData,
      initialState: { pageIndex: currentPage }, // Pass our hoisted table state
      manualPagination: true,
      pageCount: controlledPageCount,
      paginateExpandedRows: false,
    },
    useExpanded,
    usePagination
  );

  return (
    <div className="flex flex-col">
      <div className="pb-2 flex flex-col lg:flex-row-reverse justify-between">
        {withPagination && (
          <Pagination
            currentPage={pageIndex + 1}
            totalPages={controlledPageCount}
            onPaginationChange={onPaginationChange}
          />
        )}
        <>
          {title && (
            <span className="text-xl font-semibold text-gray-700 pt-2 ml-4">
              {title}
            </span>
          )}
        </>
        <>{Filter && <Filter />}</>
      </div>
      <div className="overflow-x-auto">
        <div className="py-2 align-middle inline-block min-w-full">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table
              className="min-w-full divide-y divide-gray-200"
              {...getTableProps()}
            >
              <thead>
                {headerGroups.map((headerGroup: any) => (
                  <tr
                    className="bg-gray-50"
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column: any) => (
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        {...column.getHeaderProps()}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row: any, i: number) => {
                  const isEven = i % 2 === 0;
                  prepareRow(row);
                  return (
                    <>
                      <tr
                        {...row.getRowProps()}
                        className={classNames({
                          "bg-gray-50": isEven,
                          "bg-white": !isEven,
                        })}
                      >
                        {row.cells.map((cell: any, index: number) => {
                          if (index === 0) {
                            return (
                              <td
                                className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 align-middle"
                                {...cell.getCellProps(
                                  row.getToggleRowExpandedProps()
                                )}
                              >
                                <div className="flex flex-row items-center">
                                  {(row.canExpand || SubComponent) && (
                                    <React.Fragment>
                                      {row.isExpanded ? (
                                        <ChevronDownIcon className="h-6 w-6 text-gray-600" />
                                      ) : (
                                        <ChevronRightIcon className="h-6 w-6 text-gray-600" />
                                      )}
                                    </React.Fragment>
                                  )}
                                  {cell.isAggregated ? (
                                    cell.render("Aggregated")
                                  ) : cell.isPlaceholder ? (
                                    <span className="hidden">dont display</span>
                                  ) : (
                                    cell.render("Cell")
                                  )}
                                </div>
                              </td>
                            );
                          }
                          if (cell.isPlaceholder) {
                            return null;
                          }
                          return (
                            <td
                              className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 align-middle"
                              {...cell.getCellProps()}
                            >
                              <span>
                                {cell.isAggregated
                                  ? cell.render("Aggregated")
                                  : cell.isPlaceholder
                                  ? null
                                  : cell.render("Cell")}
                              </span>
                            </td>
                          );
                        })}
                      </tr>
                      {row.isExpanded ? (
                        <>
                          {SubComponent && (
                            <tr className="bg-white border-b border-t border-gray-200">
                              <td colSpan={visibleColumns.length}>
                                {renderRowSubComponent({ row })}
                              </td>
                            </tr>
                          )}
                        </>
                      ) : null}
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
