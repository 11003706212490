import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/outline";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPaymentStatus,
  InitialWalletState,
} from "../../../domains/Reseller/Wallet";
import { getStatus } from "../../../utils/app";
import { DescriptionPanel } from "../../App";
import _ from "lodash";

interface PayoutDetailsProps {
  payoutDetails: any;
}
export const QueryStatus: FC<PayoutDetailsProps> = ({ payoutDetails }) => {
  const dispatch = useDispatch();

  const { paymentStatus } = useSelector(
    (state: { resellerWallet: InitialWalletState }) => state.resellerWallet
  );

  useEffect(() => {
    const fetchPayoutAsync = async () => {
      if (!_.isEmpty(payoutDetails)) {
        await dispatch(
          fetchPaymentStatus(payoutDetails?.org?.id, payoutDetails?.id)
        );
      }
    };
    fetchPayoutAsync();
  }, [dispatch, payoutDetails?.id, payoutDetails?.org?.id, payoutDetails]);
  return (
    <div>
      <div className="w-full">
        {Object.keys(paymentStatus).length === 0 ? (
          <div>
            <p className="text-center mt-20">No status query result</p>
          </div>
        ) : (
          <div className="mx-auto w-full bg-white">
            <div className="mb-5">
              <DescriptionPanel label="ID" description={paymentStatus?.id} />
              <DescriptionPanel
                label="Reseller ref"
                description={paymentStatus?.reference}
              />
              <DescriptionPanel
                label="Receipt"
                description={paymentStatus?.receiptNumber}
              />
              <DescriptionPanel
                label="Command ID"
                description={paymentStatus?.commandId}
              />
              <DescriptionPanel
                label="SP ID"
                description={paymentStatus?.serviceProviderId}
              />
              <DescriptionPanel
                label="Status"
                description={getStatus(paymentStatus?.status)}
              />
              <DescriptionPanel
                label="Message"
                description={paymentStatus?.message}
              />
              <DescriptionPanel
                label="Details"
                description={paymentStatus?.details}
              />
              <DescriptionPanel
                label="Aggregator/PSP ref"
                description={paymentStatus?.gwReference}
              />
            </div>
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between rounded bg-gray-100 px-4 py-2 text-left text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                    <span>Request Parameters</span>
                    <ChevronUpIcon
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-gray-900`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                    <table className="table-auto">
                      <thead>
                        <tr>
                          <th className="px-4 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            ID
                          </th>
                          <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Label
                          </th>
                          <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Value
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {paymentStatus?.requestParameters?.map((doc: any) => (
                          <tr>
                            <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                              {doc?.id}
                            </td>
                            <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                              {doc?.label}
                            </td>
                            <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                              {doc?.value}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure as="div" className="mt-2">
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between rounded bg-gray-100 px-4 py-2 text-left text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                    <span>Reference Parameters</span>
                    <ChevronUpIcon
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-gray-500`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th className="px-4 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            ID
                          </th>
                          <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Label
                          </th>
                          <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Value
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {paymentStatus?.referenceParameters?.map((doc: any) => (
                          <tr>
                            <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                              {doc?.id}
                            </td>
                            <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                              {doc?.label}
                            </td>
                            <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                              {doc?.value}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure as="div" className="mt-2">
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between rounded bg-gray-100 px-4 py-2 text-left text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                    <span>Result Parameters</span>
                    <ChevronUpIcon
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-gray-500`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                    <table className="table-fixed">
                      <thead>
                        <tr>
                          <th className="px-4 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            ID
                          </th>
                          <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Label
                          </th>
                          <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Value
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {paymentStatus?.resultParameters?.map((doc: any) => (
                          <tr>
                            <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                              {doc?.id}
                            </td>
                            <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                              {doc?.label}
                            </td>
                            <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                              {doc?.value}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        )}
      </div>
    </div>
  );
};
