import axios, { AxiosAdapter } from "axios";
import { cacheAdapterEnhancer } from "axios-extensions";
import { getAuthToken } from "./auth";
import { getBaseUrl } from "./env";

const API = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    "Content-Type": "application/json",
  },
  adapter: cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, {
    enabledByDefault: false,
  }),
});

API.interceptors.request.use(function (config) {
  const token = getAuthToken();
  config.headers.Authorization = token !== null ? `Bearer ${token}` : "";
  return config;
});

export default API;
