import { FC, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { TextField, TextArea } from "../../App/FormComponents";
import { Modal } from "../../App";
import {
  InitialUserStateProps,
  toggleUserModal,
  updateGroup,
} from "../../../domains/Users";
import { openNotification } from "../../../domains/App";
import { schemaValidation } from "../../../constants";
import _ from "lodash";

interface ContactInput {
  name: string;
  description: string;
}

interface EditGroupFormProps {
  group: any;
}

export const EditGroupForm: FC<EditGroupFormProps> = ({ group }) => {
  const dispatch = useDispatch();
  const { userModals, userLoader } = useSelector(
    (state: { users: InitialUserStateProps }) => state.users
  );
  const { control, handleSubmit, reset } = useForm<ContactInput>({
    resolver: joiResolver(schemaValidation.GroupSchema),
    defaultValues: {
      name: "",
      description: "",
    },
  });

  useEffect(() => {
    if (!_.isEmpty(group)) {
      reset({
        name: group?.name || "",
        description: group?.description || "",
      });
    }
  }, [group, reset]);

  const onClose = async () => {
    reset();
    await dispatch(
      toggleUserModal({
        editGroup: false,
      })
    );
  };

  const onSubmit: SubmitHandler<ContactInput> = async (data) => {
    try {
      await dispatch(updateGroup(group?.id, data));
      await onClose();
      await dispatch(
        openNotification({
          openNotification: true,
          type: "success",
          message: "Group updated!",
        })
      );
    } catch (e) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Something went wrong",
        })
      );
    }
  };

  return (
    <Modal
      formId="edit_group_form"
      btnType="submit"
      modalTitle="Update Group"
      isOpen={userModals.editGroup}
      onClose={onClose}
      btnLoading={userLoader.submit}
    >
      <form
        id="edit_group_form"
        className="space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="name"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="name"
              label="Name"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="description"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextArea
              id="description"
              label="Description"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
      </form>
    </Modal>
  );
};
