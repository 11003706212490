import { FC, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { CheckBox2, Select, TextField } from "../../App/FormComponents";
import { Modal } from "../../App";
import {
  InitialTransactionFeeRateState,
  toggleTransactionFeeRateModal,
  updateTransactionFeeRate,
} from "../../../domains/TransactionFeeRate";
import { openNotification } from "../../../domains/App";
import { schemaValidation } from "../../../constants";
import _ from "lodash";

interface TransactionFeeRate {
  feeType: string;
  feeAmount: number;
  startingInclusive: number;
  endingInclusive: number;
  enabled: boolean;
  collectionType: string;
}

interface EditTransactionFeeRateFormProps {
  transactionFeeRate: any;
}

export const EditTransactionFeeRateForm: FC<EditTransactionFeeRateFormProps> =
  ({ transactionFeeRate }) => {
    const dispatch = useDispatch();
    const { transactionFeeRateModals, transactionFeeRatesLoaders } =
      useSelector(
        (state: { transactionFeeRate: InitialTransactionFeeRateState }) =>
          state.transactionFeeRate
      );
    const { control, handleSubmit, reset } = useForm<TransactionFeeRate>({
      resolver: joiResolver(schemaValidation.TransactionFeeRateSchema),
      defaultValues: {
        feeType: "",
        feeAmount: 0,
        startingInclusive: 0,
        endingInclusive: 0,
        enabled: false,
        collectionType: "MARK_UP",
      },
    });

    useEffect(() => {
      if (!_.isEmpty(transactionFeeRate)) {
        reset({
          feeType: transactionFeeRate?.feeType || "",
          feeAmount: transactionFeeRate?.feeAmount || 0,
          startingInclusive: transactionFeeRate?.startingInclusive || 0,
          endingInclusive: transactionFeeRate?.endingInclusive || 0,
          enabled: transactionFeeRate?.enabled || false,
          collectionType: "MARK_UP",
        });
      }
    }, [transactionFeeRate, reset]);

    const onClose = async () => {
      reset();
      await dispatch(
        toggleTransactionFeeRateModal({
          editTransactionFeeRate: false,
        })
      );
    };

    const onSubmit: SubmitHandler<TransactionFeeRate> = async (props) => {
      try {
        transactionFeeRate = {
          ...transactionFeeRate,
          feeType: props?.feeType,
          feeAmount: props?.feeAmount,
          startingInclusive: props?.startingInclusive,
          endingInclusive: props?.endingInclusive,
          enabled: props?.enabled,
        };
        await dispatch(updateTransactionFeeRate(transactionFeeRate, props));
        await onClose();
        await dispatch(
          openNotification({
            openNotification: true,
            type: "success",
            message: "Transaction Fee Rate updated!",
          })
        );
      } catch (e) {
        dispatch(
          openNotification({
            openNotification: true,
            type: "error",
            message: "Something went wrong",
          })
        );
      }
    };
    return (
      <Modal
        formId="edit_transaction_fee_rate_form"
        btnType="submit"
        modalTitle="Edit Transaction Fee Rate"
        isOpen={transactionFeeRateModals.editTransactionFeeRate}
        onClose={onClose}
        btnLoading={transactionFeeRatesLoaders.submit}
      >
        <form
          id="edit_transaction_fee_rate_form"
          className="space-y-2"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            control={control}
            name="feeType"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Select
                id="feeType"
                label="Fee Type"
                selectedOption={value}
                error={error?.message}
                onChange={onChange}
                options={[
                  {
                    value: "PERCENTAGE",
                    label: "PERCENTAGE",
                  },
                  {
                    value: "ABS_VAL",
                    label: "ABS_VAL",
                  },
                  // {
                  //   value: "RATIO",
                  //   label: "RATIO",
                  // },
                  {
                    value: "NONE",
                    label: "NONE",
                  },
                ]}
              />
            )}
          />

          <Controller
            control={control}
            name="feeAmount"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id="feeAmount"
                label="Fee Amount"
                type="text"
                value={value}
                error={error?.message}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="startingInclusive"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id="startingInclusive"
                label="Starting Inclusive"
                type="text"
                value={value}
                error={error?.message}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="endingInclusive"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id="endingInclusive"
                label="Ending Inclusive"
                type="text"
                value={value}
                error={error?.message}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="enabled"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <CheckBox2
                label="Enabled"
                value={value}
                onChange={onChange}
                name="enabled"
              />
            )}
          />

          <Controller
            control={control}
            name="collectionType"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id="collectionType"
                type="hidden"
                value={value}
                error={error?.message}
                onChange={onChange}
              />
            )}
          />
        </form>
      </Modal>
    );
  };
