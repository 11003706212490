import classNames from "classnames";
import { FC, useMemo } from "react";
import { usePagination, useTable } from "react-table";
import { Pagination } from "./Pagination";

interface TableProps {
  data: any[];
  columns: any[];
  currentPage?: number;
  onPaginationChange?: (page: number) => void;
  pageCount?: number;
  withPagination?: boolean;
  title?: string;
}

export const Table: FC<TableProps> = ({
  data,
  columns,
  currentPage = 0,
  pageCount: controlledPageCount,
  withPagination = true,
  title,
  onPaginationChange,
}) => {
  const memoizedColumns = useMemo(() => columns, [columns]);
  const memoizedData = useMemo(() => data, [data]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex },
  } = useTable(
    {
      columns: memoizedColumns,
      data: memoizedData,
      initialState: { pageIndex: currentPage }, // Pass our hoisted table state
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    usePagination
  );

  return (
    <div className="flex flex-col">
      <div className="pb-2 flex flex-row-reverse justify-between">
        {title && (
          <span className="text-xl font-semibold text-gray-700 pt-2 ml-6">
            {title}
          </span>
        )}
      </div>
      <div className="overflow-x-auto">
        <div className="py-2 align-middle inline-block min-w-full">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table
              className="min-w-full divide-y divide-gray-200"
              {...getTableProps()}
            >
              <thead>
                {headerGroups.map((headerGroup: any) => (
                  <tr
                    className="bg-gray-50"
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column: any) => (
                      <th
                        scope="col"
                        style={{ color: "#111827" }}
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        {...column.getHeaderProps()}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {page.map((row: any, i: number) => {
                  const isEven = i % 2 === 0;
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      className={classNames({
                        "bg-gray-50": isEven,
                        "bg-white": !isEven,
                      })}
                    >
                      {row.cells.map((cell: any) => {
                        return (
                          <td
                            className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {withPagination && (
        <div className="flex flex-row-reverse justify-between">
          <Pagination
            currentPage={pageIndex + 1}
            totalPages={controlledPageCount}
            onPaginationChange={onPaginationChange}
          />
        </div>
      )}
    </div>
  );
};
