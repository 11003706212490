import { createReducer } from "@reduxjs/toolkit";
import { Types } from "./actionTypes";
import { PaginationProps } from "../App";
import _ from "lodash";

export interface InitialIntgState {
  cache: Record<string, any>;
  productIntgs: any[];
  selectedIntg: any;
  intgsPagination: PaginationProps;
  intgModals: any;
  intgsLoaders: any;
}

const initialState: InitialIntgState = {
  productIntgs: [],
  intgsPagination: {
    number: 0,
    totalPages: 1,
    size: 10,
    totalElements: 0,
  },
  selectedIntg: {},
  intgsLoaders: {
    submit: false,
    fetchIntgs: false,
  },
  intgModals: {
    addIntg: false,
    editIntg: false,
    deleteIntg: false,
    disableIntg: false,
  },
  cache: {}
};

export const intgsReducer = createReducer(initialState, {

  [Types.TOGGLE_INTG_MODALS]: (state, action) => {
    state.intgModals = {
      ...state.intgModals,
      ...action.payload,
    };
  },

  [Types.FETCH_PRODUCT_INTGS]: (state) =>
  {
    state.intgsLoaders.fetchIntgs = true;
  },
  [Types.FETCH_PRODUCT_INTGS_SUCCESS]: (state, action) =>
  // {
  //   const {
  //     data
  //   } = action.payload;
  //   state.intgsLoaders.fetchIntgs = false;
  //   state.productIntgs = data;
  // },
    {
    const {
      data: { content, page },
    } = action.payload;
    state.intgsLoaders.fetchIntgs = false;
    state.productIntgs = content;
    state.intgsPagination = {
      number: page.number,
      size: page.size,
      totalPages: page.totalPages,
      totalElements: page.totalElements,
    };
  },
  [Types.FETCH_PRODUCT_INTGS_FAIL]: (state) =>
  {
    state.intgsLoaders.fetchIntgs = false;
  },

  [Types.CREATE_INTG]: (state) => {
    state.intgsLoaders.submit = true;
  },
  [Types.CREATE_INTG_SUCCESS]: (state, action) => {
    state.intgsLoaders.submit = false;
    state.productIntgs = [action.payload.data, ...state.productIntgs];
  },
  [Types.CREATE_INTG_FAIL]: (state) => {
    state.intgsLoaders.submit = false;
  },


  [Types.DELETE_INTG]: (state) => {
    state.intgsLoaders.submit = true;
  },
  [Types.DELETE_INTG_SUCCESS]: (state, action) => {
    const {
      previousAction: {
        payload: {
          request: {
            deleteProp: { id },
          },
        },
      }, 
    } = action.meta;
    state.productIntgs = [
      ...state.productIntgs.filter((intg) => intg.id !== id),
    ];
    if (_.isEmpty(state.productIntgs)) {
      state.intgsPagination.number = 0;
    }
    state.intgsLoaders.submit = false;
  },
  [Types.DELETE_INTG_FAIL]: (state) => {
    state.intgsLoaders.submit = false;
  },

  [Types.UPDATE_INTG]: (state) => {
    state.intgsLoaders.submit = true;
  },
  [Types.UPDATE_INTG_SUCCESS]: (state, action) => {
    
    const {
      previousAction: {
        payload: {
          request: {
            intg: { id },
            intg,
          },
        },
      },
    } = action.meta;
    state.intgsLoaders.submit = false;
    state.productIntgs = [
      ...state.productIntgs.map((integration) =>
        integration.id === id ? intg : integration
      ),
    ];
  },
  [Types.UPDATE_INTG_FAIL]: (state) => {
    state.intgsLoaders.submit = false;
  },

  [Types.CHANGE_INTG_STATUS]: (state) =>
  {
    state.intgsLoaders.submit = true;
  },

  [Types.CHANGE_INTG_STATUS_SUCCESS]: (state, action) =>
  {
    const {
      previousAction: {
        payload: {
          request: {
            selectedIntg: { id },
            selectedIntg,
          },
        },
      },
    } = action.meta;
    state.intgsLoaders.submit = false;
    state.productIntgs = [
      ...state.productIntgs.map((intg) =>
        intg.id === id ? selectedIntg : intg
      ),
    ];
  },
  [Types.CHANGE_INTG_STATUS_FAIL]: (state) =>
  {
    state.intgsLoaders.submit = false;
  },
});
