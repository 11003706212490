export const Types: Record<string, string> = {
  TOGGLE_DFSTYPE_MODALS: "TOGGLE_DFSTYPE_MODALS",

  FETCH_DFSTYPES: "FETCH_DFSTYPES",
  FETCH_DFSTYPES_SUCCESS: "FETCH_DFSTYPES_SUCCESS",
  FETCH_DFSTYPES_FAIL: "FETCH_DFSTYPES_FAIL",

  CREATE_DFSTYPE: "CREATE_DFSTYPE",
  CREATE_DFSTYPE_SUCCESS: "CREATE_DFSTYPE_SUCCESS",
  CREATE_DFSTYPE_FAIL: "CREATE_DFSTYPE_FAIL",

  UPDATE_DFSTYPE: "UPDATE_DFSTYPE",
  UPDATE_DFSTYPE_SUCCESS: "UPDATE_DFSTYPE_SUCCESS",
  UPDATE_DFSTYPE_FAIL: "UPDATE_DFSTYPE_FAIL",

  DELETE_DFSTYPE: "DELETE_DFSTYPE",
  DELETE_DFSTYPE_SUCCESS: "DELETE_DFSTYPE_SUCCESS",
  DELETE_DFSTYPE_FAIL: "DELETE_DFSTYPE_FAIL",
};
