import { CashIcon } from "@heroicons/react/outline";
import { FC } from "react";
import { Loader } from "../../App";

interface WalletCardProps {
  control?: any;
  wallet: any;
  loading: boolean;
  title: string;
  Filter?: React.FC<{}>;
}
export const WalletCard: FC<WalletCardProps> = ({ loading, wallet }) => {
  if (loading) {
    return (
      <div className="mt-4 rounded-lg flex flex-row items-center justify-center w-full h-60 bg-white">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="my-2 grid grid-cols-1  lg:grid-cols-2 md:grid-cols-2 gap-3">
        {wallet[0]?.balances.map((row: any) => (
          <div className={`p-3 bg-white shadow-sm rounded`}>
            <div className="flex justify-between py-4">
              <div className="flex">
                <div className="h-16 w-16 bg-green-100 rounded-full center-contents">
                  <CashIcon className="h-6 text-tandaGreen w-6" />
                </div>

                <div className="px-2 pb-3 flex flex-col justify-center align-middle  ">
                  <p className="text-tandaGreen mb-2 capitalize">
                    KES {row?.available}
                  </p>
                  <p className="text-xl mb-1">{row?.name}</p>
                  <p className="text-xs"></p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
