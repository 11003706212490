import { useState, FC } from "react";
import classNames from "classnames";
import { Button } from ".";
import { VariablesModal } from "../Messaging";

interface TemplateEditorProps {
  error?: string;
  id: string;
  label?: string;
  rows?: number;
  placeholder?: string;
  onChange?: (value: any) => void;
  value?: string;
  isTemplateContext?: boolean;
}

export const TemplateEditor: FC<TemplateEditorProps> = ({
  error,
  placeholder,
  id,
  label,
  rows = 4,
  onChange,
  value,
}) => {
  let textareaRef: HTMLTextAreaElement | null = null;
  const [modalState, setModalState] = useState({
    openVariables: false,
  });

  const handleOpenVariables = () =>
    setModalState({
      ...modalState,
      openVariables: true,
    });
  const handleCloseVariables = () =>
    setModalState({
      ...modalState,
      openVariables: false,
    });

  const handleVariable = (variable: string) => {
    const startPosition = textareaRef?.selectionStart;
    const variableString = "${" + variable?.replaceAll(".", "_") + "}";
    const newText = `${value?.slice(
      0,
      startPosition
    )}${variableString} ${value?.slice(startPosition)}`;
    onChange?.(newText);
  };

  return (
    <div className="d-flex flex-col w-full">
      <div className="flex">
        {label && (
          <label
            htmlFor={id}
            className="block text-sm font-medium text-gray-700"
          >
            {label}
          </label>
        )}
      </div>
      <div
        className={classNames(
          "shadow-sm bg-white block w-full border rounded-md",
          {
            "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500":
              !!error,
          }
        )}
      >
        {modalState.openVariables && (
          <VariablesModal
            isOpen={modalState.openVariables}
            onClose={handleCloseVariables}
            onVariableChange={handleVariable}
          />
        )}
        <div className="w-full flex border-b border-gray-300 text-gray-600 p-2 space-x-2">
          <Button size="xs" variant="secondary" onClick={handleOpenVariables}>
            Variables
          </Button>
        </div>
        <textarea
          id={id}
          ref={(node) => (textareaRef = node)}
          rows={rows}
          className={classNames("w-full border-0 rounded text-sm", {
            "focus:ring-0 focus:border-0": !error,
          })}
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange?.(e.target.value)}
        />
      </div>
      {error && (
        <p className="m-1 text-sm text-red-600" id={`${id}-error`}>
          {error}
        </p>
      )}
    </div>
  );
};
