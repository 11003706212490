import { FC } from "react";
import {
  useForm,
  FormProvider,
  SubmitHandler,
  Controller,
} from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Banners, GoBackLink, Button } from "../../App";
import {
  InitialMessageState,
  createEventListener,
  inAppRecipients,
  eventTypes,
  initiatorTypes,
} from "../../../domains/Messages";
import { openNotification } from "../../../domains/App";
import { PickServiceProvider } from "../Campaign/ServiceProvider";
import { Editor } from "../Campaign/Editor";
import { DataEntry, DropdownDataEntry, formatEventData } from "../Campaign";
import { schemaValidation } from "../../../constants";
import { Select, TextField } from "../../App/FormComponents";
import { DescriptionPanel } from "../Messages";
import { Selectors } from "./Selectors";

interface EventListener {
  name: string;
  eventType: string;
  initiatorTypes: string[];
  selectorTypes: any[];
  smsBody: string;
  smsServiceProvider: string;
  emailBody: string;
  emailSubject: string;
  emailServiceProvider: string;
  inAppBody: string;
  inAppSubject: string;
  inAppRecipient: string;
  inAppServiceProvider: string;
  systemBody: string;
  systemSubject: string;
  systemTo: string;
  systemCC: string[];
  systemBCC: string[];
  systemServiceProvider: string;
  customerBody: string;
  customerServiceProvider: string;
}

export const CreateEventListenersPage: FC = () => {
  const dispatch = useDispatch();
  const { messageLoaders } = useSelector(
    (state: { message: InitialMessageState }) => state.message
  );

  const methods = useForm<EventListener>({
    resolver: joiResolver(schemaValidation.EventListenerSchema),
    defaultValues: {
      name: "",
      eventType: "",
      initiatorTypes: [],
      systemBCC: [],
      systemCC: [],
      selectorTypes: [],
    },
  });

  const onSubmit: SubmitHandler<EventListener> = async (data) => {
    const eventData = formatEventData(data);
    try {
      await dispatch(createEventListener(eventData));
      await dispatch(
        openNotification({
          openNotification: true,
          type: "success",
          message: "Event created!",
        })
      );
      methods.reset();
    } catch (e) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Something went wrong, event not created!",
        })
      );
    }
  };

  return (
    <Layout>
      <Banners>
        <GoBackLink path="/messages/event-listeners" />
      </Banners>
      <div>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 divide-y divide-gray-300">
              <DescriptionPanel label="Name">
                <Controller
                  name="name"
                  control={methods.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <TextField
                        id="name"
                        type="text"
                        value={value}
                        error={error?.message}
                        onChange={onChange}
                      />
                    );
                  }}
                />
              </DescriptionPanel>
              <DescriptionPanel label="Event Type">
                <Controller
                  name="eventType"
                  control={methods.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Select
                        id="eventType"
                        selectedOption={value}
                        options={eventTypes}
                        error={error?.message}
                        onChange={onChange}
                      />
                    );
                  }}
                />
              </DescriptionPanel>
              <DropdownDataEntry
                label="Initiator Types"
                formValue="initiatorTypes"
                options={initiatorTypes}
              />
              <Selectors label="Selectors" formValue="selectorTypes" />
              <PickServiceProvider
                id="smsServiceProvider"
                label="Sms Service Provider"
              />
              <Editor id="smsBody" label="SMS Body" />
              <PickServiceProvider
                id="emailServiceProvider"
                label="Email Service Provider"
              />
              <DescriptionPanel label="System Subject">
                <Controller
                  name="emailSubject"
                  control={methods.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <TextField
                        id="emailSubject"
                        type="text"
                        value={value}
                        error={error?.message}
                        onChange={onChange}
                      />
                    );
                  }}
                />
              </DescriptionPanel>
              <Editor id="emailBody" label="Email Body" />
              <PickServiceProvider
                id="inAppServiceProvider"
                label="InApp Service Provider"
              />
              <DescriptionPanel label="InApp Recipient">
                <Controller
                  name="inAppRecipient"
                  control={methods.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Select
                        id="inAppRecipient"
                        selectedOption={value}
                        options={inAppRecipients}
                        error={error?.message}
                        onChange={onChange}
                      />
                    );
                  }}
                />
              </DescriptionPanel>
              <DescriptionPanel label="InApp Subject">
                <Controller
                  name="inAppSubject"
                  control={methods.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <TextField
                        id="inAppSubject"
                        type="text"
                        value={value}
                        error={error?.message}
                        onChange={onChange}
                      />
                    );
                  }}
                />
              </DescriptionPanel>
              <Editor id="inAppBody" label="InApp Body" />
              <PickServiceProvider
                id="systemServiceProvider"
                label="System Service Provider"
              />
              <DescriptionPanel label="System Subject">
                <Controller
                  name="systemSubject"
                  control={methods.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <TextField
                        id="systemSubject"
                        type="text"
                        value={value}
                        error={error?.message}
                        onChange={onChange}
                      />
                    );
                  }}
                />
              </DescriptionPanel>
              <DescriptionPanel label="System To Email">
                <Controller
                  name="systemTo"
                  control={methods.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <TextField
                        id="systemTo"
                        type="text"
                        value={value}
                        error={error?.message}
                        onChange={onChange}
                      />
                    );
                  }}
                />
              </DescriptionPanel>
              <DataEntry label="System CC Emails" formValue="systemCC" />
              <DataEntry label="System BCC Emails" formValue="systemBCC" />
              <Editor id="systemBody" label="System Message" />
              <PickServiceProvider
                id="customerServiceProvider"
                label="Customer Service Provider"
              />
              <Editor id="customerBody" label="Customer Body" />
              <div className="flex flex-row justify-end pt-2">
                <Button type="submit" loading={messageLoaders.submit}>
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </Layout>
  );
};
