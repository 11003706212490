import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader, Table } from "../../App";
import { PaginationProps } from "../../../domains/App";
import { RadioBox } from "../../App/FormComponents";
import { EditIOWalletForm } from "./EditIOWallet";
import {
  changeStatusIOWallet,
  InitialIOWalletState,
  toggleIOWalletModal,
  updateSelectedIOWallet,
} from "../../../domains/IOWallets";
import { ToggleModal } from "../../App/ToggleModal";
import { useHistory } from "react-router";
import { ToggleButton } from "../../App/FormComponents/ToggleButton";

interface IOWalletTableProps {
  control?: any;
  columns: any[];
  data: any[];
  onChange?: (value: string) => void;
  onPaginationChange?: (page: number) => void;
  pagination: PaginationProps;
  loading: boolean;
  title: string;
}

export const IOWalletTable: FC<IOWalletTableProps> = ({
  columns,
  data,
  onChange = (value) => {},
  onPaginationChange,
  pagination,
  loading,
  title,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let [selectedIOWallet, setSelectedIOWallet] = useState<any>();

  const { iowalletModals, iowalletsLoaders } = useSelector(
    (state: { iowallet: InitialIOWalletState }) => state.iowallet
  );

  const handleOpenModal = (id: string) => {
    setSelectedIOWallet(id);
    dispatch(
      toggleIOWalletModal({
        disableIOWallet: true,
      })
    );
  };

  const goToPath = (iowallet: any) => () => {
    dispatch(updateSelectedIOWallet(iowallet));
    history.push(`/tanda-io/${iowallet.id}/gateways`);
  };

  const handleCloseModal = () =>
    dispatch(
      toggleIOWalletModal({
        disableIOWallet: false,
      })
    );
  // const handleEditModal = (value: any) =>
  // {
  //   setSelectedIOWallet(value);
  //   dispatch(
  //     toggleIOWalletModal({
  //       editIOWallet: true,
  //     })
  //   );
  // };

  const onToggleStatus = async () => {
    if (!!selectedIOWallet) {
      const status = {
        enabled: !selectedIOWallet.enabled,
        available: !selectedIOWallet.available,
      };
      selectedIOWallet = {
        ...selectedIOWallet,
        enabled: !selectedIOWallet.enabled,
        available: !selectedIOWallet.available,
      };
      await dispatch(changeStatusIOWallet(selectedIOWallet, status));
    }
    handleCloseModal();
  };

  const status = "Change availability";
  const message = "Are you sure you want to " + status + " this iowallet?";

  const getColumns = () => {
    const availableColumns = [
      {
        id: "name",
        Header: "Name",
        accessor: (row: any) => (
          <div>
            <span
              className="cursor-pointer underline text-tandaPurple"
              onClick={goToPath(row)}
            >
              {row.name}
            </span>
          </div>
        ),
      },
      {
        id: "minimunValue",
        Header: "Minimum(KES)",
        accessor: (row: any) => {
          return <span>{(row?.minimumValue).toFixed(2)}</span>;
        },
      },
      {
        id: "maximumValue",
        Header: "Maximum(KES)",
        accessor: (row: any) => {
          return <span>{(row?.maximumValue).toFixed(2)}</span>;
        },
      },
      {
        id: "sp",
        Header: "Service provider",
        accessor: (row: any) => {
          return <span>{row?.sp?.name}</span>;
        },
      },
      {
        id: "enabled",
        Header: "Status",
        accessor: (row: any) => {
          return <span>{row?.enabled ? "Enabled" : "Disabled"}</span>;
        },
      },
      {
        id: "available",
        Header: "Availability",
        accessor: (row: any) => {
          return <span>{row?.available ? "Available" : "Unavailable"}</span>;
        },
      },
      {
        id: "actions",
        accessor: (row: any) => {
          return (
            <div className="flex flex-row items-center space-x-2">
              {row?.available ? (
                <ToggleButton
                  onChange={() => handleOpenModal(row)}
                  name={"status"}
                  value={row?.enabled}
                >
                  Unavailable?
                </ToggleButton>
              ) : (
                <ToggleButton
                  onChange={() => handleOpenModal(row)}
                  name={"status"}
                  value={row?.enabled}
                >
                  Available?
                </ToggleButton>
              )}
            </div>
          );
        },
      },
      {
        id: "select",
        accessor: (row: any) => {
          return (
            <RadioBox
              name="serviceProvider"
              onChange={onChange}
              value={JSON.stringify(row)}
            />
          );
        },
      },
    ];

    return columns.map((column) =>
      availableColumns.find(
        (availableColumn) => availableColumn.id === column.id
      )
    );
  };

  if (loading) {
    return (
      <div className="mt-4 rounded-lg flex flex-row items-center justify-center w-full h-60 bg-white">
        <Loader />
      </div>
    );
  }
  return (
    <>
      <ToggleModal
        isOpen={iowalletModals.disableIOWallet}
        onClose={handleCloseModal}
        onSubmit={onToggleStatus}
        message={message}
        title={status}
        loading={iowalletsLoaders.submit}
      />
      <EditIOWalletForm iowallet={selectedIOWallet} />

      <Table
        title={title}
        columns={getColumns()}
        data={data}
        pageCount={pagination.totalPages}
        currentPage={pagination.number}
        onPaginationChange={onPaginationChange}
      />
    </>
  );
};
