import { FC } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { TabItemProps } from "../../domains/App";
import { InitialResellerState } from "../../domains/Reseller/Reseller";
import { InitialResellerDfsState } from "../../domains/Reseller/ResellerDfs";
import { changeCase, checkValue } from "../../utils/app";
import { GoBackLink, RoundedTabs } from "../App";

export const ResellerDfsTabs: FC = () => {
  const { resellerId = "" } = useParams<{ resellerId?: string }>();
  const { dfsId = "" } = useParams<{ dfsId?: string }>();
  let { selectedResellerDfs } = useSelector(
    (state: { resellerDfs: InitialResellerDfsState }) => state.resellerDfs
  );
  let { selectedReseller } = useSelector(
    (state: { reseller: InitialResellerState }) => state.reseller
  );

  const selectedResellerName = localStorage.getItem("selectedWallet");

  const viewHOTabs: TabItemProps[] = [
    {
      key: "reseller_products_commission_rate",
      label: "Commission Rate",
      path: `/tanda-io/resellers/${resellerId}/io-products/${dfsId}/commissions`,
    },
    {
      key: "reseller_products_fees_rate",
      label: "Fee Rates",
      path: `/tanda-io/resellers/${resellerId}/io-products/${dfsId}/fees`,
    },
    {
      key: "reseller_products_gateway",
      label: "Gateway",
      path: `/tanda-io/resellers/${resellerId}/io-products/${dfsId}/gateway`,
    },
  ];

  return (
    <>
      <div className="bg-white flex justify-between items-center px-3">
        <div className="font-semibold text-lg">
          {checkValue(
            changeCase(selectedReseller?.name || selectedResellerName)
          )}
          {" - "}
          {checkValue(
            changeCase(selectedResellerDfs?.dfs?.name || selectedResellerName)
          )}
        </div>
        <div className="md:-ml-32">
          <RoundedTabs tabs={viewHOTabs} />
        </div>
        <div>
          <GoBackLink
            path={`/tanda-io/resellers/${resellerId}/io-products`}
            label="Back"
          />
        </div>
      </div>
    </>
  );
};
