import { CogIcon } from "@heroicons/react/outline";
import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchReseller,
  InitialResellerState,
} from "../../../domains/Reseller/Reseller";
import { Loader } from "../../App";
import { TextField } from "../../App/FormComponents";

export const Info: FC = () => {
  const dispatch = useDispatch();
  let { reseller, selectedReseller, resellersLoaders } = useSelector(
    (state: { reseller: InitialResellerState }) => state.reseller
  );

  useEffect(() => {
    const fetchResellerAsync = async () => {
      dispatch(fetchReseller(selectedReseller?.id));
    };

    fetchResellerAsync();
  }, [dispatch, selectedReseller?.id]);

  return (
    <>
      {resellersLoaders.fetchReseller ? (
        <div className="mt-4 rounded-lg flex flex-row items-center justify-center w-full h-60 bg-white">
          <Loader />
        </div>
      ) : (
        <div>
          <div>
            <div className="w-full center-contents">
              <div className="h-20 bg-gray-100 w-20 center-contents rounded-full">
                <CogIcon className="h-12 text-gray-300 w-12" />
              </div>
            </div>
            <div className="my-3">
              <div className="font-bold mt-5 text-tandaPurple">Business</div>
              <div className="mt-3 grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 gap-4">
                <TextField
                  id="account-name"
                  label="Name"
                  type="text"
                  value={reseller?.name}
                  disabled
                  onChange={(value) => {}}
                />
                <TextField
                  id="status"
                  label="Status"
                  type="text"
                  value={reseller?.status}
                  disabled
                  onChange={(value) => {}}
                />
              </div>
              <div className="font-bold mt-5 text-tandaPurple">
                Nominated Director
              </div>
              <div className="mt-3 grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-4">
                <TextField
                  id="first-name"
                  label="First name"
                  type="text"
                  value={reseller?.owner?.firstName}
                  disabled
                  onChange={(value) => {}}
                />
                <TextField
                  id="other-names"
                  label="Other names"
                  type="text"
                  value={reseller?.owner?.otherNames}
                  disabled
                  onChange={(value) => {}}
                />
                <TextField
                  id="email"
                  label="Email"
                  type="text"
                  value={reseller?.owner?.emailAddress}
                  disabled
                  onChange={(value) => {}}
                />
                <TextField
                  id="national-id"
                  label="National ID"
                  type="text"
                  value={reseller?.owner?.nationalId}
                  disabled
                  onChange={(value) => {}}
                />
                <TextField
                  id="phone"
                  label="Phone"
                  type="text"
                  value={"+" + reseller?.owner?.phoneNumber}
                  disabled
                  onChange={(value) => {}}
                />
                <TextField
                  id="status"
                  label="Status"
                  type="text"
                  value={reseller?.owner?.status}
                  disabled
                  onChange={(value) => {}}
                />
              </div>
            </div>
            <div className="mb-4">
              {reseller?.ids?.map((doc: any) => (
                <div key={doc.id}>
                  <div className="font-bold mt-5 mb-4 text-tandaPurple">
                    {doc.typeName}
                  </div>
                  <div className="mt-3 grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 gap-4">
                    <TextField
                      id="id-num"
                      label="Number"
                      type="text"
                      value={doc.idNumber}
                      onChange={(value) => {}}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
