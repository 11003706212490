import { getURL } from "../../../utils";
import { Types } from "./actionTypes";

export const toggleResellerModal = (payload: any) => async (dispatch: any) =>
  dispatch({
    type: Types.TOGGLE_RESELLER_MODALS,
    payload,
  });

export const updateSelectedReseller =
  (payload: any) => async (dispatch: any) => {
    await dispatch({
      type: Types.UPDATE_SELECTED_RESELLER,
      payload,
    });
  };

export const updateSelectedApiClient =
  (payload: any) => async (dispatch: any) => {
    await dispatch({
      type: Types.UPDATE_SELECTED_API_CLIENT,
      payload,
    });
  };

export const updateSelectedApiClientRoles =
  (payload: any) => async (dispatch: any) => {
    await dispatch({
      type: Types.UPDATE_SELECTED_API_CLIENT_ROLES,
      payload,
    });
  };

export const fetchResellers = (pagination?: any) => async (dispatch: any) =>
  await dispatch({
    type: Types.FETCH_RESELLERS,
    payload: {
      request: {
        method: "GET",
        url: getURL(`/io-accounts/v2/organizations`, pagination),
        useCache: true,
      },
    },
  });

export const fetchResellersBalances =
  (pagination?: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.FETCH_RESELLERS_BALANCES,
      payload: {
        request: {
          method: "GET",
          url: getURL(`io-wallets/v1/wallets/balances`, pagination),
          useCache: true,
        },
      },
    });

export const fetchReseller = (resellerId: string) => async (dispatch: any) =>
  await dispatch({
    type: Types.FETCH_RESELLER,
    payload: {
      request: {
        method: "GET",
        url: getURL(`/io-accounts/v2/organizations/${resellerId}`),
        useCache: true,
      },
    },
  });

export const fetchResellerApiClients =
  (organizationId: string, pagination?: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.FETCH_RESELLER_API_CLIENTS,
      payload: {
        request: {
          method: "GET",
          url: getURL(
            `/io-accounts/v2/organizations/${organizationId}/api-clients`,
            pagination
          ),
          useCache: true,
        },
      },
    });

export const fetchResellerApiClientRoles =
  (organizationId: string, apiClientId: string) => async (dispatch: any) =>
    await dispatch({
      type: Types.FETCH_RESELLER_API_CLIENT_ROLES,
      payload: {
        request: {
          method: "GET",
          url: getURL(
            `/io-accounts/v2/organizations/${organizationId}/api-clients/${apiClientId}/roles`
          ),
          useCache: true,
        },
      },
    });

export const createReseller =
  (countryId: string, data: any) => async (dispatch: any) => {
    await dispatch({
      type: Types.CREATE_RESELLER,
      payload: {
        request: {
          url: `/walletsv2/v1/countries/${countryId}/reselleres`,
          method: "POST",
          data,
        },
      },
    });
  };
export const deleteReseller = (reseller: any) => async (dispatch: any) =>
  await dispatch({
    type: Types.DELETE_MESSAGE_TEMPLATE,
    payload: {
      request: {
        url: `/walletsv2/v1/reselleres/${reseller.id}`,
        method: "DELETE",
        deleteProp: reseller,
      },
    },
  });

export const updateReseller =
  (reseller: any, data: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.UPDATE_RESELLER,
      payload: {
        request: {
          url: `/walletsv2/v1/reselleres/${reseller.id}`,
          method: "PATCH",
          data,
          reseller,
        },
      },
    });

export const changeStatusReseller =
  (selectedReseller: any, status: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.CHANGE_RESELLER_STATUS,
      payload: {
        request: {
          url: `/wallets/v1/reselleres/${selectedReseller.id}/status`,
          method: "PATCH",
          data: status,
          selectedReseller,
        },
      },
    });

export const grantApiClientRole =
  (orgId: string, clientId: string, roleTypeId: string) =>
  async (dispatch: any) => {
    await dispatch({
      type: Types.GRANT_API_CLIENT_ROLE,
      payload: {
        request: {
          url: `/io-accounts/v2/organizations/${orgId}/api-clients/${clientId}/roles/${roleTypeId}`,
          method: "PUT",
          roleTypeId,
        },
      },
    });
  };
export const revokeApiClientRole =
  (orgId: string, clientId: string, roleTypeId: string) =>
  async (dispatch: any) =>
    await dispatch({
      type: Types.REVOKE_API_CLIENT_ROLE,
      payload: {
        request: {
          url: `/io-accounts/v2/organizations/${orgId}/api-clients/${clientId}/roles/${roleTypeId}`,
          method: "DELETE",
          deleteProp: roleTypeId,
        },
      },
    });

export const updateSelectedRole = (payload: any) => async (dispatch: any) => {
  await dispatch({
    type: Types.UPDATE_SELECTED_ROLE,
    payload,
  });
};
