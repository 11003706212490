interface Config {
  users: {
    allGroups: {
      title: string;
      listColumns: {
        id: string;
      }[];
    };
    campaignGroups: {
      title: string;
      listColumns: {
        id: string;
      }[];
    };
    campaignSelectableGroups: {
      title: string;
      listColumns: {
        id: string;
      }[];
    };
    campaignContacts: {
      title: string;
      listColumns: {
        id: string;
      }[];
    };
    groupOverview: {
      title: string;
      listColumns: {
        id: string;
      }[];
    };
  };
  messages: {
    sms: {
      title: string;
      listColumns: {
        id: string;
      }[];
    };
    inApp: {
      title: string;
      listColumns: {
        id: string;
      }[];
    };
    email: {
      title: string;
      listColumns: {
        id: string;
      }[];
    };
    outbox: {
      title: string;
      listColumns: {
        id: string;
      }[];
    };
    templates: {
      title: string;
      listColumns: {
        id: string;
      }[];
    };
    editorTemplates: {
      title: string;
      listColumns: {
        id: string;
      }[];
    };
  };
  serviceProvider: {
    normal: {
      listColumns: {
        id: string;
      }[];
    };
    select: {
      listColumns: {
        id: string;
      }[];
    };
  };
  products: {
    product: {
      normal: {
        title: string;
        listColumns: {
          id: string;
        }[];
      };
      select: {
        title: string;
        listColumns: {
          id: string;
        }[];
      };
    };
    batchType: {
      title: string;
      listColumns: {
        id: string;
      }[];
    };
    serviceProvider: {
      title: string;
      listColumns: {
        id: string;
      }[];
    };
    transactionFeeRate: {
      title: string;
      listColumns: {
        id: string;
      }[];
    };
    commissionFeeRate: {
      title: string;
      listColumns: {
        id: string;
      }[];
    };
    customerTransactionFeeRate: {
      title: string;
      listColumns: {
        id: string;
      }[];
    };
    intgs: {
      title: string;
      listColumns: {
        id: string;
      }[];
    };
  };
  HOApplications: {
    HOApplication: {
      title: string;
      listColumns: {
        id: string;
      }[];
    };
    hoApplicationDocument: {
      title: string;
      listColumns: {
        id: string;
      }[];
    };
    hoApplicationStores: {
      title: string;
      listColumns: {
        id: string;
      }[];
    };
    hoApplicationStoresOperators: {
      title: string;
      listColumns: {
        id: string;
      }[];
    };
    hoApplicationDetails: {
      title: string;
      listColumns: {
        id: string;
      }[];
    };
    hoApplicationPoses: {
      title: string;
      listColumns: {
        id: string;
      }[];
    };
  };
  headOffices: {
    ho: {
      title: string;
      listColumns: {
        id: string;
      }[];
    };
    hoDocument: {
      title: string;
      listColumns: {
        id: string;
      }[];
    };
    hoStores: {
      title: string;
      listColumns: {
        id: string;
      }[];
    };
    hoStoresOperators: {
      title: string;
      listColumns: {
        id: string;
      }[];
    };
    hoDetails: {
      title: string;
      listColumns: {
        id: string;
      }[];
    };
    hoPoses: {
      title: string;
      listColumns: {
        id: string;
      }[];
    };
  };
  bswallets: {
    bswallet: {
      normal: {
        title: string;
        listColumns: {
          id: string;
        }[];
      };
      select: {
        title: string;
        listColumns: {
          id: string;
        }[];
      };
    };
    withdrawals: {
      title: string;
      listColumns: {
        id: string;
      }[];
    };
  };
  iowallets: {
    iowallet: {
      normal: {
        title: string;
        listColumns: {
          id: string;
        }[];
      };
      select: {
        title: string;
        listColumns: {
          id: string;
        }[];
      };
    };
    batchType: {
      title: string;
      listColumns: {
        id: string;
      }[];
    };
    serviceProvider: {
      title: string;
      listColumns: {
        id: string;
      }[];
    };
    transactionFeeRate: {
      title: string;
      listColumns: {
        id: string;
      }[];
    };
    commissionFeeRate: {
      title: string;
      listColumns: {
        id: string;
      }[];
    };
    customerTransactionFeeRate: {
      title: string;
      listColumns: {
        id: string;
      }[];
    };
    intgs: {
      title: string;
      listColumns: {
        id: string;
      }[];
    };
  };
  loan: {
    title: string;
    listColumns: {
      id: string;
    }[];
  };
  loanTransaction: {
    title: string;
    listColumns: {
      id: string;
    }[];
  };
  ioTransaction: {
    title: string;
    listColumns: {
      id: string;
    }[];
  };
  walletPayment: {
    title: string;
    listColumns: {
      id: string;
    }[];
  };
  walletCollections: {
    title: string;
    listColumns: {
      id: string;
    }[];
  };
  resellerDfs: {
    title: string;
    listColumns: {
      id: string;
    }[];
  };
  resellerDfsRate: {
    title: string;
    listColumns: {
      id: string;
    }[];
  };
}

export const config: Config = {
  users: {
    allGroups: {
      title: "Groups",
      listColumns: [
        {
          id: "groupName",
        },
        {
          id: "groupDescription",
        },
        {
          id: "actions",
        },
      ],
    },
    campaignGroups: {
      title: "Groups",
      listColumns: [
        {
          id: "select_group",
        },
        {
          id: "groupName",
        },
        {
          id: "groupDescription",
        },
      ],
    },
    campaignSelectableGroups: {
      title: "Groups",
      listColumns: [
        {
          id: "groupName",
        },
        {
          id: "groupDescription",
        },
      ],
    },
    campaignContacts: {
      title: "Contacts",
      listColumns: [
        {
          id: "select_contact",
        },
        {
          id: "name",
        },
        {
          id: "phoneNumber",
        },
      ],
    },
    groupOverview: {
      title: "Contacts",
      listColumns: [
        {
          id: "name",
        },
        {
          id: "phoneNumber",
        },
        {
          id: "email",
        },
        {
          id: "lastModified",
        },
        {
          id: "actions",
        },
      ],
    },
  },
  messages: {
    sms: {
      title: "SMS Requests",
      listColumns: [
        {
          id: "providerName",
        },
        {
          id: "body",
        },
        {
          id: "status",
        },
        {
          id: "dateCreated",
        },
      ],
    },
    email: {
      title: "Email Requests",
      listColumns: [
        {
          id: "providerName",
        },
        {
          id: "subject",
        },
        {
          id: "body",
        },
        {
          id: "status",
        },
        {
          id: "dateCreated",
        },
      ],
    },
    inApp: {
      title: "InApp Requests",
      listColumns: [
        {
          id: "providerName",
        },
        {
          id: "subject",
        },
        {
          id: "body",
        },
        {
          id: "status",
        },
        {
          id: "dateCreated",
        },
      ],
    },
    outbox: {
      title: "Outbox",
      listColumns: [
        {
          id: "sender",
        },
        {
          id: "receiver",
        },
        {
          id: "requestType",
        },
        {
          id: "status",
        },
        {
          id: "dateCreated",
        },
      ],
    },
    templates: {
      title: "Templates",
      listColumns: [
        {
          id: "name",
        },
        {
          id: "body",
        },
        {
          id: "lastModified",
        },
        {
          id: "actions",
        },
      ],
    },
    editorTemplates: {
      title: "Templates",
      listColumns: [
        {
          id: "blank",
        },
        {
          id: "select",
        },
        {
          id: "name",
        },
        {
          id: "body",
        },
      ],
    },
  },
  serviceProvider: {
    normal: {
      listColumns: [
        {
          id: "name",
        },
        {
          id: "apiId",
        },
        {
          id: "apiName",
        },
        {
          id: "channel",
        },
        {
          id: "actions",
        },
      ],
    },
    select: {
      listColumns: [
        {
          id: "select",
        },
        {
          id: "viewName",
        },
        {
          id: "apiId",
        },
        {
          id: "apiName",
        },
        {
          id: "channel",
        },
      ],
    },
  },
  products: {
    product: {
      normal: {
        title: "Products",
        listColumns: [
          {
            id: "name",
          },
          {
            id: "minimunValue",
          },
          {
            id: "maximumValue",
          },
          {
            id: "sp",
          },

          {
            id: "actions",
          },
        ],
      },
      select: {
        title: "Products",
        listColumns: [
          {
            id: "select",
          },
          {
            id: "viewName",
          },
          {
            id: "apiId",
          },
          {
            id: "apiName",
          },
          {
            id: "channel",
          },
        ],
      },
    },
    batchType: {
      title: "Batch Type",
      listColumns: [
        {
          id: "name",
        },
        {
          id: "actions",
        },
      ],
    },
    serviceProvider: {
      title: "Service Provider",
      listColumns: [
        {
          id: "code",
        },
        {
          id: "name",
        },
        {
          id: "actions",
        },
      ],
    },
    transactionFeeRate: {
      title: "Transaction Fee Rate",
      listColumns: [
        {
          id: "feeType",
        },
        {
          id: "feeAmount",
        },
        {
          id: "startingInclusive",
        },

        {
          id: "endingInclusive",
        },
        {
          id: "actions",
        },
      ],
    },
    commissionFeeRate: {
      title: "Commission Fee Rate",
      listColumns: [
        {
          id: "feeType",
        },
        {
          id: "feeAmount",
        },
        {
          id: "startingInclusive",
        },
        {
          id: "endingInclusive",
        },

        {
          id: "actions",
        },
      ],
    },
    customerTransactionFeeRate: {
      title: "Customer Transaction Fee Rate",
      listColumns: [
        {
          id: "feeType",
        },
        {
          id: "feeAmount",
        },
        {
          id: "startingInclusive",
        },
        {
          id: "endingInclusive",
        },
        {
          id: "collectionType",
        },
        {
          id: "actions",
        },
      ],
    },
    intgs: {
      title: "Gateways",
      listColumns: [
        {
          id: "paymentGatewayId",
        },
        {
          id: "paymentGatewayName",
        },
        {
          id: "minimumValue",
        },
        {
          id: "maximumValue",
        },
        {
          id: "actions",
        },
      ],
    },
  },
  HOApplications: {
    HOApplication: {
      title: "Head Office Applications",
      listColumns: [
        {
          id: "names",
        },
        {
          id: "id",
        },

        {
          id: "phoneNumber",
        },
        {
          id: "status",
        },
        {
          id: "dateCreated",
        },
      ],
    },
    hoApplicationDocument: {
      title: "Head Office Application Documents",
      listColumns: [
        {
          id: "documentType",
        },
        {
          id: "documentNumber",
        },
        {
          id: "documentFiles",
        },
        {
          id: "dateCreated",
        },
      ],
    },
    hoApplicationStores: {
      title: "Head Office Stores",
      listColumns: [
        {
          id: "name",
        },
        {
          id: "agentStoreType",
        },
        {
          id: "dateCreated",
        },
        {
          id: "lastModified",
        },
      ],
    },
    hoApplicationStoresOperators: {
      title: "Head Office Stores",
      listColumns: [
        {
          id: "name",
        },
        {
          id: "email",
        },
        {
          id: "maritalStatus",
        },
        {
          id: "phoneNumber",
        },
        {
          id: "dateCreated",
        },
      ],
    },
    hoApplicationPoses: {
      title: "Head Office Stores Poses",
      listColumns: [
        {
          id: "name",
        },
        {
          id: "posNumber",
        },
        {
          id: "posType",
        },
        {
          id: "dateCreated",
        },
        {
          id: "lastModified",
        },
      ],
    },
    hoApplicationDetails: {
      title: "Transaction Fee Rate",
      listColumns: [
        {
          id: "documentType",
        },
        {
          id: "documentNumber",
        },
        {
          id: "documentFiles",
        },
        {
          id: "dateCreated",
        },
      ],
    },
  },
  headOffices: {
    ho: {
      title: "Head Office Applications",
      listColumns: [
        {
          id: "names",
        },
        {
          id: "id",
        },

        {
          id: "phoneNumber",
        },
        {
          id: "status",
        },
        {
          id: "dateCreated",
        },
      ],
    },
    hoDocument: {
      title: "Head Office Application Documents",
      listColumns: [
        {
          id: "documentType",
        },
        {
          id: "documentNumber",
        },
        {
          id: "documentFiles",
        },
        {
          id: "dateCreated",
        },
      ],
    },
    hoStores: {
      title: "Head Office Stores",
      listColumns: [
        {
          id: "name",
        },
        {
          id: "name1",
        },
        {
          id: "name2",
        },
      ],
    },
    hoStoresOperators: {
      title: "Head Office Stores",
      listColumns: [
        {
          id: "name",
        },
        {
          id: "email",
        },
        {
          id: "maritalStatus",
        },
        {
          id: "phoneNumber",
        },
        {
          id: "dateCreated",
        },
      ],
    },
    hoPoses: {
      title: "Head Office Stores Poses",
      listColumns: [
        {
          id: "name",
        },
        {
          id: "posNumber",
        },
        {
          id: "posType",
        },
        {
          id: "dateCreated",
        },
        {
          id: "lastModified",
        },
      ],
    },
    hoDetails: {
      title: "Transaction Fee Rate",
      listColumns: [
        {
          id: "documentType",
        },
        {
          id: "documentNumber",
        },
        {
          id: "documentFiles",
        },
        {
          id: "dateCreated",
        },
      ],
    },
  },
  bswallets: {
    bswallet: {
      normal: {
        title: "BSWallets",
        listColumns: [
          {
            id: "name",
          },
          {
            id: "number",
          },
          {
            id: "type",
          },
          {
            id: "withdrawable",
          },
          {
            id: "actual",
          },
          {
            id: "actions",
          },
        ],
      },
      select: {
        title: "BSWallets",
        listColumns: [
          {
            id: "name",
          },
          {
            id: "number",
          },
          {
            id: "type",
          },
          {
            id: "withdrawable",
          },
          {
            id: "actual",
          },
          {
            id: "actions",
          },
        ],
      },
    },
    withdrawals: {
      title: "Withdrawals",
      listColumns: [
        {
          id: "amount",
        },
        {
          id: "spName",
        },
        {
          id: "channel",
        },
        {
          id: "destinationAccountName",
        },
        {
          id: "dateCreated",
        },
      ],
    },
  },
  iowallets: {
    iowallet: {
      normal: {
        title: "IOWallet",
        listColumns: [
          {
            id: "name",
          },
          {
            id: "minimunValue",
          },
          {
            id: "maximumValue",
          },
          {
            id: "sp",
          },
          {
            id: "actions",
          },
        ],
      },
      select: {
        title: "IOWallet",
        listColumns: [
          {
            id: "select",
          },
          {
            id: "viewName",
          },
          {
            id: "apiId",
          },
          {
            id: "apiName",
          },
          {
            id: "channel",
          },
        ],
      },
    },
    batchType: {
      title: "Batch Type",
      listColumns: [
        {
          id: "name",
        },
        {
          id: "actions",
        },
      ],
    },
    serviceProvider: {
      title: "Service Provider",
      listColumns: [
        {
          id: "code",
        },
        {
          id: "name",
        },
        {
          id: "actions",
        },
      ],
    },
    transactionFeeRate: {
      title: "Transaction Fee Rate",
      listColumns: [
        {
          id: "feeType",
        },
        {
          id: "feeAmount",
        },
        {
          id: "startingInclusive",
        },

        {
          id: "endingInclusive",
        },
        {
          id: "actions",
        },
      ],
    },
    commissionFeeRate: {
      title: "Commission Fee Rate",
      listColumns: [
        {
          id: "feeType",
        },
        {
          id: "feeAmount",
        },
        {
          id: "startingInclusive",
        },
        {
          id: "endingInclusive",
        },
        {
          id: "actions",
        },
      ],
    },
    customerTransactionFeeRate: {
      title: "Customer Transaction Fee Rate",
      listColumns: [
        {
          id: "feeType",
        },
        {
          id: "feeAmount",
        },
        {
          id: "startingInclusive",
        },
        {
          id: "endingInclusive",
        },
        {
          id: "collectionType",
        },
        {
          id: "actions",
        },
      ],
    },
    intgs: {
      title: "Gateways",
      listColumns: [
        {
          id: "paymentGatewayId",
        },
        {
          id: "paymentGatewayName",
        },
        {
          id: "minimumValue",
        },
        {
          id: "maximumValue",
        },
        {
          id: "actions",
        },
      ],
    },
  },
  loan: {
    title: "Loans",
    listColumns: [
      {
        id: "correlatorId",
      },
      {
        id: "initialPrincipal",
      },
      {
        id: "shopName",
      },
      {
        id: "dateCreated",
      },
      {
        id: "status",
      },
    ],
  },
  loanTransaction: {
    title: "Loan Transactions",
    listColumns: [
      {
        id: "commandId",
      },
      {
        id: "amountDeposited",
      },
      {
        id: "amountWithdrawn",
      },
      {
        id: "dateCreated",
      },
      {
        id: "label",
      },
    ],
  },
  ioTransaction: {
    title: "IO Transactions",
    listColumns: [
      {
        id: "organizationName",
      },
      {
        id: "walletAccountName",
      },
      {
        id: "amount",
      },
      {
        id: "ffDirectionName",
      },
      {
        id: "productName",
      },
      {
        id: "dateCreated",
      },
    ],
  },
  walletCollections: {
    title: "Reseller Wallet Payments",
    listColumns: [
      {
        id: "paymentRef",
      },
      {
        id: "orgName",
      },
      {
        id: "wallet",
      },
      {
        id: "type",
      },
      {
        id: "accountNumber",
      },
      {
        id: "amount",
      },
      {
        id: "status",
      },
      {
        id: "dateFulfilled",
      },
      {
        id: "actions",
      },
    ],
  },
  walletPayment: {
    title: "Reseller Wallet Payments",
    listColumns: [
      {
        id: "paymentRef",
      },
      {
        id: "orgName",
      },
      {
        id: "productName",
      },
      {
        id: "type",
      },
      {
        id: "amount",
      },
      {
        id: "account",
      },
      {
        id: "status",
      },
      {
        id: "datetimeCompleted",
      },
      {
        id: "actions",
      },
    ],
  },
  resellerDfs: {
    title: "Reseller Products",
    listColumns: [
      {
        id: "dfs",
      },
      {
        id: "minimumValue",
      },
      {
        id: "maximumValue",
      },
      {
        id: "available",
      },
      {
        id: "dateCreated",
      },
      {
        id: "actions",
      },
    ],
  },
  resellerDfsRate: {
    title: "Reseller Products Commission Rate",
    listColumns: [
      {
        id: "type",
      },
      {
        id: "starting",
      },
      {
        id: "ending",
      },
      {
        id: "amount",
      },
      {
        id: "enabled",
      },
      {
        id: "actions",
      },
    ],
  },
};
