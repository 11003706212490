import { FC, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { Select, TextArea, TextField } from "../../App/FormComponents";
import { Modal } from "../../App";
import { createWithdrawal } from "../../../domains/Withdrawal";
import { openNotification } from "../../../domains/App";
import { schemaValidation } from "../../../constants";
import {
  InitialWithdrawalState,
  toggleWithdrawalModal,
} from "../../../domains/Withdrawal";
import {
  fetchWithdrawalAccounts,
  InitialWithdrawalAccountState,
} from "../../../domains/WithdrawalAccount";

interface Withdrawal {
  amount: string;
  destinationAccount: any;
  channel: string;
  narration: string;
}
interface WithdrawalFormProps {
  bSWallet: any;
}

export const WithdrawalForm: FC<WithdrawalFormProps> = ({ bSWallet }) => {
  const dispatch = useDispatch();
  const { withdrawalModals, withdrawalsLoaders } = useSelector(
    (state: { withdrawal: InitialWithdrawalState }) => state.withdrawal
  );

  const { withdrawalAccounts } = useSelector(
    (state: { withdrawalAccount: InitialWithdrawalAccountState }) =>
      state.withdrawalAccount
  );

  useEffect(() => {
    const fetchWithdrawalAccountsAsync = async () =>
      await dispatch(fetchWithdrawalAccounts());

    fetchWithdrawalAccountsAsync();
  }, [dispatch]);

  const { control, handleSubmit, reset } = useForm<Withdrawal>({
    resolver: joiResolver(schemaValidation.WithdrawalSchema),
    defaultValues: {
      amount: "",
      channel: "",
    },
  });
  const withdrawalAccountSelectOptions = withdrawalAccounts.map((f) => ({
    label: f.accountName + " - " + f.accountNumber,
    value: f,
  }));

  const onClose = async () => {
    reset();
    await dispatch(
      toggleWithdrawalModal({
        addWithdrawal: false,
      })
    );
  };

  const onSubmit: SubmitHandler<Withdrawal> = async (props) => {
    try {
      const withdrawal = {
        amount: props.amount,
        channel: props.channel,
        destinationAccountType: props.destinationAccount.accountType,
        destinationAccountNumber: props.destinationAccount.accountNumber,
        spCode: props.destinationAccount.spCode,
        destinationSubAccountNumber: props.destinationAccount.subAccountNumber,
        narration: props.narration,
      };

      await dispatch(createWithdrawal(bSWallet.id, withdrawal));
      await onClose();
      await dispatch(
        openNotification({
          openNotification: true,
          type: "success",
          message: "Withdrawal done",
        })
      );
    } catch (e) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Something went wrong",
        })
      );
    }
  };

  return (
    <Modal
      formId="withdrawal_form"
      btnType="submit"
      modalTitle="Withdraw"
      isOpen={withdrawalModals.addWithdrawal}
      onClose={onClose}
      btnLoading={withdrawalsLoaders.submit}
    >
      <form
        id="withdrawal_form"
        className="space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="amount"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="amount"
              label="Amount"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />

        <Controller
          control={control}
          name="destinationAccount"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Select
              id="destinationAccount"
              label="Destination Account"
              selectedOption={value}
              error={error?.message}
              onChange={onChange}
              options={withdrawalAccountSelectOptions}
            />
          )}
        />
        <Controller
          control={control}
          name="channel"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Select
              id="channel"
              label="Channel"
              selectedOption={value}
              error={error?.message}
              onChange={onChange}
              options={[
                {
                  value: "PESALINK",
                  label: "PESALINK",
                },
                {
                  value: "MPESA",
                  label: "MPESA",
                },
              ]}
            />
          )}
        />

        <Controller
          control={control}
          name="narration"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextArea
              id="narration"
              label="Narration"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
      </form>
    </Modal>
  );
};
