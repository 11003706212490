import { Types } from "./actionTypes";
import { NotificationProps } from "./types";

export const toggleSidebar = (openSidebar: boolean) => async (dispatch: any) =>
  await dispatch({
    type: Types.TOGGLE_SIDEBAR,
    payload: {
      openSidebar,
    },
  });

export const closeNotification = () => async (dispatch: any) =>
  await dispatch({
    type: Types.REMOVE_NOTIFICATION,
  });

export const openNotification =
  (payload: NotificationProps) => async (dispatch: any) =>
    await dispatch({
      type: Types.ADD_NOTIFICATION,
      payload,
    });
