import { FC } from "react";
import { useLocation } from "react-router-dom";

export const NavbarContext: FC = () => {
  const { pathname } = useLocation();
  const isCampaign = [
    "/messages",
    "/messages/outbox",
    "/messages/email-requests",
    "/messages/sms-requests",
    "/messages/in-app-requests",
  ].includes(pathname);
  const isCreateCampaign = pathname.includes("create-campaign/");
  const isContacts = pathname.includes("/messages/groups");
  const isServiceProviders = pathname.includes("/messages/service-providers");
  const isTemplates = pathname.includes("/messages/templates");
  const isEventListeners = pathname.includes("/messages/event-listeners");
  const isCreateEventListener = pathname.includes(
    "/messages/create-event-listeners"
  );
  const isProductsServiceProviders = pathname.includes(
    "/products/service-providers"
  );
  const isProductsBatchTypes = pathname.includes("/products/batch-types");
  const isAgentTransactionFees = pathname.includes("/agent-transaction-fees");
  const isAgentCommissionFees = pathname.includes("/agent-commission-fees");
  const isCustomerTransactionFees = pathname.includes(
    "/customer-transaction-fees"
  );
  const isGateways = pathname.includes("/gateways");
  const isTandaIOProducts = pathname.includes("/tanda-io/io-products");
  const isTandaIOTransaction = pathname.includes("/tanda-io/transactions");
  const isProducts = pathname.includes("/products");
  const isBcws = pathname.includes("/products/bcws");
  const isLoansTransactions = pathname.includes("/transactions");
  const isLoans = pathname.includes("/loans");
  const isHoApplications = pathname.includes("/agency/application");
  const isHo = pathname.includes("/agency/head-offices");
  const isResellers =
    pathname.includes("/resellers") || pathname.includes("/organizations");
  const isPayouts = pathname.includes("/payouts");
  const isSub = pathname.includes("/sub-wallet-activity");
  const isSubWallets = pathname.includes("/sub-wallets");
  const isReports = pathname.includes("/reports");
  const getTitle = () => {
    switch (true) {
      case isReports:
        return (
          <h1 className="text-xl font-extrabold text-gray-700">Reports</h1>
        );
      case isCampaign:
        return (
          <h1 className="text-xl font-extrabold text-gray-700">Campaigns</h1>
        );
      case isCreateCampaign:
        return (
          <h1 className="text-xl font-extrabold text-gray-700">
            Create Campaign
          </h1>
        );
      case isContacts:
        return (
          <h1 className="text-xl font-extrabold text-gray-700">Contacts</h1>
        );
      case isServiceProviders:
        return (
          <h1 className="text-xl font-extrabold text-gray-700">
            Service Providers
          </h1>
        );
      case isTemplates:
        return (
          <h1 className="text-xl font-extrabold text-gray-700">Templates</h1>
        );
      case isEventListeners:
        return (
          <h1 className="text-xl font-extrabold text-gray-700">
            Event Listeners
          </h1>
        );
      case isCreateEventListener:
        return (
          <h1 className="text-xl font-extrabold text-gray-700">
            Create Event Listener
          </h1>
        );

      case isProductsServiceProviders:
        return (
          <h1 className="text-xl font-extrabold text-gray-700">
            Service Providers
          </h1>
        );
      case isTandaIOTransaction:
        return (
          <h1 className="text-xl font-extrabold text-gray-700">
            Tanda I/O Transaction
          </h1>
        );
      case isGateways:
        return (
          <h1 className="text-xl font-extrabold text-gray-700">
            Products Gateways
          </h1>
        );

      case isProductsBatchTypes:
        return (
          <h1 className="text-xl font-extrabold text-gray-700">Batch Types</h1>
        );
      case isAgentTransactionFees:
        return (
          <h1 className="text-xl font-extrabold text-gray-700">
            Agent Transaction Fees
          </h1>
        );
      case isAgentCommissionFees:
        return (
          <h1 className="text-xl font-extrabold text-gray-700">
            Agent Commission Fees
          </h1>
        );
      case isCustomerTransactionFees:
        return (
          <h1 className="text-xl font-extrabold text-gray-700">
            Customer Transaction Fees
          </h1>
        );
      case isTandaIOProducts:
        return (
          <h1 className="text-xl font-extrabold text-gray-700">
            Tanda I/O Products
          </h1>
        );

      case isBcws:
        return (
          <h1 className="text-xl font-extrabold text-gray-700">
            Business Client Wallets
          </h1>
        );
      case isProducts:
        return (
          <h1 className="text-xl font-extrabold text-gray-700">Products</h1>
        );

      case isLoansTransactions:
        return (
          <h1 className="text-xl font-extrabold text-gray-700">
            Loan Transactions
          </h1>
        );
      case isLoans:
        return <h1 className="text-xl font-extrabold text-gray-700">Loans</h1>;
      case isHoApplications:
        return (
          <h1 className="text-xl font-extrabold text-gray-700">
            Agency Applications
          </h1>
        );
      case isHo:
        return (
          <h1 className="text-xl font-extrabold text-gray-700">Head Offices</h1>
        );
      case isResellers:
        return (
          <h1 className="text-xl font-extrabold text-gray-700">Resellers</h1>
        );
      case isPayouts:
        return (
          <h1 className="text-xl font-extrabold text-gray-700">
            Reseller Activity
          </h1>
        );
      case isSub:
        return (
          <h1 className="text-xl font-extrabold text-gray-700">
            Sub wallets Activity
          </h1>
        );
      case isSubWallets:
        return (
          <h1 className="text-xl font-extrabold text-gray-700">Sub wallets</h1>
        );
      default:
        return;
    }
  };

  return <>{getTitle()}</>;
};
