import _ from "lodash";

export const getURL = (url: string, queryParams = {}) => {
  if (_.isEmpty(queryParams)) {
    return url;
  }
  const params = new URLSearchParams({
    ...queryParams,
  });
  return `${url}?${params.toString()}`;
};

export const getInitials = (name?: string) => {
  if (!name) return "";
  return name
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase())
    .join("");
};

export const matchSidebarPaths = (routerPath: string, optionPath: string) => {
  switch (true) {
    case routerPath.includes("/create-campaign"):
      return optionPath.includes("/create-campaign");
    case routerPath.includes("/groups"):
      return optionPath.includes("/groups");
    case optionPath === "/messages":
      return [
        "/messages",
        "/messages/outbox",
        "/messages/email-requests",
        "/messages/sms-requests",
        "/messages/in-app-requests",
      ].includes(routerPath);
    default:
      return routerPath.includes(optionPath);
  }
};

export const tryToParse = ({
  value,
  defaultValue,
}: {
  value: string;
  defaultValue: any;
}) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return defaultValue;
  }
};
