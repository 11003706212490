export const Types: Record<string, string> = {
  CHANGE_MESSAGE_TABS: "CHANGE_MESSAGE_TABS",
  TOGGLE_MESSAGE_MODALS: "TOGGLE_MESSAGE_MODALS",

  FETCH_SMS_REQUESTS: "FETCH_SMS_REQUESTS",
  FETCH_SMS_REQUESTS_SUCCESS: "FETCH_SMS_REQUESTS_SUCCESS",
  FETCH_SMS_REQUESTS_FAIL: "FETCH_SMS_REQUESTS_FAIL",

  FETCH_MESSAGES: "FETCH_MESSAGES",
  FETCH_MESSAGES_SUCCESS: "FETCH_MESSAGES_SUCCESS",
  FETCH_MESSAGES_FAIL: "FETCH_MESSAGES_FAIL",

  FETCH_TEMPLATES: "FETCH_TEMPLATES",
  FETCH_TEMPLATES_SUCCESS: "FETCH_TEMPLATES_SUCCESS",
  FETCH_TEMPLATES_FAIL: "FETCH_TEMPLATES_FAIL",

  FETCH_SERVICE_PROVIDERS: "FETCH_SERVICE_PROVIDERS",
  FETCH_SERVICE_PROVIDERS_SUCCESS: "FETCH_SERVICE_PROVIDERS_SUCCESS",
  FETCH_SERVICE_PROVIDERS_FAIL: "FETCH_SERVICE_PROVIDERS_FAIL",

  CREATE_SERVICE_PROVIDER: "CREATE_SERVICE_PROVIDER",
  CREATE_SERVICE_PROVIDER_SUCCESS: "CREATE_SERVICE_PROVIDER_SUCCESS",
  CREATE_SERVICE_PROVIDER_FAIL: "CREATE_SERVICE_PROVIDER_FAIL",

  CREATE_MESSAGE_TEMPLATE: "CREATE_MESSAGE_TEMPLATE",
  CREATE_MESSAGE_TEMPLATE_SUCCESS: "CREATE_MESSAGE_TEMPLATE_SUCCESS",
  CREATE_MESSAGE_TEMPLATE_FAIL: "CREATE_MESSAGE_TEMPLATE_FAIL",

  CREATE_SMS_REQUESTS: "CREATE_SMS_REQUESTS",
  CREATE_SMS_REQUESTS_SUCCESS: "CREATE_SMS_REQUESTS_SUCCESS",
  CREATE_SMS_REQUESTS_FAIL: "CREATE_SMS_REQUESTS_FAIL",

  UPDATE_MESSAGE_TEMPLATE: "UPDATE_MESSAGE_TEMPLATE",
  UPDATE_MESSAGE_TEMPLATE_SUCCESS: "UPDATE_MESSAGE_TEMPLATE_SUCCESS",
  UPDATE_MESSAGE_TEMPLATE_FAIL: "UPDATE_MESSAGE_TEMPLATE_FAIL",

  DELETE_MESSAGE_TEMPLATE: "DELETE_MESSAGE_TEMPLATE",
  DELETE_MESSAGE_TEMPLATE_SUCCESS: "DELETE_MESSAGE_TEMPLATE_SUCCESS",
  DELETE_MESSAGE_TEMPLATE_FAIL: "DELETE_MESSAGE_TEMPLATE_FAIL",

  FETCH_TEMPLATE_VARIABLES: "FETCH_TEMPLATE_VARIABLES",
  FETCH_TEMPLATE_VARIABLES_SUCCESS: "FETCH_TEMPLATE_VARIABLES_SUCCESS",
  FETCH_TEMPLATE_VARIABLES_FAIL: "FETCH_TEMPLATE_VARIABLES_FAIL",

  UPDATE_SP: "UPDATE_SP",
  UPDATE_SP_SUCCESS: "UPDATE_SP_SUCCESS",
  UPDATE_SP_FAIL: "UPDATE_SP_FAIL",

  DELETE_SP: "DELETE_SP",
  DELETE_SP_SUCCESS: "DELETE_SP_SUCCESS",
  DELETE_SP_FAIL: "DELETE_SP_FAIL",

  UPDATE_SELECTED_SP: "UPDATE_SELECTED_SP",

  UPDATE_SP_PROPS: "UPDATE_SP_PROPS",
  UPDATE_SP_PROPS_SUCCESS: "UPDATE_SP_PROPS_SUCCESS",
  UPDATE_SP_PROPS_FAIL: "UPDATE_SP_PROPS_FAIL",

  DELETE_SP_PROPS: "DELETE_SP_PROPS",
  DELETE_SP_PROPS_SUCCESS: "DELETE_SP_PROPS_SUCCESS",
  DELETE_SP_PROPS_FAIL: "DELETE_SP_PROPS_FAIL",

  CREATE_SP_PROPS: "CREATE_SP_PROPS",
  CREATE_SP_PROPS_SUCCESS: "CREATE_SP_PROPS_SUCCESS",
  CREATE_SP_PROPS_FAIL: "CREATE_SP_PROPS_FAIL",

  FETCH_SP_PROPS: "FETCH_SP_PROPS",
  FETCH_SP_PROPS_SUCCESS: "FETCH_SP_PROPS_SUCCESS",
  FETCH_SP_PROPS_FAIL: "FETCH_SP_PROPS_FAIL",

  FETCH_EMAIL_REQUESTS: "FETCH_EMAIL_REQUESTS",
  FETCH_EMAIL_REQUESTS_SUCCESS: "FETCH_EMAIL_REQUESTS_SUCCESS",
  FETCH_EMAIL_REQUESTS_FAIL: "FETCH_EMAIL_REQUESTS_FAIL",

  CREATE_EMAIL_REQUESTS: "CREATE_EMAIL_REQUESTS",
  CREATE_EMAIL_REQUESTS_SUCCESS: "CREATE_EMAIL_REQUESTS_SUCCESS",
  CREATE_EMAIL_REQUESTS_FAIL: "CREATE_EMAIL_REQUESTS_FAIL",

  FETCH_IN_APP_REQUESTS: "FETCH_IN_APP_REQUESTS",
  FETCH_IN_APP_REQUESTS_SUCCESS: "FETCH_IN_APP_REQUESTS_SUCCESS",
  FETCH_IN_APP_REQUESTS_FAIL: "FETCH_IN_APP_REQUESTS_FAIL",

  CREATE_IN_APP_REQUESTS: "CREATE_IN_APP_REQUESTS",
  CREATE_IN_APP_REQUESTS_SUCCESS: "CREATE_IN_APP_REQUESTS_SUCCESS",
  CREATE_IN_APP_REQUESTS_FAIL: "CREATE_IN_APP_REQUESTS_FAIL",

  CREATE_EMAIL_REQUEST_CACHE: "CREATE_EMAIL_REQUEST_CACHE",
  CREATE_IN_APP_REQUEST_CACHE: "CREATE_IN_APP_REQUEST_CACHE",
  CREATE_SMS_REQUEST_CACHE: "CREATE_SMS_REQUEST_CACHE",

  FETCH_EVENT_LISTENERS: "FETCH_EVENT_LISTENERS",
  FETCH_EVENT_LISTENERS_SUCCESS: "FETCH_EVENT_LISTENERS_SUCCESS",
  FETCH_EVENT_LISTENERS_FAIL: "FETCH_EVENT_LISTENERS_FAIL",

  CREATE_EVENT_LISTENER: "CREATE_EVENT_LISTENER",
  CREATE_EVENT_LISTENER_SUCCESS: "CREATE_EVENT_LISTENER_SUCCESS",
  CREATE_EVENT_LISTENER_FAIL: "CREATE_EVENT_LISTENER_FAIL",

  UPDATE_EVENT_LISTENER: "UPDATE_EVENT_LISTENER",
  UPDATE_EVENT_LISTENER_SUCCESS: "UPDATE_EVENT_LISTENER_SUCCESS",
  UPDATE_EVENT_LISTENER_FAIL: "UPDATE_EVENT_LISTENER_FAIL",

  DELETE_EVENT_LISTENER: "DELETE_EVENT_LISTENER",
  DELETE_EVENT_LISTENER_SUCCESS: "DELETE_EVENT_LISTENER_SUCCESS",
  DELETE_EVENT_LISTENER_FAIL: "DELETE_EVENT_LISTENER_FAIL",
};
