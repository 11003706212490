import { FC } from "react";
import { Loader, ExpandTable } from "../../App";
import { PaginationProps } from "../../../domains/App";
import dayJs from "dayjs";

interface MessageTableProps {
  columns: any[];
  data: any[];
  onPaginationChange?: (page: number) => void;
  pagination: PaginationProps;
  loading: boolean;
  SubComponent?: React.FC<{ row: any }>;
}

export const MessagesTable: FC<MessageTableProps> = ({
  columns,
  data,
  pagination,
  loading,
  onPaginationChange,
  SubComponent,
}) => {
  const getMessagesColumns = () => {
    const availableColumns = [
      {
        id: "providerName",
        Header: "Provider Name",
        accessor: (row: any) => <span>{row?.sp?.name}</span>,
      },
      {
        id: "body",
        Header: "Body",
        accessor: (row: any) => (
          <p className="truncate max-w-xs">{row?.body}</p>
        ),
      },
      {
        id: "dateCreated",
        Header: "Sent At",
        accessor: (row: any) => {
          return (
            <span>{dayJs(row?.dateCreated).format("YYYY-MM-DD hh:mm A")}</span>
          );
        },
      },
      {
        id: "subject",
        Header: "Subject",
        accessor: (row: any) => (
          <p className="truncate max-w-xs">{row?.subject}</p>
        ),
      },
      {
        id: "sender",
        Header: "Sender",
        accessor: (row: any) => <span>{row?.from}</span>,
      },
      {
        id: "receiver",
        Header: "Receiver",
        accessor: (row: any) => <span>{row?.to}</span>,
      },
      {
        id: "requestType",
        Header: "Request Type",
        accessor: (row: any) => <span>{row?.requestType}</span>,
      },
      {
        id: "status",
        Header: "Status",
        accessor: (row: any) => <span>{row?.statusDescription}</span>,
      },
      {
        id: "lastModified",
        Header: "Last Update",
        accessor: (row: any) => {
          return (
            <span>{dayJs(row?.lastModified).format("YYYY-MM-DD hh:mm A")}</span>
          );
        },
      },
      {
        id: "name",
        Header: "Name",
        accessor: (row: any) => <span>{row?.name}</span>,
      },
    ];

    return columns.map((column) =>
      availableColumns.find(
        (availableColumn) => availableColumn.id === column.id
      )
    );
  };

  if (loading) {
    return (
      <div className="mt-4 flex flex-row items-center justify-center w-full h-60 bg-white rounded-lg">
        <Loader />
      </div>
    );
  }
  return (
    <ExpandTable
      columns={getMessagesColumns()}
      data={data}
      pageCount={pagination.totalPages}
      currentPage={pagination.number}
      onPaginationChange={onPaginationChange}
      SubComponent={SubComponent}
    />
  );
};
