import {
  CheckCircleIcon,
  ClockIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import dayjs from "dayjs";
import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { PaginationProps } from "../../../domains/App";
import { toggleWalletModal } from "../../../domains/Reseller/Wallet";
import { checkValue } from "../../../utils/app";
import { Loader, SecondTable } from "../../App";
import { PayoutDetails } from "./PayoutDetails";

interface PayoutsProps {
  control?: any;
  columns: any[];
  data: any[];
  onChange?: (value: string) => void;
  onPaginationChange?: (page: number) => void;
  loading: boolean;
  pagination: PaginationProps;
  title: string;
  SubComponent?: React.FC<{ row: any }>;
}

export const PayoutsTable: FC<PayoutsProps> = ({
  columns,
  data,
  onPaginationChange,
  loading,
  pagination,
  SubComponent,
}) => {
  const dispatch = useDispatch();
  let [selectedTransaction, setSelectedTransaction] = useState<any>();

  const getStatus = (row: string) => {
    switch (row) {
      case "000000":
        return (
          <span className="flex text-tandaGreen">
            <CheckCircleIcon className="h-4 mr-1 w-4" />
            <span>Success</span>
          </span>
        );
      case "000001":
        return (
          <span className="flex text-gray-400">
            <ClockIcon className="h-4 mr-1 w-4" />
            <span>Pending</span>
          </span>
        );
      default:
        return (
          <span className="flex text-tandaRed">
            <XCircleIcon className="h-4 mr-1 w-4" />
            <span>Failed</span>
          </span>
        );
    }
  };
  const getAmount = (row: any) => {
    switch (row?.type) {
      case "I":
        return (
          <span className="flex text-tandaGreen">
            + <span>{(row?.amount).toFixed(2)}</span>
          </span>
        );
      default:
        return (
          <span className="flex text-tandaRed">
            - <span>{(row?.amount).toFixed(2)}</span>
          </span>
        );
    }
  };
  const handleOpenDrawer = (id: string) => {
    setSelectedTransaction(id);
    dispatch(
      toggleWalletModal({
        showDetails: true,
      })
    );
  };

  const getColumns = () => {
    const availableColumns = [
      {
        id: "paymentRef",
        Header: "Receipt Number",
        accessor: "receiptNumber",
      },
      {
        id: "orgName",
        Header: "Org",
        accessor: (row: any) => {
          return <span>{row?.org?.name}</span>;
        },
      },
      {
        id: "productName",
        Header: "Product",
        accessor: (row: any) => {
          return <span>{row.dfs.name}</span>;
        },
      },
      {
        id: "type",
        Header: "Type",
        accessor: (row: any) => {
          return <span>{row.dfs.batch}</span>;
        },
      },
      {
        id: "productName",
        Header: "Product",
        accessor: (row: any) => {
          return <span>{row.dfs.name}</span>;
        },
      },
      {
        id: "batchTypeName",
        Header: "Batch",
        accessor: "batchTypeName",
      },
      {
        id: "amount",
        Header: "Amount(KES)",
        accessor: (row: any) => {
          return <span>{getAmount(row)}</span>;
        },
      },
      {
        id: "account",
        Header: "Account",
        accessor: (row: any) => {
          return <span>{checkValue(row?.accountNumber)}</span>;
        },
      },
      {
        id: "status",
        Header: "Status",
        accessor: (row: any) => {
          return <span>{getStatus(row?.status)}</span>;
        },
      },
      {
        id: "datetimeCompleted",
        Header: "Completed on",
        accessor: (row: any) => {
          return (
            <span>
              {typeof row?.dateFulfilled === "undefined" ||
              row?.dateFulfilled === null
                ? "-"
                : dayjs(row?.dateFulfilled).format("YYYY-MM-DD hh:mm A")}
            </span>
          );
        },
      },
      {
        id: "actions",
        accessor: (row: any) => {
          return (
            <div className="flex flex-row items-center">
              <div
                className="center-contents font-bold text-xs text-tandaPurple cursor-pointer flex"
                onClick={() => handleOpenDrawer(row)}
              >
                <div className="center-contents"></div>
                <div className="center-contents">View more</div>
              </div>
            </div>
          );
        },
      },
    ];

    return columns.map((column) =>
      availableColumns.find(
        (availableColumn) => availableColumn.id === column.id
      )
    );
  };

  if (loading) {
    return (
      <div className="mt-4 rounded-lg flex flex-row items-center justify-center w-full h-60 bg-white">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <PayoutDetails payoutDetails={selectedTransaction} />
      <div className="px-3">
        <SecondTable
          columns={getColumns()}
          data={data}
          pageCount={pagination.totalPages}
          currentPage={pagination.number}
          onPaginationChange={onPaginationChange}
        />
      </div>
    </>
  );
};
