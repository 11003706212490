import _ from "lodash";
import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  deleteResellerDfsFeeRate,
  InitialResellerDfsFeeRateState,
  toggleResellerDfsFeeRateModal,
} from "../../../domains/Reseller/ResellerDfsFeeRate";
import { Button, DeleteModal, Loader, Table } from "../../App";
import { RadioBox } from "../../App/FormComponents";
import { EditResellerDfsFeeRateForm } from "./EditResellerDfsFeeRate";

interface ResellerDfsFeeRateTableProps {
  control?: any;
  columns: any[];
  data: any[];
  onChange?: (value: string) => void;

  loading: boolean;
}

export const ResellerDfsFeeRateTable: FC<ResellerDfsFeeRateTableProps> = ({
  columns,
  data,
  onChange = (value) => {},
  loading,
}) => {
  const dispatch = useDispatch();
  const { dfsId = "" } = useParams<{ dfsId?: string }>();
  const [selectedResellerDfsFeeRate, setSelectedResellerDfsFeeRate] =
    useState<any>();

  const { resellerDfsFeeRatesLoaders, resellerDfsFeeRateModals } = useSelector(
    (state: { resellerDfsFeeRate: InitialResellerDfsFeeRateState }) =>
      state.resellerDfsFeeRate
  );

  const handleEditModal = (value: any) => {
    setSelectedResellerDfsFeeRate(value);
    dispatch(
      toggleResellerDfsFeeRateModal({
        editResellerDfsFeeRate: true,
      })
    );
  };
  const handleOpenModal = (id: string) => {
    setSelectedResellerDfsFeeRate(id);
    dispatch(
      toggleResellerDfsFeeRateModal({
        deleteResellerDfsFeeRate: true,
      })
    );
  };

  const handleCloseModal = () =>
    dispatch(
      toggleResellerDfsFeeRateModal({
        deleteResellerDfsFeeRate: false,
      })
    );

  const onDeleteTemplate = async () => {
    if (!_.isNil(selectedResellerDfsFeeRate)) {
      await dispatch(
        deleteResellerDfsFeeRate(dfsId, { id: selectedResellerDfsFeeRate })
      );
    }
    handleCloseModal();
  };

  const getColumns = () => {
    const availableColumns = [
      {
        id: "type",
        Header: "Type",
        accessor: "type",
      },
      {
        id: "starting",
        Header: "Starting(KES)",
        accessor: "starting",
      },
      {
        id: "ending",
        Header: "Ending(KES)",
        accessor: "ending",
      },
      {
        id: "amount",
        Header: "Amount",
        accessor: "amount",
      },
      {
        id: "enabled",
        Header: "enabled",
        accessor: (row: any) => {
          return <span>{row?.enabled ? "Yes" : "No"}</span>;
        },
      },
      {
        id: "actions",
        accessor: (row: any) => {
          return (
            <div className="flex flex-row items-center space-x-2">
              <Button
                onClick={() => handleEditModal(row)}
                size="xs"
                variant="primary"
              >
                Edit
              </Button>
              <Button
                onClick={() => handleOpenModal(row?.id)}
                size="xs"
                variant="danger"
              >
                Delete
              </Button>
            </div>
          );
        },
      },
      {
        id: "select",
        accessor: (row: any) => {
          return (
            <RadioBox
              name="resellerDfsCommissionRate"
              onChange={onChange}
              value={JSON.stringify(row)}
            />
          );
        },
      },
    ];

    return columns.map((column) =>
      availableColumns.find(
        (availableColumn) => availableColumn.id === column.id
      )
    );
  };

  if (loading) {
    return (
      <div className="mt-4 rounded-lg flex flex-row items-center justify-center w-full h-60 bg-white">
        <Loader />
      </div>
    );
  }
  return (
    <>
      <DeleteModal
        isOpen={resellerDfsFeeRateModals.deleteResellerDfsFeeRate}
        onClose={handleCloseModal}
        onSubmit={onDeleteTemplate}
        message="Are you sure you want to delete this product fee rate?"
        loading={resellerDfsFeeRatesLoaders.submit}
      />
      <EditResellerDfsFeeRateForm
        resellerDfsFeeRate={selectedResellerDfsFeeRate}
      />

      <Table columns={getColumns()} data={data} />
    </>
  );
};
