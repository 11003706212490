import { createReducer } from "@reduxjs/toolkit";
import { Types } from "./actionTypes";

export interface InitialSubWalletsState {
  cache: Record<string, any>;
  selectedProduct: any;
  subWallets: any[];
  subWalletsLoaders: any;
  subWalletsPagination: any;
  subWalletBalances: any;
  orgBalances: any;
}

const initialState: InitialSubWalletsState = {
  subWallets: [],
  subWalletBalances: {},
  orgBalances: {},
  subWalletsLoaders: {
    fetchSubWallets: false,
    fetchSubWalletBalances: false,
    fetchOrgBalances: false,
  },
  subWalletsPagination: {
    number: 0,
    totalPages: 1,
    size: 10,
    totalElements: 0,
  },
  cache: {
    emailRequests: {},
    smsRequests: {},
    inAppRequests: {},
  },
  selectedProduct: {},
};

export const subWalletsReducer = createReducer(initialState, {
  [Types.FETCH_SUB_WALLETS]: (state) => {
    state.subWalletsLoaders.fetchSubWallets = true;
  },
  [Types.FETCH_SUB_WALLETS_SUCCESS]: (state, action) => {
    const {
      data: { content, page },
    } = action.payload;
    state.subWalletsLoaders.fetchSubWallets = false;
    state.subWallets = content;
    state.subWalletsPagination = {
      number: page.number,
      size: page.size,
      totalPages: page.totalPages,
      totalElements: page.totalElements,
    };
  },
  [Types.FETCH_SUB_WALLETS_FAIL]: (state) => {
    state.subWalletsLoaders.fetchSubWallets = false;
  },
  [Types.FETCH_SUB_WALLET_BALANCES]: (state) => {
    state.subWalletsLoaders.fetchSubWalletBalances = true;
  },
  [Types.FETCH_SUB_WALLET_BALANCES_SUCCESS]: (state, action) => {
    const { data } = action.payload;
    state.subWalletsLoaders.fetchSubWalletBalances = false;
    state.subWalletBalances = data;
  },
  [Types.FETCH_SUB_WALLET_BALANCES_FAIL]: (state) => {
    state.subWalletsLoaders.fetchSubWalletBalances = false;
  },

  [Types.FETCH_ORG_SUB_WALLET_BALANCES]: (state) => {
    state.subWalletsLoaders.fetchOrgBalances = true;
  },
  [Types.FETCH_ORG_SUB_WALLET_BALANCES_SUCCESS]: (state, action) => {
    const { data } = action.payload;
    state.subWalletsLoaders.fetchOrgBalances = false;
    state.orgBalances = data;
  },
  [Types.FETCH_ORG_SUB_WALLET_BALANCES_FAIL]: (state) => {
    state.subWalletsLoaders.fetchOrgBalances = false;
  },
});
