import { Types } from "./actionTypes";

export const toggleResellerDfsCommissionRateModal =
  (payload: any) => async (dispatch: any) =>
    dispatch({
      type: Types.TOGGLE_RESELLER_DFS_COMMISSION_RATE_MODALS,
      payload,
    });

export const fetchResellerDfsCommissionRates =
  (resellerDfsId: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.FETCH_RESELLER_DFS_COMMISSION_RATES,
      payload: {
        request: {
          method: "GET",
          url: `/io-wallets/v1/reseller-dfses/${resellerDfsId}/commission-rates`,
          useCache: true,
        },
      },
    });

export const createResellerDfsCommissionRate =
  (resellerDfsId: any, data: any) => async (dispatch: any) => {
    await dispatch({
      type: Types.CREATE_RESELLER_DFS_COMMISSION_RATE,
      payload: {
        request: {
          url: `/io-wallets/v1/reseller-dfses/${resellerDfsId}/commission-rates`,
          method: "POST",
          data,
        },
      },
    });
  };

export const updateResellerDfsCommissionRate =
  (resellerDfsId: string, resellerDfsCommissionRateId: any, data: any) =>
  async (dispatch: any) =>
    await dispatch({
      type: Types.UPDATE_RESELLER_DFS_COMMISSION_RATE,
      payload: {
        request: {
          url: `/io-wallets/v1/reseller-dfses/${resellerDfsId}/commission-rates/${resellerDfsCommissionRateId}`,
          method: "PATCH",
          data,
        },
      },
    });

export const deleteResellerDfsCommissionRate =
  (resellerDfsId: any, resellerDfsCommissionRate: any) =>
  async (dispatch: any) =>
    await dispatch({
      type: Types.DELETE_RESELLER_DFS_COMMISSION_RATE,
      payload: {
        request: {
          url: `/io-wallets/v1/reseller-dfses/${resellerDfsId}/commission-rates/${resellerDfsCommissionRate?.id}`,
          method: "DELETE",
          deleteProp: resellerDfsCommissionRate,
        },
      },
    });
