import { createReducer } from "@reduxjs/toolkit";
import { Types } from "./actionTypes";
import { PaginationProps } from "../App";
import _ from "lodash";

export interface InitialIOIntgstate {
  cache: Record<string, any>;
  productIOIntgs: any[];
  selectedIntg: any;
  iointgsPagination: PaginationProps;
  intgModals: any;
  iointgsLoaders: any;
}

const initialState: InitialIOIntgstate = {
  productIOIntgs: [],
  iointgsPagination: {
    number: 0,
    totalPages: 1,
    size: 10,
    totalElements: 0,
  },
  selectedIntg: {},
  iointgsLoaders: {
    submit: false,
    fetchIOIntgs: false,
  },
  intgModals: {
    addIntg: false,
    editIntg: false,
    deleteIntg: false,
    disableIntg: false,
  },
  cache: {},
};

export const iointgsReducer = createReducer(initialState, {
  [Types.TOGGLE_INTG_MODALS]: (state, action) => {
    state.intgModals = {
      ...state.intgModals,
      ...action.payload,
    };
  },

  [Types.FETCH_PRODUCT_IOINTGS]: (state) => {
    state.iointgsLoaders.fetchIOIntgs = true;
  },
  [Types.FETCH_PRODUCT_IOINTGS_SUCCESS]: (state, action) => {
    const {
      data: { content, page },
    } = action.payload;
    state.iointgsLoaders.fetchIOIntgs = false;
    state.productIOIntgs = content;
    state.iointgsPagination = {
      number: page.number,
      size: page.size,
      totalPages: page.totalPages,
      totalElements: page.totalElements,
    };
  },
  [Types.FETCH_PRODUCT_IOINTGS_FAIL]: (state) => {
    state.iointgsLoaders.fetchIOIntgs = false;
  },

  [Types.CREATE_INTG]: (state) => {
    state.iointgsLoaders.submit = true;
  },
  [Types.CREATE_INTG_SUCCESS]: (state, action) => {
    state.iointgsLoaders.submit = false;
    state.productIOIntgs = [action.payload.data, ...state.productIOIntgs];
  },
  [Types.CREATE_INTG_FAIL]: (state) => {
    state.iointgsLoaders.submit = false;
  },

  [Types.DELETE_INTG]: (state) => {
    state.iointgsLoaders.submit = true;
  },
  [Types.DELETE_INTG_SUCCESS]: (state, action) => {
    const {
      previousAction: {
        payload: {
          request: {
            deleteProp: { id },
          },
        },
      },
    } = action.meta;
    state.productIOIntgs = [
      ...state.productIOIntgs.filter((intg) => intg.id !== id),
    ];
    if (_.isEmpty(state.productIOIntgs)) {
      state.iointgsPagination.number = 0;
    }
    state.iointgsLoaders.submit = false;
  },
  [Types.DELETE_INTG_FAIL]: (state) => {
    state.iointgsLoaders.submit = false;
  },

  [Types.UPDATE_INTG]: (state) => {
    state.iointgsLoaders.submit = true;
  },
  [Types.UPDATE_INTG_SUCCESS]: (state, action) => {
    const {
      previousAction: {
        payload: {
          request: {
            intg: { id },
            intg,
          },
        },
      },
    } = action.meta;
    state.iointgsLoaders.submit = false;
    state.productIOIntgs = [
      ...state.productIOIntgs.map((integration) =>
        integration.id === id ? intg : integration
      ),
    ];
  },
  [Types.UPDATE_INTG_FAIL]: (state) => {
    state.iointgsLoaders.submit = false;
  },

  [Types.CHANGE_INTG_STATUS]: (state) => {
    state.iointgsLoaders.submit = true;
  },

  [Types.CHANGE_INTG_STATUS_SUCCESS]: (state, action) => {
    const {
      previousAction: {
        payload: {
          request: {
            selectedIntg: { id },
            selectedIntg,
          },
        },
      },
    } = action.meta;
    state.iointgsLoaders.submit = false;
    state.productIOIntgs = [
      ...state.productIOIntgs.map((intg) =>
        intg.id === id ? selectedIntg : intg
      ),
    ];
  },
  [Types.CHANGE_INTG_STATUS_FAIL]: (state) => {
    state.iointgsLoaders.submit = false;
  },
});
