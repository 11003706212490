import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useFormContext } from "react-hook-form";
import _ from "lodash";
import { Button, LargeModal } from "../../App";
import { DescriptionPanel } from "../Messages";
import { UsersTable } from "../Users";
import { InitialUserStateProps, fetchAllGroups } from "../../../domains/Users";
import { config } from "../../../constants";
import { ErrorMessage } from "../../App/FormComponents";

interface GroupModalProps {
  isOpen: boolean;
  onClose: () => void;
  onChange: (value: string) => void;
}

const GroupModal: FC<GroupModalProps> = ({ isOpen, onClose, onChange }) => {
  const dispatch = useDispatch();
  const { groups, groupPagination, userLoader } = useSelector(
    (state: { users: InitialUserStateProps }) => state.users
  );

  useEffect(() => {
    const fetchGroupAsync = async () => await dispatch(fetchAllGroups());

    fetchGroupAsync();
  }, [dispatch]);

  const handlePagination = (page: number) => {
    dispatch(
      fetchAllGroups({
        page: page - 1,
        size: groupPagination.size,
      })
    );
  };
  return (
    <LargeModal modalTitle="Pick Groups" isOpen={isOpen} onClose={onClose}>
      <UsersTable
        title={config.users.campaignGroups.title}
        columns={config.users.campaignGroups.listColumns}
        data={groups}
        pagination={groupPagination}
        loading={userLoader.fetchAllGroups}
        onPaginationChange={handlePagination}
        onChange={onChange}
      />
    </LargeModal>
  );
};

interface PickGroupsProps {
  formValue: string;
  label: string;
}

export const PickGroups: FC<PickGroupsProps> = ({ formValue, label }) => {
  const {
    formState: { errors },
    watch,
    control,
  } = useFormContext();
  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => setOpenModal(false);
  const handleClick = () => setOpenModal(true);
  const formGroups = watch(formValue);

  const handleGroups = (group: string) => {
    let groups = [...formGroups];
    if (groups.some((g: string) => g === group)) {
      groups.filter((g) => g !== group);
    } else {
      groups = [...groups, group];
    }
    return groups;
  };

  return (
    <>
      <Controller
        name={formValue}
        control={control}
        render={({ field: { onChange } }) => {
          return (
            <div>
              {openModal && (
                <GroupModal
                  isOpen={openModal}
                  onClose={handleClose}
                  onChange={(group: string) => {
                    const groups = handleGroups(group);
                    onChange(groups);
                  }}
                />
              )}
            </div>
          );
        }}
      />

      <DescriptionPanel label={label}>
        <div className="grid grid-cols-1">
          <div>
            <Button onClick={handleClick} variant="primary" size="md">
              Select
            </Button>
          </div>
          <div className="space-x-1 space-y-1">
            {!_.isEmpty(formGroups) &&
              formGroups.map((group: string, i: number) => {
                const parsedGroup = JSON.parse(group) || {};
                return (
                  <span
                    key={i}
                    className="mt-3 inline-flex items-center px-3 py-1 rounded-full text-sm font-medium cursor-pointer mb-2 bg-white border border-gray-600 text-gray-800"
                  >
                    {parsedGroup?.name}
                  </span>
                );
              })}
            <ErrorMessage error={errors?.[formValue]?.message} />
          </div>
        </div>
      </DescriptionPanel>
    </>
  );
};
