import { createReducer } from "@reduxjs/toolkit";
import { PaginationProps } from "./../../App/types";
import { Types } from "./actionTypes";
export interface InitialWalletState {
  cache: Record<string, any>;
  collectionWallets: any[];
  primaryCollectionWallet: any;
  wallet: any;
  paymentStatus: any;
  searchPaymentsParams: any;
  searchCollectionsParams: any;
  payments: any[];
  collections: any[];
  walletModals: any;
  walletsLoaders: any;
  walletsPagination: PaginationProps;
  selectedWallet: any;
  collectionWalletsPagination: PaginationProps;
}

const initialState: InitialWalletState = {
  wallet: {} || [],
  collectionWallets: [],
  paymentStatus: {} || [],
  primaryCollectionWallet: {},
  payments: [],
  collections: [],
  searchPaymentsParams: {},
  searchCollectionsParams: {},
  walletsPagination: {
    number: 0,
    totalPages: 1,
    size: 10,
    totalElements: 0,
  },
  collectionWalletsPagination: {
    number: 0,
    totalPages: 1,
    size: 10,
    totalElements: 0,
  },
  walletModals: {
    addWallet: false,
    editWallet: false,
    deleteWallet: false,
    disableWallet: false,
    disableEnableWallet: false,
    editCollectionWallet: false,
    showDetails: false,
    addCollectionWallet: false,
    downloadPayoutsStatement: false,
    reverseTransaction: false,
    downloadCollectionsStatement: false,
    creditCollectionWallet: false,
    debitCollectionWallet: false,
    manageCollectionWallet: false,
  },
  walletsLoaders: {
    submit: false,
    reversingTransaction: false,
    creditSubmit: false,
    debitSubmit: false,
    fetchWallets: false,
    fetchPayments: false,
    fetchCollections: false,
    fetchPaymentStatus: false,
    fetchCollectionWallets: false,
    fetchPrimaryCollectionsWallet: false,
    downloadPayoutsStatement: false,
    downloadCollectionsStatement: false,
  },

  cache: {
    emailRequests: {},
    smsRequests: {},
    inAppRequests: {},
  },
  selectedWallet: {},
};

export const resellerWalletReducer = createReducer(initialState, {
  [Types.UPDATE_SELECTED_WALLET]: (state, action) => {
    state.selectedWallet = action.payload;
  },

  [Types.UPDATE_PAYOUTS_SEARCH_PARAMS]: (state, action) => {
    state.searchPaymentsParams = action.payload;
  },
  [Types.UPDATE_COLLECTIONS_SEARCH_PARAMS]: (state, action) => {
    state.searchCollectionsParams = action.payload;
  },

  [Types.TOGGLE_WALLET_MODALS]: (state, action) => {
    state.walletModals = {
      ...state.walletModals,
      ...action.payload,
    };
  },

  [Types.FETCH_WALLET]: (state) => {
    state.wallet = {};
    state.walletsLoaders.fetchWallets = true;
  },
  [Types.FETCH_WALLET_SUCCESS]: (state, action) => {
    state.wallet = {};
    const { data } = action.payload;
    state.walletsLoaders.fetchWallets = false;
    state.wallet = data;
  },
  [Types.FETCH_WALLET_FAIL]: (state) => {
    state.wallet = {};
    state.walletsLoaders.fetchWallets = false;
  },

  [Types.FETCH_WALLET_PAYMENTS]: (state) => {
    state.walletsLoaders.fetchPayments = true;
  },
  [Types.FETCH_WALLET_PAYMENTS_SUCCESS]: (state, action) => {
    const {
      data: { content, page },
    } = action.payload;
    state.walletsLoaders.fetchPayments = false;
    state.payments = content;
    state.walletsPagination = {
      number: page.number,
      size: page.size,
      totalPages: page.totalPages,
      totalElements: page.totalElements,
    };
  },
  [Types.FETCH_WALLET_PAYMENTS_FAIL]: (state) => {
    state.payments = [];
    state.walletsLoaders.fetchPayments = false;
  },

  [Types.FETCH_PAYMENT_STATUS]: (state) => {
    state.walletsLoaders.fetchPaymentStatus = true;
  },
  [Types.FETCH_PAYMENT_STATUS_SUCCESS]: (state, action) => {
    const { data } = action.payload;
    state.walletsLoaders.fetchPaymentStatus = false;
    state.paymentStatus = data;
  },
  [Types.FETCH_PAYMENT_STATUS_FAIL]: (state) => {
    state.paymentStatus = {};
    state.walletsLoaders.fetchPaymentStatus = false;
  },

  [Types.FETCH_WALLET_COLLECTIONS]: (state) => {
    state.walletsLoaders.fetchCollections = true;
  },
  [Types.FETCH_WALLET_COLLECTIONS_SUCCESS]: (state, action) => {
    const {
      data: { content, page },
    } = action.payload;
    state.walletsLoaders.fetchCollections = false;
    state.collections = content;
    state.walletsPagination = {
      number: page.number,
      size: page.size,
      totalPages: page.totalPages,
      totalElements: page.totalElements,
    };
  },
  [Types.FETCH_WALLET_COLLECTIONS_FAIL]: (state) => {
    state.collections = [];
    state.walletsLoaders.fetchCollections = false;
  },

  [Types.UPDATE_RESELLER_WALLET]: (state) => {
    state.walletsLoaders.submit = true;
  },

  [Types.UPDATE_RESELLER_WALLET_SUCCESS]: (state, action) => {
    const {
      previousAction: {
        payload: {
          request: { p },
        },
      },
    } = action.meta;
    state.walletsLoaders.submit = false;
    state.wallet = p;
  },
  [Types.UPDATE_RESELLER_WALLET_FAIL]: (state) => {
    state.walletsLoaders.submit = false;
  },

  [Types.FETCH_PRIMARY_COLLECTION_WALLET]: (state) => {
    state.walletsLoaders.fetchPrimaryCollectionsWallet = true;
  },
  [Types.FETCH_PRIMARY_COLLECTION_WALLET_SUCCESS]: (state, action) => {
    const { data } = action.payload;
    state.walletsLoaders.fetchPrimaryCollectionsWallet = false;
    state.primaryCollectionWallet = data;
  },
  [Types.FETCH_PRIMARY_COLLECTION_WALLET_FAIL]: (state) => {
    state.walletsLoaders.fetchPrimaryCollectionsWallet = false;
  },

  [Types.FETCH_COLLECTION_WALLETS]: (state) => {
    state.walletsLoaders.fetchCollectionWallets = true;
  },
  [Types.FETCH_COLLECTION_WALLETS_SUCCESS]: (state, action) => {
    const { data } = action.payload;
    state.walletsLoaders.fetchCollectionWallets = false;
    state.collectionWallets = data.content;
    state.collectionWalletsPagination = {
      number: data.page.number,
      size: data.page.size,
      totalPages: data.page.totalPages,
      totalElements: data.page.totalElements,
    };
  },
  [Types.FETCH_COLLECTION_WALLETS_FAIL]: (state) => {
    state.walletsLoaders.fetchCollectionWallets = false;
  },

  [Types.CREATE_COLLECTION_WALLET]: (state) => {
    state.walletsLoaders.submit = true;
  },
  [Types.CREATE_COLLECTION_WALLET_SUCCESS]: (state, action) => {
    state.walletsLoaders.submit = false;
    state.collectionWallets = [action.payload.data, ...state.collectionWallets];
  },
  [Types.CREATE_COLLECTION_WALLET_FAIL]: (state) => {
    state.walletsLoaders.submit = false;
  },

  [Types.UPDATE_COLLECTION_WALLET]: (state) => {
    state.walletsLoaders.submit = true;
  },
  [Types.UPDATE_COLLECTION_WALLET_SUCCESS]: (state, action) => {
    state.walletsLoaders.submit = false;
    state.collectionWallets = [
      ...state.collectionWallets.map((value) =>
        value.id === action.payload.data.id ? action.payload.data : value
      ),
    ];
  },
  [Types.UPDATE_COLLECTION_WALLET_FAIL]: (state) => {
    state.walletsLoaders.submit = false;
  },

  [Types.CREDIT_COLLECTION_WALLET]: (state) => {
    state.walletsLoaders.creditSubmit = true;
  },
  [Types.CREDIT_COLLECTION_WALLET_SUCCESS]: (state, action) => {
    state.walletsLoaders.creditSubmit = false;
    state.collectionWallets = [
      ...state.collectionWallets.map((value) =>
        value.id === action.payload.data.id ? action.payload.data : value
      ),
    ];
  },
  [Types.CREDIT_COLLECTION_WALLET_FAIL]: (state) => {
    state.walletsLoaders.creditSubmit = false;
  },

  [Types.DEBIT_COLLECTION_WALLET]: (state) => {
    state.walletsLoaders.debitSubmit = true;
  },
  [Types.DEBIT_COLLECTION_WALLET_SUCCESS]: (state, action) => {
    state.walletsLoaders.debitSubmit = false;
    state.collectionWallets = [
      ...state.collectionWallets.map((value) =>
        value.id === action.payload.data.id ? action.payload.data : value
      ),
    ];
  },
  [Types.DEBIT_COLLECTION_WALLET_FAIL]: (state) => {
    state.walletsLoaders.debitSubmit = false;
  },

  [Types.FETCH_ORG_PAYMENTS]: (state) => {
    state.walletsLoaders.fetchPayments = true;
  },
  [Types.FETCH_ORG_PAYMENTS_SUCCESS]: (state, action) => {
    const {
      data: { content, page },
    } = action.payload;
    state.walletsLoaders.fetchPayments = false;
    state.payments = content;
    state.walletsPagination = {
      number: page.number,
      size: page.size,
      totalPages: page.totalPages,
      totalElements: page.totalElements,
    };
  },
  [Types.FETCH_ORG_PAYMENTS_FAIL]: (state) => {
    state.payments = [];
    state.walletsLoaders.fetchPayments = false;
  },
});
