import { FC } from "react";
import { ExpandTable, Loader } from "../App";
import dayJs from "dayjs";

interface LoanTableProps {
  control?: any;
  columns: any[];
  data: any[];
  onChange?: (value: string) => void;
  onPaginationChange?: (page: number) => void;
  loading: boolean;
  title: string;
  SubComponent?: React.FC<{ row: any }>;
}

export const LoanTransactionPageTable: FC<LoanTableProps> = ({
  columns,
  data,
  onChange = (value) => {},
  onPaginationChange,
  loading,
  title,
  SubComponent,
}) => {
  // const { selectedLoan } = useSelector(
  //   (state: { loan: InitialLoanState }) => state.loan);
  const getColumns = () => {
    const availableColumns = [
      {
        id: "commandId",
        Header: "Type",
        accessor: "commandId",
      },
      {
        id: "amountDeposited",
        Header: "Deposited(KES)",
        accessor: (row: any) => {
          return <span>{(row?.amountDeposited).toFixed(2)}</span>;
        },
      },
      {
        id: "amountWithdrawn",
        Header: "Withdrawn(KES)",
        accessor: (row: any) => {
          return <span>{(row?.amountWithdrawn).toFixed(2)}</span>;
        },
      },
      {
        id: "label",
        Header: "Status",
        accessor: "label",
      },
      {
        id: "dateCreated",
        Header: "Timestamp",
        accessor: (row: any) => {
          return (
            <span>{dayJs(row?.dateCreated).format("YYYY-MM-DD hh:mm A")}</span>
          );
        },
      },
      {
        id: "lastModified",
        Header: "Last Update",
        accessor: (row: any) => {
          return (
            <span>{dayJs(row?.lastModified).format("YYYY-MM-DD hh:mm A")}</span>
          );
        },
      },
    ];

    return columns.map((column) =>
      availableColumns.find(
        (availableColumn) => availableColumn.id === column.id
      )
    );
  };

  if (loading) {
    return (
      <div className="mt-4 rounded-lg flex flex-row items-center justify-center w-full h-60 bg-white">
        <Loader />
      </div>
    );
  }
  return (
    <>
      <ExpandTable
        // title={`${selectedLoan.agentName} ${title}`}
        columns={getColumns()}
        data={data}
        pageCount={0}
        currentPage={0}
        onPaginationChange={onPaginationChange}
        SubComponent={SubComponent}
      />
    </>
  );
};
