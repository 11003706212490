import { createReducer } from "@reduxjs/toolkit";
import { PaginationProps } from "../App";
import { Types } from "./actionTypes";

export interface InitialLoanState {
  cache: Record<string, any>;
  loans: any[];
  loansTransaction: any[];
  selectedLoan: any;
  searchParams: any;
  loansPagination: PaginationProps;
  loansLoaders: any;
  loansModels: any;
}

const initialState: InitialLoanState = {
  loans: [],
  loansTransaction: [],
  loansPagination: {
    number: 0,
    totalPages: 1,
    size: 10,
    totalElements: 0,
  },
  selectedLoan: {},
  searchParams: {},
  loansLoaders: {
    submit: false,
    fetchLoans: false,
    fetchLoanTransactions: false,
    fetchLoanStatement: false,
  },
  loansModels: {
    downloadStatement: false,
  },
  cache: {},
};

export const loansReducer = createReducer(initialState, {
  [Types.TOGGLE_LOAN_MODALS]: (state, action) => {
    state.loansModels = {
      ...state.loansModels,
      ...action.payload,
    };
  },
  [Types.UPDATE_SELECTED_LOAN]: (state, action) => {
    state.selectedLoan = action.payload;
  },

  [Types.UPDATE_SEARCH_PARAMS]: (state, action) => {
    state.searchParams = action.payload;
  },

  [Types.FETCH_LOANS]: (state) => {
    state.loansLoaders.fetchLoans = true;
  },
  [Types.FETCH_LOANS_SUCCESS]: (state, action) => {
    const {
      data: { content, page },
    } = action.payload;
    state.loansLoaders.fetchLoans = false;
    state.loans = content;
    state.loansPagination = {
      number: page.number,
      size: page.size,
      totalPages: page.totalPages,
      totalElements: page.totalElements,
    };
  },
  [Types.FETCH_LOANS_FAIL]: (state) => {
    state.loansLoaders.fetchLoans = false;
  },

  [Types.FETCH_LOAN_TRANSACTIONS]: (state) => {
    state.loansLoaders.fetchLoanTransactions = true;
  },
  [Types.FETCH_LOAN_TRANSACTIONS_SUCCESS]: (state, action) => {
    const { data: content } = action.payload;
    state.loansLoaders.fetchLoanTransactions = false;
    state.loansTransaction = content;
  },
  [Types.FETCH_LOAN_TRANSACTIONS_FAIL]: (state) => {
    state.loansLoaders.fetchLoanTransactions = false;
  },

  [Types.FETCH_LOAN_STATEMENT]: (state) => {
    state.loansLoaders.fetchLoanStatement = true;
  },
  [Types.FETCH_LOAN_STATEMENT_SUCCESS]: (state, action) => {
    const { data: content } = action.payload;
    state.loansLoaders.fetchLoanStatement = false;
    state.loansTransaction = content;
  },
  [Types.FETCH_LOAN_STATEMENT_FAIL]: (state) => {
    state.loansLoaders.fetchLoanStatement = false;
  },
});
