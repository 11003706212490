import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TextField } from "../../App/FormComponents";
import { DescriptionPanel } from "../Messages";

export const Subject: FC = () => {
  const { control } = useFormContext();

  return (
    <DescriptionPanel label="Subject">
      <Controller
        name="subject"
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <TextField
              id="subject"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          );
        }}
      />
    </DescriptionPanel>
  );
};
