import { createReducer } from "@reduxjs/toolkit";
import { Types } from "./actionTypes";

export interface InitialBSWalletState {
  cache: Record<string, any>;
  bswallets: any[];
  bswalletModals: any;
  bswalletsLoaders: any;
  selectedBSWallet: any;
}

const initialState: InitialBSWalletState = {
  bswallets: [],

  bswalletModals: {
    addBSWallet: false,
    editBSWallet: false,
    deleteBSWallet: false,
    disableBSWallet: false,
    disableEnableBSWallet: false,
  },
  bswalletsLoaders: {
    submit: false,
    fetchBSWallets: false,
    fetchTrasactionFees: false,
  },

  cache: {
    emailRequests: {},
    smsRequests: {},
    inAppRequests: {},
  },
  selectedBSWallet: {},
};

export const bswalletsReducer = createReducer(initialState, {
  [Types.UPDATE_SELECTED_BSWALLET]: (state, action) => {
    state.selectedBSWallet = action.payload;
  },
  [Types.TOGGLE_BSWALLET_MODALS]: (state, action) => {
    state.bswalletModals = {
      ...state.bswalletModals,
      ...action.payload,
    };
  },

  [Types.FETCH_BSWALLETS]: (state) => {
    state.bswalletsLoaders.fetchBSWallets = true;
  },
  [Types.FETCH_BSWALLETS_SUCCESS]: (state, action) => {
    const { data } = action.payload;
    state.bswalletsLoaders.fetchBSWallets = false;
    state.bswallets = data;
  },
  [Types.FETCH_BSWALLETS_FAIL]: (state) => {
    state.bswalletsLoaders.fetchBSWallets = false;
  },

  [Types.CREATE_BSWALLET]: (state) => {
    state.bswalletsLoaders.submit = true;
  },
  [Types.CREATE_BSWALLET_SUCCESS]: (state, action) => {
    state.bswalletsLoaders.submit = false;
    state.bswallets = [action.payload.data, ...state.bswallets];
  },
  [Types.CREATE_BSWALLET_FAIL]: (state) => {
    state.bswalletsLoaders.submit = false;
  },

  [Types.UPDATE_BSWALLET]: (state) => {
    state.bswalletsLoaders.submit = true;
  },

  [Types.UPDATE_BSWALLET_SUCCESS]: (state, action) => {
    const {
      previousAction: {
        payload: {
          request: {
            bswallet: { id },
            bswallet,
          },
        },
      },
    } = action.meta;
    state.bswalletsLoaders.submit = false;
    state.bswallets = [
      ...state.bswallets.map((pr) => (pr.id === id ? bswallet : pr)),
    ];
  },
  [Types.UPDATE_BSWALLET_FAIL]: (state) => {
    state.bswalletsLoaders.submit = false;
  },

  [Types.CHANGE_BSWALLET_STATUS]: (state) => {
    state.bswalletsLoaders.submit = true;
  },

  [Types.CHANGE_BSWALLET_STATUS_SUCCESS]: (state, action) => {
    const {
      previousAction: {
        payload: {
          request: {
            selectedBSWallet: { id },
            selectedBSWallet,
          },
        },
      },
    } = action.meta;
    state.bswalletsLoaders.submit = false;
    state.bswallets = [
      ...state.bswallets.map((bswallet) =>
        bswallet.id === id ? selectedBSWallet : bswallet
      ),
    ];
  },
  [Types.CHANGE_BSWALLET_STATUS_FAIL]: (state) => {
    state.bswalletsLoaders.submit = false;
  },
});
