import { FC } from "react";

interface CheckBox2Props {
  label: string;
  name: string;
  value: boolean;
  onChange: (value: boolean) => void;
}

export const CheckBox2: FC<CheckBox2Props> = ({
  label,
  name,
  value,
  onChange,
}) => {
  return (
    <div className="mb-2">
      <div className="flex items-start">
        <div className="flex items-center h-5">
          <input
            name={name}
            type="checkbox"
            className="pr-4 focus:ring-tandaPurple
             h-4 w-4 text-tandaPurple 
            border-gray-300 rounded"
            onChange={() => onChange(!value)}
            checked={value}
          />
          {label && (
            <label
              htmlFor={name}
              className="ml-2 block text-sm font-medium text-gray-700"
            >
              {label}
            </label>
          )}
        </div>
      </div>
    </div>
  );
};
