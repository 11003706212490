import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader, Table } from "../../App";
import { PaginationProps } from "../../../domains/App";
import { RadioBox } from "../../App/FormComponents";
import { EditIntgForm } from ".";
import {
  changeStatus,
  deleteIntg,
  InitialIOIntgstate,
  toggleIntgModal,
} from "../../../domains/IOIntegration";
import { ToggleModal } from "../../App/ToggleModal";
import { InitialIOWalletState } from "../../../domains/IOWallets";
import { ToggleButton } from "../../App/FormComponents/ToggleButton";

interface IntgTableProps {
  control?: any;
  columns: any[];
  data: any[];
  onChange?: (value: string) => void;
  onPaginationChange?: (page: number) => void;
  pagination: PaginationProps;
  loading: boolean;
}

export const IntgTable: FC<IntgTableProps> = ({
  columns,
  data,
  onChange = (value) => {},
  onPaginationChange,
  pagination,
  loading,
}) => {
  const dispatch = useDispatch();
  let [selectedIntg, setSelectedIntg] = useState<any>();
  const { selectedIOWallet } = useSelector(
    (state: { iowallet: InitialIOWalletState }) => state.iowallet
  );
  const { intgModals, iointgsLoaders } = useSelector(
    (state: { iointgs: InitialIOIntgstate }) => state.iointgs
  );

  // const handleOpenDeleteModal = (id: string) =>
  // {
  //   setSelectedIntg(id);
  //   dispatch(
  //     toggleIntgModal({
  //       deleteIntg: true,
  //     })
  //   );
  // };

  const handleCloseDeleteModal = () =>
    dispatch(
      toggleIntgModal({
        deleteIntg: false,
      })
    );

  const handleOpenDisableModal = (id: string) => {
    setSelectedIntg(id);
    dispatch(
      toggleIntgModal({
        disableIntg: true,
      })
    );
  };

  const handleCloseDisableModal = () =>
    dispatch(
      toggleIntgModal({
        disableIntg: false,
      })
    );
  const onDelete = async () => {
    if (!!selectedIntg) {
      await dispatch(deleteIntg({ id: selectedIntg }));
    }
    handleCloseDeleteModal();
  };

  // Change status between enable and disable
  const onToggleStatus = async () => {
    if (!!selectedIntg) {
      const status = {
        enabled: !selectedIntg.enabled,
      };
      selectedIntg = { ...selectedIntg, enabled: !selectedIntg.enabled };
      await dispatch(changeStatus(selectedIntg, status));
    }
    handleCloseDisableModal();
  };

  const status = selectedIntg?.enabled ? "Disable" : "Enable";
  const message =
    "Are you sure you want to " + status + " this Transaction Fee Rate?";

  // const handleEditModal = (value: any) =>
  // {
  //   setSelectedIntg(value);
  //   dispatch(
  //     toggleIntgModal({
  //       editIntg: true,
  //     })
  //   );
  // };
  const getColumns = () => {
    const availableColumns = [
      {
        id: "paymentGatewayId",
        Header: "Payment Gateway",
        accessor: "paymentGatewayId",
      },
      {
        id: "paymentGatewayName",
        Header: "Name",
        accessor: "paymentGatewayName",
      },
      {
        id: "minimumValue",
        Header: "Minimum(KES)",
        accessor: (row: any) => {
          return <span>{(row?.minimumValue).toFixed(2)}</span>;
        },
      },
      {
        id: "maximumValue",
        Header: "Maximum(KES)",
        accessor: (row: any) => {
          return <span>{(row?.maximumValue).toFixed(2)}</span>;
        },
      },
      {
        id: "enabled",
        Header: "Status",
        accessor: (row: any) => {
          return <span>{row?.enabled ? "Enabled" : "Disabled"}</span>;
        },
      },
      {
        id: "actions",
        accessor: (row: any) => {
          return (
            <div className="flex flex-row items-center space-x-2">
              {row?.enabled ? (
                <ToggleButton
                  onChange={() => handleOpenDisableModal(row)}
                  name={"status"}
                  value={row?.enabled}
                >
                  Disable
                </ToggleButton>
              ) : (
                <ToggleButton
                  onChange={() => handleOpenDisableModal(row)}
                  name={"status"}
                  value={row?.enabled}
                >
                  Disable
                </ToggleButton>
              )}
              {/* <Button
                onClick={() => handleOpenDeleteModal(row.id)}
                size="xs"
                variant="danger"
              >
                Delete
              </Button>
              <Button
                onClick={() => handleEditModal(row)}
                size="xs"
                variant="primary"
              >
                Edit
              </Button> */}
            </div>
          );
        },
      },
      {
        id: "select",
        accessor: (row: any) => {
          return (
            <RadioBox
              name="serviceProvider"
              onChange={onChange}
              value={JSON.stringify(row)}
            />
          );
        },
      },
    ];

    return columns.map((column) =>
      availableColumns.find(
        (availableColumn) => availableColumn.id === column.id
      )
    );
  };

  if (loading) {
    return (
      <div className="mt-4 rounded-lg flex flex-row items-center justify-center w-full h-60 bg-white">
        <Loader />
      </div>
    );
  }
  return (
    <>
      <ToggleModal
        isOpen={intgModals.deleteIntg}
        onClose={handleCloseDeleteModal}
        onSubmit={onDelete}
        message="Are you sure you want to Delete this Customer Transaction Fee Rate?"
        title="Delete"
        loading={iointgsLoaders.submit}
      />
      <ToggleModal
        isOpen={intgModals.disableIntg}
        onClose={handleCloseDisableModal}
        onSubmit={onToggleStatus}
        message={message}
        title={status}
        loading={iointgsLoaders.submit}
      />
      <EditIntgForm intg={selectedIntg} />

      <Table
        title={selectedIOWallet.name}
        columns={getColumns()}
        data={data}
        pageCount={pagination.totalPages}
        currentPage={pagination.number}
        onPaginationChange={onPaginationChange}
      />
    </>
  );
};
