import { LOCAL_STORE_KEY } from "../constants";

export const getAuthToken = () => {
  return window.localStorage.getItem(LOCAL_STORE_KEY);
};

export const setAuthToken = (authToken: string) => {
  return window.localStorage.setItem(LOCAL_STORE_KEY, authToken);
};

export const removeAuthToken = () => {
  return window.localStorage.removeItem(LOCAL_STORE_KEY);
};
