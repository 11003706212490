import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader, Button, Table } from "../../App";
import { PaginationProps } from "../../../domains/App";
import { RadioBox } from "../../App/FormComponents";
import { EditCustomerTransactionFeeRateForm } from "../CustomerTransactionFeeRates";
import {
  changeStatus,
  deleteCustomerTransactionFeeRate,
  InitialCustomerTransactionFeeRateState,
  toggleCustomerTransactionFeeRateModal,
} from "../../../domains/CustomerTransactionFeeRate";
import { ToggleModal } from "../../App/ToggleModal";
import { InitialProductState } from "../../../domains/Products";
import { ToggleButton } from "../../App/FormComponents/ToggleButton";

interface CustomerTransactionFeeRateTableProps {
  control?: any;
  columns: any[];
  data: any[];
  onChange?: (value: string) => void;
  onPaginationChange?: (page: number) => void;
  pagination: PaginationProps;
  loading: boolean;
  SubComponent?: React.FC<{ row: any }>;
}

export const CustomerTransactionFeeRateTable: FC<CustomerTransactionFeeRateTableProps> =
  ({
    columns,
    data,
    onChange = (value) => {},
    onPaginationChange,
    pagination,
    loading,
  }) => {
    const dispatch = useDispatch();
    let [
      selectedCustomerTransactionFeeRate,
      setSelectedCustomerTransactionFeeRate,
    ] = useState<any>();
    const { selectedProduct } = useSelector(
      (state: { product: InitialProductState }) => state.product
    );
    const {
      customerTransactionFeeRateModals,
      customerTransactionFeeRatesLoaders,
    } = useSelector(
      (state: {
        customerTransactionFeeRate: InitialCustomerTransactionFeeRateState;
      }) => state.customerTransactionFeeRate
    );

    const handleOpenDeleteModal = (id: string) => {
      setSelectedCustomerTransactionFeeRate(id);
      dispatch(
        toggleCustomerTransactionFeeRateModal({
          deleteCustomerTransactionFeeRate: true,
        })
      );
    };

    const handleCloseDeleteModal = () =>
      dispatch(
        toggleCustomerTransactionFeeRateModal({
          deleteCustomerTransactionFeeRate: false,
        })
      );
    const handleOpenDisableModal = (id: any) => {
      setSelectedCustomerTransactionFeeRate(id);
      dispatch(
        toggleCustomerTransactionFeeRateModal({
          disableCustomerTransactionFeeRate: true,
        })
      );
    };

    const handleCloseDisableModal = () =>
      dispatch(
        toggleCustomerTransactionFeeRateModal({
          disableCustomerTransactionFeeRate: false,
        })
      );
    const onDelete = async () => {
      if (!!selectedCustomerTransactionFeeRate) {
        await dispatch(
          deleteCustomerTransactionFeeRate({
            id: selectedCustomerTransactionFeeRate,
          })
        );
      }
      handleCloseDeleteModal();
    };

    // Change status between enable and disable
    const onToggleStatus = async () => {
      if (!!selectedCustomerTransactionFeeRate) {
        const status = {
          enabled: !selectedCustomerTransactionFeeRate.enabled,
        };
        selectedCustomerTransactionFeeRate = {
          ...selectedCustomerTransactionFeeRate,
          enabled: !selectedCustomerTransactionFeeRate.enabled,
        };

        await dispatch(
          changeStatus(selectedCustomerTransactionFeeRate, status)
        );
      }
      handleCloseDisableModal();
    };

    const status = selectedCustomerTransactionFeeRate?.enabled
      ? "Disable"
      : "Enable";
    const message =
      "Are you sure you want to " + status + " this Transaction Fee Rate?";

    const handleEditModal = (value: any) => {
      setSelectedCustomerTransactionFeeRate(value);
      dispatch(
        toggleCustomerTransactionFeeRateModal({
          editCustomerTransactionFeeRate: true,
        })
      );
    };

    const getColumns = () => {
      const availableColumns = [
        {
          id: "feeType",
          Header: "Fee Type",
          accessor: "feeType",
        },
        {
          id: "feeAmount",
          Header: "Fee Amount(KES)",
          accessor: (row: any) => {
            return <span>{(row?.feeAmount).toFixed(2)}</span>;
          },
        },
        {
          id: "startingInclusive",
          Header: "Starting Inclusive(KES)",
          accessor: (row: any) => {
            return <span>{(row?.startingInclusive).toFixed(2)}</span>;
          },
        },
        {
          id: "endingInclusive",
          Header: "Ending Inclusive(KES)",
          accessor: (row: any) => {
            return <span>{(row?.endingInclusive).toFixed(2)}</span>;
          },
        },
        {
          id: "enabled",
          Header: "Status",
          accessor: (row: any) => {
            return <span>{row?.enabled ? "Enabled" : "Disabled"}</span>;
          },
        },
        {
          id: "collectionType",
          Header: "Collection Type",
          accessor: "collectionType",
        },
        {
          id: "actions",
          accessor: (row: any) => {
            return (
              <div className="flex flex-row items-center space-x-2">
                {row?.enabled ? (
                  <ToggleButton
                    onChange={() => handleOpenDisableModal(row)}
                    name={"status"}
                    value={row?.enabled}
                  >
                    Disable
                  </ToggleButton>
                ) : (
                  <ToggleButton
                    onChange={() => handleOpenDisableModal(row)}
                    name={"status"}
                    value={row?.enabled}
                  >
                    Disable
                  </ToggleButton>
                )}
                <Button
                  onClick={() => handleOpenDeleteModal(row.id)}
                  size="xs"
                  variant="danger"
                >
                  Delete
                </Button>
                <Button
                  onClick={() => handleEditModal(row)}
                  size="xs"
                  variant="primary"
                >
                  Edit
                </Button>
              </div>
            );
          },
        },
        {
          id: "select",
          accessor: (row: any) => {
            return (
              <RadioBox
                name="commissionFeeRate"
                onChange={onChange}
                value={JSON.stringify(row)}
              />
            );
          },
        },
      ];

      return columns.map((column) =>
        availableColumns.find(
          (availableColumn) => availableColumn.id === column.id
        )
      );
    };

    if (loading) {
      return (
        <div className="mt-4 rounded-lg flex flex-row items-center justify-center w-full h-60 bg-white">
          <Loader />
        </div>
      );
    }
    return (
      <>
        <ToggleModal
          isOpen={
            customerTransactionFeeRateModals.deleteCustomerTransactionFeeRate
          }
          onClose={handleCloseDeleteModal}
          onSubmit={onDelete}
          message="Are you sure you want to Delete this Customer Transaction Fee Rate?"
          title="Delete"
          loading={customerTransactionFeeRatesLoaders.submit}
        />
        <ToggleModal
          isOpen={
            customerTransactionFeeRateModals.disableCustomerTransactionFeeRate
          }
          onClose={handleCloseDisableModal}
          onSubmit={onToggleStatus}
          message={message}
          title={status}
          loading={customerTransactionFeeRatesLoaders.submit}
        />
        <EditCustomerTransactionFeeRateForm
          customerTransactionFeeRate={selectedCustomerTransactionFeeRate}
        />

        <Table
          title={selectedProduct.name}
          columns={getColumns()}
          data={data}
          pageCount={pagination.totalPages}
          currentPage={pagination.number}
          onPaginationChange={onPaginationChange}
        />
      </>
    );
  };
