import { getURL } from "../../utils";
import { Types } from "./actionTypes";

export const toggleLoanModal = (payload: any) => async (dispatch: any) =>
  dispatch({
    type: Types.TOGGLE_LOAN_MODALS,
    payload,
  });

export const updateSelectedLoan = (payload: any) => async (dispatch: any) => {
  await dispatch({
    type: Types.UPDATE_SELECTED_LOAN,
    payload,
  });
};

export const updateSearchParams = (payload: any) => async (dispatch: any) => {
  await dispatch({
    type: Types.UPDATE_SEARCH_PARAMS,
    payload,
  });
};

export const fetchLoans = (pagination?: any) => async (dispatch: any) =>
  await dispatch({
    type: Types.FETCH_LOANS,
    payload: {
      request: {
        method: "GET",
        url: getURL(`/loans/v1/disbursed-loans`, pagination),
        useCache: true,
      },
    },
  });

export const fetchLoanTransactions =
  (loanId: string) => async (dispatch: any) => {
    await dispatch({
      type: Types.FETCH_LOAN_TRANSACTIONS,
      payload: {
        request: {
          url: `/loans/v1/disbursed-loans/${loanId}/transactions`,
          method: "GET",
          useCache: true,
        },
      },
    });
  };

export const fetchLoanStatement = (loanId: string) => async (dispatch: any) => {
  await dispatch({
    type: Types.FETCH_LOAN_STATEMENT,
    payload: {
      request: {
        url: `/loans/v1/disbursed-loans/${loanId}/transactions`,
        method: "GET",
        useCache: true,
      },
    },
  });
};
