import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Banners, SuccessOrErrorState } from "../../App";
import { BatchTypeTable } from "./BatchTypeTable";
import { BatchTypeForm } from "./BatchTypeForm";
import {
  fetchBatchTypes,
  InitialBatchTypeState,
} from "../../../domains/BatchType";
import { config } from "../../../constants";
import _ from "lodash";

export const BatchTypePage: FC = () => {
  const dispatch = useDispatch();
  const { batchTypes, batchTypesLoaders, batchTypesPagination } = useSelector(
    (state: { batchType: InitialBatchTypeState }) => state.batchType
  );

  useEffect(() => {
    const fetchBatchTypesAsync = async () => await dispatch(fetchBatchTypes());

    fetchBatchTypesAsync();
  }, [dispatch]);

  const handlePagination = (page: number) => {
    dispatch(
      fetchBatchTypes({
        page: page - 1,
        size: batchTypesPagination.size,
      })
    );
  };
  return (
    <Layout>
      <div>
        <Banners>
          <p className="pl-3">Products Batch Types</p>
        </Banners>
      </div>
      <BatchTypeForm />
      <div className="pt-3">
        {_.isEmpty(batchTypes) && !batchTypesLoaders.fetchBatchTypes ? (
          <SuccessOrErrorState state="empty" message="No Batch Types Found" />
        ) : (
          <BatchTypeTable
            columns={config.products.batchType.listColumns}
            data={batchTypes}
            pagination={batchTypesPagination}
            loading={batchTypesLoaders.fetchBatchTypes}
            onPaginationChange={handlePagination}
          />
        )}
      </div>
    </Layout>
  );
};
