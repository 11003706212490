import { getURL } from "../../utils";
import { Types } from "./actionTypes";

export const toggleCustomerTransactionFeeRateModal =
  (payload: any) => async (dispatch: any) =>
    dispatch({
      type: Types.TOGGLE_CUSTOMERTRANSACTIONFEERATE_MODALS,
      payload,
    });

export const fetchCustomerTransactionFees =
  (productId: string, pagination?: any) => async (dispatch: any) => {
    await dispatch({
      type: Types.FETCH_CUSTOMERTRANSACTIONFEERATES,
      payload: {
        request: {
          url: getURL(
            `/wallets/v1/dfses/${productId}/customer-transaction-fees`,
            pagination
          ),
          method: "GET",
        },
      },
    });
  };

export const createCustomerTransactionFeeRate =
  (productId: any, data: any) => async (dispatch: any) => {
    await dispatch({
      type: Types.CREATE_CUSTOMERTRANSACTIONFEERATE,
      payload: {
        request: {
          url: `/wallets/v1/dfses/${productId}/customer-transaction-fees`,
          method: "POST",
          data,
        },
      },
    });
  };

export const updateCustomerTransactionFeeRate =
  (customerTransactionFeeRate: any, data: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.UPDATE_CUSTOMERTRANSACTIONFEERATE,
      payload: {
        request: {
          url: `/wallets/v1/customer-transaction-fees/${customerTransactionFeeRate.id}`,
          method: "PUT",
          data,
          customerTransactionFeeRate,
        },
      },
    });

export const deleteCustomerTransactionFeeRate =
  (customerTransactionFeeRate: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.DELETE_CUSTOMERTRANSACTIONFEERATE,
      payload: {
        request: {
          url: `/wallets/v1/customer-transaction-fees/${customerTransactionFeeRate.id}`,
          method: "DELETE",
          deleteProp: customerTransactionFeeRate,
        },
      },
    });

export const changeStatus =
  (selectedCustomerTransactionFeeRate: any, status: any) =>
  async (dispatch: any) =>
    await dispatch({
      type: Types.CHANGE_CUST_STATUS,
      payload: {
        request: {
          url: `/wallets/v1/customer-transaction-fees/${selectedCustomerTransactionFeeRate.id}`,
          method: "PATCH",
          data: status,
          selectedCustomerTransactionFeeRate,
        },
      },
    });
