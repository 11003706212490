import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout, SuccessOrErrorState, Banners } from "../../App";
import { UsersTable } from "./UsersTable";
import { config } from "../../../constants";
import {
  fetchAllGroups,
  InitialUserStateProps,
  toggleUserModal,
} from "../../../domains/Users";
import { useEffect } from "react";
import _ from "lodash";
import { GroupForm } from "./GroupForm";

export const AllGroupsPage: FC = () => {
  const dispatch = useDispatch();
  const { userLoader, groups, groupPagination } = useSelector(
    (state: { users: InitialUserStateProps }) => state.users
  );

  useEffect(() => {
    const fetchAllGroupsAsync = () => {
      dispatch(fetchAllGroups());
    };
    fetchAllGroupsAsync();
  }, [dispatch]);

  const handlePagination = (page: number) => {
    dispatch(
      fetchAllGroups({
        page: page - 1,
        size: groupPagination.size,
      })
    );
  };

  const handleAction = () => {
    dispatch(
      toggleUserModal({
        groupModal: true,
      })
    );
  };

  return (
    <Layout>
      <div>
        <Banners withAction actionLabel="Add Group" onClick={handleAction}>
          <p className="pl-3">Do you want to create a group?</p>
        </Banners>
      </div>
      <GroupForm />
      <div className="pt-6">
        {_.isEmpty(groups) && !userLoader.fetchAllGroups ? (
          <SuccessOrErrorState
            message="No groups available"
            state="empty"
            withAction
            actionLabel="Add Group"
            onClick={handleAction}
          />
        ) : (
          <UsersTable
            columns={config.users.allGroups.listColumns}
            pagination={groupPagination}
            data={groups}
            loading={userLoader.fetchAllGroups}
            onPaginationChange={handlePagination}
          />
        )}
      </div>
    </Layout>
  );
};
