import dayjs from "dayjs";
import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  InitialWalletState,
  toggleWalletModal,
} from "../../../domains/Reseller/Wallet";
import { checkValue } from "../../../utils/app";
import { DescriptionPanel, Drawer } from "../../App";
import { QueryStatus } from "../Payouts/QueryStatus";

interface PayoutDetailsProps {
  payoutDetails: any;
}

export const SubWalletsDetails: FC<PayoutDetailsProps> = ({
  payoutDetails,
}) => {
  const dispatch = useDispatch();
  const { walletModals } = useSelector(
    (state: { resellerWallet: InitialWalletState }) => state.resellerWallet
  );
  const [openTab, setOpenTab] = useState(1);
  const onClose = async () => {
    setOpenTab(1);
    await dispatch(
      toggleWalletModal({
        showDetails: false,
      })
    );
  };
  const getStatus = (row: any) => {
    switch (row) {
      case "000000":
        return "Success";
      case "000001":
        return "Pending";
      default:
        return "Failed";
    }
  };

  return (
    <Drawer
      isOpen={walletModals.showDetails}
      onClose={onClose}
      drawerTitle="Transaction Description"
    >
      <>
        <div className="flex flex-wrap -mt-10">
          <div className="w-full">
            <ul
              className="flex mb-0 list-none flex-wrap flex-row"
              role="tablist"
            >
              <li className="-mb-px mr-2 last:mr-0 text-center">
                <a
                  className={
                    "inline-block p-2 rounded-t-lg border-b-4 border-transparent hover:text-tandaGreen hover:border-tandaGreen hover:border-b-4" +
                    (openTab === 1
                      ? "text-tandaGreen border-b-2 border-tandaGreen"
                      : "text-gray-600 bg-white")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(1);
                  }}
                  data-toggle="tab"
                  href="#link1"
                  role="tablist"
                >
                  Basic info
                </a>
              </li>
              {payoutDetails?.type.k !== "TransactionFee" &&
                payoutDetails?.type.k !== "CommissionFee" && (
                  <li className="-mb-px mr-2 last:mr-0 text-center">
                    <a
                      className={
                        "inline-block p-2 rounded-t-lg border-b-4 border-transparent hover:text-tandaGreen hover:border-tandaGreen hover:border-4 dark:hover:text-gray-300" +
                        (openTab === 2
                          ? "text-tandaGreen border-b-2 border-tandaGreen"
                          : "text-gray-600 ")
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(2);
                      }}
                      data-toggle="tab"
                      href="#link2"
                      role="tablist"
                    >
                      More info
                    </a>
                  </li>
                )}
            </ul>
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
              <div className="px-4 py-5 flex-auto">
                <div className="tab-content tab-space">
                  <div
                    className={openTab === 1 ? "block" : "hidden"}
                    id="link1"
                  >
                    <div className="w-full">
                      <DescriptionPanel
                        label="ID"
                        description={payoutDetails?.id}
                      />
                      <DescriptionPanel
                        label="Payment Ref"
                        description={payoutDetails?.reference}
                      />
                      <DescriptionPanel
                        label="Product"
                        description={payoutDetails?.dfs?.name}
                      />
                      <DescriptionPanel
                        label="Actual Before"
                        description={"KES " + payoutDetails?.actualBefore}
                      />
                      <DescriptionPanel
                        label="Customer Name"
                        description={checkValue(payoutDetails?.customerName)}
                      />
                      <DescriptionPanel
                        label="Customer Account"
                        description={checkValue(payoutDetails?.customerAccount)}
                      />
                      <DescriptionPanel
                        label="Type"
                        description={payoutDetails?.type?.name}
                      />
                      <DescriptionPanel
                        label="Amount"
                        description={"KES " + payoutDetails?.amount?.toFixed(2)}
                      />
                      <DescriptionPanel
                        label="Status"
                        description={getStatus(payoutDetails?.status)}
                      />
                      <DescriptionPanel
                        label="Initialized On"
                        description={dayjs(payoutDetails?.dateCreated).format(
                          "YYYY-MM-DD hh:mm A"
                        )}
                      />
                      <DescriptionPanel
                        label="Completed On"
                        description={dayjs(payoutDetails?.lastModified).format(
                          "YYYY-MM-DD hh:mm A"
                        )}
                      />
                    </div>{" "}
                  </div>
                  {payoutDetails?.type.k !== "TransactionFee" &&
                    payoutDetails?.type.k !== "CommissionFee" && (
                      <div
                        className={openTab === 2 ? "block" : "hidden"}
                        id="link2"
                      >
                        <QueryStatus payoutDetails={payoutDetails} />
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Drawer>
  );
};
