import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Banners, SuccessOrErrorState } from "../../App";
import { ProductTable } from "./ProductTable";
import { ProductForm } from "./ProductForm";
import {
  fetchProducts,
  InitialProductState,
  toggleProductModal,
} from "../../../domains/Products";
import { config } from "../../../constants";
import _ from "lodash";

export const ProductPage: FC = () => {
  const dispatch = useDispatch();
  const { products, productsLoaders, productsPagination } = useSelector(
    (state: { product: InitialProductState }) => state.product
  );

  useEffect(() => {
    const fetchProductsAsync = async () => await dispatch(fetchProducts());

    fetchProductsAsync();
  }, [dispatch]);

  const handlePagination = (page: number) => {
    dispatch(
      fetchProducts({
        page: page - 1,
        size: productsPagination.size,
      })
    );
  };

  const handleModal = async () => {
    await dispatch(
      toggleProductModal({
        addProduct: true,
      })
    );
  };
  return (
    <Layout>
      <div>
        <Banners withAction actionLabel="Create Product" onClick={handleModal}>
          <p className="pl-3">Do you want to create a product?</p>
        </Banners>
      </div>
      <ProductForm />
      <div className="pt-2">
        {_.isEmpty(products) && !productsLoaders.fetchProducts ? (
          <SuccessOrErrorState state="empty" message="No Product Found" />
        ) : (
          <ProductTable
            title={config.products.product.normal.title}
            columns={config.products.product.normal.listColumns}
            data={products}
            pagination={productsPagination}
            loading={productsLoaders.fetchProducts}
            onPaginationChange={handlePagination}
          />
        )}
      </div>
    </Layout>
  );
};
