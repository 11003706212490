export const checkValue = (variable: any) => {
  if (typeof variable === "undefined" || variable === null) {
    return "-";
  } else {
    return variable;
  }
};

export const changeCase = (word: any) => {
  if (typeof word === "undefined" || word === null) {
    return "";
  } else {
    const l = word?.toLowerCase().trim();
    const words = l?.split(" ");

    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0]?.toUpperCase() + words[i]?.substr(1);
    }

    return words?.join(" ");
  }
};

export const formatAmount = (amount: any) => {
  const formattedCurrency = new Intl.NumberFormat("en-KE", {
    style: "currency",
    currency: "KSh",
  }).format(amount);

  return formattedCurrency.replace("KSH", "KSh");
};

export const getStatus = (row: any) => {
  switch (row) {
    case "000000":
      return "Success";
    case "000001":
      return "Pending";
    default:
      return "Failed";
  }
};

export const API_CLIENT_ROLES = [
  {
    id: "001",
    code: "qbalance",
    name: "Query Balance",
  },
  {
    id: "002",
    code: "transact",
    name: "Transact",
  },
  {
    id: "003",
    code: "qstatus",
    name: "Query Status",
  },
];
