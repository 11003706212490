import { joiResolver } from "@hookform/resolvers/joi";
import { FC } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { schemaValidation } from "../../../constants";
import { openNotification } from "../../../domains/App";
import {
  debitCollectionWallet,
  fetchCollectionWallets,
  InitialWalletState,
  toggleWalletModal,
} from "../../../domains/Reseller/Wallet";
import { DescriptionPanel, Modal } from "../../App";
import { TextField } from "../../App/FormComponents";

interface Wallet {
  amount: number;
}

interface DebitWalletFormProps {
  wallet: any;
}

export const DebitCollectionWalletForm: FC<DebitWalletFormProps> = ({
  wallet,
}) => {
  const dispatch = useDispatch();
  const { walletModals, walletsLoaders } = useSelector(
    (state: { wallet: InitialWalletState }) => state.wallet
  );
  const { resellerId = "" } = useParams<{ resellerId?: string }>();

  const { control, handleSubmit, reset } = useForm<Wallet>({
    resolver: joiResolver(schemaValidation.CreditDebitCollectionWalletSchema),
    defaultValues: {
      amount: 0,
    },
  });

  const onClose = async () => {
    reset();
    await dispatch(
      toggleWalletModal({
        debitCollectionWallet: false,
      })
    );
  };

  const onSubmit: SubmitHandler<Wallet> = async (props) => {
    try {
      await dispatch(debitCollectionWallet(resellerId, wallet, props));
      await dispatch(fetchCollectionWallets(resellerId));
      await onClose();
      await dispatch(
        openNotification({
          openNotification: true,
          type: "success",
          message: "Wallet debited successfully!",
        })
      );
    } catch (e: any) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: e?.error?.response?.data?.message,
        })
      );
    }
  };
  return (
    <Modal
      formId="debit_collection_wallet_form"
      btnType="submit"
      modalTitle={`Debit ${wallet?.name} Sub Wallet`}
      isOpen={walletModals.debitCollectionWallet}
      onClose={onClose}
      btnLoading={walletsLoaders.debitSubmit}
    >
      <div className="mb-4">
        <DescriptionPanel
          label="Actual"
          description={`KES ${wallet?.actual}`}
        />
        <DescriptionPanel
          label="Available"
          description={`KES ${wallet?.available}`}
        />
      </div>
      <form
        id="debit_collection_wallet_form"
        className="space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="amount"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="amount"
              label="Enter Amount"
              type="number"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
      </form>
    </Modal>
  );
};
