export const Types: Record<string, string> = {
  CHANGE_BSWALLETS_TABS: "CHANGE_BSWALLETS_TABS",
  TOGGLE_BSWALLET_MODALS: "TOGGLE_BSWALLET_MODALS",

  FETCH_BSWALLETS: "FETCH_BSWALLETS",
  FETCH_BSWALLETS_SUCCESS: "FETCH_BSWALLETS_SUCCESS",
  FETCH_BSWALLETS_FAIL: "FETCH_BSWALLETS_FAIL",

  CREATE_BSWALLET: "CREATE_BSWALLET",
  CREATE_BSWALLET_SUCCESS: "CREATE_BSWALLET_SUCCESS",
  CREATE_BSWALLET_FAIL: "CREATE_BSWALLET_FAIL",

  UPDATE_BSWALLET: "UPDATE_BSWALLET",
  UPDATE_BSWALLET_SUCCESS: "UPDATE_BSWALLET_SUCCESS",
  UPDATE_BSWALLET_FAIL: "UPDATE_BSWALLET_FAIL",

  DELETE_BSWALLET_: "DELETE_BSWALLET",
  DELETE_BSWALLET_SUCCESS: "DELETE_BSWALLET_SUCCESS",
  DELETE_BSWALLET_FAIL: "DELETE_BSWALLET_FAIL",

  CHANGE_BSWALLET_STATUS: "CHANGE_BSWALLET_STATUS",
  CHANGE_BSWALLET_STATUS_SUCCESS: "CHANGE_BSWALLET_STATUS_SUCCESS",
  CHANGE_BSWALLET_STATUS_FAIL: "CHANGE_BSWALLET_STATUS_FAIL",

  UPDATE_SELECTED_BSWALLET: "UPDATE_SELECTED_BSWALLET",
  UPDATE_SELECTED_BSWALLET_SUCCESS: "UPDATE_SELECTED_BSWALLET_SUCCESS",
  UPDATE_SELECTED_BSWALLET_FAIL: "UPDATE_SELECTED_BSWALLET_FAIL",

  FETCH_BSWALLET_TRASACTIONS: "FETCH_BSWALLET_TRASACTIONS",
  FETCH_BSWALLET_TRASACTIONS_SUCCESS: "FETCH_BSWALLET_TRASACTIONS_SUCCESS",
  FETCH_BSWALLET_TRASACTIONS_FAIL: "FETCH_BSWALLET_TRASACTIONS_FAIL",
};
