import { FC, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { TextField, Select } from "../../App/FormComponents";
import { Modal } from "../../App";
import {
  InitialMessageState,
  toggleMessagingModal,
  updateServiceProvider,
} from "../../../domains/Messages";
import { openNotification } from "../../../domains/App";
import { schemaValidation } from "../../../constants";
import _ from "lodash";

interface ServiceProvider {
  apiIntgId: string;
  apiIntgName: string;
  channel: string;
  code: string;
  name: string;
}

interface EditServiceProviderFormProps {
  serviceProvider: any;
}

export const EditServiceProviderForm: FC<EditServiceProviderFormProps> = ({
  serviceProvider,
}) => {
  const dispatch = useDispatch();
  const { messageModals, messageLoaders } = useSelector(
    (state: { message: InitialMessageState }) => state.message
  );
  const { control, handleSubmit, reset } = useForm<ServiceProvider>({
    resolver: joiResolver(schemaValidation.ServiceProviderSchema),
    defaultValues: {
      apiIntgId: "",
      apiIntgName: "",
      channel: "",
      code: "",
      name: "",
    },
  });

  useEffect(() => {
    if (!_.isEmpty(serviceProvider)) {
      reset({
        apiIntgId: serviceProvider?.apiIntgId || "",
        apiIntgName: serviceProvider?.apiIntgName || "",
        channel: serviceProvider?.channel || "",
        code: serviceProvider?.code || "",
        name: serviceProvider?.name || "",
      });
    }
  }, [serviceProvider, reset]);

  const onClose = async () => {
    reset();
    await dispatch(
      toggleMessagingModal({
        editServiceProvider: false,
      })
    );
  };

  const onSubmit: SubmitHandler<ServiceProvider> = async (props) => {
    try {
      await dispatch(updateServiceProvider(serviceProvider?.id, props));
      await onClose();
      await dispatch(
        openNotification({
          openNotification: true,
          type: "success",
          message: "Service provider updated!",
        })
      );
    } catch (e) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Something went wrong",
        })
      );
    }
  };
  return (
    <Modal
      formId="edit_service_provider_form"
      btnType="submit"
      modalTitle="Edit service provider"
      isOpen={messageModals.editServiceProvider}
      onClose={onClose}
      btnLoading={messageLoaders.submit}
    >
      <form
        id="edit_service_provider_form"
        className="space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="code"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="code"
              label="Code"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="name"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="name"
              label="Name"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="channel"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Select
              id="channel"
              label="Channel"
              selectedOption={value}
              error={error?.message}
              onChange={onChange}
              options={[
                {
                  value: "no_selection",
                  label: "No selection",
                  unavailable: true,
                },
                {
                  value: "Sms",
                  label: "SMS",
                },
                {
                  value: "Email",
                  label: "Emails",
                },
                {
                  value: "InApp",
                  label: "In App",
                },
              ]}
            />
          )}
        />
        <Controller
          control={control}
          name="apiIntgId"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="apiIntgId"
              label="Api IntgId"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="apiIntgName"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="apiIntgName"
              label="Api IntgName"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
      </form>
    </Modal>
  );
};
