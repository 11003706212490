import classNames from "classnames";
import { FC } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { TabItemProps } from "../../domains/App";

interface TabsProps {
  tabs: TabItemProps[];
}

export const RoundedTabs: FC<TabsProps> = ({ tabs }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const goToPath = (path: string) => history.push(path);

  return (
    <>
      <div className="lg:hidden">
        <label htmlFor="selected-tab" className="sr-only">
          Select a tab
        </label>
        <select
          id="selected-tab"
          name="selected-tab"
          className="mt-4 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-tandaPurple focus:border-tandaPurple sm:text-sm rounded-md"
          defaultValue={tabs[0].path}
          onChange={(e) => goToPath(e.target.value)}
        >
          {tabs.map((tab) => (
            <option value={tab.path} key={tab.key}>
              {tab.label}
            </option>
          ))}
        </select>
      </div>
      <div></div>
      <div className="hidden  lg:block">
        <div className="border-gray-200 bg-white py-3 px-3 rounded-lg">
          <nav className="flex space-x-4 font-bold">
            {tabs.map((tab) => {
              const matches = pathname === tab.path;
              return (
                <Link
                  key={tab.key}
                  className={classNames(
                    "w-auto px-4 rounded-full text-center py-2 text-sm font-semibold text-gray-700",
                    {
                      "bg-tandaGreenLight text-tandaTextGreen": matches,
                      "hover:bg-white/[0.12] hover:text-gray-70 text-gray-700":
                        !matches,
                    }
                  )}
                  to={tab.path}
                >
                  {tab.label}
                </Link>
              );
            })}
          </nav>
        </div>
      </div>
    </>
  );
};
