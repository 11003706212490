import { createReducer } from "@reduxjs/toolkit";
import { Types } from "./actionTypes";
import { PaginationProps } from "../App";
import _ from "lodash";

export interface InitialPaymentGatewayState {
  cache: Record<string, any>;
  paymentGateways: any[];
  selectedPaymentGateway: any;
  paymentGatewaysPagination: PaginationProps;
  paymentGatewayModals: any;
  paymentGatewaysLoaders: any;
}

const initialState: InitialPaymentGatewayState = {
  paymentGateways: [],
  paymentGatewaysPagination: {
    number: 0,
    totalPages: 1,
    size: 100,
    totalElements: 0,
  },
  selectedPaymentGateway: {},
  paymentGatewaysLoaders: {
    submit: false,
    fetchPaymentGateways: false,
  },
  paymentGatewayModals: {
    addPaymentGateway: false,
    editPaymentGateway: false,
    deletePaymentGateway: false,
  },
  cache: {},
};

export const paymentGatewaysReducer = createReducer(initialState, {
  [Types.TOGGLE_PAYMENTGATEWAY_MODALS]: (state, action) => {
    state.paymentGatewayModals = {
      ...state.paymentGatewayModals,
      ...action.payload,
    };
  },

  [Types.FETCH_PAYMENTGATEWAYS]: (state) => {
    state.paymentGatewaysLoaders.fetchPaymentGateways = true;
  },
  [Types.FETCH_PAYMENTGATEWAYS_SUCCESS]: (state, action) =>
    // {
    //   const {
    //     data
    //   } = action.payload;
    //   state.paymentGatewaysLoaders.fetchPaymentGateways = false;
    //   state.paymentGateways = data;
    // },
    {
      const {
        data: { content, page },
      } = action.payload;
      state.paymentGatewaysLoaders.fetchPaymentGateways = false;
      state.paymentGateways = content;
      state.paymentGatewaysPagination = {
        number: page.number,
        size: page.size,
        totalPages: page.totalPages,
        totalElements: page.totalElements,
      };
    },
  [Types.FETCH_PAYMENTGATEWAYS_FAIL]: (state) => {
    state.paymentGatewaysLoaders.fetchPaymentGateways = false;
  },

  [Types.CREATE_PAYMENTGATEWAY]: (state) => {
    state.paymentGatewaysLoaders.submit = true;
  },
  [Types.CREATE_PAYMENTGATEWAY_SUCCESS]: (state, action) => {
    state.paymentGatewaysLoaders.submit = false;
    state.paymentGateways = [action.payload.data, ...state.paymentGateways];
  },
  [Types.CREATE_PAYMENTGATEWAY_FAIL]: (state) => {
    state.paymentGatewaysLoaders.submit = false;
  },

  [Types.DELETE_PAYMENTGATEWAY]: (state) => {
    state.paymentGatewaysLoaders.submit = true;
  },
  [Types.DELETE_PAYMENTGATEWAY_SUCCESS]: (state, action) => {
    const {
      previousAction: {
        payload: {
          request: {
            deleteProp: { id },
          },
        },
      },
    } = action.meta;
    state.paymentGateways = [
      ...state.paymentGateways.filter(
        (paymentGateway) => paymentGateway.id !== id
      ),
    ];
    if (_.isEmpty(state.paymentGateways)) {
      state.paymentGatewaysPagination.number = 0;
    }
    state.paymentGatewaysLoaders.submit = false;
  },
  [Types.DELETE_PAYMENTGATEWAY_FAIL]: (state) => {
    state.paymentGatewaysLoaders.submit = false;
  },

  [Types.UPDATE_PAYMENTGATEWAY]: (state) => {
    state.paymentGatewaysLoaders.submit = true;
  },
  [Types.UPDATE_PAYMENTGATEWAY_SUCCESS]: (state, action) => {
    state.paymentGatewaysLoaders.submit = false;
    state.paymentGateways = [
      ...state.paymentGateways.map((sp) =>
        sp.id === action.payload.data.id ? action.payload.data : sp
      ),
    ];
  },
  [Types.UPDATE_PAYMENTGATEWAY_FAIL]: (state) => {
    state.paymentGatewaysLoaders.submit = false;
  },
});
