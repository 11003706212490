export const Types: Record<string, string> = {
  CHANGE_RESELLERS_TABS: "CHANGE_RESELLERS_TABS",
  TOGGLE_RESELLER_MODALS: "TOGGLE_RESELLER_MODALS",

  FETCH_RESELLERS: "FETCH_RESELLERS",
  FETCH_RESELLERS_SUCCESS: "FETCH_RESELLERS_SUCCESS",
  FETCH_RESELLERS_FAIL: "FETCH_RESELLERS_FAIL",

  FETCH_RESELLERS_BALANCES: "FETCH_RESELLERS_BALANCES",
  FETCH_RESELLERS_BALANCES_SUCCESS: "FETCH_RESELLERS_BALANCES_SUCCESS",
  FETCH_RESELLERS_BALANCES_FAIL: "FETCH_RESELLERS_BALANCES_FAIL",

  FETCH_RESELLER: "FETCH_RESELLER",
  FETCH_RESELLER_SUCCESS: "FETCH_RESELLER_SUCCESS",
  FETCH_RESELLER_FAIL: "FETCH_RESELLER_FAIL",

  FETCH_RESELLER_API_CLIENTS: "FETCH_RESELLER_API_CLIENTS",
  FETCH_RESELLER_API_CLIENTS_SUCCESS: "FETCH_RESELLER_API_CLIENTS_SUCCESS",
  FETCH_RESELLER_API_CLIENTS_FAIL: "FETCH_RESELLER_API_CLIENTS_FAIL",

  FETCH_RESELLER_API_CLIENT_ROLES: "FETCH_RESELLER_API_CLIENT_ROLES",
  FETCH_RESELLER_API_CLIENT_ROLES_SUCCESS:
    "FETCH_RESELLER_API_CLIENT_ROLES_SUCCESS",
  FETCH_RESELLER_API_CLIENT_ROLES_FAIL: "FETCH_RESELLER_API_CLIENT_ROLES_FAIL",

  CREATE_RESELLER: "CREATE_RESELLER",
  CREATE_RESELLER_SUCCESS: "CREATE_RESELLER_SUCCESS",
  CREATE_RESELLER_FAIL: "CREATE_RESELLER_FAIL",

  UPDATE_RESELLER: "UPDATE_RESELLER",
  UPDATE_RESELLER_SUCCESS: "UPDATE_RESELLER_SUCCESS",
  UPDATE_RESELLER_FAIL: "UPDATE_RESELLER_FAIL",

  DELETE_RESELLER_: "DELETE_RESELLER",
  DELETE_RESELLER_SUCCESS: "DELETE_RESELLER_SUCCESS",
  DELETE_RESELLER_FAIL: "DELETE_RESELLER_FAIL",

  CHANGE_RESELLER_STATUS: "CHANGE_RESELLER_STATUS",
  CHANGE_RESELLER_STATUS_SUCCESS: "CHANGE_RESELLER_STATUS_SUCCESS",
  CHANGE_RESELLER_STATUS_FAIL: "CHANGE_RESELLER_STATUS_FAIL",

  UPDATE_SELECTED_RESELLER: "UPDATE_SELECTED_RESELLER",
  UPDATE_SELECTED_RESELLER_SUCCESS: "UPDATE_SELECTED_RESELLER_SUCCESS",
  UPDATE_SELECTED_RESELLER_FAIL: "UPDATE_SELECTED_RESELLER_FAIL",

  FETCH_RESELLER_TRASACTIONS: "FETCH_RESELLER_TRASACTIONS",
  FETCH_RESELLER_TRASACTIONS_SUCCESS: "FETCH_RESELLER_TRASACTIONS_SUCCESS",
  FETCH_RESELLER_TRASACTIONS_FAIL: "FETCH_RESELLER_TRASACTIONS_FAIL",

  UPDATE_SELECTED_API_CLIENT: "UPDATE_SELECTED_API_CLIENT",
  UPDATE_SELECTED_API_CLIENT_SUCCESS: "UPDATE_SELECTED_API_CLIENT_SUCCESS",
  UPDATE_SELECTED_API_CLIENT_FAIL: "UPDATE_SELECTED_API_CLIENT_FAIL",

  UPDATE_SELECTED_API_CLIENT_ROLES: "UPDATE_SELECTED_API_CLIENT_ROLES",
  UPDATE_SELECTED_API_CLIENT_ROLES_SUCCESS:
    "UPDATE_SELECTED_API_CLIENT_ROLES_SUCCESS",
  UPDATE_SELECTED_API_CLIENT_ROLES_FAIL:
    "UPDATE_SELECTED_API_CLIENT_ROLES_FAIL",

  GRANT_API_CLIENT_ROLE: "GRANT_API_CLIENT_ROLE",
  GRANT_API_CLIENT_ROLE_SUCCESS: "GRANT_API_CLIENT_ROLE_SUCCESS",
  GRANT_API_CLIENT_ROLE_FAIL: "GRANT_API_CLIENT_ROLE_FAIL",

  REVOKE_API_CLIENT_ROLE: "REVOKE_API_CLIENT_ROLE",
  REVOKE_API_CLIENT_ROLE_SUCCESS: "REVOKE_API_CLIENT_ROLE_SUCCESS",
  REVOKE_API_CLIENT_ROLE_FAIL: "REVOKE_API_CLIENT_ROLE_FAIL",

  UPDATE_SELECTED_ROLE: "UPDATE_SELECTED_ROLE",
  UPDATE_SELECTED_ROLE_SUCCESS: "UPDATE_SELECTED_ROLE_SUCCESS",
  UPDATE_SELECTED_ROLE_FAIL: "UPDATE_SELECTED_ROLE_FAIL",
};
