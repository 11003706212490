import _ from "lodash";
import { tryToParse } from "../../../utils";

export const formatCampaignData = (data: any) => {
  const serviceProvider = tryToParse({
    value: data.serviceProvider,
    defaultValue: {},
  });
  let campaignData: Record<string, any> = {
    sp: {
      id: serviceProvider?.id,
    },
    headers: [],
    subject: data?.subject,
    body: data?.body,
    phoneNumbers: data?.phoneNumbers?.map((item: any) => item.value),
  };

  if (data.contacts) {
    campaignData.contacts = data.contacts.map((contact: any) => {
      const parsedContact = tryToParse({
        value: contact,
        defaultValue: {},
      });
      return {
        id: parsedContact?.id,
      };
    });
  }
  if (data.groups) {
    campaignData.groups = data.groups.map((group: any) => {
      const parsedGroup = tryToParse({
        value: group,
        defaultValue: {},
      });
      return {
        id: parsedGroup?.id,
      };
    });
  }
  if (data.toGroups) {
    campaignData.toGroups = data.toGroups.map((group: any) => {
      const parsedGroup = tryToParse({
        value: group,
        defaultValue: {},
      });
      return {
        id: parsedGroup?.id,
      };
    });
    campaignData.toEmails = data?.toEmails?.map((item: any) => item.value);
  }
  if (data.ccGroups) {
    campaignData.ccGroups = data.ccGroups.map((group: any) => {
      const parsedGroup = tryToParse({
        value: group,
        defaultValue: {},
      });
      return {
        id: parsedGroup?.id,
      };
    });
    campaignData.ccEmails = data?.ccEmails?.map((item: any) => item.value);
  }
  if (data.bccGroups) {
    campaignData.bccGroups = data.bccGroups.map((group: any) => {
      const parsedGroup = tryToParse({
        value: group,
        defaultValue: {},
      });
      return {
        id: parsedGroup?.id,
      };
    });
    campaignData.bccEmails = data?.bccEmails?.map((item: any) => item.value);
  }

  campaignData = _.omitBy(campaignData, _.isNil);
  return campaignData;
};

export const formatEventData = (data: any) => {
  let eventData: Record<string, any> = {
    name: data.name,
    eventType: data.eventType,
    initiatorTypes: data?.initiatorTypes?.map(
      (initiatorType: any) => initiatorType.value
    ),
    selectors: [],
    delivery: {},
    copy: {},
  };
  eventData = _.omitBy(eventData, _.isNil);
  if (data.smsServiceProvider) {
    const serviceProvider = tryToParse({
      value: data.smsServiceProvider,
      defaultValue: {},
    });
    eventData.delivery = {
      sms: {
        sp: {
          id: serviceProvider?.id,
        },
        body: data?.smsBody,
      },
    };
    eventData.delivery.sms = _.omitBy(eventData.delivery.sms, _.isNil);
  }
  if (data.inAppServiceProvider) {
    const serviceProvider = tryToParse({
      value: data.inAppServiceProvider,
      defaultValue: {},
    });
    eventData.delivery = {
      ...eventData.delivery,
      inApp: {
        sp: {
          id: serviceProvider?.id,
        },
        body: data?.inAppBody,
        subject: data?.inAppSubject,
        recipient: data?.inAppRecipient,
      },
    };
    eventData.delivery.inApp = _.omitBy(eventData.delivery.inApp, _.isNil);
  }
  if (data.emailServiceProvider) {
    const serviceProvider = tryToParse({
      value: data.smsServiceProvider,
      defaultValue: {},
    });
    eventData.delivery = {
      ...eventData.delivery,
      email: {
        sp: {
          id: serviceProvider?.id,
        },
        body: data?.emailBody,
        subject: data?.emailSubject,
      },
    };
    eventData.delivery.email = _.omitBy(eventData.delivery.email, _.isNil);
  }
  if (data.systemServiceProvider) {
    const systemServiceProvider = tryToParse({
      value: data.systemServiceProvider,
      defaultValue: {},
    });
    const customerServiceProvider = tryToParse({
      value: data.customerServiceProvider,
      defaultValue: {},
    });
    eventData.copy = {
      system: {
        sp: {
          id: systemServiceProvider?.id,
        },
        subject: data?.systemSubject,
        body: data?.systemBody,
        to: data?.systemTo,
        cc: data?.systemCC?.map((item: any) => item.value),
        bcc: data?.systemBCC?.map((item: any) => item.value),
      },
    };
    eventData.copy.system = _.omitBy(eventData.copy.system, _.isNil);
    if (data.customerServiceProvider) {
      eventData.copy = {
        ...eventData.copy,
        customer: {
          sp: {
            id: customerServiceProvider?.id,
          },
          body: data?.customerBody,
        },
      };
    }
    eventData.copy.customer = _.omitBy(eventData.copy.customer, _.isNil);
  }

  if (data.selectorTypes) {
    eventData.selectors = data.selectorTypes.map((selector: any) => ({
      k: selector.key,
      op: selector.operator,
      v: [selector.value],
    }));
  }
  return eventData;
};
