import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { SelectMulti } from "../../App/FormComponents";
import { DescriptionPanel } from "../Messages";

interface DropdownDataEntryProps {
  formValue: string;
  label: string;
  options: any[];
}

export const DropdownDataEntry: FC<DropdownDataEntryProps> = ({
  formValue,
  label,
  options,
}) => {
  const { control } = useFormContext();

  return (
    <DescriptionPanel label={label}>
      <Controller
        name={formValue}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          const handleChange = (values: any[]) => {
            onChange(values);
          };
          const errorMessage: string | undefined = (error as any)?.[0]?.message;
          return (
            <SelectMulti
              id={formValue}
              values={value}
              onChange={handleChange}
              options={options}
              error={errorMessage}
            />
          );
        }}
      />
    </DescriptionPanel>
  );
};
