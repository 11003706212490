import { CashIcon, DownloadIcon } from "@heroicons/react/outline";
import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { DownloadStatementForm } from ".";
import { toggleReportModal } from "../../domains/Reports";
import { Layout } from "../App";
import { StatementCard } from "./StatementCard";

export const SmeReportPage: FC = () => {
  const dispatch = useDispatch();
  let [selectUrl, setSelectedUrl] = useState<any>();
  let [selectName, setSelectedName] = useState<any>();

  const data = [
    {
      name: "Daily Product Activity Report",
      description: "Daily Product Activity Report",
      url: "/wallets/v1/reports/sales-dayonday",
    },
    {
      name: "Cashpoint Activity Report",
      description: "Cashpoint Activity Report",
      url: "/wallets/v1/reports/cashpoint",
    },
  ];

  const handleModal = async ({ name, url }: any) => {
    setSelectedUrl(url);
    setSelectedName(name);
    await dispatch(
      toggleReportModal({
        downloadStatement: true,
      })
    );
  };

  return (
    <Layout>
      <div className="px-4">
        <DownloadStatementForm name={selectName} url={selectUrl} />
        <span className="text-xl font-semibold mb-4 p-4">
          Cashpoint & Paypoint (v2) Statements
        </span>

        <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-4">
          {data.map((value: any) => (
            <StatementCard
              name={value.name}
              description={value.description}
              SubComponent={() => {
                return (
                  <div className="ml-2 flex justify-between">
                    <button
                      onClick={() => handleModal(value)}
                      className="action-buttons btn-danger text-xs px-6"
                    >
                      <span className="flex items-center justify-center space-x-1">
                        <DownloadIcon className="h-4 w-4 " />
                        <span>Download</span>
                      </span>
                    </button>
                    <span className="rounded-full p-2 bg-green-100">
                      <CashIcon className="h-4 w-4 text-tandaGreen" />
                    </span>
                  </div>
                );
              }}
            />
          ))}
        </div>
      </div>
    </Layout>
  );
};
