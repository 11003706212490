import { DownloadIcon } from "@heroicons/react/outline";
import _ from "lodash";
import { FC, useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { config } from "../../constants";
import { openNotification } from "../../domains/App";
import {
  fetchLoans,
  InitialLoanState,
  toggleLoanModal,
  updateSearchParams,
} from "../../domains/Loan";
import { Button, Layout, SuccessOrErrorState } from "../App";
import { TextField } from "../App/FormComponents";
import { DownloadStatementForm, LoanTable } from "../Loans";
import { DescriptionPanel } from "../Messaging";

interface CustomerTransactionFeeRate {
  page: number;
  size: number;
  q: string;
  since: string;
  until: string;
}

export const LoanPage: FC = () => {
  const dispatch = useDispatch();
  const { searchParams, loans, loansLoaders, loansPagination } = useSelector(
    (state: { loan: InitialLoanState }) => state.loan
  );
  useEffect(() => {
    const fetchLoansAsync = async () => await dispatch(fetchLoans());

    fetchLoansAsync();
  }, [dispatch]);

  const handlePagination = (page: number) => {
    dispatch(
      fetchLoans({
        page: page - 1,
        size: loansPagination.size,
        q: searchParams?.q || "",
        since: searchParams?.since || "",
        until: searchParams?.until || "",
      })
    );
  };
  const { control, handleSubmit } = useForm<CustomerTransactionFeeRate>({
    defaultValues: {
      page: 0,
      size: 10,
      q: "",
      since: "",
      until: "",
    },
  });
  const onSubmit: SubmitHandler<CustomerTransactionFeeRate> = async (props) => {
    const params = {
      page: 0,
      size: 20,
      q: `254${props.q}`,
      since: props.since,
      until: props.until,
    };
    dispatch(updateSearchParams(params));
    try {
      await dispatch(fetchLoans(params));
    } catch (e) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Something went wrong",
        })
      );
    }
  };

  const reset = () => {
    const s = {
      page: 0,
      size: 10,
      q: "",
      since: "",
      until: "",
    };
    dispatch(updateSearchParams(s));
    const fetchLoansAsync = async () => await dispatch(fetchLoans());
    fetchLoansAsync();
  };

  const handleModal = async () => {
    await dispatch(
      toggleLoanModal({
        downloadStatement: true,
      })
    );
  };

  return (
    <Layout>
      <DownloadStatementForm />

      <div className="pt-3">
        {_.isEmpty(loans) && !loansLoaders.fetchLoans ? (
          <SuccessOrErrorState state="empty" message="No Loans Found" />
        ) : (
          <LoanTable
            title={config.loan.title}
            columns={config.loan.listColumns}
            data={loans}
            pagination={loansPagination}
            loading={loansLoaders.fetchLoans}
            onPaginationChange={handlePagination}
            SubComponent={(value: any) => {
              return (
                <>
                  <div className="w-full pt-2 pb-4 ml-7 px-4 grid grid-cols-2">
                    <DescriptionPanel
                      label="Agent Name"
                      description={value?.row?.original?.agentName}
                    />
                    <DescriptionPanel
                      label="Phone Number"
                      description={value?.row?.original?.agentPhoneNumber}
                    />
                    <DescriptionPanel
                      label="Due Date"
                      description={value?.row?.original?.dueDate}
                    />
                    <DescriptionPanel
                      label="Initial Interest"
                      description={"KES ".concat(
                        value?.row?.original?.initialInterest.toFixed(2)
                      )}
                    />
                    <DescriptionPanel
                      label="Outstanding Debt"
                      description={"KES ".concat(
                        value?.row?.original?.outstandingDebt.toFixed(2)
                      )}
                    />
                    <DescriptionPanel
                      label="Outstanding Interest"
                      description={"KES ".concat(
                        value?.row?.original?.outstandingInterest.toFixed(2)
                      )}
                    />
                    <DescriptionPanel
                      label="Outstanding Penalty"
                      description={"KES ".concat(
                        value?.row?.original?.outstandingPenalty.toFixed(2)
                      )}
                    />
                    <DescriptionPanel
                      label="Outstanding Principal"
                      description={"KES ".concat(
                        value?.row?.original?.outstandingPrincipal.toFixed(2)
                      )}
                    />
                  </div>
                </>
              );
            }}
            Filter={() => {
              return (
                <>
                  <form
                    id="customer_transaction_fee_form"
                    className="space-y-2"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-1 pb-2">
                      <Controller
                        control={control}
                        name="q"
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <TextField
                            id="q"
                            label="Phone Number"
                            type="text"
                            startAddOnText="254"
                            inputClass="inline"
                            value={value}
                            error={error?.message}
                            onChange={onChange}
                          />
                        )}
                      />
                      <div className="md:pt-4 flex items-center space-x-2">
                        <Button btnClass="primary" size="md" type="submit">
                          Filter
                        </Button>

                        <Button
                          btnClass="primary"
                          size="md"
                          type="reset"
                          onClick={() => reset()}
                        >
                          Clear
                        </Button>
                      </div>
                      <div className="flex items-center md:pt-4">
                        <Button
                          variant="danger"
                          size="md"
                          type="reset"
                          onClick={() => handleModal()}
                        >
                          <DownloadIcon className="h-4 w-4 mr-2" />
                          Loan Statement
                        </Button>
                      </div>
                    </div>
                  </form>
                </>
              );
            }}
          />
        )}
      </div>
    </Layout>
  );
};
