import { FC } from "react";
import { Modal } from "./Modal";

interface ToogleModalProps {
  isOpen: boolean;
  loading?: boolean;
  message: string;
  title: string;
  onClose: () => void;
  onSubmit: () => void;
}

export const ToggleModal: FC<ToogleModalProps> = ({
  isOpen,
  loading = false,
  message, 
  title,
  onClose,
  onSubmit,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      modalTitle={title}
      btnVariant="danger"
      btnLoading={loading}
      onSubmit={onSubmit}
      submitBtnLabel={title}
    >
      <p className="text-sm text-gray-900 sm:mt-0 sm:col-span-2">{message}</p>
    </Modal>
  );
};
