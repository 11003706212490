import { FC } from "react";

interface AltAuthLayoutProps {
  message: string;
  showRegistrationLink?: boolean;
}

export const AltAuthLayout: FC<AltAuthLayoutProps> = ({
  children,
  message,
}) => {
  return (
    <div className="min-h-screen bg-white flex">
      <div className="hidden lg:block relative w-0 flex-1">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src="/images/customers-poster.png"
          alt=""
        />
        <div className="absolute w-full h-full  flex flex-row  items-center justify-center">
          <img
            className="h-24 w-auto"
            src="/images/logo-lg.png"
            alt="Workflow"
          />
        </div>
      </div>
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <div className="lg:hidden flex flex-row justify-center">
              <img
                className="h-24 w-auto"
                src="/images/logo-lg.png"
                alt="Workflow"
              />
            </div>
            <h2 className="mt-2 text-3xl font-extrabold text-gray-900">
              {message}
            </h2>
          </div>
          <div className="mt-8">{children}</div>
        </div>
      </div>
    </div>
  );
};
