import { FC } from "react";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Layout, Button } from "../../App";
import { createCampaignTabs, schemaValidation } from "../../../constants";
import { PickServiceProvider } from "./ServiceProvider";
import { PickGroups } from "./Group";
import { Subject } from "./Subject";
import { Editor } from "./Editor";
import { DataEntry } from "./DataEntry";
import {
  createEmailCampaign,
  InitialMessageState,
} from "../../../domains/Messages";
import { openNotification } from "../../../domains/App";
import { formatCampaignData } from "./common";

interface EmailCampaign {
  serviceProvider: string;
  subject: string;
  body: string;
  toGroups: string[];
  toEmails: string[];
  bccGroups: string[];
  bccEmails: string[];
  ccGroups: string[];
  ccEmails: string[];
}

export const CreateEmailCampaignPage: FC = () => {
  const methods = useForm<EmailCampaign>({
    resolver: joiResolver(schemaValidation.CreateEmailCampaignSchema),
    defaultValues: {
      serviceProvider: "",
      subject: "",
      body: "",
      toGroups: [],
      toEmails: [],
      bccGroups: [],
      bccEmails: [],
      ccGroups: [],
      ccEmails: [],
    },
  });
  const dispatch = useDispatch();
  const { messageLoaders } = useSelector(
    (state: { message: InitialMessageState }) => state.message
  );

  const onSubmit: SubmitHandler<EmailCampaign> = async (data) => {
    const formattedData = formatCampaignData(data);
    try {
      await dispatch(createEmailCampaign(formattedData));
      await dispatch(
        openNotification({
          openNotification: true,
          type: "success",
          message: "Email campaign sent!",
        })
      );
      methods.reset();
    } catch (e) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Something went wrong, campaign not sent",
        })
      );
    }
  };

  return (
    <Layout>
      <div className="px-4 sm:px-6 md:px-0">
        <Tabs tabs={createCampaignTabs} />
      </div>
      <div>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 divide-y divide-gray-300">
              <PickServiceProvider />
              <Subject />
              <Editor />
              <PickGroups label="To Groups" formValue="toGroups" />
              <DataEntry label="To Emails" formValue="toEmails" />
              <PickGroups label="BCC Groups" formValue="bccGroups" />
              <DataEntry label="BCC Emails" formValue="bccEmails" />
              <PickGroups label="CC Groups" formValue="ccGroups" />
              <DataEntry label="CC Emails" formValue="ccEmails" />
              <div className="flex flex-row justify-end pt-2">
                <Button type="submit" loading={messageLoaders.submit}>
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </Layout>
  );
};
