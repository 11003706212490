import { FC } from "react";
import Select from "react-select";

const components = {
  DropdownIndicator: null,
};

interface Option {
  value: string;
  label: string;
}

interface SelectMultiProps {
  error?: string;
  id: string;
  placeholder?: string;
  values: Option[];
  onChange: (values: Option[]) => void;
  options: Option[];
}

export const SelectMulti: FC<SelectMultiProps> = ({
  error,
  id,
  placeholder = "Select...",
  values,
  onChange,
  options,
}) => {
  const handleChange = (value: any, actionMeta: any) => {
    onChange(value);
  };

  return (
    <div className="mb-2 w-full">
      <Select
        id={id}
        className="rounded-md shadow-sm tanda-remove-input-ring"
        components={components}
        isClearable
        isMulti
        options={options}
        onChange={handleChange}
        placeholder={placeholder}
        value={values}
        styles={{
          input: (provided, state) => ({
            ...provided,
            borderRadius: "0.375rem",
            borderColor: "rgba(209, 213, 219, var(--tw-border-opacity))",
          }),
          placeholder: (provided, state) => ({
            ...provided,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
          }),
        }}
      />
      {error && (
        <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
          {error}
        </p>
      )}
    </div>
  );
};
