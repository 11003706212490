import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { TextField } from "../../App/FormComponents";
import { Modal } from "../../App";
import { openNotification } from "../../../domains/App";
import { schemaValidation } from "../../../constants";
import {
  InitialWalletState,
  toggleWalletModal,
} from "../../../domains/Reseller/Wallet";
import API from "../../../utils/api";
interface FormInput {
  reversalReference: string;
}

interface ReverseTransactionFormProps {
  transactionDetails: any;
}

export const ReverseTransaction: FC<ReverseTransactionFormProps> = ({
  transactionDetails,
}) => {
  const dispatch = useDispatch();
  const { walletModals } = useSelector(
    (state: { resellerWallet: InitialWalletState }) => state.resellerWallet
  );
  const [loader, setLoader] = useState(false);
  const { control, handleSubmit, reset } = useForm<FormInput>({
    resolver: joiResolver(schemaValidation.ReversalSchema),
    defaultValues: {
      reversalReference: "",
    },
  });

  const onClose = async () => {
    reset();
    await dispatch(
      toggleWalletModal({
        reverseTransaction: false,
      })
    );
  };

  const onSubmit: SubmitHandler<FormInput> = async (props) => {
    try {
      setLoader(true);
      let url = `io-wallets/v1/resellers/${transactionDetails?.org?.id}/wallets/${transactionDetails.wallet.id}/payments/${transactionDetails.id}/reversal`;
      // let url = `io-wallets/v1/organizations/${transactionDetails?.org?.id}/sub-wallets/${transactionDetails.wallet.id}/payments/${transactionDetails.id}/reversal`;

      API.put(url, {
        reversalReference: props.reversalReference,
      })
        .then((response) => {
          if (response.status === 204) {
            setLoader(false);
            dispatch(
              openNotification({
                openNotification: true,
                type: "success",
                message: "Request sent successfully",
              })
            );
          }
          onClose();
        })
        .catch((err) => {
          setLoader(false);
          dispatch(
            openNotification({
              openNotification: true,
              type: "error",
              message: `Payment not fyeound`,
            })
          );
        });
    } catch (e) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Something went wrong",
        })
      );
    }
  };
  return (
    <Modal
      formId="group_form"
      btnType="submit"
      modalTitle="Reverse Transaction"
      isOpen={walletModals.reverseTransaction}
      onClose={onClose}
      btnLoading={loader}
    >
      <form
        id="group_form"
        className="space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="reversalReference"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="reversalReference"
              label="Service Provider Reversal Reference"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
      </form>
    </Modal>
  );
};
