import { createReducer } from "@reduxjs/toolkit";
import { Types } from "./actionTypes";

export interface InitialResellerDfsGatewayState {
  cache: Record<string, any>;
  resellerGateways: any[];
  resellerDfsGateways: any;
  paymentGateways: any[];
  selectedResellerDfsGateway: any;
  resellerDfsGatewayModals: any;
  resellerDfsGatewaysLoaders: any;
}

const initialState: InitialResellerDfsGatewayState = {
  resellerGateways: [],
  resellerDfsGateways: [],
  paymentGateways: [],
  selectedResellerDfsGateway: {},
  resellerDfsGatewaysLoaders: {
    submit: false,
    fetchResellerDfsGateways: false,
    fetchIOPayementGateways: false,
  },
  resellerDfsGatewayModals: {
    addResellerDfsGateway: false,
    editResellerDfsGateway: false,
    deleteResellerDfsGateway: false,
  },
  cache: {},
};

export const resellerDfsGatewaysReducer = createReducer(initialState, {
  [Types.TOGGLE_RESELLER_DFS_GATEWAY_MODALS]: (state, action) => {
    state.resellerDfsGatewayModals = {
      ...state.resellerDfsGatewayModals,
      ...action.payload,
    };
  },

  [Types.FETCH_RESELLER_GATEWAYS]: (state) => {
    state.resellerDfsGatewaysLoaders.fetchIOPayementGateways = true;
  },
  [Types.FETCH_RESELLER_GATEWAYS_SUCCESS]: (state, action) => {
    const { data } = action.payload;
    state.resellerDfsGatewaysLoaders.fetchIOPayementGateways = false;
    state.paymentGateways = data;
  },
  [Types.FETCH_RESELLER_GATEWAYS_FAIL]: (state) => {
    state.paymentGateways = [];
    state.resellerDfsGatewaysLoaders.fetchIOPayementGateways = false;
  },

  [Types.FETCH_RESELLER_DFS_GATEWAY]: (state) => {
    state.resellerDfsGatewaysLoaders.fetchResellerDfsGateways = true;
  },
  [Types.FETCH_RESELLER_DFS_GATEWAY_SUCCESS]: (state, action) => {
    const { data } = action.payload;
    state.resellerDfsGatewaysLoaders.fetchResellerDfsGateways = false;
    state.resellerDfsGateways = data;
  },
  [Types.FETCH_RESELLER_DFS_GATEWAY_FAIL]: (state) => {
    state.resellerDfsGateways = {};
    state.resellerDfsGatewaysLoaders.fetchResellerDfsGateways = false;
  },

  [Types.CREATE_RESELLER_DFS_GATEWAY]: (state) => {
    state.resellerDfsGatewaysLoaders.submit = true;
  },
  [Types.CREATE_RESELLER_DFS_GATEWAY_SUCCESS]: (state, action) => {
    state.resellerDfsGatewaysLoaders.submit = false;
    state.resellerDfsGateways = action.payload.data;
  },
  [Types.CREATE_RESELLER_DFS_GATEWAY_FAIL]: (state) => {
    state.resellerDfsGatewaysLoaders.submit = false;
  },

  [Types.DELETE_RESELLER_DFS_GATEWAY]: (state) => {
    state.resellerDfsGatewaysLoaders.submit = true;
  },
  [Types.DELETE_RESELLER_DFS_GATEWAY_SUCCESS]: (state) => {
    state.resellerDfsGateways = {};
    state.resellerDfsGatewaysLoaders.submit = false;
  },
  [Types.DELETE_RESELLER_DFS_GATEWAY_FAIL]: (state) => {
    state.resellerDfsGatewaysLoaders.submit = false;
  },

  [Types.UPDATE_RESELLER_DFS_GATEWAY]: (state) => {
    state.resellerDfsGatewaysLoaders.submit = true;
  },
  [Types.UPDATE_RESELLER_DFS_GATEWAY_SUCCESS]: (state, action) => {
    state.resellerDfsGatewaysLoaders.submit = false;

    state.resellerDfsGateways = {};
    state.resellerDfsGateways = action.payload.data;
  },
  [Types.UPDATE_RESELLER_DFS_GATEWAY_FAIL]: (state) => {
    state.resellerDfsGatewaysLoaders.submit = false;
  },
});
