import { Types } from "./actionTypes";
import { getURL } from "../../utils";

export const toggleProductModal = (payload: any) => async (dispatch: any) =>
  dispatch({
    type: Types.TOGGLE_PRODUCT_MODALS,
    payload,
  });

export const updateSelectedProduct =
  (payload: any) => async (dispatch: any) => {
    await dispatch({
      type: Types.UPDATE_SELECTED_PRODUCT,
      payload,
    });
  };

export const fetchProducts = (pagination?: any) => async (dispatch: any) =>
  await dispatch({
    type: Types.FETCH_PRODUCTS,
    payload: {
      request: {
        method: "GET",
        url: getURL("/wallets/v1/dfses", pagination),
        useCache: true,
      },
    },
  });

export const createProduct = (data: any) => async (dispatch: any) => {
  await dispatch({
    type: Types.CREATE_PRODUCT,
    payload: {
      request: {
        url: `/wallets/v1/dfses`,
        method: "POST",
        data,
      },
    },
  });
};
export const deleteProduct = (product: any) => async (dispatch: any) =>
  await dispatch({
    type: Types.DELETE_MESSAGE_TEMPLATE,
    payload: {
      request: {
        url: `/wallets/v1/dfses/${product.id}`,
        method: "DELETE",
        deleteProp: product,
      },
    },
  });

export const updateProduct =
  (product: any, data: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.UPDATE_PRODUCT,
      payload: {
        request: {
          url: `/wallets/v1/dfses/${product.id}`,
          method: "PATCH",
          data,
          product,
        },
      },
    });

export const changeStatusProduct =
  (selectedProduct: any, status: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.CHANGE_PRODUCT_STATUS,
      payload: {
        request: {
          url: `/wallets/v1/dfses/${selectedProduct.id}/status`,
          method: "PATCH",
          data: status,
          selectedProduct,
        },
      },
    });
