import _ from "lodash";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchResellerDfsGateways,
  InitialResellerDfsGatewayState,
  toggleResellerDfsGatewayModal,
} from "../../../domains/Reseller/ResellerDfsGateway";
import { Banners, Layout, SuccessOrErrorState } from "../../App";
import { ResellerDfsTabs } from "../ResellerDfsTabs";
import { ResellerDfsGatewayCard } from "./ResellerDfsGatewayCard";
import { ResellerDfsGatewayForm } from "./ResellerDfsGatewayForm";
import API from "../../../utils/api";

export const ResellerDfsGatewayPage: FC = () => {
  const dispatch = useDispatch();
  const { resellerDfsGateways, resellerDfsGatewaysLoaders } = useSelector(
    (state: { resellerDfsGateway: InitialResellerDfsGatewayState }) =>
      state.resellerDfsGateway
  );
  const { dfsId = "" } = useParams<{ dfsId?: string }>();
  const [paymentGateways, setPaymentGateways] = useState({});
  useEffect(() => {
    const fetchResellerDfsGatewaysAsync = async () =>
      await dispatch(fetchResellerDfsGateways(dfsId));
    // const fetchPaymentGatewayssAsync = async () =>
    //   await dispatch(fetchPaymentGateways());
    const fetchGateways = async () => {
      const params = new URLSearchParams([["size", "100"]]);
      const config = {
        params: params,
      };
      API.get(`/io-wallets/v1/payment-gateways`, config).then((res) => {
        if (res.data.content.length > 0) {
          const paymentGatewayOptions = res.data.content.map((f: any) => ({
            label: f.name,
            value: f.id,
          }));
          setPaymentGateways(paymentGatewayOptions);
        } else {
          setPaymentGateways([]);
        }
      });
    };
    fetchGateways();
    fetchResellerDfsGatewaysAsync();
    // fetchPaymentGatewayssAsync();
  }, [dispatch, dfsId]);

  const handleModal = async () => {
    await dispatch(
      toggleResellerDfsGatewayModal({
        addResellerDfsGateway: true,
      })
    );
  };
  return (
    <Layout>
      <ResellerDfsTabs />
      <div className="bg-white mt-4">
        <Banners withAction actionLabel="Add Gateway" onClick={handleModal}>
          <p className="pl-3">Preferred Reseller Product Gateway</p>
        </Banners>
      </div>
      <ResellerDfsGatewayForm paymentGateWays={paymentGateways} />
      <div className="pt-3">
        {_.isEmpty(resellerDfsGateways) &&
        !resellerDfsGatewaysLoaders.fetchResellerDfsGateways ? (
          <SuccessOrErrorState
            state="empty"
            message="No Product Gateway Found"
          />
        ) : (
          <ResellerDfsGatewayCard
            paymentGateways={paymentGateways}
            data={resellerDfsGateways}
            loading={resellerDfsGatewaysLoaders.fetchResellerDfsGateways}
          />
        )}
      </div>
    </Layout>
  );
};
