import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Layout,
  Banners,
  SuccessOrErrorState,
  Tabs,
  GoBackLink,
} from "../../App";
import { IntgTable, IntgForm } from "../PaymentGateway";

import {
  fetchProductIOIntgs,
  InitialIOIntgstate,
  toggleIntgModal,
} from "../../../domains/IOIntegration";
import { config } from "../../../constants";
import _ from "lodash";
import { useParams } from "react-router";
import { TabItemProps } from "../../../domains/App";

export const IOIntgPage: FC = () => {
  const dispatch = useDispatch();
  const { productIOIntgs, iointgsLoaders, iointgsPagination } = useSelector(
    (state: { iointgs: InitialIOIntgstate }) => state.iointgs
  );
  const { productId = "" } = useParams<{ productId?: string }>();

  useEffect(() => {
    const fetchProductIOIntgsAsync = async () =>
      await dispatch(fetchProductIOIntgs(productId));

    fetchProductIOIntgsAsync();
  }, [dispatch, productId]);

  const handlePagination = (page: number) => {
    dispatch(
      fetchProductIOIntgs(productId, {
        page: page - 1,
        size: iointgsPagination.size,
      })
    );
  };

  const handleModal = async () => {
    await dispatch(
      toggleIntgModal({
        addIntg: true,
      })
    );
  };
  const viewProductTabs: TabItemProps[] = [
    {
      key: "gateway",
      label: "Gateways",
      path: `/tanda-io/${productId}/gateways`,
    },
  ];

  return (
    <Layout>
      <div>
        <Tabs tabs={viewProductTabs} />
        <div className="pt-2">
          <Banners
            // withAction
            actionLabel="Add Gateway"
            onClick={handleModal}
          >
            <div className="ml-1">
              <GoBackLink path="/tanda-io/products" />
            </div>
            <p className="pl-3">Tanda I/O Gateway</p>
          </Banners>
        </div> 
      </div>
      <IntgForm productId={productId} />
      <div className="pt-2">
        {_.isEmpty(productIOIntgs) && !iointgsLoaders.fetchIOIntgs ? (
          <SuccessOrErrorState state="empty" message="No Integrations Found" />
        ) : (
          <IntgTable
            columns={config.iowallets.intgs.listColumns}
            data={productIOIntgs}
            pagination={iointgsPagination}
            loading={iointgsLoaders.fetchIOIntgs}
            onPaginationChange={handlePagination}
          />
        )}
      </div>
    </Layout>
  );
};
