import _ from "lodash";
import { FC, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { openNotification } from "../../../domains/App";
import {
  fetchResellers,
  fetchResellersBalances,
  InitialResellerState,
  toggleResellerModal,
  updateSelectedReseller,
} from "../../../domains/Reseller/Reseller";
import { formatAmount } from "../../../utils/app";
import {
  Button,
  Layout,
  Loader,
  Pagination,
  SuccessOrErrorState,
  Table,
} from "../../App";
import { TextField } from "../../App/FormComponents";
import { EditReseller } from "./EditReseller";
import { ResellerForm } from "./ResellerForm";
interface SearchParams {
  q: string;
}

export const ResellerResellerPage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  let { resellers, resellersLoaders, resellersPagination, balances } =
    useSelector((state: { reseller: InitialResellerState }) => state.reseller);
  const { control, reset, handleSubmit } = useForm<SearchParams>({
    defaultValues: {
      q: "",
    },
  });

  const [selectedReseller, setSelectedReseller] = useState<any>();
  const handlePagination = (page: number) => {
    dispatch(
      fetchResellers({
        page: page - 1,
        size: resellersPagination.size,
      })
    );
  };

  useEffect(() => {
    const fetchResellersAsync = async () =>
      await dispatch(fetchResellers({ size: 12 }));

    const fetchBalances = async () => {
      await dispatch(fetchResellersBalances());
    };

    fetchResellersAsync();
    fetchBalances();
  }, [dispatch]);

  const onSubmit: SubmitHandler<SearchParams> = async (props) => {
    const params = {
      search: props?.q?.trim() ?? "",
    };

    try {
      await dispatch(fetchResellers(params));
    } catch (e) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Something went wrong",
        })
      );
    }
  };
  const onReset = () => {
    reset();
    dispatch(fetchResellers({ size: 12 }));
  };
  const handleModal = async () => {
    await dispatch(
      toggleResellerModal({
        addReseller: true,
      })
    );
  };

  const goToPath = (reseller: any) => () => {
    dispatch(updateSelectedReseller(reseller));
    history.push(`/tanda-io/resellers/${reseller.id}/wallets`);
  };

  const handleOpenEditModal = async (reseller: any) => {
    setSelectedReseller(reseller);
    await dispatch(updateSelectedReseller(reseller));
    await dispatch(
      toggleResellerModal({
        editReseller: true,
      })
    );
  };

  const handleRefresh = async () => {
    await dispatch(fetchResellersBalances());
  };

  return (
    <Layout>
      <ResellerForm />
      <EditReseller selectedReseller={selectedReseller} />
      <div>
        {resellersLoaders.fetchBalances ? (
          <div className="mt-4 rounded-lg flex flex-row items-center justify-center w-full h-24">
            <Loader />
          </div>
        ) : (
          <div>
            <div className="flex flex-row-reverse mb-4">
              <Button onClick={handleRefresh}>Refresh</Button>
            </div>
            <div className="grid grid-cols-12 grid-rows-2 gap-4 mb-4">
              <div className="col-span-4 bg-white rounded-lg overflow-hidden p-8">
                <div className="items-start flex flex-col gap-2">
                  <p className="text-3xl font-medium text-gray-600">
                    {balances.countOfOrganizations}
                  </p>
                  <p className="text-gray-500">Count of Resellers</p>
                </div>
              </div>
              <div className="bg-white col-span-4 rounded-lg overflow-hidden p-8">
                <div className="items-start flex flex-col gap-2">
                  <p className="text-3xl font-medium text-gray-600">
                    {formatAmount(balances.sumOfAvailableFloat)}
                  </p>
                  <p className="text-gray-500">Sum of Float Available</p>
                </div>
              </div>

              <div className="col-span-4 bg-white rounded-lg overflow-hidden p-8">
                <div className="items-start flex flex-col gap-2">
                  <p className="text-3xl font-medium text-gray-600">
                    {formatAmount(balances.sumOfActualFloat)}
                  </p>
                  <p className="text-gray-500">Sum of Float Actual</p>
                </div>
              </div>
              <div className="col-span-6 bg-white rounded-lg overflow-hidden p-8">
                <div className="items-start flex flex-col gap-2">
                  <p className="text-3xl font-medium text-gray-600">
                    {formatAmount(balances.sumOfAvailableCommission)}
                  </p>
                  <p className="text-gray-500">Sum of Commission Available</p>
                </div>
              </div>
              <div className="col-span-6 bg-white rounded-lg overflow-hidden p-8">
                <div className="items-start flex flex-col gap-2">
                  <p className="text-3xl font-medium text-gray-600">
                    {formatAmount(balances.sumOfActualCommission)}
                  </p>
                  <p className="text-gray-500">Sum of Commission Actual</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="flex w-full flex-col space-x-2 lg:flex-row justify-between items-center">
        <div>
          <form
            id="transaction filters"
            className="space-y-2"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex flex-col space-x-2 lg:flex-row justify-end items-center">
              <Controller
                control={control}
                name="q"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    id="q"
                    placeholder="Reseller name"
                    type="text"
                    value={value}
                    error={error?.message}
                    onChange={onChange}
                  />
                )}
              />
              <div className="flex flex-row items-center -mt-1">
                <div className="mr-2">
                  <Button btnClass="primary" size="md" type="submit">
                    Search
                  </Button>
                </div>
                <div>
                  <Button
                    btnClass="t"
                    size="md"
                    type="reset"
                    onClick={() => onReset()}
                  >
                    Clear
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div>
          <button
            className=" rounded btn-danger py-2 text-sm  px-8"
            onClick={() => handleModal()}
          >
            Add a reseller +
          </button>
        </div>
      </div>
      <div className="mt-4">
        {_.isEmpty(resellers) && !resellersLoaders.fetchResellers ? (
          <SuccessOrErrorState state="empty" message="No Reseller Found" />
        ) : (
          <div>
            <Table
              columns={[
                {
                  id: "name",
                  Header: "Name",
                  accessor: (row: any) => (
                    <div>
                      <span className="text-tandaPurple">{row.name}</span>
                    </div>
                  ),
                },
                {
                  id: "actions",
                  Header: "Actions",
                  accessor: (row: any) => {
                    return (
                      <div className="flex flex-row space-x-2">
                        <Button
                          onClick={goToPath(row)}
                          size="xs"
                          variant="primary"
                        >
                          View
                        </Button>
                        <Button
                          onClick={() => handleOpenEditModal(row)}
                          size="xs"
                          variant="primary"
                        >
                          Edit
                        </Button>
                      </div>
                    );
                  },
                },
              ]}
              data={resellers}
            />
            <div>
              <div className="pb-2 mt-4 flex flex-row-reverse justify-between">
                <Pagination
                  currentPage={resellersPagination.number + 1}
                  totalPages={resellersPagination.totalPages}
                  onPaginationChange={handlePagination}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};
