import { Types } from "./actionTypes";
import { getURL } from "../../utils";

export const toggleWithdrawalModal = (payload: any) => async (dispatch: any) =>
  dispatch({
    type: Types.TOGGLE_WITHDRAWAL_MODALS,
    payload,
  });

export const updateSelectedWithdrawal =
  (payload: any) => async (dispatch: any) => {
    await dispatch({
      type: Types.UPDATE_SELECTED_WITHDRAWAL,
      payload,
    });
  };

export const fetchWithdrawals =
  (walletId: any, pagination?: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.FETCH_WITHDRAWALS,
      payload: {
        request: {
          method: "GET",
          url: getURL(
            `/wallets/v1/business-client-wallets/${walletId}/withdrawals`,
            pagination
          ),
          useCache: true,
        },
      },
    });

export const createWithdrawal =
  (gatewayId: any, data: any) => async (dispatch: any) => {
    await dispatch({
      type: Types.CREATE_WITHDRAWAL,
      payload: {
        request: {
          url: `/wallets/v1/business-client-wallets/${gatewayId}/withdrawals`,
          method: "POST",
          data,
        },
      },
    });
  };
