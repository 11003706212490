import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Layout,
  Banners,
  SuccessOrErrorState,
  Tabs,
  GoBackLink,
} from "../../App";
import {
  TransactionFeeRateForm,
  TransactionFeeRateTable,
} from "../TransactionFeeRates";
import {
  fetchAgentTransactionFees,
  InitialTransactionFeeRateState,
  toggleTransactionFeeRateModal,
} from "../../../domains/TransactionFeeRate";
import { config } from "../../../constants";
import _ from "lodash";
import { useParams } from "react-router";
import { TabItemProps } from "../../../domains/App";

export const TransactionFeeRatePage: FC = () => {
  const dispatch = useDispatch();
  const {
    agentTransactionFees,
    transactionFeeRatesLoaders,
    transactionFeeRatesPagination,
  } = useSelector(
    (state: { transactionFeeRate: InitialTransactionFeeRateState }) =>
      state.transactionFeeRate
  );

  const { productId = "" } = useParams<{ productId?: string }>();
  useEffect(() => {
    const fetchAgentTransactionFeesAsync = async () =>
      await dispatch(fetchAgentTransactionFees(productId));

    fetchAgentTransactionFeesAsync();
  }, [dispatch, productId]);

  const handlePagination = (page: number) => {
    dispatch(
      fetchAgentTransactionFees(productId, {
        page: page - 1,
        size: transactionFeeRatesPagination.size,
      })
    );
  };

  const handleModal = async () => {
    await dispatch(
      toggleTransactionFeeRateModal({
        addTransactionFeeRate: true,
      })
    );
  };
  const viewProductTabs: TabItemProps[] = [
    {
      key: "agent_transaction_fee",
      label: "Agent Transaction Fees",
      path: `/products/${productId}/agent-transaction-fees`,
    },
    {
      key: "agent_commission_fee",
      label: "Agent Commission Fees",
      path: `/products/${productId}/agent-commission-fees`,
    },
    {
      key: "customer_transaction_fee",
      label: "Customer Transaction Fees",
      path: `/products/${productId}/customer-transaction-fees`,
    },
    {
      key: "gateway",
      label: "Gateways",
      path: `/products/${productId}/gateways`,
    },
  ];
  return (
    <Layout>
      <div>
        <Tabs tabs={viewProductTabs} />
        <div className="pt-2">
          <Banners
            withAction
            actionLabel="Add Transaction Fee Rate"
            onClick={handleModal}
          >
            <div className="ml-1">
              <GoBackLink path="/products" />
            </div>
            <p className="pl-3">
              Do you want to create a Transaction Fee Rate?
            </p>
          </Banners>
        </div>
      </div>
      <TransactionFeeRateForm productId={productId} />
      <div className="pt-2">
        {_.isEmpty(agentTransactionFees) &&
        !transactionFeeRatesLoaders.fetchTransactionFeeRates ? (
          <SuccessOrErrorState
            state="empty"
            message="No Transaction Fee Rates Found"
          />
        ) : (
          <TransactionFeeRateTable
            columns={config.products.transactionFeeRate.listColumns}
            data={agentTransactionFees}
            pagination={transactionFeeRatesPagination}
            loading={transactionFeeRatesLoaders.fetchTransactionFeeRates}
            onPaginationChange={handlePagination}
          />
        )}
      </div>
    </Layout>
  );
};
