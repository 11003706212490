export const Types: Record<string, string> = {
  TOGGLE_LOAN_MODALS: "TOGGLE_LOAN_MODALS",

  UPDATE_SELECTED_LOAN: "UPDATE_SELECTED_LOAN",
  UPDATE_SELECTED_LOAN_SUCCESS: "UPDATE_SELECTED_LOAN_SUCCESS",
  UPDATE_SELECTED_LOAN_FAIL: "UPDATE_SELECTED_LOAN_FAIL",

  UPDATE_SEARCH_PARAMS: "UPDATE_SEARCH_PARAMS",
  UPDATE_SEARCH_PARAMS_SUCCESS: "UPDATE_SEARCH_PARAMS_SUCCESS",
  UPDATE_SEARCH_PARAMS_FAIL: "UPDATE_SEARCH_PARAMS_FAIL",

  FETCH_LOANS: "FETCH_LOANS",
  FETCH_LOANS_SUCCESS: "FETCH_LOANS_SUCCESS",
  FETCH_LOANS_FAIL: "FETCH_LOANS_FAIL",

  FETCH_LOAN_TRANSACTIONS: "FETCH_LOAN_TRANSACTIONS",
  FETCH_LOAN_TRANSACTIONS_SUCCESS: "FETCH_LOAN_TRANSACTIONS_SUCCESS",
  FETCH_LOAN_TRANSACTIONS_FAIL: "FETCH_LOAN_TRANSACTIONS_FAIL",

  FETCH_LOAN_STATEMENT: "FETCH_LOAN_STATEMENT",
  FETCH_LOAN_STATEMENT_SUCCESS: "FETCH_LOAN_STATEMENT_SUCCESS",
  FETCH_LOAN_STATEMENT_FAIL: "FETCH_LOAN_STATEMENT_FAIL",
};
