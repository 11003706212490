import { FC, useState } from "react";
import dayJs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Loader, ExpandTable, Button, DeleteModal } from "../../App";
import { RadioBox } from "../../App/FormComponents";
import { PaginationProps } from "../../../domains/App";
import {
  toggleMessagingModal,
  InitialMessageState,
  deleteTemplate,
} from "../../../domains/Messages";
import { EditTemplateForm } from "./EditTemplateForm";

interface TemplatesTableProps {
  columns: any[];
  data: any[];
  onPaginationChange?: (page: number) => void;
  pagination: PaginationProps;
  loading: boolean;
  SubComponent?: React.FC<{ row: any }>;
  onChange?: (value: string) => void;
}

export const TemplatesTable: FC<TemplatesTableProps> = ({
  columns,
  data,
  pagination,
  loading,
  onPaginationChange,
  onChange = (value) => {},
  SubComponent,
}) => {
  const dispatch = useDispatch();
  const { messageModals, messageLoaders } = useSelector(
    (state: { message: InitialMessageState }) => state.message
  );

  const [selected, setSelected] = useState<any>();
  const [selectedId, setSelectedId] = useState<string>();

  const handleOpenModal = (id: string) => {
    setSelectedId(id);
    dispatch(
      toggleMessagingModal({
        deleteTemplates: true,
      })
    );
  };
  const handleEditModal = (value: any) => {
    setSelected(value);
    dispatch(
      toggleMessagingModal({
        editTemplates: true,
      })
    );
  };

  const handleCloseModal = () =>
    dispatch(
      toggleMessagingModal({
        deleteTemplates: false,
      })
    );

  const onDeleteTemplate = async () => {
    if (!_.isNil(selectedId)) {
      await dispatch(deleteTemplate({ id: selectedId }));
    }
    handleCloseModal();
  };

  const getTemplateColumns = () => {
    const availableColumns = [
      {
        id: "name",
        Header: "Name",
        accessor: (row: any) => <span>{row?.name}</span>,
      },
      {
        id: "body",
        Header: "Body",
        accessor: (row: any) => (
          <p className="truncate max-w-xs">{row?.body}</p>
        ),
      },
      {
        id: "lastModified",
        Header: "Last Update",
        accessor: (row: any) => {
          return (
            <span>{dayJs(row?.lastModified).format("YYYY-MM-DD hh:mm A")}</span>
          );
        },
      },
      {
        id: "actions",
        accessor: (row: any) => {
          return (
            <div className="flex flex-row items-center space-x-2">
              <Button
                onClick={() => handleOpenModal(row.id)}
                size="xs"
                variant="danger"
              >
                Delete
              </Button>
              <Button
                onClick={() => handleEditModal(row)}
                size="xs"
                variant="primary"
              >
                Edit
              </Button>
            </div>
          );
        },
      },
      {
        id: "blank",
        accessor: (row: any) => {
          return <div className="px-1"></div>;
        },
      },
      {
        id: "select",
        accessor: (row: any) => {
          return (
            <div className="pl-3">
              <RadioBox
                name="serviceProvider"
                onChange={onChange}
                value={row?.body}
              />
            </div>
          );
        },
      },
    ];

    return columns.map((column) =>
      availableColumns.find(
        (availableColumn) => availableColumn.id === column.id
      )
    );
  };

  if (loading) {
    return (
      <div className="mt-4 flex flex-row items-center justify-center w-full h-60 bg-white rounded-lg">
        <Loader />
      </div>
    );
  }
  return (
    <>
      <DeleteModal
        isOpen={messageModals.deleteTemplates}
        onClose={handleCloseModal}
        onSubmit={onDeleteTemplate}
        message="Are you sure you want to delete this template?"
        loading={messageLoaders.submit}
      />
      <EditTemplateForm template={selected} />
      <ExpandTable
        columns={getTemplateColumns()}
        data={data}
        pageCount={pagination.totalPages}
        currentPage={pagination.number}
        onPaginationChange={onPaginationChange}
        SubComponent={SubComponent}
      />
    </>
  );
};
