import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Layout,
  Banners,
  SuccessOrErrorState,
  Tabs,
  GoBackLink,
} from "../../App";
import { CustomerTransactionFeeRateTable } from "./CustomerTransactionFeeRateTable";
import { CustomerTransactionFeeRateForm } from "./CustomerTransactionFeeRateForm";
import {
  fetchCustomerTransactionFees,
  InitialCustomerTransactionFeeRateState,
  toggleCustomerTransactionFeeRateModal,
} from "../../../domains/CustomerTransactionFeeRate";
import { config } from "../../../constants";
import _ from "lodash";
import { TabItemProps } from "../../../domains/App";
import { useParams } from "react-router";

export const CustomerTransactionFeeRatePage: FC = () => {
  const dispatch = useDispatch();
  const {
    customerTransactionFees,
    customerTransactionFeeRatesLoaders,
    customerTransactionFeeRatesPagination,
  } = useSelector(
    (state: {
      customerTransactionFeeRate: InitialCustomerTransactionFeeRateState;
    }) => state.customerTransactionFeeRate
  );
  const { productId = "" } = useParams<{ productId?: string }>();

  useEffect(() => {
    const fetchCustomerTransactionFeesAsync = async () =>
      await dispatch(fetchCustomerTransactionFees(productId));

    fetchCustomerTransactionFeesAsync();
  }, [dispatch, productId]);

  const handlePagination = (page: number) => {
    dispatch(
      fetchCustomerTransactionFees(productId, {
        page: page - 1,
        size: customerTransactionFeeRatesPagination.size,
      })
    );
  };

  const handleModal = async () => {
    await dispatch(
      toggleCustomerTransactionFeeRateModal({
        addCustomerTransactionFeeRate: true,
      })
    );
  };
  const viewProductTabs: TabItemProps[] = [
    {
      key: "agent_transaction_fee",
      label: "Agent Transaction Fees",
      path: `/products/${productId}/agent-transaction-fees`,
    },
    {
      key: "agent_commission_fee",
      label: "Agent Commission Fees",
      path: `/products/${productId}/agent-commission-fees`,
    },
    {
      key: "customer_transaction_fee",
      label: "Customer Transaction Fees",
      path: `/products/${productId}/customer-transaction-fees`,
    },
    {
      key: "gateway",
      label: "Gateways",
      path: `/products/${productId}/gateways`,
    },
  ];

  return (
    <Layout>
      <div>
        <Tabs tabs={viewProductTabs} />
        <div className="pt-2">
          <Banners
            withAction
            actionLabel="Add Customer Transaction Fee"
            onClick={handleModal}
          >
            <div className="ml-1">
              <GoBackLink path="/products" />
            </div>
            <p className="pl-3">
              Do you want to create a Customer Transaction Fee Rate?
            </p>
          </Banners>
        </div>
      </div>
      <CustomerTransactionFeeRateForm productId={productId} />
      <div className="pt-2">
        {_.isEmpty(customerTransactionFees) &&
        !customerTransactionFeeRatesLoaders.fetchCustomerTransactionFeeRates ? (
          <SuccessOrErrorState
            state="empty"
            message="No Customer Transaction Fee Rates Found"
          />
        ) : (
          <CustomerTransactionFeeRateTable
            columns={config.products.customerTransactionFeeRate.listColumns}
            data={customerTransactionFees}
            pagination={customerTransactionFeeRatesPagination}
            loading={
              customerTransactionFeeRatesLoaders.fetchCustomerTransactionFeeRates
            }
            onPaginationChange={handlePagination}
          />
        )}
      </div>
    </Layout>
  );
};
