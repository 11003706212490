import {
  CheckCircleIcon,
  ClockIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import dayjs from "dayjs";
import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { PaginationProps } from "../../../domains/App";
import { toggleWalletModal } from "../../../domains/Reseller/Wallet";
import { checkValue } from "../../../utils/app";
import { Loader, SecondTable } from "../../App";
import { ReverseTransaction } from "./ReverseTransaction";
import { SubWalletsDetails } from "./SubWalletDetails";

interface WalletCardProps {
  control?: any;
  columns: any[];
  data: any[];
  onChange?: (value: string) => void;
  onPaginationChange?: (page: number) => void;
  loading: boolean;
  pagination: PaginationProps;
  title: string;
  SubComponent?: React.FC<{ row: any }>;
}

export const SubWalletsTable: FC<WalletCardProps> = ({
  columns,
  data,
  onPaginationChange,
  loading,
  pagination,
}) => {
  const dispatch = useDispatch();
  let [selectedTransaction, setSelectedTransaction] = useState<any>();

  const getStatus = (row: any) => {
    switch (row) {
      case "000000":
        return (
          <span className="flex text-tandaGreen">
            <CheckCircleIcon className="h-4 mr-1 w-4" />
            <span>Success</span>
          </span>
        );
      case "000001":
        return (
          <span className="flex text-gray-400">
            <ClockIcon className="h-4 mr-1 w-4" />
            <span>Pending</span>
          </span>
        );
      default:
        return (
          <span className="flex text-tandaRed">
            <XCircleIcon className="h-4 mr-1 w-4" />
            <span>Failed</span>
          </span>
        );
    }
  };
  const getAmount = (row: any) => {
    switch (row?.category) {
      case "D":
        return (
          <span className="flex text-tandaGreen">
            + <span>{(row?.amount).toFixed(2)}</span>
          </span>
        );
      default:
        return (
          <span className="flex text-tandaRed">
            - <span>{(row?.amount).toFixed(2)}</span>
          </span>
        );
    }
  };

  const handleOpenDrawer = (id: string) => {
    setSelectedTransaction(id);
    dispatch(
      toggleWalletModal({
        showDetails: true,
      })
    );
  };

  const handleOpenReverseDrawer = (id: string) => {
    setSelectedTransaction(id);
    dispatch(
      toggleWalletModal({
        reverseTransaction: true,
      })
    );
  };
  const getColumns = () => {
    const availableColumns = [
      {
        id: "paymentRef",
        Header: "Payment Ref",
        accessor: "reference",
      },
      {
        id: "orgName",
        Header: "Org",
        accessor: (row: any) => {
          return <span>{row?.org?.name}</span>;
        },
      },

      {
        id: "wallet",
        Header: "Wallet",
        accessor: (row: any) => {
          return <span>{checkValue(row?.wallet?.account)}</span>;
        },
      },
      {
        id: "type",
        Header: "Type",
        accessor: (row: any) => {
          return <span>{checkValue(row?.type?.name)}</span>;
        },
      },
      {
        id: "accountNumber",
        Header: "A/C",
        accessor: (row: any) => {
          return <span>{checkValue(row?.customerAccount)}</span>;
        },
      },
      {
        id: "amount",
        Header: "Amount(KES)",
        accessor: (row: any) => {
          return <span>{getAmount(row)}</span>;
        },
      },
      {
        id: "actualBefore",
        Header: "Actual(KES)",
        accessor: (row: any) => {
          return <span>{(row?.actualBefore).toFixed(2)}</span>;
        },
      },

      {
        id: "status",
        Header: "Status",
        accessor: (row: any) => {
          return <span>{getStatus(row?.status)}</span>;
        },
      },
      {
        id: "dateFulfilled",
        Header: "Completed on",
        accessor: (row: any) => {
          return (
            <span>{dayjs(row?.dateCreated).format("YYYY-MM-DD hh:mm A")}</span>
          );
        },
      },
      {
        id: "actions",
        accessor: (row: any) => {
          return (
            <div className="flex flex-row items-center">
              <div className="center-contents text-xs space-x-3 text-tandaPurple cursor-pointer flex">
                {row.type.k === "CustomerPayment" && (
                  <div
                    onClick={() => handleOpenReverseDrawer(row)}
                    className="center-contents rounded-full px-5 py-2 bg-blue-50 text-tandaPurple text-xs"
                  >
                    Reverse
                  </div>
                )}
                <div
                  onClick={() => handleOpenDrawer(row)}
                  className="center-contents font-bold"
                >
                  View more
                </div>
              </div>
            </div>
          );
        },
      },
    ];

    return columns.map((column) =>
      availableColumns.find(
        (availableColumn) => availableColumn.id === column.id
      )
    );
  };

  if (loading) {
    return (
      <div className="mt-4 rounded-lg flex flex-row items-center justify-center w-full h-60 bg-white">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <SubWalletsDetails payoutDetails={selectedTransaction} />
      <ReverseTransaction transactionDetails={selectedTransaction} />
      <div>
        <SecondTable
          columns={getColumns()}
          data={data}
          pageCount={pagination.totalPages}
          currentPage={pagination.number}
          onPaginationChange={onPaginationChange}
        />
      </div>
    </>
  );
};
