import { FC } from "react";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Layout, Button } from "../../App";
import { createCampaignTabs, schemaValidation } from "../../../constants";
import { PickServiceProvider } from "./ServiceProvider";
import { PickGroups } from "./Group";
import { Subject } from "./Subject";
import { Editor } from "./Editor";
import { DataEntry } from "./DataEntry";
import {
  createSmsCampaign,
  InitialMessageState,
} from "../../../domains/Messages";
import { openNotification } from "../../../domains/App";
import { formatCampaignData } from "./common";
import { PickContacts } from "./Contact";

interface SmsCampaign {
  serviceProvider: string;
  subject: string;
  body: string;
  groups: string[];
  contacts: string[];
  phoneNumbers: string[];
}

export const CreateSmsCampaignPage: FC = () => {
  const methods = useForm<SmsCampaign>({
    resolver: joiResolver(schemaValidation.CreateSmsCampaignSchema),
    defaultValues: {
      serviceProvider: "",
      subject: "",
      body: "",
      groups: [],
      contacts: [],
      phoneNumbers: [],
    },
  });
  const dispatch = useDispatch();
  const { messageLoaders } = useSelector(
    (state: { message: InitialMessageState }) => state.message
  );

  const onSubmit: SubmitHandler<SmsCampaign> = async (data) => {
    const formattedData = formatCampaignData(data);
    try {
      await dispatch(createSmsCampaign(formattedData));
      await dispatch(
        openNotification({
          openNotification: true,
          type: "success",
          message: "Email campaign sent!",
        })
      );
      methods.reset();
    } catch (e) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Something went wrong, campaign not sent",
        })
      );
    }
  };

  return (
    <Layout>
      <div className="px-4 sm:px-6 md:px-0">
        <Tabs tabs={createCampaignTabs} />
      </div>
      <div>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 divide-y divide-gray-300">
              <PickServiceProvider />
              <Subject />
              <Editor />
              <PickGroups label="Groups" formValue="groups" />
              <PickContacts />
              <DataEntry
                label="Phone Numbers (2547...)"
                formValue="phoneNumbers"
              />
              <div className="flex flex-row justify-end pt-2">
                <Button type="submit" loading={messageLoaders.submit}>
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </Layout>
  );
};
