import { configureStore } from "@reduxjs/toolkit";
import axios, { AxiosAdapter } from "axios";
import { cacheAdapterEnhancer } from "axios-extensions";
import axiosMiddleware from "redux-axios-middleware";
import logger from "redux-logger";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import { getAuthToken, getBaseUrl, removeAuthToken } from "../utils";
import { Types as AuthTypes } from "./Auth/actionTypes";
import rootReducer from "./rootReducer";

const persistConfig = {
  key: "vgbRoot",
  version: 1,
  storage: storageSession,
  whitelist: ["auth", "reseller", "resellerDfs"],
};

const axiosClient = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    "Content-Type": "application/json",
  },
  adapter: cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, {
    enabledByDefault: false,
  }),
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const axiosMiddlewareConfig = {
  returnRejectedPromiseOnError: true,
  interceptors: {
    request: [
      ({ getState }: any, config: any) => {
        const accessToken = getState().auth.authToken ?? getAuthToken();
        if (!config.url.includes("/oauth/token")) {
          if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
          }
        }
        return config;
      },
    ],
    response: [
      {
        error: ({ getState }: any, error: any) => {
          const isAuthenticated = getState().auth.isAuthenticated;
          if (
            error &&
            error.response &&
            error.response.status === 401 &&
            !error.config.url.includes("/oauth/token") &&
            isAuthenticated
          ) {
            removeAuthToken();
            const url = window.location.origin;
            window.location.assign(`${url}/logout`);
          }
          return Promise.reject(error);
        },
      },
    ],
  },
};

let store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          AuthTypes.AUTHENTICATE,
        ],
      },
    })
      .concat(axiosMiddleware(axiosClient, axiosMiddlewareConfig))
      .concat(logger),
});

if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "test") {
  store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [
            FLUSH,
            REHYDRATE,
            PAUSE,
            PERSIST,
            PURGE,
            REGISTER,
            AuthTypes.AUTHENTICATE,
          ],
        },
      }).concat(axiosMiddleware(axiosClient, axiosMiddlewareConfig)),
  });
}

export default store;
export const persistor = persistStore(store);
