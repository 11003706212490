export const Types: Record<string, string> = {
  FETCH_CONTACTS: "FETCH_CONTACTS",
  FETCH_CONTACTS_SUCCESS: "FETCH_CONTACTS_SUCCESS",
  FETCH_CONTACTS_FAIL: "FETCH_CONTACTS_FAIL",

  FETCH_GROUPS: "FETCH_GROUPS",
  FETCH_GROUPS_SUCCESS: "FETCH_GROUPS_SUCCESS",
  FETCH_GROUPS_FAIL: "FETCH_GROUPS_FAIL",

  CHANGE_USERS_TABS: "CHANGE_USERS_TABS",

  TOGGLE_SELECTED_CONTACT: "TOGGLE_SELECTED_CONTACT",
  TOGGLE_SELECTED_GROUP: "TOGGLE_SELECTED_GROUP",

  UPDATE_SELECTED_CONTACT: "UPDATE_SELECTED_CONTACT",
  UPDATE_SELECTED_GROUP: "UPDATE_SELECTED_GROUP",

  TOGGLE_USER_MODAL: "TOGGLE_USER_MODAL",
  TOGGLE_DELETE_CONTEXT: "TOGGLE_DELETE_CONTEXT",

  CREATE_GROUP: "CREATE_GROUP",
  CREATE_GROUP_SUCCESS: "CREATE_GROUP_SUCCESS",
  CREATE_GROUP_FAIL: "CREATE_GROUP_FAIL",

  CREATE_CONTACT: "CREATE_CONTACT",
  CREATE_CONTACT_SUCCESS: "CREATE_CONTACT_SUCCESS",
  CREATE_CONTACT_FAIL: "CREATE_CONTACT_FAIL",

  DELETE_GROUP: "DELETE_GROUP",
  DELETE_GROUP_SUCCESS: "DELETE_GROUP_SUCCESS",
  DELETE_GROUP_FAIL: "DELETE_GROUP_FAIL",

  DELETE_CONTACT: "DELETE_CONTACT",
  DELETE_CONTACT_SUCCESS: "DELETE_CONTACT_SUCCESS",
  DELETE_CONTACT_FAIL: "DELETE_CONTACT_FAIL",

  UPDATE_CONTACT: "UPDATE_CONTACT",
  UPDATE_CONTACT_SUCCESS: "UPDATE_CONTACT_SUCCESS",
  UPDATE_CONTACT_FAIL: " UPDATE_CONTACT_FAIL",

  UPDATE_GROUP: "UPDATE_GROUP",
  UPDATE_GROUP_SUCCESS: "UPDATE_GROUP_SUCCESS",
  UPDATE_GROUP_FAIL: "UPDATE_GROUP_FAIL",

  UPLOAD_CONTACTS: "UPLOAD_CONTACTS",
  UPLOAD_CONTACTS_SUCCESS: "UPLOAD_CONTACTS_SUCCESS",
  UPLOAD_CONTACTS_FAIL: "UPLOAD_CONTACTS_FAIL",

  UPDATE_UPLOAD_CONTACTS: "UPDATE_UPLOAD_CONTACTS",

  FETCH_INDIVIDUAL_GROUP: "FETCH_INDIVIDUAL_GROUP",
  FETCH_INDIVIDUAL_GROUP_SUCCESS: "FETCH_INDIVIDUAL_GROUP_SUCCESS",
  FETCH_INDIVIDUAL_GROUP_FAIL: "FETCH_INDIVIDUAL_GROUP_FAIL",

  CLEAR_CONTACTS: "CLEAR_CONTACTS",
};
