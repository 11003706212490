import { FC } from "react";
import _ from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import { SelectCreatable } from "../../App/FormComponents";
import { DescriptionPanel } from "../Messages";

interface DataEntryProps {
  formValue: string;
  label: string;
}

export const DataEntry: FC<DataEntryProps> = ({ formValue, label }) => {
  const { control } = useFormContext();

  return (
    <DescriptionPanel label={label}>
      <Controller
        name={formValue}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          const handleChange = (values: any[]) => {
            onChange(values);
          };
          const errorMessage: string | undefined = (error as any)?.find(
            (err: any) => !_.isEmpty(err)
          )?.value?.message;
          return (
            <SelectCreatable
              id={formValue}
              values={value}
              onChange={handleChange}
              error={errorMessage}
            />
          );
        }}
      />
    </DescriptionPanel>
  );
};
