import { createReducer } from "@reduxjs/toolkit";
import _ from "lodash";
import { PaginationProps } from "../../App";
import { Types } from "./actionTypes";

export interface InitialResellerDfsState {
  cache: Record<string, any>;
  resellerDfss: any[];
  selectedResellerDfs: any;
  resellerDfssPagination: PaginationProps;
  resellerDfsModals: any;
  resellerDfssLoaders: any;
}

const initialState: InitialResellerDfsState = {
  resellerDfss: [],
  resellerDfssPagination: {
    number: 0,
    totalPages: 0,
    size: 100,
    totalElements: 0,
  },
  selectedResellerDfs: {},
  resellerDfssLoaders: {
    submit: false,
    fetchResellerDfss: false,
  },
  resellerDfsModals: {
    addResellerDfs: false,
    editResellerDfs: false,
    deleteResellerDfs: false,
  },
  cache: {},
};

export const resellerDfsReducer = createReducer(initialState, {
  [Types.TOGGLE_RESELLER_DFS_MODALS]: (state, action) => {
    state.resellerDfsModals = {
      ...state.resellerDfsModals,
      ...action.payload,
    };
  },
  [Types.UPDATE_SELECTED_RESELLER_DFS]: (state, action) => {
    state.selectedResellerDfs = action.payload;
  },

  [Types.FETCH_RESELLER_DFSS]: (state) => {
    state.resellerDfssLoaders.fetchResellerDfss = true;
  },
  [Types.FETCH_RESELLER_DFSS_SUCCESS]: (state, action) => {
    const { data } = action.payload;
    state.resellerDfssLoaders.fetchResellerDfss = false;
    state.resellerDfss = data;
  },
  [Types.FETCH_RESELLER_DFSS_FAIL]: (state) => {
    state.resellerDfssLoaders.fetchResellerDfss = false;
  },

  [Types.CREATE_RESELLER_DFS]: (state) => {
    state.resellerDfssLoaders.submit = true;
  },
  [Types.CREATE_RESELLER_DFS_SUCCESS]: (state, action) => {
    state.resellerDfssLoaders.submit = false;
    state.resellerDfss = [action.payload.data, ...state.resellerDfss];
  },
  [Types.CREATE_RESELLER_DFS_FAIL]: (state) => {
    state.resellerDfssLoaders.submit = false;
  },

  [Types.DELETE_RESELLER_DFS]: (state) => {
    state.resellerDfssLoaders.submit = true;
  },
  [Types.DELETE_RESELLER_DFS_SUCCESS]: (state, action) => {
    const {
      previousAction: {
        payload: {
          request: {
            deleteProp: { id },
          },
        },
      },
    } = action.meta;
    state.resellerDfss = [
      ...state.resellerDfss.filter((resellerDfs) => resellerDfs.id !== id),
    ];
    if (_.isEmpty(state.resellerDfss)) {
      state.resellerDfssPagination.number = 0;
    }
    state.resellerDfssLoaders.submit = false;
  },
  [Types.DELETE_RESELLER_DFS_FAIL]: (state) => {
    state.resellerDfssLoaders.submit = false;
  },

  [Types.UPDATE_RESELLER_DFS]: (state) => {
    state.resellerDfssLoaders.submit = true;
  },
  [Types.UPDATE_RESELLER_DFS_SUCCESS]: (state, action) => {
    state.resellerDfssLoaders.submit = false;
    state.resellerDfss = [
      ...state.resellerDfss.map((sp) =>
        sp.id === action.payload.data.id ? action.payload.data : sp
      ),
    ];
  },
  [Types.UPDATE_RESELLER_DFS_FAIL]: (state) => {
    state.resellerDfssLoaders.submit = false;
  },
});
