import { XIcon } from "@heroicons/react/outline";
import { FC } from "react";

interface DrawerProps {
  isOpen: boolean;
  drawerTitle: string;
  onClose: () => void;
}

export const Drawer: FC<DrawerProps> = ({
  isOpen,
  drawerTitle,
  onClose,
  children,
}) => {
  return (
    <div
      className={
        "fixed overflow-hidden z-10 bg-gray-900 bg-opacity-40 inset-0 transform ease-in-out " +
        (isOpen
          ? "opacity-100 duration-500 translate-x-0  "
          : "delay-500 opacity-0 translate-x-full")
      }
    >
      <section
        className={
          "w-screen max-w-xl right-0 absolute  bg-white h-full duration-500 ease-in-out transform" +
          (isOpen ? " translate-x-0 " : " translate-x-full ")
        }
      >
        <article className="relative w-screen max-w-xl pb-10 flex flex-col space-y-6 overflow-y-scroll h-full">
          <div className="flex justify-between flex-row-reverse p-2 items-center">
            <header className="font-bold text-lg">{drawerTitle}</header>
            <button
              onClick={onClose}
              className="inline-flex p-2 rounded-2xl hover:bg-gray-100 center-contents text-xl ml-2"
            >
              <XIcon className="h-5 w-5 text-black" />
            </button>
          </div>
          <div className="m-5">{children}</div>
        </article>
      </section>
      <section
        className=" w-screen h-full cursor-pointer "
        onClick={() => {
          onClose();
        }}
      ></section>
    </div>
  );
};
