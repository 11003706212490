import { FC } from "react";
import classNames from "classnames";
import { useHistory, useLocation, Link } from "react-router-dom";
import { TabItemProps } from "../../domains/App";

interface TabsProps {
  tabs: TabItemProps[];
}

export const Tabs: FC<TabsProps> = ({ tabs }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const goToPath = (path: string) => history.push(path);

  return (
    <>
      <div className="lg:hidden">
        <label htmlFor="selected-tab" className="sr-only">
          Select a tab
        </label>
        <select
          id="selected-tab"
          name="selected-tab"
          className="mt-4 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-tandaPurple focus:border-tandaPurple sm:text-sm rounded-md"
          defaultValue={tabs[0].path}
          onChange={(e) => goToPath(e.target.value)}
        >
          {tabs.map((tab) => (
            <option value={tab.path} key={tab.key}>
              {tab.label}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden lg:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab) => {
              const matches = pathname === tab.path;
              return (
                <Link
                  key={tab.key}
                  className={classNames(
                    "whitespace-nowrap py-1 px-1 border-b-2 font-medium text-sm",
                    {
                      "border-tandaPurple text-tandaPurple": matches,
                      "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700":
                        !matches,
                    }
                  )}
                  to={tab.path}
                >
                  {tab.label}
                </Link>
              );
            })}
          </nav>
        </div>
      </div>
    </>
  );
};
