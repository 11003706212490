export const Types: Record<string, string> = {
  TOGGLE_CUSTOMERTRANSACTIONFEERATE_MODALS:
    "TOGGLE_CUSTOMERTRANSACTIONFEERATE_MODALS",

  FETCH_CUSTOMERTRANSACTIONFEERATES: "FETCH_CUSTOMERTRANSACTIONFEERATES",
  FETCH_CUSTOMERTRANSACTIONFEERATES_SUCCESS:
    "FETCH_CUSTOMERTRANSACTIONFEERATES_SUCCESS",
  FETCH_CUSTOMERTRANSACTIONFEERATES_FAIL:
    "FETCH_CUSTOMERTRANSACTIONFEERATES_FAIL",

  CREATE_CUSTOMERTRANSACTIONFEERATE: "CREATE_CUSTOMERTRANSACTIONFEERATE",
  CREATE_CUSTOMERTRANSACTIONFEERATE_SUCCESS:
    "CREATE_CUSTOMERTRANSACTIONFEERATE_SUCCESS",
  CREATE_CUSTOMERTRANSACTIONFEERATE_FAIL:
    "CREATE_CUSTOMERTRANSACTIONFEERATE_FAIL",

  UPDATE_CUSTOMERTRANSACTIONFEERATE: "UPDATE_CUSTOMERTRANSACTIONFEERATE",
  UPDATE_CUSTOMERTRANSACTIONFEERATE_SUCCESS:
    "UPDATE_CUSTOMERTRANSACTIONFEERATE_SUCCESS",
  UPDATE_CUSTOMERTRANSACTIONFEERATE_FAIL:
    "UPDATE_CUSTOMERTRANSACTIONFEERATE_FAIL",

  DELETE_CUSTOMERTRANSACTIONFEERATE: "DELETE_CUSTOMERTRANSACTIONFEERATE",
  DELETE_CUSTOMERTRANSACTIONFEERATE_SUCCESS:
    "DELETE_CUSTOMERTRANSACTIONFEERATE_SUCCESS",
  DELETE_CUSTOMERTRANSACTIONFEERATE_FAIL:
    "DELETE_CUSTOMERTRANSACTIONFEERATE_FAIL",

  FETCH_CUST_TRANSACTION_FEES: "FETCH_CUST_TRANSACTION_FEES",
  FETCH_CUST_TRANSACTION_FEES_SUCCESS: "FETCH_CUST_TRANSACTION_FEES_SUCCESS",
  FETCH_CUST_TRANSACTION_FEES_FAIL: "FETCH_CUST_TRANSACTION_FEES_FAIL",

  CHANGE_CUST_STATUS: "CHANGE_CUST_STATUS",
  CHANGE_CUST_STATUS_SUCCESS: "CHANGE_CUST_STATUS_SUCCESS",
  CHANGE_CUST_STATUS_FAIL: "CHANGE_CUST_STATUS_FAIL",
};
