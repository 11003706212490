import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Banners } from "../../App";
import { ServiceProviderTable } from "./ServiceProviderTable";
import { ServiceProviderForm } from "./ServiceProviderForm";
import {
  fetchServiceProviders,
  InitialMessageState,
  toggleMessagingModal,
} from "../../../domains/Messages";
import { config } from "../../../constants";

export const ServiceProvidersPage: FC = () => {
  const dispatch = useDispatch();
  const { serviceProviders, messageLoaders, serviceProvidersPagination } =
    useSelector((state: { message: InitialMessageState }) => state.message);

  useEffect(() => {
    const fetchServiceProvidersAsync = async () =>
      await dispatch(fetchServiceProviders());

    fetchServiceProvidersAsync();
  }, [dispatch]);

  const handlePagination = (page: number) => {
    dispatch(
      fetchServiceProviders({
        page: page - 1,
        size: serviceProvidersPagination.size,
      })
    );
  };

  const handleModal = async () => {
    await dispatch(
      toggleMessagingModal({
        addServiceProvider: true,
      })
    );
  };

  return (
    <Layout>
      <div>
        <Banners
          withAction
          actionLabel="Create Service Provider"
          onClick={handleModal}
        >
          <p className="pl-3">Do you want to create a service provider?</p>
        </Banners>
      </div>
      <ServiceProviderForm />
      <div className="pt-6">
        <ServiceProviderTable
          columns={config.serviceProvider.normal.listColumns}
          data={serviceProviders}
          pagination={serviceProvidersPagination}
          loading={messageLoaders.fetchServiceProviders}
          onPaginationChange={handlePagination}
        />
      </div>
    </Layout>
  );
};
