import { createReducer } from "@reduxjs/toolkit";
import { Types } from "./actionTypes";
import { UserTabs } from "./types";
import { PaginationProps } from "../App";
import _ from "lodash";

export interface InitialUserStateProps {
  contacts: any[];
  groups: any[];
  userLoader: Record<string, boolean>;
  contactPagination: PaginationProps;
  groupPagination: PaginationProps;
  userTab: string;
  selectedContact: any;
  selectedGroup: any;
  selectedContacts: any[];
  selectedGroups: any[];
  uploadedContacts: any[];
  userModals: Record<string, boolean>;
}

const initialState: InitialUserStateProps = {
  contacts: [],
  groups: [],
  userLoader: {
    submit: false,
    fetchAllGroups: false,
    fetchGroup: false,
    fetchContacts: false,
  },
  contactPagination: {
    number: 1,
    totalPages: 1,
    size: 10,
    totalElements: 0,
  },
  groupPagination: {
    number: 1,
    totalPages: 1,
    size: 10,
    totalElements: 0,
  },
  userTab: UserTabs.Contact,
  selectedContact: {},
  selectedGroup: {},
  selectedContacts: [],
  selectedGroups: [],
  uploadedContacts: [],
  userModals: {
    deleteModal: false,
    groupModal: false,
    editGroup: false,
    editContact: false,
    contactModal: false,
    contactUpload: false,
  },
};

const getIdFromUrl = (url: string, idSize = 30) =>
  url.split("/").find((value) => value.length >= idSize);

export const usersReducer = createReducer(initialState, {
  [Types.FETCH_CONTACTS]: (state) => {
    state.userLoader.fetchContacts = true;
  },
  [Types.FETCH_CONTACTS_SUCCESS]: (state, action) => {
    const {
      data: { content, page },
    } = action.payload;
    state.userLoader.fetchContacts = false;
    state.contacts = content;
    state.contactPagination = {
      number: page.number,
      size: page.size,
      totalPages: page.totalPages,
      totalElements: page.totalElements,
    };
  },
  [Types.FETCH_CONTACTS_FAIL]: (state) => {
    state.userLoader.fetchContacts = false;
  },

  [Types.CREATE_GROUP]: (state) => {
    state.userLoader.submit = true;
  },
  [Types.CREATE_GROUP_SUCCESS]: (state, action) => {
    state.userLoader.submit = false;
    state.groups = [action.payload.data, ...state.groups];
  },
  [Types.CREATE_GROUP_FAIL]: (state) => {
    state.userLoader.submit = false;
  },

  [Types.CREATE_CONTACT]: (state) => {
    state.userLoader.submit = true;
  },
  [Types.CREATE_CONTACT_SUCCESS]: (state, action) => {
    state.userLoader.submit = false;
    state.contacts = [action.payload.data, ...state.contacts];
  },
  [Types.CREATE_CONTACT_FAIL]: (state) => {
    state.userLoader.submit = false;
  },

  [Types.UPDATE_CONTACT]: (state) => {
    state.userLoader.submit = true;
  },
  [Types.UPDATE_CONTACT_SUCCESS]: (state, action) => {
    state.userLoader.submit = false;
    state.contacts = [
      ...state.contacts.map((contact) =>
        contact.id === action.payload.data.id ? action.payload.data : contact
      ),
    ];
  },
  [Types.UPDATE_CONTACT_FAIL]: (state) => {
    state.userLoader.submit = false;
  },

  [Types.UPDATE_GROUP]: (state) => {
    state.userLoader.submit = true;
  },
  [Types.UPDATE_GROUP_SUCCESS]: (state, action) => {
    state.userLoader.submit = false;
    state.groups = [
      ...state.groups.map((group) =>
        group.id === action.payload.data.id ? action.payload.data : group
      ),
    ];
  },
  [Types.UPDATE_GROUP_FAIL]: (state) => {
    state.userLoader.submit = false;
  },

  [Types.FETCH_GROUPS]: (state) => {
    state.userLoader.fetchAllGroups = true;
  },
  [Types.FETCH_GROUPS_SUCCESS]: (state, action) => {
    const {
      data: { content, page },
    } = action.payload;
    state.userLoader.fetchAllGroups = false;
    state.groups = content;
    state.groupPagination = {
      number: page.number,
      size: page.size,
      totalPages: page.totalPages,
      totalElements: page.totalElements,
    };
  },
  [Types.FETCH_GROUPS_FAIL]: (state) => {
    state.userLoader.fetchAllGroups = false;
  },

  [Types.FETCH_INDIVIDUAL_GROUP]: (state) => {
    state.userLoader.fetchGroup = true;
  },
  [Types.FETCH_INDIVIDUAL_GROUP_SUCCESS]: (state, action) => {
    const { data } = action.payload;
    state.userLoader.fetchGroup = false;
    state.selectedGroup = data;
  },
  [Types.FETCH_INDIVIDUAL_GROUP_FAIL]: (state) => {
    state.userLoader.fetchGroup = false;
  },

  [Types.CHANGE_USERS_TABS]: (state, action) => {
    state.userTab = action.payload;
  },

  [Types.TOGGLE_SELECTED_CONTACT]: (state, action) => {
    const { user } = action.payload;
    if (_.isString(user) && user === "all") {
      if (state.selectedContacts.length === state.contacts.length) {
        state.selectedContacts = [];
      } else {
        state.selectedContacts = [...state.contacts];
      }
    } else {
      if (state.selectedContacts.some((contact) => contact.id === user.id)) {
        state.selectedContacts = state.selectedContacts.filter(
          (contact) => contact.id !== user.id
        );
      } else {
        state.selectedContacts = [...state.selectedContacts, user];
      }
    }
  },
  [Types.TOGGLE_SELECTED_GROUP]: (state, action) => {
    const { user } = action.payload;
    if (_.isString(user) && user === "all") {
      if (state.selectedGroups.length === state.groups.length) {
        state.selectedGroups = [];
      } else {
        state.selectedGroups = [...state.groups];
      }
    } else {
      if (state.selectedGroups.some((group) => group.id === user.id)) {
        state.selectedGroups = state.selectedGroups.filter(
          (group) => group.id !== user.id
        );
      } else {
        state.selectedGroups = [...state.selectedGroups, user];
      }
    }
  },

  [Types.DELETE_CONTACT]: (state) => {
    state.userLoader.submit = true;
  },
  [Types.DELETE_CONTACT_SUCCESS]: (state, action) => {
    const {
      config: { url },
    } = action.payload;
    const id = getIdFromUrl(url);
    state.contacts = [...state.contacts.filter((contact) => contact.id !== id)];
    state.selectedContacts = [];
    if (_.isEmpty(state.contacts)) {
      state.contactPagination.number = 0;
    }
    state.userLoader.submit = false;
  },
  [Types.DELETE_CONTACT_FAIL]: (state) => {
    state.userLoader.submit = false;
  },

  [Types.DELETE_GROUP]: (state) => {
    state.userLoader.submit = true;
  },
  [Types.DELETE_GROUP_SUCCESS]: (state, action) => {
    const {
      config: { url },
    } = action.payload;
    const id = _.last(url.split("/"));
    state.groups = [...state.groups.filter((group) => group.id !== id)];
    state.selectedGroups = [];
    if (_.isEmpty(state.groups)) {
      state.groupPagination.number = 0;
    }
    state.userLoader.submit = false;
  },
  [Types.DELETE_GROUP_FAIL]: (state) => {
    state.userLoader.submit = false;
  },

  [Types.TOGGLE_USER_MODAL]: (state, action) => {
    state.userModals = {
      ...state.userModals,
      ...action.payload,
    };
  },

  [Types.UPDATE_SELECTED_CONTACT]: (state, action) => {
    state.selectedContact = { ...action.payload };
  },
  [Types.UPDATE_SELECTED_GROUP]: (state, action) => {
    state.selectedGroup = { ...action.payload };
  },

  [Types.UPLOAD_CONTACTS]: (state, action) => {
    state.userLoader.submit = true;
  },
  [Types.UPLOAD_CONTACTS_SUCCESS]: (state, action) => {
    state.userLoader.submit = false;
  },
  [Types.UPLOAD_CONTACTS_FAIL]: (state, action) => {
    state.userLoader.submit = false;
  },

  [Types.UPDATE_UPLOAD_CONTACTS]: (state, action) => {
    const {
      payload: { data, remove },
    } = action;
    if (remove) {
      state.uploadedContacts = state.uploadedContacts.filter(
        (contact) => contact.id !== data.id
      );
      return;
    }
    state.uploadedContacts = state.uploadedContacts.map((contact) =>
      contact.id === data.id
        ? {
            ...contact,
            ...data,
          }
        : contact
    );
  },

  [Types.CLEAR_CONTACTS]: (state, action) => {
    state.contacts = [];
  },
});
