import { FC } from "react";
import { ExpandTable, Loader } from "../../App";
import dayJs from "dayjs";

interface LoanTableProps {
  control?: any;
  columns: any[];
  data: any[];
  onChange?: (value: string) => void;
  onPaginationChange?: (page: number) => void;
  loading: boolean;
  title: string;
  SubComponent?: React.FC<{ row: any }>;
}

export const IOTransactionTable: FC<LoanTableProps> = ({
  columns,
  data,
  onChange = (value) => { },
  onPaginationChange,
  loading,
  title,
  SubComponent,
}) => {
  const getColumns = () => {
    const availableColumns = [
      {
        id: "organizationName",
        Header: "Organization",
        accessor: "organizationName",
      },
      {
        id: "walletAccountName",
        Header: "Wallet Account",
        accessor: "walletAccountName",
      },
      
      {
        id: "amount",
        Header: "Amount(KES)",
        accessor: (row: any) => {
          return <span>{(row?.amount).toFixed(2)}</span>;
        },
      },
      {
        id: "ffDirectionName",
        Header: "Direction",
        accessor: "ffDirectionName",
      },
      {
        id: "amountWithdrawn",
        Header: "Withdrawn(KES)",
        accessor: (row: any) => {
          return <span>{(row?.amountWithdrawn).toFixed(2)}</span>;
        },
      },
      {
        id: "productName",
        Header: "Product",
        accessor: "productName",
      },
      {
        id: "datetimeCompleted",
        Header: "Completed at",
        accessor: (row: any) => {
          return (
            <span>{dayJs(row?.datetimeCompleted).format("YYYY-MM-DD hh:mm A")}</span>
          );
        },
      },
      {
        id: "lastModified",
        Header: "Last Update",
        accessor: (row: any) => {
          return (
            <span>{dayJs(row?.lastModified).format("YYYY-MM-DD hh:mm A")}</span>
          );
        },
      },
    ];

    return columns.map((column) =>
      availableColumns.find(
        (availableColumn) => availableColumn.id === column.id
      )
    );
  };

  if (loading) {
    return (
      <div className="mt-4 rounded-lg flex flex-row items-center justify-center w-full h-60 bg-white">
        <Loader />
      </div>
    );
  }
  return (
    <>
      <ExpandTable
        columns={getColumns()}
        data={data}
        pageCount={0}
        currentPage={0}
        onPaginationChange={onPaginationChange}
        SubComponent={SubComponent}
      />
    </>
  );
};
