import _ from "lodash";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOrganizationPayments,
  InitialWalletState,
  toggleWalletModal,
  updatePayoutsSearchParams,
} from "../../../domains/Reseller/Wallet";
import { Button, Layout, SuccessOrErrorState } from "../../App";

import { DownloadIcon } from "@heroicons/react/outline";
import dayjs from "dayjs";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { config } from "../../../constants";
import { openNotification } from "../../../domains/App";
import { TextField } from "../../App/FormComponents";
import { DateTimePicker } from "../../App/FormComponents/DateTimePicker";
import { DescriptionPanel } from "../../Messaging";
import { ResellerTabs } from "../ResellerTabs";
import { DownloadStatementForm } from "./DownloadStatementForm";
import { PayoutsTable } from "./PayoutsTable";
import { useParams } from "react-router-dom";

interface SearchParams {
  page: number;
  size: number;
  since: string;
  until: string;
  q: string;
}

export const PayoutsPage: FC = () => {
  const { searchPaymentsParams, payments, walletsLoaders, walletsPagination } =
    useSelector(
      (state: { resellerWallet: InitialWalletState }) => state.resellerWallet
    );

  const params: any = useParams();
  const dispatch = useDispatch();
  let defaultSince =
    dayjs(new Date().setDate(new Date().getDate() - 1))
      .toISOString()
      .split(".")[0] + "Z";
  let defaultTill = dayjs(new Date()).toISOString().split(".")[0] + "Z";

  const handlePagination = (page: number) => {
    dispatch(
      fetchOrganizationPayments(params?.resellerId, {
        page: page - 1,
        size: walletsPagination.size,
        since: searchPaymentsParams?.since || defaultSince,
        until: searchPaymentsParams?.until || defaultTill,
      })
    );
  };
  useEffect(() => {
    const fetchPayoutsAsync = async () =>
      await dispatch(
        fetchOrganizationPayments(params?.resellerId, {
          page: 0,
          size: 10,
          since: defaultSince,
          until: defaultTill,
        })
      );

    fetchPayoutsAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.resellerId]);
  const { control, reset, handleSubmit } = useForm<SearchParams>({
    defaultValues: {
      page: 0,
      size: 10,
      since: defaultSince,
      until: defaultTill,
    },
  });

  const onSubmit: SubmitHandler<SearchParams> = async (props) => {
    const query = {
      page: 0,
      size: 10,
      since: dayjs(props.since).toISOString().split(".")[0] + "Z",
      until: dayjs(props.until).toISOString().split(".")[0] + "Z",
    };
    dispatch(updatePayoutsSearchParams(query));
    try {
      await dispatch(fetchOrganizationPayments(params?.resellerId, query));
    } catch (e) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Something went wrong",
        })
      );
    }
  };

  const onReferenceSubmit: SubmitHandler<SearchParams> = async (props) => {
    const query = {
      search: props?.q?.trim() ?? "",
    };
    dispatch(updatePayoutsSearchParams(query));
    try {
      await dispatch(fetchOrganizationPayments(params?.resellerId, query));
    } catch (e) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Something went wrong",
        })
      );
    }
  };
  const onReset = () => {
    reset();
    dispatch(
      fetchOrganizationPayments(params?.resellerId, {
        page: 0,
        size: 10,
        since: defaultSince,
        till: defaultTill,
      })
    );
  };
  const handleModal = async () => {
    await dispatch(
      toggleWalletModal({
        downloadPayoutsStatement: true,
      })
    );
  };
  return (
    <Layout>
      <ResellerTabs />
      <DownloadStatementForm type="Payouts" />
      <div className="mt-4">
        <div className="flex flex-col lg:flex-row-reverse justify-between items-center">
          <Button
            variant="danger"
            size="sm"
            type="reset"
            onClick={() => handleModal()}
          >
            <DownloadIcon className="h-4 w-4 mr-3 " /> Statement
          </Button>
          <form
            id="transaction filters"
            className="space-y-2"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex flex-col lg:flex-row lg:space-x-2 justify-between items-center">
              <Controller
                control={control}
                name="since"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <DateTimePicker
                    id="since"
                    label="Since:"
                    placeholder="Enter time since"
                    value={value}
                    error={error?.message}
                    onChange={onChange}
                    onClick={handleSubmit(onSubmit)}
                    includeTime={true}
                    max={defaultTill}
                  />
                )}
              />
              <Controller
                control={control}
                name="until"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <DateTimePicker
                    id="until"
                    label="Until:   "
                    placeholder="Enter till"
                    value={value}
                    error={error?.message}
                    onChange={onChange}
                    onClick={handleSubmit(onSubmit)}
                    includeTime={true}
                    max={defaultTill}
                  />
                )}
              />
            </div>
          </form>

          <form
            id="transaction filters"
            className="space-y-2"
            onSubmit={handleSubmit(onReferenceSubmit)}
          >
            <div className="flex flex-col space-x-2 lg:flex-row justify-between items-center">
              <Controller
                control={control}
                name="q"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    id="q"
                    placeholder="Receipt Number | Transaction ID"
                    type="text"
                    value={value}
                    error={error?.message}
                    onChange={onChange}
                  />
                )}
              />
              <div className="flex flex-row items-center -mt-1">
                <div className="mr-2">
                  <Button btnClass="primary" size="md" type="submit">
                    Search
                  </Button>
                </div>
                <div>
                  <Button
                    btnClass="primary"
                    size="md"
                    type="reset"
                    onClick={() => onReset()}
                  >
                    Clear
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
        {_.isEmpty(payments) && !walletsLoaders.fetchPayments ? (
          <SuccessOrErrorState state="empty" message="No Payments Found" />
        ) : (
          <PayoutsTable
            title={config.walletPayment.title}
            columns={config.walletPayment.listColumns}
            data={payments}
            pagination={walletsPagination}
            onPaginationChange={handlePagination}
            loading={walletsLoaders.fetchPayments}
            SubComponent={(value: any) => {
              return (
                <>
                  <div className="w-full pt-2 pb-4 ml-7 px-4 grid grid-cols-2">
                    <DescriptionPanel
                      label="Direction Name "
                      description={value?.row?.original?.ffDirectionName}
                    />
                    <DescriptionPanel
                      label="Product Type"
                      description={value?.row?.original?.productTypeName}
                    />
                    <DescriptionPanel
                      label="Service Provider"
                      description={value?.row?.original?.serviceProviderName}
                    />
                    <DescriptionPanel
                      label="Date Initiated"
                      description={dayjs(
                        value?.row?.original?.dateCreated
                      ).format("YYYY-MM-DD hh:mm A")}
                    />
                    <DescriptionPanel
                      label="Integration"
                      description={value?.row?.original?.intgName}
                    />

                    <DescriptionPanel
                      label="Status Queryable"
                      description={
                        value?.row?.original?.isStatusQueryable ? "Yes" : "No"
                      }
                    />
                    <DescriptionPanel
                      label="Message"
                      description={value?.row?.original?.statusMessage}
                    />
                    <DescriptionPanel
                      label="Transaction Ref"
                      description={value?.row?.original?.spReference}
                    />
                  </div>
                </>
              );
            }}
          />
        )}
      </div>
    </Layout>
  );
};
