export const Types: Record<string, string> = {
  TOGGLE_SERVICEPROVIDER_MODALS: "TOGGLE_SERVICEPROVIDER_MODALS",

  FETCH_SERVICEPROVIDERS: "FETCH_SERVICEPROVIDERS",
  FETCH_SERVICEPROVIDERS_SUCCESS: "FETCH_SERVICEPROVIDERS_SUCCESS",
  FETCH_SERVICEPROVIDERS_FAIL: "FETCH_SERVICEPROVIDERS_FAIL",

  CREATE_SERVICEPROVIDER: "CREATE_SERVICEPROVIDER",
  CREATE_SERVICEPROVIDER_SUCCESS: "CREATE_SERVICEPROVIDER_SUCCESS",
  CREATE_SERVICEPROVIDER_FAIL: "CREATE_SERVICEPROVIDER_FAIL",

  UPDATE_SERVICEPROVIDER: "UPDATE_SERVICEPROVIDER",
  UPDATE_SERVICEPROVIDER_SUCCESS: "UPDATE_SERVICEPROVIDER_SUCCESS",
  UPDATE_SERVICEPROVIDER_FAIL: "UPDATE_SERVICEPROVIDER_FAIL",

  DELETE_SERVICEPROVIDER: "DELETE_SERVICEPROVIDER",
  DELETE_SERVICEPROVIDER_SUCCESS: "DELETE_SERVICEPROVIDER_SUCCESS",
  DELETE_SERVICEPROVIDER_FAIL: "DELETE_SERVICEPROVIDER_FAIL",
};
