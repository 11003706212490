import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchResellerApiClients,
  InitialResellerState,
  toggleResellerModal,
  updateSelectedApiClient,
} from "../../../domains/Reseller/Reseller";
import { Button, Drawer, Loader, SuccessOrErrorState, Table } from "../../App";
import { AddClient } from "./AddClient";
import { EditClient } from "./EditClient";
import _ from "lodash";
import dayJs from "dayjs";
import { ApiClientRoles } from "./ApiClientRoles";

export const ApiClients = () => {
  const dispatch = useDispatch();
  let { reseller, apiClients, resellersLoaders, resellerModals } = useSelector(
    (state: { reseller: InitialResellerState }) => state.reseller
  );

  const columns = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Last Modified",
      accessor: (row: any) => {
        return (
          <span>{dayJs(row?.lastModified).format("YYYY-MM-DD hh:mm A")}</span>
        );
      },
    },
    {
      id: "actions",
      accessor: (row: any) => {
        return (
          <div className="flex flex-row items-center gap-2">
            {/* <Button onClick={openDetailsDrawer}>View More</Button> */}
            <Button onClick={() => openRolesDrawer(row)}>Roles</Button>
          </div>
        );
      },
    },
  ];

  const closeDetailsDrawer = async () => {
    await dispatch(
      toggleResellerModal({
        viewClient: false,
      })
    );
  };

  // const openDetailsDrawer = async () => {
  //   await dispatch(
  //     toggleResellerModal({
  //       viewClient: true,
  //     })
  //   );
  // };

  const openRolesDrawer = async (client: any) => {
    dispatch(updateSelectedApiClient(client));
    await dispatch(
      toggleResellerModal({
        viewClientRoles: true,
      })
    );
  };


  useEffect(() => {
    const fetchResellerApiClientsAsync = () => {
      dispatch(fetchResellerApiClients(reseller?.id));
    };
    fetchResellerApiClientsAsync();
  }, [dispatch, reseller?.id]);

  return (
    <div>
      <AddClient />
      <EditClient />
      <Drawer
        isOpen={resellerModals.viewClient}
        drawerTitle={"View Api Client"}
        onClose={closeDetailsDrawer}
      >
        <div>
          <div>Client ID</div>
        </div>
      </Drawer>
      <ApiClientRoles />
      {resellersLoaders.fetchApiClients ? (
        <div className="mt-4 rounded-lg flex flex-row items-center justify-center w-full h-24">
          <Loader />
        </div>
      ) : (
        <>
          {!_.isEmpty(apiClients) && (
            <div>
              <div>
                <Table columns={columns} data={apiClients} />
              </div>
            </div>
          )}
          {_.isEmpty(apiClients) && (
            <SuccessOrErrorState state="empty" message="No Api clients Found" />
          )}
        </>
      )}
    </div>
  );
};
