import { joiResolver } from "@hookform/resolvers/joi";
import _ from "lodash";
import { FC, useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { schemaValidation } from "../../../constants";
import { openNotification } from "../../../domains/App";
import {
  InitialResellerDfsGatewayState,
  toggleResellerDfsGatewayModal,
  updateResellerDfsGateway,
} from "../../../domains/Reseller/ResellerDfsGateway";
import { Modal } from "../../App";
import { Select } from "../../App/FormComponents";

interface ResellerDfsGateway {
  id: string;
}

interface EditResellerDfsGatewayFormProps {
  resellerDfsGateway: any;
    paymentGateways: any;
}

export const EditResellerDfsGatewayForm: FC<EditResellerDfsGatewayFormProps> =
  ({ resellerDfsGateway,paymentGateways }) => {
    const dispatch = useDispatch();
    const { resellerDfsGatewayModals, resellerDfsGatewaysLoaders } =
      useSelector(
        (state: { resellerDfsGateway: InitialResellerDfsGatewayState }) =>
          state.resellerDfsGateway
      );
    // const { paymentGateways } = useSelector(
    //   (state: { paymentGateway: InitialPaymentGatewayState }) =>
    //     state.paymentGateway
    // );
    // const paymentGatewayOptions = paymentGateways.map((f: any) => ({
    //   label: f.name,
    //   value: f.id,
    // }));
    const { dfsId = "" } = useParams<{ dfsId?: string }>();

    const { control, handleSubmit, reset } = useForm<ResellerDfsGateway>({
      resolver: joiResolver(schemaValidation.ResellerDfsGatewaySchema),
      defaultValues: {
        id: "",
      },
    });

    useEffect(() => {
      if (!_.isEmpty(resellerDfsGateway)) {
        reset({
          id: resellerDfsGateway?.id || "",
        });
      }
    }, [resellerDfsGateway, reset]);

    const onClose = async () => {
      reset();
      await dispatch(
        toggleResellerDfsGatewayModal({
          editResellerDfsGateway: false,
        })
      );
    };

    const onSubmit: SubmitHandler<ResellerDfsGateway> = async (props) => {
      try {
        await dispatch(updateResellerDfsGateway(dfsId, props));
        await onClose();
        await dispatch(
          openNotification({
            openNotification: true,
            type: "success",
            message: "Reseller Product Gateway updated!",
          })
        );
      } catch (e) {
        dispatch(
          openNotification({
            openNotification: true,
            type: "error",
            message: "Something went wrong",
          })
        );
      }
    };
    return (
      <Modal
        formId="edit_reseller_dfs_gateway_form"
        btnType="submit"
        modalTitle="Edit Product Gateway"
        isOpen={resellerDfsGatewayModals.editResellerDfsGateway}
        onClose={onClose}
        btnLoading={resellerDfsGatewaysLoaders.submit}
      >
        <form
          id="edit_reseller_dfs_gateway_form"
          className="space-y-2"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            control={control}
            name="id"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Select
                id="id"
                label="Payment Gateway"
                selectedOption={value}
                error={error?.message}
                onChange={onChange}
                options={[
                  {
                    value: "no_selection",
                    label: "No selection",
                    unavailable: true,
                  },
                  ...paymentGateways,
                ]}
              />
            )}
          />{" "}
        </form>
      </Modal>
    );
  };
