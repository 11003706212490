import { joiResolver } from "@hookform/resolvers/joi";
import _ from "lodash";
import { FC, useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { schemaValidation } from "../../../constants";
import { openNotification } from "../../../domains/App";
import {
  InitialWalletState,
  toggleWalletModal,
  updateCollectionWallet,
} from "../../../domains/Reseller/Wallet";
import { Modal } from "../../App";
import { TextField } from "../../App/FormComponents";

interface Wallet {
  name: "";
  ipnUrl: "";
  username: "";
  password: "";
}

interface EditWalletFormProps {
  wallet: any;
}

export const EditCollectionWalletForm: FC<EditWalletFormProps> = ({
  wallet,
}) => {
  const dispatch = useDispatch();
  const { walletModals, walletsLoaders } = useSelector(
    (state: { wallet: InitialWalletState }) => state.wallet
  );
  const { resellerId = "" } = useParams<{ resellerId?: string }>();

  const { control, handleSubmit, reset } = useForm<Wallet>({
    resolver: joiResolver(schemaValidation.CollectionWalletSchema),
    defaultValues: {
      name: "",
      ipnUrl: "",
      username: "",
      password: "",
    },
  });

  useEffect(() => {
    if (!_.isEmpty(wallet)) {
      reset({
        name: wallet?.name || "",
        ipnUrl: wallet?.ipnUrl || "",
        username: wallet?.username || "",
        password: wallet?.password || "",
      });
    }
  }, [wallet, reset]);

  const onClose = async () => {
    reset();
    await dispatch(
      toggleWalletModal({
        editCollectionWallet: false,
      })
    );
  };

  const onSubmit: SubmitHandler<Wallet> = async (props) => {
    try {
      let p = {
        ...props,
        id: wallet?.id,
      };
      await dispatch(updateCollectionWallet(resellerId, p, props));
      await onClose();
      await dispatch(
        openNotification({
          openNotification: true,
          type: "success",
          message: "IPN URL updated!",
        })
      );
    } catch (e) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Something went wrong",
        })
      );
    }
  };
  return (
    <Modal
      formId="update_collection_wallet_form"
      btnType="submit"
      modalTitle="Update Sub Wallet"
      isOpen={walletModals.editCollectionWallet}
      onClose={onClose}
      btnLoading={walletsLoaders.submit}
    >
      <form
        id="update_collection_wallet_form"
        className="space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="name"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="name"
              label="Name"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="ipnUrl"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="ipnUrl"
              label="IPN Url"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="username"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="username"
              label="User name"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="password"
              label="Password"
              type="password"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
      </form>
    </Modal>
  );
};
