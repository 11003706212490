import { joiResolver } from "@hookform/resolvers/joi";
import _ from "lodash";
import { FC, useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { schemaValidation } from "../../../constants";
import { openNotification } from "../../../domains/App";
import {
  InitialResellerDfsCommissionRateState,
  toggleResellerDfsCommissionRateModal,
  updateResellerDfsCommissionRate,
} from "../../../domains/Reseller/ResellerDfsCommissionRate";
import { Modal } from "../../App";
import { CheckBox2, Select, TextField } from "../../App/FormComponents";

interface ResellerDfsCommissionRate {
  type: string;
  starting: number;
  ending: number;
  amount: number;
  enabled: boolean;
}

interface EditResellerDfsCommissionRateFormProps {
  resellerDfsCommissionRate: any;
}

export const EditResellerDfsCommissionRateForm: FC<EditResellerDfsCommissionRateFormProps> =
  ({ resellerDfsCommissionRate }) => {
    const dispatch = useDispatch();
    const {
      resellerDfsCommissionRateModals,
      resellerDfsCommissionRatesLoaders,
    } = useSelector(
      (state: {
        resellerDfsCommissionRate: InitialResellerDfsCommissionRateState;
      }) => state.resellerDfsCommissionRate
    );
    const { dfsId = "" } = useParams<{ dfsId?: string }>();

    const { control, handleSubmit, reset } = useForm<ResellerDfsCommissionRate>(
      {
        resolver: joiResolver(schemaValidation.ResellerDfsRateSchema),
        defaultValues: {
          type: "",
          amount: 0,
          starting: 0,
          ending: 0,
          enabled: false,
        },
      }
    );

    useEffect(() => {
      if (!_.isEmpty(resellerDfsCommissionRate)) {
        reset({
          type: resellerDfsCommissionRate?.type || "",
          amount: resellerDfsCommissionRate?.amount || 0,
          starting: resellerDfsCommissionRate?.starting || 0,
          ending: resellerDfsCommissionRate?.ending || "",
          enabled: resellerDfsCommissionRate?.enabled || "",
        });
      }
    }, [resellerDfsCommissionRate, reset]);

    const onClose = async () => {
      reset();
      await dispatch(
        toggleResellerDfsCommissionRateModal({
          editResellerDfsCommissionRate: false,
        })
      );
    };

    const onSubmit: SubmitHandler<ResellerDfsCommissionRate> = async (
      props
    ) => {
      try {
        await dispatch(
          updateResellerDfsCommissionRate(
            dfsId,
            resellerDfsCommissionRate?.id,
            props
          )
        );
        await onClose();
        await dispatch(
          openNotification({
            openNotification: true,
            type: "success",
            message: "Product Commission Rate updated!",
          })
        );
      } catch (e) {
        dispatch(
          openNotification({
            openNotification: true,
            type: "error",
            message: "Something went wrong",
          })
        );
      }
    };
    return (
      <Modal
        formId="edit_reseller_dfs_commission_rate_form"
        btnType="submit"
        modalTitle="Edit Product Commission Rate"
        isOpen={resellerDfsCommissionRateModals.editResellerDfsCommissionRate}
        onClose={onClose}
        btnLoading={resellerDfsCommissionRatesLoaders.submit}
      >
        <form
          id="edit_reseller_dfs_commission_rate_form"
          className="space-y-2"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            control={control}
            name="type"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Select
                id="type"
                label="Fee Type"
                selectedOption={value}
                error={error?.message}
                onChange={onChange}
                options={[
                  {
                    value: "PERCENTAGE",
                    label: "PERCENTAGE",
                  },
                  {
                    value: "ABS_VAL",
                    label: "ABS_VAL",
                  },
                  {
                    value: "NONE",
                    label: "NONE",
                  },
                ]}
              />
            )}
          />

          <Controller
            control={control}
            name="starting"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id="starting"
                label="Starting"
                type="number"
                value={value}
                error={error?.message}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="ending"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id="ending"
                label="Ending"
                type="number"
                value={value}
                error={error?.message}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="amount"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id="amount"
                label="Amount"
                type="number"
                value={value}
                error={error?.message}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="enabled"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <CheckBox2
                name="enabled"
                label="Enabled"
                value={value}
                onChange={onChange}
              />
            )}
          />
        </form>
      </Modal>
    );
  };
