import {
  ChatIcon,
  CodeIcon,
  CreditCardIcon,
  CubeIcon,
  PresentationChartLineIcon,
} from "@heroicons/react/outline";
import { FC } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Navbar, Sidebar } from ".";
import { sidebarNavigation, userMenuNavigation } from "../../constants";
import { SmallScreenDeviceSidebar } from "./SmallScreenDevices";

const iconMapping: Record<
  string,
  FC<{
    className: string;
  }>
> = {
  dashboard: ChatIcon,
  product: CubeIcon,
  loans: CreditCardIcon,
  wallet: CodeIcon,
  report: PresentationChartLineIcon,
};

export const Layout: FC = ({ children }) => {
  const isAuthenticated = useSelector<any>(
    (state) => state.auth.isAuthenticated
  );
  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <SmallScreenDeviceSidebar
        navigation={sidebarNavigation}
        iconMapping={iconMapping}
      />
      <Sidebar navigation={sidebarNavigation} iconMapping={iconMapping} />
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <Navbar navigation={userMenuNavigation} />
        <main className="flex-1 relative overflow-y-auto focus:outline-none">
          <div className="py-6">
            <div className="max-w-7xl mx-auto px-2 md:px-4">{children}</div>
          </div>
        </main>
      </div>
    </div>
  );
};
