import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import _ from "lodash";
import {
  Layout,
  SuccessOrErrorState,
  GoBackLink,
  Banners,
  Button,
} from "../../App";
import { UsersTable } from "./UsersTable";
import { config } from "../../../constants";
import {
  fetchGroup,
  fetchContacts,
  InitialUserStateProps,
  toggleUserModal,
} from "../../../domains/Users";
import { ContactForm } from "./ContactForm";
import { ContactUpload } from "./ContactUpload";

export const GroupOverviewPage: FC = () => {
  const dispatch = useDispatch();
  const { userLoader, selectedGroup, contacts, contactPagination } =
    useSelector((state: { users: InitialUserStateProps }) => state.users);
  const { groupId } = useParams<{ groupId: string }>();

  useEffect(() => {
    const fetchGroupAsync = () => {
      dispatch(fetchGroup(groupId));
      dispatch(fetchContacts(groupId));
    };
    fetchGroupAsync();
  }, [dispatch, groupId]);

  const handlePagination = (page: number) => {
    dispatch(
      fetchContacts(groupId, {
        page: page - 1,
        size: contactPagination.size,
      })
    );
  };

  const handleAction = (modalType: string) => {
    dispatch(
      toggleUserModal({
        [modalType]: true,
      })
    );
  };

  return (
    <Layout>
      <div>
        <Banners
          withAction
          actionLabel="Add Contact"
          onClick={() => handleAction("contactModal")}
        >
          <div className="flex items-center w-full">
            <GoBackLink path="/messages/groups" />
            <Button
              btnClass="ml-auto mr-2"
              onClick={() => handleAction("contactUpload")}
            >
              Upload Contacts
            </Button>
          </div>
        </Banners>
      </div>
      <ContactForm />
      <ContactUpload />
      <div className="pt-6">
        {_.isEmpty(contacts) &&
        !userLoader.fetchGroup &&
        !userLoader.fetchContacts ? (
          <SuccessOrErrorState
            state="empty"
            message="No contacts found"
            withAction
            actionLabel="Add Contact"
            onClick={() => handleAction("contactModal")}
          />
        ) : (
          <UsersTable
            title={selectedGroup?.name}
            columns={config.users.groupOverview.listColumns}
            pagination={contactPagination}
            data={contacts}
            loading={userLoader.fetchGroup || userLoader.fetchContacts}
            onPaginationChange={handlePagination}
          />
        )}
      </div>
    </Layout>
  );
};
