import { FC, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { CheckBox2, Select, TextField } from "../../App/FormComponents";
import { Modal } from "../../App";
import {
  InitialCommissionFeeRateState,
  toggleCommissionFeeRateModal,
  updateCommissionFeeRate,
} from "../../../domains/CommissionFeeRate";
import { openNotification } from "../../../domains/App";
import { schemaValidation } from "../../../constants";
import _ from "lodash";

interface CommissionFeeRate {
  feeType: string;
  feeAmount: number;
  startingInclusive: number;
  endingInclusive: number;
  enabled: boolean;
  collectionType: string;
}

interface EditCommissionFeeRateFormProps {
  commissionFeeRate: any;
}

export const EditCommissionFeeRateForm: FC<EditCommissionFeeRateFormProps> = ({
  commissionFeeRate,
}) => {
  const dispatch = useDispatch();
  const { commissionFeeRateModals, commissionFeeRatesLoaders } = useSelector(
    (state: { commissionFeeRate: InitialCommissionFeeRateState }) =>
      state.commissionFeeRate
  );
  // const { productId = "" } = useParams<{ productId?: string }>();
  const { control, handleSubmit, reset } = useForm<CommissionFeeRate>({
    resolver: joiResolver(schemaValidation.CommissionFeeRateSchema),
    defaultValues: {
      feeType: "",
      feeAmount: 0,
      startingInclusive: 0,
      endingInclusive: 0,
      collectionType: "MARK_UP",
    },
  });

  useEffect(() => {
    if (!_.isEmpty(commissionFeeRate)) {
      reset({
        feeType: commissionFeeRate?.feeType || "",
        feeAmount: commissionFeeRate?.feeAmount || "",
        startingInclusive: commissionFeeRate?.startingInclusive || "",
        endingInclusive: commissionFeeRate?.endingInclusive || "",
        enabled: commissionFeeRate?.enabled || "",
        collectionType: "MARK_UP",
      });
    }
  }, [commissionFeeRate, reset]);

  const onClose = async () => {
    reset();
    await dispatch(
      toggleCommissionFeeRateModal({
        editCommissionFeeRate: false,
      })
    );
  };

  const onSubmit: SubmitHandler<CommissionFeeRate> = async (props) => {
    try {
      commissionFeeRate = {
        ...commissionFeeRate,
        feeType: props?.feeType,
        feeAmount: props?.feeAmount,
        startingInclusive: props?.startingInclusive,
        endingInclusive: props?.endingInclusive,
        enabled: props?.enabled,
      };
      await dispatch(updateCommissionFeeRate(commissionFeeRate, props));
      await onClose();
      await dispatch(
        openNotification({
          openNotification: true,
          type: "success",
          message: "Commission Fee Rate updated!",
        })
      );
    } catch (e) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Something went wrong",
        })
      );
    }
  };
  return (
    <Modal
      formId="edit_commission_fee_rate_form"
      btnType="submit"
      modalTitle="Edit Commission Fee Rate"
      isOpen={commissionFeeRateModals.editCommissionFeeRate}
      onClose={onClose}
      btnLoading={commissionFeeRatesLoaders.submit}
    >
      <form
        id="edit_commission_fee_rate_form"
        className="space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="feeType"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Select
              id="feeType"
              label="Fee Type"
              selectedOption={value}
              error={error?.message}
              onChange={onChange}
              options={[
                {
                  value: "PERCENTAGE",
                  label: "PERCENTAGE",
                },
                {
                  value: "ABS_VAL",
                  label: "ABS_VAL",
                },
                // {
                //   value: "RATIO",
                //   label: "RATIO",
                // },
                {
                  value: "NONE",
                  label: "NONE",
                },
              ]}
            />
          )}
        />
        <Controller
          control={control}
          name="feeAmount"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="feeAmount"
              label="Fee Amount"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="startingInclusive"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="startingInclusive"
              label="Starting Inclusive"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="endingInclusive"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="endingInclusive"
              label="Ending Inclusive"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="enabled"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <CheckBox2
              label="Enabled"
              value={value}
              onChange={onChange}
              name="enabled"
            />
          )}
        />

        <Controller
          control={control}
          name="collectionType"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="collectionType"
              type="hidden"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
      </form>
    </Modal>
  );
};
