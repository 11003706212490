import { Types } from "./actionTypes";
import { getURL } from "../../utils";

export const toggleBatchTypeModal = (payload: any) => async (dispatch: any) =>
  dispatch({
    type: Types.TOGGLE_BATCHTYPE_MODALS,
    payload,
  });

export const fetchBatchAllTypes = () => async (dispatch: any) =>
  await dispatch({
    type: Types.FETCH_BATCHTYPES,
    payload: {
      request: {
        method: "GET",
        url: getURL("/wallets/v1/batch-types", { size: 100 }),
        useCache: true,
      },
    },
  });

export const fetchBatchTypes = (pagination?: any) => async (dispatch: any) =>
  await dispatch({
    type: Types.FETCH_BATCHTYPES,
    payload: {
      request: {
        method: "GET",
        url: getURL("/wallets/v1/batch-types", pagination),
        useCache: true,
      },
    },
  });

export const createBatchType = (data: any) => async (dispatch: any) => {
  await dispatch({
    type: Types.CREATE_BATCHTYPE,
    payload: {
      request: {
        url: `/wallets/v1/batch-types`,
        method: "POST",
        data,
      },
    },
  });
};

export const updateBatchType =
  (templateId: string, data: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.UPDATE_BATCHTYPE,
      payload: {
        request: {
          url: `/wallets/v1/batch-types/${templateId}`,
          method: "PUT",
          data,
        },
      },
    });

export const deleteBatchType = (spProperty: any) => async (dispatch: any) =>
  await dispatch({
    type: Types.DELETE_BATCHTYPE,
    payload: {
      request: {
        url: `/wallets/v1/batch-types/${spProperty.id}`,
        method: "DELETE",
        deleteProp: spProperty,
      },
    },
  });
