import { FC } from "react";
import classnames from "classnames";

interface Option {
  id: string;
  label: string;
}

interface BadgesProps {
  options: Option[];
  onChange: (option: any) => void;
  selectedOption?: Option;
}

export const Badges: FC<BadgesProps> = ({
  options,
  onChange,
  selectedOption,
}) => {
  const selectBadge = (option: Option) => () => onChange(option);

  return (
    <div className="py-3">
      <div className="flex flex-wrap space-y-1 space-x-4">
        {options.map((option) => {
          return (
            <span
              className={classnames(
                "inline-flex items-center px-3 py-1 rounded-full text-sm font-medium cursor-pointer mb-2",
                {
                  "bg-gray-100 text-gray-800": selectedOption?.id !== option.id,
                  "bg-indigo-100 text-tandaPurple2":
                    selectedOption?.id === option.id,
                }
              )}
              onClick={selectBadge(option)}
            >
              {option.label}
            </span>
          );
        })}
      </div>
    </div>
  );
};
