import { Types } from "./actionTypes";
import { getURL } from "../../utils";

export const toggleServiceProviderModal = (payload: any) => async (dispatch: any) =>
  dispatch({
    type: Types.TOGGLE_SERVICEPROVIDER_MODALS,
    payload,
  });

export const fetchServiceProviders = (pagination?: any) => async (dispatch: any) =>
  await dispatch({
    type: Types.FETCH_SERVICEPROVIDERS,
    payload: {
      request: {
        method: "GET",
        // url: getURL("/api/service-providers", pagination),
        url: getURL("/wallets/v1/sps", pagination),
        useCache: true,
      },
    },
  });

export const createServiceProvider = (data: any) => async (dispatch: any) =>
{
  await dispatch({
    type: Types.CREATE_SERVICEPROVIDER,
    payload: {
      request: {
        url: `/api/service-providers`,
        method: "POST",
        data,
      },
    },
  });
};

export const updateServiceProvider =
  (templateId: string, data: any) => async (dispatch: any) =>
    await dispatch({
      type: Types.UPDATE_SERVICEPROVIDER,
      payload: {
        request: {
          url: `/mc-messaging/v1/message-templates/${templateId}`,
          method: "PUT",
          data,
        },
      },
    });

export const deleteServiceProvider = (spProperty: any) => async (dispatch: any) =>
  await dispatch({
    type: Types.DELETE_SERVICEPROVIDER,
    payload: {
      request: {
        url: `/mc-messaging/v1/sp-props/${spProperty.id}`,
        method: "DELETE",
        deleteProp: spProperty,
      },
    },
  });