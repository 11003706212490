import { FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { TextField } from "../../App/FormComponents";
import { Modal } from "../../App";
import { createBatchType } from "../../../domains/BatchType";
import { openNotification } from "../../../domains/App";
import { schemaValidation } from "../../../constants";
import {
  InitialBatchTypeState,
  toggleBatchTypeModal,
} from "../../../domains/BatchType";

interface BatchType {
  name: string;
  number: number;
  type: string;
  withdrawable: boolean;
}

export const BatchTypeForm: FC = () => {
  const dispatch = useDispatch();
  const { batchTypeModals, batchTypesLoaders } = useSelector(
    (state: { batchType: InitialBatchTypeState }) => state.batchType
  );
  const { control, handleSubmit, reset } = useForm<BatchType>({
    resolver: joiResolver(schemaValidation.BatchTypeSchema),
    defaultValues: {
      name: "",
      number: 0,
      type: "",
      withdrawable: false,
    },
  });

  const onClose = async () => {
    reset();
    await dispatch(
      toggleBatchTypeModal({
        addBatchType: false,
      })
    );
  };

  const onSubmit: SubmitHandler<BatchType> = async (props) => {
    try {
      await dispatch(createBatchType(props));
      await onClose();
      await dispatch(
        openNotification({
          openNotification: true,
          type: "success",
          message: "Batch Type added!",
        })
      );
    } catch (e) {
      dispatch(
        openNotification({
          openNotification: true,
          type: "error",
          message: "Something went wrong",
        })
      );
    }
  };
  return (
    <Modal
      formId="batch_type_form"
      btnType="submit"
      modalTitle="Add a BatchType"
      isOpen={batchTypeModals.addBatchType}
      onClose={onClose}
      btnLoading={batchTypesLoaders.submit}
    >
      <form
        id="batch_type_form"
        className="space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="name"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="name"
              label="Name"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="number"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id="number"
              label="Number"
              type="text"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
      </form>
    </Modal>
  );
};
