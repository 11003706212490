import { FC } from "react";
import classNames from "classnames";
import { Loader } from ".";

interface ButtonProps {
  form?: string;
  loading?: boolean;
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  size?: "md" | "lg" | "sm" | "xs" | "xl";
  variant?: "primary" | "secondary" | "default" | "danger" | "disable";
  ref?: any;
  btnClass?: string;
}

const colors = {
  primary: {
    btn: "inline-flex items-center border border-transparent rounded shadow-sm text-white bg-tandaPurple hover:bg-tandaPurpleLight focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-tandaPurple",
    loader: "white",
  },
  secondary: {
    btn: "inline-flex items-center border border-transparent rounded shadow-sm text-white bg-tandaGreen hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500",
    loader: "white",
  },
  default: {
    btn: "inline-flex items-center border border-gray-300 rounded shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-tandaPurple",
    loader: "gray",
  },
  danger: {
    btn: "inline-flex items-center border border-transparent rounded shadow-sm text-white bg-tandaRed hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500",
    loader: "white",
  },
  disable: {
    btn: "inline-flex items-center border border-transparent rounded shadow-sm text-white bg-gray-400 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500",
    loader: "white",
  },
};

const sizes = {
  xs: "px-2.5 py-1.5 text-xs font-medium",
  sm: "px-3 py-2 text-sm font-medium leading-4",
  md: "px-4 py-2 text-sm font-medium",
  lg: "px-4 py-2 text-base font-medium",
  xl: "px-6 py-3 text-base font-medium",
};

export const Button: FC<ButtonProps> = ({
  children,
  size = "md",
  loading = false,
  variant = "default",
  btnClass = "",
  type = "button",
  ...props
}) => {
  const { btn, loader } = colors[variant];
  const sizeColors = sizes[size];
  return (
    <button
      {...props}
      type={type}
      className={classNames(btn, sizeColors, btnClass)}
    >
      {loading && <Loader size={6} color={loader} />}
      {children}
    </button>
  );
};
